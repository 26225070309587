import React from 'react';
import './Export.css'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
export default function Export(){
    return(
        <div>
            <h1 id='cohead'> Wholesale farm producers </h1>

<div className='cocounut'>
<h1>Coconut</h1>
<div className='cocounut-card'>
    <div>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../../img/acoco.webp" />
      <Card.Body>
        <Card.Title>A-Type</Card.Title>
        <Card.Text>
        Tall coconut trees typically produce larger coconuts compared to dwarf varieties
        </Card.Text>
        <Button variant="success">Buy now</Button>
      </Card.Body>
    </Card>
    </div>
    <div>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top"  src="../../img/bcoco.webp" />
      <Card.Body>
        <Card.Title>B-Type</Card.Title>
        <Card.Text>
        Dwarf coconut trees produce smaller coconuts with a higher ratio of flesh to water.
        </Card.Text>
        <Button variant="success">Buy now</Button>
      </Card.Body>
    </Card>
    </div>
    <div>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top"  src="../../img/ccoco.webp" />
      <Card.Body>
        <Card.Title>C-Type</Card.Title>
        <Card.Text>
        Hybrid coconuts are a result of crossbreeding between tall and dwarf coconut varieties.
        </Card.Text>
        <Button variant="success">Buy now</Button>
      </Card.Body>
    </Card>
    </div>
</div>

</div>

<div className='Rise'>
<h1>Rice</h1>
<div className='Rise-card'>
    <div>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../../img/arice.webp" />
      <Card.Body>
        <Card.Title>Long-Grain Rice</Card.Title>
        <Card.Text>
        Long-grain rice has slender, elongated grains that stay separate and fluffy when cooked
        </Card.Text>
        <Button variant="success">Buy now</Button>
      </Card.Body>
    </Card>
    </div>
    <div>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top"  src="../../img/brice.webp" />
      <Card.Body>
        <Card.Title>Medium-Grain Rice</Card.Title>
        <Card.Text>
        Medium-grain rice has grains that are shorter and plumper than long-grain rice.
        </Card.Text>
        <Button variant="success">Buy now</Button>
      </Card.Body>
    </Card>
    </div>
    <div>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top"  src="../../img/crice.webp" />
      <Card.Body>
        <Card.Title>Short-Grain Rice</Card.Title>
        <Card.Text>
        hort-grain rice has short, plump grains that are almost round. 
        </Card.Text>
        <Button variant="success">Buy now</Button>
      </Card.Body>
    </Card>
    </div>
</div>

</div>

            <div>

            
            {/*
            
            //--------card----------- //

            <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Card Title</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
      </Card.Body>
    </Card> */}

            </div>
           
        </div>
    );
}