import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


export default function Ecofriendly() {
    return (
        <div>

            
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
{/* 
            <div  id="language" >

        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a>
        

        </div> */}

<h1 id="mhead">Zha Sustainable Certification Process</h1>
<p className="freecenter">“Free Certification with Zha Compliance”</p>
   
   <div className='second-mcontent'>
           <img src='../../img/certificate.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>To obtain an eco-friendly certification for builders & realtors, follow these general steps:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>Research Zha Acknowledgement Programs : </span> Our reputable eco-friendly certification programs applicable to the construction industry, Research On Establishment Of Environmental Assessment Standards 

</li>

<li><span>Understand Requirements : </span>  Familiarize yourself with the specific criteria and requirements of the chosen certification. This may include sustainable building materials, energy efficiency, water conservation, and indoor air quality.

</li>
<li><span>Training and Education : </span>  Ensure that your team is educated on sustainable construction practices. Some certifications may require certain training for key personnel.

</li>
<li><span>Implement Sustainable Practices : </span>  Integrate eco-friendly practices into your construction processes. This might involve using recycled materials, optimizing energy usage, and adopting green building techniques.

</li>
<li><span>Documentation : </span> Keep thorough records of your sustainable efforts. Documentation is crucial for certification, showcasing compliance with the established standards.

</li>
<li><span>Engage Stakeholders : </span> Involve all stakeholders, including contractors, suppliers, and workers, in the eco-friendly initiatives. Everyone should be aligned with the goal of achieving certification.

</li>
<li><span>Pre-Assessment : </span> Consider a pre-assessment to identify areas that may need improvement before the official certification process. This step can help address any shortcomings and increase the chances of success.

</li>
<li><span>Formal Application : </span> Submit a formal application to the certification body. Include all necessary documentation, evidence, and fees as required.


</li>
<li><span>Site Inspection : </span> Prepare for a site inspection by representatives from the certification body. They will assess whether your construction project aligns with the specified eco-friendly standards.

</li>
<li><span>Certification Decision : </span> Await the certification decision. If successful, you will receive formal recognition of your project's eco-friendly status.

</li>

<li><span>Promote Certification : </span> Once certified, use the eco-friendly label to promote your construction projects. This can enhance your company's reputation and attract environmentally conscious clients.
</li>

<li><span>Add Zha Certification Sign in the Promotions : </span> Once you receive a confirmation email from ZHA Foundation on ZHA eco-friendly certification, the builder can publish it in its promotion and advertisements. ZHA Foundation website also lists the Builders Name and the Certification Number who have been certified as "Zha Eco-Friendly Sustainability Constructor."</li>

</ol>



<p>Remember, the specific steps may vary depending on the certification program chosen, so it's essential to thoroughly review the requirements of the selected certification body. </p>


</section>
 

</div>
 
 
);
}

