import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


export default function Ecofriendly() {
    return (
        <div>

            
            
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Wellness-Program">English</NavLink></a>
         
        </div>
{/* 
            <div  id="language" >

        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a>
        

        </div> */}

<h1 id="mhead">ಝಾ ಸ್ವಾಸ್ಥ್ಯ ಕಾರ್ಯಕ್ರಮ</h1>

<div className='second-mcontent'>
           <img src='../../img/self.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>ಝಾ ಅವರ ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಸ್ವಾಸ್ಥ್ಯ ಉಚಿತ ತರಬೇತಿಯು ವೈವಿಧ್ಯಮಯ ಚಟುವಟಿಕೆಗಳನ್ನು ಒಳಗೊಳ್ಳುತ್ತದೆ, ಯೋಗಕ್ಷೇಮಕ್ಕೆ ಸಮಗ್ರ ವಿಧಾನವನ್ನು ಖಾತ್ರಿಪಡಿಸುತ್ತದೆ. ಇದು ಒಳಗೊಂಡಿದೆ:


</p>
 
<ol className="goalpoints">

	
   
   <li><span>ಅಥ್ಲೆಟಿಕ್ ತರಬೇತಿ ಕಾರ್ಯಕ್ರಮಗಳು :</span> ಮ್ಯಾರಥಾನ್, ಸೈಕ್ಲಿಂಗ್ ಮತ್ತು ವಾಲಿಬಾಲ್‌ನಂತಹ ವಿವಿಧ ವಿಭಾಗಗಳನ್ನು ಒಳಗೊಂಡ ದೈಹಿಕ ಸದೃಢತೆಗೆ ತಕ್ಕಂತೆ ಕಾರ್ಯಕ್ರಮಗಳು.

</li>

<li><span>ಮೈಂಡ್‌ಫುಲ್ ಚಟುವಟಿಕೆಗಳು : </span>  ದೈಹಿಕ ತರಬೇತಿಯ ಹೊರತಾಗಿ, ಭಾಗವಹಿಸುವವರು ಧ್ಯಾನ ಮತ್ತು ಯೋಗದಂತಹ ಮಾನಸಿಕ ಸ್ವಾಸ್ಥ್ಯ ಚಟುವಟಿಕೆಗಳಿಂದ ಪ್ರಯೋಜನ ಪಡೆಯುತ್ತಾರೆ.
 
</li>
<li><span>ಒಳಾಂಗಣ ಚೆಸ್ :</span> ಚೆಸ್‌ನಂತಹ ಕಾರ್ಯತಂತ್ರದ ಆಟಗಳ ಮೂಲಕ ಮನಸ್ಸನ್ನು ತೊಡಗಿಸಿಕೊಳ್ಳುವುದು, ಅರಿವಿನ ಬೆಳವಣಿಗೆ ಮತ್ತು ಮಾನಸಿಕ ಚುರುಕುತನವನ್ನು ಉತ್ತೇಜಿಸುವುದು.
</li>
<p>
ಝಾ ಕ್ಷೇಮ ಕಾರ್ಯಕ್ರಮವು ಯೋಗಕ್ಷೇಮದ ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಅಂಶಗಳೆರಡನ್ನೂ ತಿಳಿಸುವ ಸಮಗ್ರ ವಿಧಾನವನ್ನು ಹೊಂದಿದೆ, ತರಬೇತಿ ಕಾರ್ಯಕ್ರಮದಲ್ಲಿ ಭಾಗವಹಿಸುವ ವ್ಯಕ್ತಿಗಳಿಗೆ ಸಮತೋಲಿತ ಮತ್ತು ಆರೋಗ್ಯಕರ ಜೀವನಶೈಲಿಯನ್ನು ಪೋಷಿಸುತ್ತದೆ.</p>

<p>ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಗ್ರಾಮೀಣ ಮತ್ತು ಉಪನಗರ ಪ್ರದೇಶಗಳಲ್ಲಿ ಝಾ ಉಚಿತ ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಸ್ವಾಸ್ಥ್ಯ ತರಬೇತಿ ಹಲವಾರು ಪ್ರಯೋಜನಗಳನ್ನು ನೀಡುತ್ತದೆ:</p>

</ol>
        
 

<ol className="goalpoints"> 


<li><span>ಸಂಪನ್ಮೂಲಗಳಿಗೆ ಪ್ರವೇಶ :</span> ಗ್ರಾಮೀಣ ಪ್ರದೇಶದ ವಿದ್ಯಾರ್ಥಿಗಳು ಹಣಕಾಸಿನ ನಿರ್ಬಂಧಗಳ ಕಾರಣದಿಂದಾಗಿ ಅವರು ಹೊಂದಿರದ ತರಬೇತಿ ಕಾರ್ಯಕ್ರಮಗಳಿಗೆ ಪ್ರವೇಶವನ್ನು ಪಡೆಯುತ್ತಾರೆ, ಯೋಗಕ್ಷೇಮಕ್ಕಾಗಿ ಸಮಾನ ಅವಕಾಶಗಳನ್ನು ಉತ್ತೇಜಿಸುತ್ತಾರೆ.


</li>
<li><span>ಸುಧಾರಿತ ದೈಹಿಕ ಆರೋಗ್ಯ : </span>ಮ್ಯಾರಥಾನ್‌ಗಳು, ಸೈಕ್ಲಿಂಗ್ ಮತ್ತು ವಾಲಿಬಾಲ್‌ನಂತಹ ಅಥ್ಲೆಟಿಕ್ ತರಬೇತಿ ಕಾರ್ಯಕ್ರಮಗಳು ವರ್ಧಿತ ದೈಹಿಕ ಸಾಮರ್ಥ್ಯಕ್ಕೆ ಕೊಡುಗೆ ನೀಡುತ್ತವೆ, ಸಕ್ರಿಯ ಜೀವನಶೈಲಿಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತವೆ.


</li>
<li><span>ವರ್ಧಿತ ಮಾನಸಿಕ ಯೋಗಕ್ಷೇಮ :</span> ಧ್ಯಾನ, ಯೋಗ ತರಗತಿಗಳು ಮತ್ತು ಒಳಾಂಗಣ ಚದುರಂಗವು ಒತ್ತಡ ಪರಿಹಾರ, ಸುಧಾರಿತ ಗಮನ ಮತ್ತು ಒಟ್ಟಾರೆ ಮಾನಸಿಕ ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವಕ್ಕೆ ಸಾಧನಗಳನ್ನು ಒದಗಿಸುತ್ತದೆ.
</li>
<li><span>ಕೌಶಲ್ಯ ಅಭಿವೃದ್ಧಿ : </span>ವೈವಿಧ್ಯಮಯ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಭಾಗವಹಿಸುವಿಕೆಯು ತಂಡದ ಕೆಲಸ, ಶಿಸ್ತು ಮತ್ತು ಕಾರ್ಯತಂತ್ರದ ಚಿಂತನೆ ಸೇರಿದಂತೆ ವ್ಯಾಪಕ ಶ್ರೇಣಿಯ ಕೌಶಲ್ಯಗಳ ಅಭಿವೃದ್ಧಿಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.
</li>
<li><span>ಸಮುದಾಯ ನಿರ್ಮಾಣ : </span>ಸ್ವಾಸ್ಥ್ಯ ಕಾರ್ಯಕ್ರಮಗಳು ಭಾಗವಹಿಸುವವರಲ್ಲಿ ಸಮುದಾಯದ ಪ್ರಜ್ಞೆಯನ್ನು ಸೃಷ್ಟಿಸುತ್ತವೆ, ಸಾಮಾಜಿಕ ಸಂಪರ್ಕಗಳು ಮತ್ತು ಬೆಂಬಲ ನೆಟ್‌ವರ್ಕ್‌ಗಳನ್ನು ಉತ್ತೇಜಿಸುತ್ತವೆ.
</li>
<li><span>ಸಬಲೀಕರಣ :</span> ತರಬೇತಿಯು ಸವಾಲುಗಳನ್ನು ನ್ಯಾವಿಗೇಟ್ ಮಾಡಲು ಮತ್ತು ಜೀವನದ ವಿವಿಧ ಅಂಶಗಳಲ್ಲಿ ಯಶಸ್ವಿಯಾಗಲು ದೈಹಿಕ ಮತ್ತು ಮಾನಸಿಕ ಎರಡೂ ಸಾಧನಗಳೊಂದಿಗೆ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಸಜ್ಜುಗೊಳಿಸುವ ಮೂಲಕ ಅವರನ್ನು ಸಬಲಗೊಳಿಸುತ್ತದೆ.
</li>
<li><span>ಆರೋಗ್ಯಕರ ಜೀವನಶೈಲಿಯನ್ನು ಉತ್ತೇಜಿಸುವುದು : </span>ದೈಹಿಕ ಚಟುವಟಿಕೆ ಮತ್ತು ಮಾನಸಿಕ ಆರೋಗ್ಯ ಅಭ್ಯಾಸಗಳನ್ನು ಉತ್ತೇಜಿಸುವ ಮೂಲಕ, ತರಬೇತಿಯು ಆರೋಗ್ಯಕರ ಜೀವನಶೈಲಿಯನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳಲು ಕೊಡುಗೆ ನೀಡುತ್ತದೆ, ಜೀವನಶೈಲಿ-ಸಂಬಂಧಿತ ಆರೋಗ್ಯ ಸಮಸ್ಯೆಗಳ ಅಪಾಯವನ್ನು ಕಡಿಮೆ ಮಾಡುತ್ತದೆ.
</li>


</ol>




<p>
ಒಟ್ಟಾರೆಯಾಗಿ, ಉಚಿತ ಕೋಚಿಂಗ್ ಕಾರ್ಯಕ್ರಮಗಳು ಗ್ರಾಮೀಣ ಮತ್ತು ಉಪನಗರ ಪ್ರದೇಶಗಳಲ್ಲಿನ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಉನ್ನತೀಕರಿಸುವ ಮತ್ತು ಸಬಲೀಕರಣಗೊಳಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿವೆ, ಅವರ ಸಮಗ್ರ ಯೋಗಕ್ಷೇಮವನ್ನು ಪೋಷಿಸುತ್ತವೆ ಮತ್ತು ಅವರಿಗೆ ಅಮೂಲ್ಯವಾದ ಜೀವನ ಕೌಶಲ್ಯಗಳನ್ನು ಒದಗಿಸುತ್ತವೆ.
 </p>


</section>
 

</div>
 
 
);
}

