import React from "react";
import "./Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';




export default function Zhasports(){




    

    return(
        <div>
            <div className="social-ma">

<h1>Zha Athletics Training Club</h1>
</div>


<header>
    <div>
    <h2>Welcome to <br></br>Zha Athletics Training Club!</h2>
    {/* <a href="https://chat.whatsapp.com/F10QKeazorbBWpAPQuf6Ym"><Button className="clickto" variant="success">Click to Join</Button>{' '}</a> */}
    </div>
    <img src="../img/MARATHON/8.webp"></img>
</header>


<section className="cycle">
    <div className="aboutcycle">
<h2>About Us
</h2>
<p>At Zha Athletics Training Club, we are dedicated to nurturing talent and promoting the sport of athletics. Our mission is to provide individuals of all ages and abilities with a platform to explore their athletic potential, set new records, and reach new heights in various track and field disciplines.</p>
</div>
</section>

<div className="cyclese2">
    <Card className="horicard" style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../img/vignesh.webp" />
      <Card.Body>
        <Card.Title>Mr.Vignesh</Card.Title>
        <Card.Text>
        Zha Sports Coach
        </Card.Text>
        {/* <a href="https://wa.link/c11b3g"><Button variant="primary">Any enquiries</Button></a> */}
      </Card.Body>
    </Card>

    <Card className="horicard" style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../img/lakshmanan.webp" />
      <Card.Body>
        <Card.Title>Mr.R.Lakshmanan</Card.Title>
        <Card.Text>
          Zha Sports Coach
        </Card.Text>
        {/* <a href="https://wa.link/c11b3g"><Button variant="primary">Any enquiries</Button></a> */}
      </Card.Body>
    </Card>
    </div>





    
<section className="whychoose">
<h3>Why Choose <span>Zha Athletics Training Club?</span></h3>
<ul>

<li><span>Expert Guidance:  </span>Our experienced coaching staff is here to provide the guidance and mentorship you need to reach your athletic goals.</li>
<li><span>Diverse Programs: </span>We cater to athletes of all ages and levels, from beginners to seasoned competitors, ensuring everyone finds a place at our club.</li>
<li><span>Competitions: </span>We offer regular opportunities to test your skills and push your limits.</li>
<li><span>Community: </span>Be part of a community of athletes who inspire and motivate each other toward greatness.</li>
<li><span>Access: </span>Enjoy the convenience of training in world-class facilities designed to elevate your performance.</li>
</ul>

</section>


<section className="cycle2">
    <div className="aboutcycle2">
<h2>Membership
</h2>
<div className="membership">
    <img src="../img/joinus.webp"></img>
<p>The monthly fee for using this club is ₹300, and members can access it for three days each week.Become a member of Zha Indoor Club and gain access to a world of indoor sports, fitness, and recreation. We offer flexible membership options to suit your individual needs, whether you're a frequent visitor or just looking to try out our facilities.

</p>
</div>
</div>
</section>




</div>



);
}