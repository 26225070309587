import React from "react";
import "../Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
export default function Zhasports(){




    

    return(
      
        <div>
            <div>
                <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sports-Training-Club">English</NavLink></a>
       



        </div>
        </div>
            <div className="social-ma">

<h1>ఝా స్పోర్ట్స్ ట్రైనింగ్ క్లబ్</h1>
</div>


<header>
    <div>
    <h2>కు స్వాగతం <br></br>ఝా స్పోర్ట్స్ ట్రైనింగ్ క్లబ్!</h2>
    {/* <p>Your Gateway to Athletic Excellence</p> */}
    </div>
    <img src="https://img.freepik.com/free-vector/summer-sports-concept_1284-9394.jpg?w=740&t=st=1697262455~exp=1697263055~hmac=01cd661d29b38dc346eff24e8983ff504c93da5e8946bdfe5e397df7e6053b94"></img>
</header>

<section className="medeatils">
<div className="aboutcycle2">
<h2>వివరాలు</h2>
<p>(నవంబర్ 14న ప్రారంభమవుతుంది)</p>
</div>
<p></p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th></th>
          <th>ఝా సైకిల్ క్లబ్</th>
          <th>ఝా అథ్లెటిక్స్ ట్రైనింగ్ క్లబ్</th>
          <th>జెహ్రా వాలీబాల్ ట్రైనింగ్ క్లబ్</th>
          <th>ఝా ఇండోర్ ట్రైనింగ్ క్లబ్ <br />(5 నుండి 16 వయస్సు మాత్రమే)</th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>నెలవారీ రుసుము</td>
         
          <td colSpan={4}>₹300 ఒక్కొక్కరికి</td>
         
        </tr>
        <tr>
          <td>పెద్దల టైమింగ్ (15 కంటే ఎక్కువ వయస్సు)</td>
          <td colSpan={4}>6AM to 10AM</td>

       
         
  
        </tr>
        <tr>
          <td>పిల్లల టైమింగ్ ( 5 నుండి 15 వయస్సు )</td>
          <td>6AM to 10AM</td>
          <td colSpan={3}>From 5PM</td>
          
        </tr>
        <tr>
          <td>రోజు</td>
          <td>వారాంతాల్లో మాత్రమే</td>
          <td colSpan={3}>వారానికి మూడుసార్లు</td>
         
        </tr>
      </tbody>
    </Table>
    </section>



<section className="parellal">
    <div className="parallax-content">
    <div className="towhithe">
<h2>మా గురించి</h2>
<p>ఝా స్పోర్ట్స్ ట్రైనింగ్ క్లబ్‌లో, గ్రామీణ ప్రాంతాల్లో క్రీడలను ప్రోత్సహించడం, దాగి ఉన్న ప్రతిభను గుర్తించడం మరియు క్రీడా ప్రపంచంలో రాణించడానికి వారికి వేదిక మరియు వనరులను అందించడం మా ప్రధాన లక్ష్యం. మా గ్రామీణ కమ్యూనిటీలలోని ప్రతి మూలలో అసాధారణ ప్రతిభ ఉందని, కనుగొనబడటానికి మరియు అభివృద్ధి చెందడానికి వేచి ఉన్నారని మేము నమ్ముతున్నాము. మా నిబద్ధత ఏమిటంటే ఆ సామర్థ్యాన్ని అన్‌లాక్ చేయడం మరియు మా అథ్లెట్లు వారి క్రీడా ఆకాంక్షల పరాకాష్టకు చేరుకోవడంలో సహాయపడటం.</p>
</div></div>
</section>



<section className="cycle2">
    <div className="aboutcycle2">
<h2>మా దృష్టి
</h2> 
<div className="membership">
    <img src="https://img.freepik.com/free-vector/businessman-looking-camera-target-it-s-like-business-looking-way-be-successful-vector-business-illustration-concept_1150-60931.jpg?w=1380&t=st=1697283092~exp=1697283692~hmac=a66770dae78b88e7c2289fde1c533f765eb3ca49a6067b53fec080eece82b27d"></img>
<p>ఝా స్పోర్ట్స్ ట్రైనింగ్ క్లబ్‌లో, మా కమ్యూనిటీ యువత జీవితాలను శక్తివంతం చేయడం మరియు మార్చడం మా దృష్టి. హానికరమైన వ్యసనాలు మరియు ఒత్తిడి లేని క్రీడల ద్వారా మా యువకులు ప్రయోజనం, ఉత్సాహం మరియు శ్రేయస్సును కనుగొనే భవిష్యత్తును సృష్టించాలని మేము కోరుకుంటున్నాము. అథ్లెటిక్ ఎక్సలెన్స్ మరియు వ్యక్తిగత ఎదుగుదల పట్ల మా అంకితభావం ద్వారా, శారీరకంగా దృఢంగా ఉండటమే కాకుండా మానసికంగా దృఢంగా, దృఢంగా మరియు ఏకాగ్రతతో కూడిన యువకుల తరాన్ని మనం పెంపొందించగలమని మేము నమ్ముతున్నాము. మా దృష్టి సానుకూల మార్పుకు ఉత్ప్రేరకం కావడమే, యువత క్రీడల వైపు మళ్లే స్ఫూర్తి, స్నేహం మరియు సాధికారత, చివరికి ఆరోగ్యకరమైన, సంతోషకరమైన మరియు మరింత శక్తివంతమైన జీవితాలకు దారితీసే ప్రపంచాన్ని పెంపొందించడం.

</p>
</div>
</div>
</section>


<section className="coretheme">
<h3>మా ప్రధాన థీమ్:<span> గ్రామీణ ప్రతిభను వెలికితీస్తోంది</span></h3>
<ul>
<li><span>క్రీడల ద్వారా యువత సాధికారత: </span>ఝా స్పోర్ట్స్ ట్రైనింగ్ క్లబ్‌లో, యువతకు క్రీడల ద్వారా సాధికారత కల్పించడం, వ్యసనం-రహిత మరియు స్థితిస్థాపక భవిష్యత్తు వైపు వారిని నడిపించడం కోసం మేము అంకితభావంతో ఉన్నాము.</li>
<li><span>గుర్తించండి:</span> మా స్కౌట్‌లు మారుమూల గ్రామాలు మరియు పట్టణాలకు ప్రయాణిస్తారు, క్రీడల పట్ల మక్కువ మరియు అసలైన ప్రతిభ ఉన్న వ్యక్తులను వెతుకుతారు.</li>
<li><span>పెంపకం:</span> మేము ఈ ప్రతిభను ఉపయోగించుకోవడానికి విస్తృతమైన శిక్షణా కార్యక్రమాలు మరియు సౌకర్యాలను అందిస్తాము, దానిని మెరుగుపరిచిన నైపుణ్యం మరియు నైపుణ్యంగా మారుస్తాము.</li>
<li><span>ఎక్సెల్</span> ఈ వర్ధమాన ప్రతిభావంతులు ప్రాంతీయ, జాతీయ మరియు అంతర్జాతీయ వేదికలపై ప్రకాశించేలా చూడడమే మా అంతిమ లక్ష్యం.
</li>
</ul>

</section>

<section className="medeatils">
<div className="aboutcycle2">
<h2>ఝా స్పోర్ట్స్ ట్రైనింగ్ క్లబ్ యొక్క నియమాలు
</h2>


 <p>1) క్లబ్ సభ్యుల మధ్య కులం లేదా మతం లేదా రాజకీయ పార్టీ లేదా హోదా గురించి చర్చించకూడదు</p> 

  <p>2) గౌరవం, నిబద్ధత, దృష్టి, సమగ్రత మరియు ఇతరులను ప్రోత్సహించడం వంటి విలువలను చూపండి</p>




</div></section>
<section className="Indoor">
    <h3>ఇండోర్ గేమ్స్</h3>

<div className="inindoor">

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/carroms-stricker-carroms-board_1353-94.jpg?w=1060&t=st=1697266133~exp=1697266733~hmac=193a552938f8bc9d95f5fb9fe521c000cdced03b1a4121a04e418230d430d6ce" />
      <Card.Body>
        <Card.Title>క్యారమ్</Card.Title>
        <Card.Text>
        ఇండోర్ క్లాసిక్, ఇది కేవలం ఆట మాత్రమే కాదు, ఖచ్చితత్వం మరియు వ్యూహం యొక్క పరీక్ష. మా క్యారమ్ ఔత్సాహికులతో చేరండి మరియు మీ నైపుణ్యాలను మెరుగుపరుచుకోండి.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/gold-silver-chess-chess-board-game-business-metaphor-leadership-concept_1150-19592.jpg?w=1060&t=st=1697265918~exp=1697266518~hmac=f4734cc77213b39552dc86a5cebd2a405cbd08682462777d3ffbc181779df389" />
      <Card.Body>
        <Card.Title>చెస్</Card.Title>
        <Card.Text>
        గ్రామీణ చెస్ ప్రాడిజీలు ప్రపంచ వేదికపై తమదైన ముద్ర వేయగల సామర్థ్యాన్ని కలిగి ఉండే తెలివి మరియు దూరదృష్టితో కూడిన గేమ్.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-people-man-woman-playing-table-tennis_93675-134489.jpg?w=1060&t=st=1697265228~exp=1697265828~hmac=54551670c7b793374f5acc44d9f553fb727750e00de361d6d2ad2b5280d4657a" />
      <Card.Body>
        <Card.Title>టేబుల్ టెన్నిస్</Card.Title>
        <Card.Text>
        వేగవంతమైన మరియు ఉత్తేజకరమైన, టేబుల్ టెన్నిస్ అనేది గ్రామీణ క్రీడాకారులు నిజంగా రాణించగల క్రీడ.
        </Card.Text>
     
      </Card.Body>
    </Card>
    
    </div>
  
</section>



<section>
    <h3>అవుట్‌డోర్ గేమ్స్</h3>

<div className="inoutdoor">
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-volleyball-man-player-court_23-2149492372.jpg?w=1060&t=st=1697265453~exp=1697266053~hmac=38b67aa1f0c569204e324ccd0a6025c358209ab74d0f8ac3f16a7b044a389ff7" />
      <Card.Body>
        <Card.Title>వాలీబాల్</Card.Title>
        <Card.Text> కమ్యూనిటీకి ఇష్టమైనది, పాల్గొనడం మరియు ప్రతిభను అభివృద్ధి చేయడం కోసం మేము సాధారణ వాలీబాల్ టోర్నమెంట్‌లను నిర్వహిస్తాము.
        </Card.Text>

      </Card.Body>
    </Card>

    {/* <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Marathon</Card.Title>
        <Card.Text>
        Take part in our marathons to test your endurance and discover the long-distance running stars of tomorrow.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
      </Card.Body>
    </Card> */}

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/different-people-participating-cross-country_23-2149157134.jpg?w=1060&t=st=1697265375~exp=1697265975~hmac=cc39d5c0cc918e43474f8a249139536b9172850571f8f2aa5b897b9215fca427" />
      <Card.Body>
        <Card.Title>అథ్లెటిక్స్</Card.Title>
        <Card.Text>
        మేము యువ క్రీడాకారులకు వివిధ ట్రాక్ మరియు ఫీల్డ్ ఈవెంట్లలో శిక్షణ ఇవ్వడానికి మరియు పోటీ పడేందుకు ఒక వేదికను అందిస్తాము.
        </Card.Text>
     
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/professional-cyclist-women_23-2149703331.jpg?w=1060&t=st=1697265287~exp=1697265887~hmac=782e175093e473ddfc0e2abd894b42673b3d40d0e72972fe4289c6e5bc083fa9" />
      <Card.Body>
        <Card.Title>సైక్లింగ్</Card.Title>
        <Card.Text>
        గ్రామీణ ప్రాంతాల నుండి పోడియం వరకు, వర్ధమాన సైక్లిస్ట్‌ల గొప్పతనాన్ని సాధించే ప్రయాణంలో వారికి మద్దతు ఇవ్వడానికి మేము ఇక్కడ ఉన్నాము.
        </Card.Text>

      </Card.Body>
    </Card>
    </div>
  
</section>





<section className="whychoose">
<h3>ఎందుకు ఎంచుకోండి <span>ఝా స్పోర్ట్స్ ట్రైనింగ్ క్లబ్‌ను ఎందుకు ఎంచుకోవాలి?</span></h3>
<ul>

<li><span>గ్రామీణ దృష్టి: </span>మా ప్రాథమిక లక్ష్యం గ్రామీణ ప్రతిభను గుర్తించడం మరియు పెంపొందించడం, ప్రతిభ ఎవరూ గుర్తించబడకుండా చూసుకోవడం.</li>
<li><span>నిపుణుల కోచింగ్: </span>మా కోచ్‌లు మీ పూర్తి సామర్థ్యాన్ని చేరుకోవడంలో మీకు సహాయం చేయడానికి మక్కువ మరియు అంకితభావంతో ఉన్నారు.</li>
<li><span>కమ్యూనిటీ బిల్డింగ్: </span>స్పోర్ట్స్‌స్మాన్‌షిప్, క్రమశిక్షణ మరియు జట్టుకృషిని విలువైన సంఘంలో చేరండి</li>
<li><span>హోలిస్టిక్ డెవలప్‌మెంట్: </span> మేము కేవలం శారీరక నైపుణ్యాలపై దృష్టి పెట్టడం లేదు; మేము క్యారెక్టర్ బిల్డింగ్ మరియు ఎడ్యుకేషన్‌ను కూడా నొక్కి చెబుతాము.</li>
<li><span>పోటీలు: </span>సాధారణ పోటీలు మరియు ఈవెంట్‌లు మా అథ్లెట్‌లకు ప్రకాశించే మరియు గుర్తింపు పొందే అవకాశాన్ని అందిస్తాయి..</li>
</ul>

</section>


<section className="cycle2">
    <div className="aboutcycle2">
<h2>సభ్యత్వం
</h2>
<div className="membership">
    <img src="../img/joinus.jpg"></img>
<p>ఈ క్లబ్‌ను ఉపయోగించడం కోసం నెలవారీ రుసుము ₹300 మరియు సభ్యులు ప్రతి వారం మూడు రోజుల పాటు దీన్ని యాక్సెస్ చేయవచ్చు. ఝా ఇండోర్ క్లబ్‌లో సభ్యుడిగా అవ్వండి మరియు ఇండోర్ క్రీడలు, ఫిట్‌నెస్ మరియు వినోద ప్రపంచానికి ప్రాప్యతను పొందండి. మీరు తరచుగా సందర్శిస్తున్నా లేదా మా సౌకర్యాలను ప్రయత్నించాలని చూస్తున్నా మీ వ్యక్తిగత అవసరాలకు అనుగుణంగా మేము సౌకర్యవంతమైన సభ్యత్వ ఎంపికలను అందిస్తున్నాము.

</p>
</div>
</div>
</section>



</div>



);
}