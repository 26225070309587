import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./Paddy.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Millet() {
    return(

        <div>
                <div  id="language" >
        
                <a href=""><NavLink to="/zhafarmerspaddyleague">English</NavLink></a>



        </div>
            <div className="social-main">

            <h1>Zha Farmers Paddy League</h1>
            </div>
           <section className="intro">
            <div className="paddyhero">
            <img src="../img/asian.jpg"></img>
            </div>
            <div className="titletext">
              <p> పెరవూరని తాలూకాలో రైతుల మధ్య రైతు వరి సంఘం నిర్వహించేందుకు ఝా ఫౌండేషన్ చొరవ తీసుకోవడం అభినందనీయమైన ప్రయత్నం. వరిని వరి అని కూడా పిలుస్తారు, ఇది భారతదేశంలో అపారమైన సాంస్కృతిక మరియు పోషకాహార ప్రాముఖ్యత కలిగిన ప్రధాన పంట. అయినప్పటికీ, ఇటీవలి సంవత్సరాలలో, దాని సాగు సవాళ్లను ఎదుర్కొంది, ఇది సాంప్రదాయ జ్ఞానం మరియు అది అందించే పోషక ప్రయోజనాలను కోల్పోవడానికి దారితీసింది. ప్రపంచవ్యాప్తంగా ఉన్న రైతులు, ఔత్సాహికులు మరియు ఆవిష్కర్తలను ఒకచోట చేర్చి, వ్యవసాయ ప్రపంచంలో విప్లవాత్మక మార్పులు తీసుకురావడానికి ఫార్మర్స్ పాడీ లీగ్ సిద్ధంగా ఉంది. IPL క్రికెట్ రూపురేఖలను మార్చినట్లే, ఝా ఫౌండేషన్ యొక్క ఫార్మర్స్ పాడీ లీగ్ అద్భుతమైన టోర్నమెంట్‌లు మరియు అత్యాధునిక సాంకేతికతలతో వరి వ్యవసాయాన్ని సరికొత్త స్థాయికి ఎదగాలని లక్ష్యంగా పెట్టుకుంది.</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/kC8byZNt8dFH4Z1e6"> <Button variant="outline-success">ఇప్పుడు నమోదు చేసుకోండి</Button>{' '}</a>

    </div>

    <div>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfkUnlmPbv846wVQGKAD41SfB_JrRIsIO3DwkMcK0Wpcpvpdw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
  </section>

  <section className="organics">

    <div className="orgcon">
      <div className="orgtext">  <h3>నాన్-ఆర్గానిక్ వరి సాగు యొక్క ప్రభావాలు</h3>
      <div className="side"></div>
      </div>
      <div className="orgflex">

      
           <ul>
        <h5>1.పర్యావరణ ప్రభావం:</h5>
        <li>నాన్-ఆర్గానిక్ వరి సాగు తరచుగా తెగుళ్లు మరియు కలుపు మొక్కలను నియంత్రించడానికి సింథటిక్ పురుగుమందులు మరియు కలుపు సంహారకాలపై ఎక్కువగా ఆధారపడుతుంది. ఈ రసాయనాలు నేల, నీరు మరియు గాలిని కలుషితం చేయడం ద్వారా పర్యావరణంపై హానికరమైన ప్రభావాలను కలిగి ఉంటాయి సింథటిక్ ఎరువుల వాడకం నేల క్షీణత, పోషక అసమతుల్యత మరియు కాలక్రమేణా నేల సారవంతం తగ్గుతుంది.</li>

        <h5>2.ఆర్థిక ప్రభావం:</h5>
        <li>నాన్-ఆర్గానిక్ ఫార్మింగ్‌కు తరచుగా సింథటిక్ ఎరువులు, పురుగుమందులు మరియు కలుపు సంహారక మందుల కోసం అధిక ఇన్‌పుట్ ఖర్చులు అవసరమవుతాయి, ఇది రైతులకు ఆర్థిక భారం కావచ్చు.</li>

        <h5>3.ఆరోగ్య ప్రభావం:</h5>
        <li>రైతులు మరియు వ్యవసాయ కార్మికులు పురుగుమందులు మరియు హెర్బిసైడ్ల నుండి రసాయన అవశేషాలకు గురికావచ్చు, ఇది ఆరోగ్య సమస్యలకు దారితీయవచ్చు.</li>

        <h5>4.కార్బన్ పాదముద్ర: </h5>
        <li>నాన్-ఆర్గానిక్ వ్యవసాయ పద్ధతులు, ముఖ్యంగా సింథటిక్ ఎరువుల వాడకంతో కూడినవి, వాతావరణ మార్పులకు దోహదపడే గ్రీన్‌హౌస్ వాయు ఉద్గారాలకు దోహదం చేస్తాయి.</li>

        <h5>5.దీర్ఘకాలిక స్థిరత్వం: </h5>
        <li>నేల క్షీణత, తగ్గిన జీవవైవిధ్యం మరియు రసాయనాలకు చీడపీడల నిరోధకత పెరగడం వల్ల సేంద్రీయేతర వరి సాగు పద్ధతులు దీర్ఘకాలికంగా నిలకడగా ఉండకపోవచ్చు.
        </li>
        </ul>

      
       
    
    <img src="../img/ori2.jpg"></img>
    </div>
    </div>
  </section>




<div className="freepik">
<h5 className="cardtitle">దీనికి సంబంధించిన కీలక అంశాల యొక్క అవలోకనం ఇక్కడ ఉంది<span> రైతులు వరి
లీగ్ </span>చొరవ:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>మా మిషన్</Card.Title>
        <Card.Text>
        పెరవురాని తాలూకాలో మరియు భారతదేశం అంతటా రైతులలో వరి సాగును ప్రోత్సహించడం మరియు పునరుద్ధరించడం మా చొరవ యొక్క ప్రధాన లక్ష్యం. వరి, తరచుగా "న్యూట్రి-తృణధాన్యాలు" అని పిలుస్తారు, దాని అసాధారణమైన పోషక విలువలు మరియు పర్యావరణ అనుకూల వ్యవసాయ పద్ధతులకు ప్రసిద్ధి చెందింది, ఇది స్థిరమైన వ్యవసాయానికి మూలస్తంభంగా మారింది.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ఝా ఫౌండేషన్ యొక్క నిబద్ధత</Card.Title>
        <Card.Text>
        మా అంకితభావం ఉన్న రైతులలో వరి వ్యవసాయాన్ని ప్రారంభించడంలో మరియు మద్దతు ఇవ్వడంలో మేము కీలక పాత్ర పోషిస్తాము. మా మద్దతులో అవసరమైన వనరులు అందించడం, సమగ్ర శిక్షణ మరియు సాంకేతిక సహాయం అందించడం ద్వారా రైతులకు వారి వ్యవసాయ పద్ధతుల్లో వరి సాగును సజావుగా చేర్చుకోవడంలో సహాయపడతాయి.

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>సేంద్రీయ వరి సాగును తిరిగి తీసుకురండి</Card.Title>
        <Card.Text>
        మా చొరవ ప్రధాన దృష్టి భారత రాష్ట్ర ప్రభుత్వంతో పాటు సేంద్రీయ వరి సాగును ప్రోత్సహించడం మరియు వ్యవసాయంలో పార్ట్ టైమ్ పనుల కోసం యువకులను సమం చేయడం. ఝా రైతులు & వినియోగదారుల కాల్ సెంటర్ ద్వారా ప్రపంచ మార్కెట్‌లో మా రైతుల ఉత్పత్తులను తయారు చేయడానికి డిజిటల్ వ్యూహాల ద్వారా సరఫరా గొలుసును సృష్టించండి. ప్రయోజనాలు చాలా రెట్లు ఉన్నాయి: ఆహార భద్రతను మెరుగుపరచడం, నేల ఆరోగ్యాన్ని మెరుగుపరచడం మరియు నీటిని ఎక్కువగా వినియోగించే సేంద్రీయ పంటలతో పోలిస్తే నీటి వినియోగాన్ని గణనీయంగా తగ్గించడం మరియు ZHA ఫౌండేషన్ ద్వారా సేంద్రీయ ఉత్పత్తులను ధృవీకరించడం</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>రైతుల పాడీ లీగ్</Card.Title>
        <Card.Text>
        మేము ఒక వినూత్నమైన కాన్సెప్ట్‌ను పరిచయం చేస్తున్నాము - "ఫార్మర్స్ పాడీ లీగ్." దరఖాస్తుల ఆధారంగా రైతులను వివిధ బృందాలుగా ఏర్పాటు చేసి, ఝా బృందం ద్వారా నిర్వహించి ప్రభుత్వ వ్యవసాయ నిపుణులచే రిఫరీ చేయడం ద్వారా. కాబట్టి మన వ్యవసాయ సమాజంలో ఆరోగ్యకరమైన పోటీని ప్రోత్సహించడం మరియు సహకారాన్ని పెంపొందించడం లక్ష్యంగా పెట్టుకోవచ్చు. ఈ లీగ్‌లు స్నేహాన్ని పెంపొందించడమే కాకుండా పాల్గొనేవారిలో ఐక్యతా భావాన్ని కలిగిస్తాయి. మేము మొదటి ముగ్గురు విజేతలకు నగదు ధరలను అందిస్తాము మరియు పాల్గొనే వారందరికీ సర్టిఫికెట్‌లను అందిస్తాము.</Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>విద్య మరియు అవగాహన</Card.Title>
        <Card.Text>
        లీగ్‌కు అతీతంగా, విద్య ప్రధానమైనది. వరి సాగు యొక్క ప్రయోజనాలు, స్థిరమైన వ్యవసాయ పద్ధతులు మరియు వరి ఆధారిత ఆహారం యొక్క పోషకాహార మేలు గురించి అవగాహనను వ్యాప్తి చేయడంలో మేము విశ్వసిస్తున్నాము. ఈ కార్యక్రమంలో మరింత మంది రైతులు చురుకుగా పాల్గొనేలా ప్రేరేపించడమే మా లక్ష్యం.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>మార్కెట్ యాక్సెస్</Card.Title>
        <Card.Text>
        మన రైతులకు న్యాయమైన మార్కెట్ యాక్సెస్‌ను నిర్ధారించడం మా విజయంలో కీలకమైన అంశం. మా రైతులు తమ వరి ఉత్పత్తులను సమాన ధరలకు అమ్ముకునేలా పటిష్టమైన మార్కెట్ అనుసంధానాన్ని సృష్టించేందుకు మేము కట్టుబడి ఉన్నాము.</Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>స్థిరత్వం</Card.Title>
        <Card.Text>
        వరి సాగు పట్ల మా దీర్ఘకాలిక దృష్టిలో సాంప్రదాయ వరి రకాలను సంరక్షించడం, విత్తన పొదుపును ప్రోత్సహించడం మరియు సహజ వ్యవసాయ పద్ధతులను ప్రోత్సహించడం వంటివి ఉన్నాయి. సుస్థిరత కేవలం ఒక లక్ష్యం కాదు; అది ఒక జీవన విధానం.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>విధాన మద్దతు</Card.Title>
        <Card.Text>
        మేము మార్పు కోసం న్యాయవాదులం. స్థానిక మరియు జాతీయ స్థాయిలలో సహాయక విధానాల కోసం మేము అవిశ్రాంతంగా కృషి చేస్తాము. ఇందులో వరి సాగు మరియు వినియోగానికి అనుకూలంగా ఉండే ప్రోత్సాహకాలు, సబ్సిడీలు మరియు విధాన సవరణలు ఉన్నాయి.</Card.Text> </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>యొక్క ముఖ్య యాజమాన్యం <span>రైతుల వరి సంఘం:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;