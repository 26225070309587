import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
{/* 
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
 
            <div className="social-ma">

{/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
<h1>Zha Skill Development (Internship) and TNPSC Group Examinations Training Center (Free)</h1>

{/* <div className='second-mcontent'>
                    <img src='../../img/family.png'></img>
                </div> */}
 
 
 <div id="coaching" className='second-mcontent'>
                    <img src='../../img/coachingcenter.jpg'></img>
                </div>



                <div className='second-mcontent'>
                    <img src='../../img/coming.jpg'></img>
                </div>          

 <div className="collabrate">
 <div className='second-mcontent'>
                    <img src='../../img/logo.webp'></img>
                </div>
                <div className="horibar"></div>
                <div className='second-mcontent'>
                    <img src='../../img/aarvam.webp'></img>
                </div>
                
 </div>
 
 <p>Welcome to ZHA Foundation's ZHA Skill Development (Internship) and TNPSC Group Examinations Training Center (Free). We are thrilled to announce our strategic partnership with Aarvam IAS Academy, aimed at enriching educational opportunities and providing valuable resources for aspiring students. Through this collaboration, we offer enhanced skill development opportunities through our internship program and free training for TNPSC Group Examinations, leveraging the expertise of Aarvam IAS Academy. Join us in this exciting journey of empowerment and education.</p>


 

<ol className="goalpoints">
     

<li><span>Online Coaching for TNPSC and IPS/IAS Exams : </span>  
<ul>
    <li><span>Subject-wise Classes :</span> Provide comprehensive subject-wise classes covering the syllabus of TNPSC and IPS/IAS exams. This includes topics such as General Studies, Aptitude, and specific subjects relevant to the exam.

</li>
<li><span>Interactive Sessions :</span>  Foster an interactive learning environment through live online classes, allowing students to ask questions and seek clarification on topics.

</li>

<li><span>Mock Tests and Practice Sessions :</span>  Conduct regular mock tests and practice sessions to familiarize students with the exam pattern and improve time management skills.

</li>
</ul>
</li> 


<li><span>Renowned Institution Collaborations : </span>  
<ul>
    <li><span>Tie-ups with Coaching Institutes :</span> Collaborate with renowned coaching institutions in Chennai and other areas to leverage their expertise in designing the curriculum and providing quality study materials.

</li>
<li><span>Guest Lectures :</span>   Arrange for guest lectures from experienced faculty members of these institutions to share valuable insights and tips with students.

</li>

 
</ul>
</li> 

<li><span>Personality Development Courses : </span>  
<ul>
    <li><span>Communication Skills :</span> Offer courses to enhance communication skills, including public speaking and effective writing, to prepare students for the interview and written stages of exams.

</li>
<li><span>Leadership Training :</span> Provide training to instill leadership qualities, critical thinking, and decision-making skills necessary for administrative roles.

</li>

<li><span>Ethics and Integrity :</span>  Emphasize the importance of ethics, integrity, and moral values in public service, aligning with the principles of the IPS/IAS exams.

</li>
</ul>
</li> 
 

<li><span>Mentorship Programs : </span>  
<ul>
    <li><span>Experienced Mentors :</span> Connect students with experienced mentors, including professionals and leaders, to guide them throughout their preparation and provide career advice.

</li>
<li><span>Career Counseling :</span>  Conduct career counseling sessions to help students understand the various career paths available after clearing the exams and make informed decisions.

</li>

 
</ul>
</li> 

<li><span>Holistic Approach : </span>  
<ul>
    <li><span>Health and Wellness Programs :</span> Implement programs focused on the overall well-being of students, including stress management, yoga, and mental health support.

</li>
<li><span>Financial Literacy :</span>  Include sessions on financial literacy to empower students with the knowledge needed to manage their finances responsibly.

</li>

 
</ul>
</li> 

<li><span>Technology Integration : </span>  
<ul>
    <li><span>Digital Learning Platforms :</span> Utilize digital learning platforms and tools to make the curriculum accessible to students in remote areas, ensuring they have the necessary resources to succeed.
</li>
 

 
</ul>
</li> 

<li><span>Monitoring and Evaluation : </span>  
<ul>
    <li><span>Regular Assessments :</span>  Implement a system of regular assessments to track the progress of students and identify areas that require additional attention.

</li>
<li><span>Feedback Mechanism :</span>  Establish a feedback mechanism where students can provide input on the effectiveness of the program, helping in continuous improvement.

</li>

 
</ul>
</li> 
</ol>

 
 

 

 

  
       
        </div>
        <div className='second-mcontent'>
       
        
       <a href="https://forms.gle/jUD5xyY4piaD7Uc26"> <img src='../../img/register.png'></img></a>     
        </div>
 
        <h3>Brochure</h3>
        <div className='second-mcontent'>
       
               <a href="https://forms.gle/jUD5xyY4piaD7Uc26"> <img src='../../img/pamblet.webp'></img></a>  
     
                </div>


                <div className="visionmi">
                <h3 >For Donation</h3>
         <p>Your support is invaluable in advancing our mission. Donations can be sent to the following account:</p>

         <div className="donationinformation">

        <ul id="donationinfo" className="goalpoints"> <li><span>Name : </span>Zha Foundation Charitable Trust
</li>
         <li><span>Bank Name : </span>Karur Vysya Bank
</li>
         <li><span>Account No : </span>1598135000003601
</li>
         <li><span>IFSC Code : </span>KVBL0001698</li>
         
         <li><span>UPI Id : </span>zhafoundation2017@okhdfcbank</li>

         <li><span>Gpay No : </span>+91 7305359417</li>
        

         </ul>
         <img src='../../img/navigation logo.webp'></img> 
          
         </div>
         </div>
        </div>




 
        


    );
}

