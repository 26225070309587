import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./Paddy.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Millet() {
    return(

        <div>
                    <div  id="language" >
        
        <a href=""><NavLink to="/zhafarmerspaddyleague">English</NavLink></a>



</div>


            <div className="social-main">

            <h1>Zha Farmers Paddy League</h1>
            </div>
           <section className="intro">
            <div className="paddyhero">
            <img src="../img/asian.jpg"></img>
            </div>
            <div className="titletext">
              <p> ಝಾ ಫೌಂಡೇಶನ್ ಪೆರವೂರಣಿ ತಾಲೂಕಿನಲ್ಲಿ ರೈತರಲ್ಲಿ ರೈತರ ಭತ್ತದ ಲೀಗ್ ನಡೆಸಲು ಮುಂದಾಗಿರುವುದು ಶ್ಲಾಘನೀಯ ಪ್ರಯತ್ನವಾಗಿದೆ. ಭತ್ತವನ್ನು ಅಕ್ಕಿ ಎಂದೂ ಕರೆಯುತ್ತಾರೆ, ಇದು ಭಾರತದಲ್ಲಿ ಅಪಾರ ಸಾಂಸ್ಕೃತಿಕ ಮತ್ತು ಪೌಷ್ಟಿಕಾಂಶದ ಪ್ರಾಮುಖ್ಯತೆಯನ್ನು ಹೊಂದಿರುವ ಪ್ರಮುಖ ಬೆಳೆಯಾಗಿದೆ. ಆದಾಗ್ಯೂ, ಇತ್ತೀಚಿನ ವರ್ಷಗಳಲ್ಲಿ, ಅದರ ಕೃಷಿಯು ಸವಾಲುಗಳನ್ನು ಎದುರಿಸುತ್ತಿದೆ, ಇದು ಸಾಂಪ್ರದಾಯಿಕ ಜ್ಞಾನ ಮತ್ತು ಅದು ನೀಡುವ ಪೌಷ್ಟಿಕಾಂಶದ ಪ್ರಯೋಜನಗಳ ನಷ್ಟಕ್ಕೆ ಕಾರಣವಾಗುತ್ತದೆ. ಪ್ರಪಂಚದಾದ್ಯಂತದ ರೈತರು, ಉತ್ಸಾಹಿಗಳು ಮತ್ತು ನವೋದ್ಯಮಗಳನ್ನು ಒಟ್ಟುಗೂಡಿಸುವ ಮೂಲಕ ಕೃಷಿ ಪ್ರಪಂಚದಲ್ಲಿ ಕ್ರಾಂತಿಯನ್ನುಂಟುಮಾಡಲು ರೈತರ ಭತ್ತದ ಲೀಗ್ ಸಿದ್ಧವಾಗಿದೆ. ಐಪಿಎಲ್ ಕ್ರಿಕೆಟ್‌ನ ಮುಖವನ್ನು ಬದಲಿಸಿದಂತೆಯೇ, ಝಾ ಫೌಂಡೇಶನ್‌ನ ಫಾರ್ಮರ್ಸ್ ಪ್ಯಾಡಿ ಲೀಗ್ ಭತ್ತದ ಕೃಷಿಯನ್ನು ಸಂಪೂರ್ಣ ಹೊಸ ಮಟ್ಟಕ್ಕೆ ಏರಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿದೆ, ಅತ್ಯಾಕರ್ಷಕ ಪಂದ್ಯಾವಳಿಗಳು ಮತ್ತು ಅತ್ಯಾಧುನಿಕ ತಂತ್ರಗಳೊಂದಿಗೆ..</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/kC8byZNt8dFH4Z1e6"> <Button variant="outline-success">Register Now</Button>{' '}</a>

    </div>

    <div>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfkUnlmPbv846wVQGKAD41SfB_JrRIsIO3DwkMcK0Wpcpvpdw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
  </section>

  <section className="organics">

    <div className="orgcon">
      <div className="orgtext">  <h3>ಸಾವಯವವಲ್ಲದ ಭತ್ತದ ಕೃಷಿಯ ಪರಿಣಾಮಗಳು</h3>
      <div className="side"></div>
      </div>
      <div className="orgflex">

      
           <ul>
        <h5>1.ಪರಿಸರ ಪ್ರಭಾವ:</h5>
        <li>ಸಾವಯವವಲ್ಲದ ಭತ್ತದ ಕೃಷಿಯು ಕೀಟಗಳು ಮತ್ತು ಕಳೆಗಳನ್ನು ನಿಯಂತ್ರಿಸಲು ಸಂಶ್ಲೇಷಿತ ಕೀಟನಾಶಕಗಳು ಮತ್ತು ಸಸ್ಯನಾಶಕಗಳನ್ನು ಹೆಚ್ಚಾಗಿ ಅವಲಂಬಿಸಿದೆ. ಈ ರಾಸಾಯನಿಕಗಳು ಮಣ್ಣು, ನೀರು ಮತ್ತು ಗಾಳಿಯನ್ನು ಕಲುಷಿತಗೊಳಿಸುವ ಮೂಲಕ ಪರಿಸರದ ಮೇಲೆ ಹಾನಿಕಾರಕ ಪರಿಣಾಮಗಳನ್ನು ಉಂಟುಮಾಡಬಹುದು ಸಂಶ್ಲೇಷಿತ ರಸಗೊಬ್ಬರಗಳ ಬಳಕೆಯು ಮಣ್ಣಿನ ಅವನತಿಗೆ ಕಾರಣವಾಗಬಹುದು, ಪೋಷಕಾಂಶಗಳ ಅಸಮತೋಲನ ಮತ್ತು ಕಾಲಾನಂತರದಲ್ಲಿ ಮಣ್ಣಿನ ಫಲವತ್ತತೆ ಕಡಿಮೆಯಾಗುತ್ತದೆ.</li>

        <h5>2.ಆರ್ಥಿಕ ಪರಿಣಾಮ:</h5>
        <li>ಸಾವಯವವಲ್ಲದ ಕೃಷಿಗೆ ಸಾಮಾನ್ಯವಾಗಿ ಸಂಶ್ಲೇಷಿತ ರಸಗೊಬ್ಬರಗಳು, ಕೀಟನಾಶಕಗಳು ಮತ್ತು ಸಸ್ಯನಾಶಕಗಳಿಗೆ ಹೆಚ್ಚಿನ ಇನ್ಪುಟ್ ವೆಚ್ಚಗಳು ಬೇಕಾಗುತ್ತವೆ, ಇದು ರೈತರಿಗೆ ಆರ್ಥಿಕ ಹೊರೆಯಾಗಬಹುದು.</li>

        <h5>3.ಆರೋಗ್ಯದ ಪರಿಣಾಮ:</h5>
        <li>ರೈತರು ಮತ್ತು ಕೃಷಿ ಕೆಲಸಗಾರರು ಕೀಟನಾಶಕಗಳು ಮತ್ತು ಸಸ್ಯನಾಶಕಗಳಿಂದ ರಾಸಾಯನಿಕ ಅವಶೇಷಗಳಿಗೆ ಒಡ್ಡಿಕೊಳ್ಳಬಹುದು, ಇದು ಆರೋಗ್ಯ ಸಮಸ್ಯೆಗಳಿಗೆ ಕಾರಣವಾಗಬಹುದು.</li>

        <h5>4.ಇಂಗಾಲದ ಹೆಜ್ಜೆಗುರುತು: </h5>
        <li>ಅಜೈವಿಕ ಕೃಷಿ ಪದ್ಧತಿಗಳು, ವಿಶೇಷವಾಗಿ ಸಂಶ್ಲೇಷಿತ ರಸಗೊಬ್ಬರಗಳ ಬಳಕೆಯನ್ನು ಒಳಗೊಂಡಿದ್ದು, ಹಸಿರುಮನೆ ಅನಿಲ ಹೊರಸೂಸುವಿಕೆಗೆ ಕೊಡುಗೆ ನೀಡುತ್ತವೆ, ಇದು ಹವಾಮಾನ ಬದಲಾವಣೆಗೆ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ.</li>

        <h5>5.ದೀರ್ಘಾವಧಿಯ ಸುಸ್ಥಿರತೆ: </h5>
        <li>ಮಣ್ಣಿನ ಅವನತಿ, ಕಡಿಮೆಯಾದ ಜೀವವೈವಿಧ್ಯ ಮತ್ತು ರಾಸಾಯನಿಕಗಳಿಗೆ ಕೀಟ ನಿರೋಧಕತೆಯನ್ನು ಹೆಚ್ಚಿಸುವುದರಿಂದ ಸಾವಯವವಲ್ಲದ ಭತ್ತದ ಕೃಷಿ ಪದ್ಧತಿಗಳು ದೀರ್ಘಾವಧಿಯಲ್ಲಿ ಸಮರ್ಥನೀಯವಾಗಿರುವುದಿಲ್ಲ.
        </li>
        </ul>

      
       
    
    <img src="../img/ori2.jpg"></img>
    </div>
    </div>
  </section>




<div className="freepik">
<h5 className="cardtitle">ರೈತರ ರಾಗಿ ಲೀಗ್ ಉಪಕ್ರಮಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಪ್ರಮುಖ ಅಂಶಗಳ ಅವಲೋಕನ ಇಲ್ಲಿದೆ:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ನಮ್ಮ ಮಿಷನ್</Card.Title>
        <Card.Text>
        ನಮ್ಮ ಉಪಕ್ರಮದ ಹೃದಯಭಾಗವು ಭಾರತದಾದ್ಯಂತ ಪೆರವೂರಣಿ ತಾಲ್ಲೂಕು ಮತ್ತು ಅದರಾಚೆಗಿನ ರೈತರಲ್ಲಿ ಭತ್ತದ ಕೃಷಿಯನ್ನು ಉತ್ತೇಜಿಸುವ ಮತ್ತು ಪುನರುಜ್ಜೀವನಗೊಳಿಸುವ ಗುರಿಯಾಗಿದೆ. ಭತ್ತವನ್ನು ಸಾಮಾನ್ಯವಾಗಿ "ಪೌಷ್ಠಿಕಾಂಶ-ಧಾನ್ಯ" ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ, ಅದರ ಅಸಾಧಾರಣ ಪೌಷ್ಟಿಕಾಂಶದ ಮೌಲ್ಯ ಮತ್ತು ಪರಿಸರ ಸ್ನೇಹಿ ಕೃಷಿ ಪದ್ಧತಿಗಳಿಗೆ ಹೆಸರುವಾಸಿಯಾಗಿದೆ, ಇದು ಸುಸ್ಥಿರ ಕೃಷಿಯ ಮೂಲಾಧಾರವಾಗಿದೆ.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಝಾ ಫೌಂಡೇಶನ್‌ನ ಬದ್ಧತೆ</Card.Title>
        <Card.Text>
        ನಮ್ಮ ಸಮರ್ಪಿತ ರೈತರಲ್ಲಿ ಭತ್ತದ ಕೃಷಿಯನ್ನು ಕಿಕ್‌ಸ್ಟಾರ್ಟ್ ಮಾಡುವಲ್ಲಿ ಮತ್ತು ಬೆಂಬಲಿಸುವಲ್ಲಿ ನಾವು ಪ್ರಮುಖ ಪಾತ್ರ ವಹಿಸುತ್ತೇವೆ. ನಮ್ಮ ಬೆಂಬಲವು ಅಗತ್ಯ ಸಂಪನ್ಮೂಲಗಳು, ಸಮಗ್ರ ತರಬೇತಿ ಮತ್ತು ತಾಂತ್ರಿಕ ಸಹಾಯವನ್ನು ಒದಗಿಸುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ, ರೈತರಿಗೆ ತಮ್ಮ ಕೃಷಿ ಪದ್ಧತಿಗಳಲ್ಲಿ ಭತ್ತದ ಕೃಷಿಯನ್ನು ಮನಬಂದಂತೆ ಸಂಯೋಜಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.
        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಸಾವಯವ ಭತ್ತದ ಕೃಷಿಯನ್ನು ಮರಳಿ ತರುವುದು</Card.Title>
        <Card.Text>
        ನಮ್ಮ ಉಪಕ್ರಮವು ಭಾರತೀಯ ರಾಜ್ಯ ಸರ್ಕಾರದ ಜೊತೆಗೆ ಸಾವಯವ ಭತ್ತದ ಕೃಷಿಯನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುವುದು ಮತ್ತು ಕೃಷಿಯಲ್ಲಿ ಅರೆಕಾಲಿಕ ಕೆಲಸಗಳಿಗೆ ಯುವಕರನ್ನು ಜೋಡಿಸುವುದು. ಝಾ ರೈತರು ಮತ್ತು ಗ್ರಾಹಕರ ಕಾಲ್ ಸೆಂಟರ್ ಮೂಲಕ ಜಾಗತಿಕ ಮಾರುಕಟ್ಟೆಯಲ್ಲಿ ನಮ್ಮ ರೈತರ ಉತ್ಪನ್ನಗಳನ್ನು ತಯಾರಿಸಲು ಡಿಜಿಟಲ್ ತಂತ್ರಗಳ ಮೂಲಕ ಪೂರೈಕೆ ಸರಪಳಿಯನ್ನು ರಚಿಸಿ. ಪ್ರಯೋಜನಗಳು ಬಹುಮಟ್ಟಿಗೆ: ಆಹಾರ ಭದ್ರತೆಯನ್ನು ಸುಧಾರಿಸುವುದು, ಮಣ್ಣಿನ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವುದು ಮತ್ತು ನೀರಿನ-ತೀವ್ರ ಸಾವಯವ ಬೆಳೆಗಳಿಗೆ ಹೋಲಿಸಿದರೆ ನೀರಿನ ಬಳಕೆಯನ್ನು ಗಣನೀಯವಾಗಿ ಕಡಿಮೆ ಮಾಡುವುದು ಮತ್ತು ZHA ಫೌಂಡೇಶನ್ ಮೂಲಕ ಸಾವಯವ ಉತ್ಪನ್ನಗಳನ್ನು ಪ್ರಮಾಣೀಕರಿಸುವುದು</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ರೈತರ ಭತ್ತದ ಲೀಗ್</Card.Title>
        <Card.Text>
        ನಾವು ನವೀನ ಪರಿಕಲ್ಪನೆಯನ್ನು ಪರಿಚಯಿಸುತ್ತಿದ್ದೇವೆ - "ಫಾರ್ಮರ್ಸ್ ಪ್ಯಾಡಿ ಲೀಗ್." ಅರ್ಜಿಗಳ ಆಧಾರದ ಮೇಲೆ ರೈತರನ್ನು ವಿವಿಧ ತಂಡಗಳಾಗಿ ಸಂಘಟಿಸುವ ಮೂಲಕ ಮತ್ತು ಝಾ ತಂಡದ ಮೂಲಕ ಸಂಘಟಿಸಿ ಮತ್ತು ಸರ್ಕಾರಿ ಕೃಷಿ ತಜ್ಞರಿಂದ ನಿರ್ಣಯಿಸುವುದು. ಆದ್ದರಿಂದ ನಾವು ಆರೋಗ್ಯಕರ ಸ್ಪರ್ಧೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಬಹುದು ಮತ್ತು ನಮ್ಮ ರೈತ ಸಮುದಾಯದ ನಡುವೆ ಸಹಕಾರವನ್ನು ಬೆಳೆಸಬಹುದು. ಈ ಲೀಗ್‌ಗಳು ಸೌಹಾರ್ದತೆಯನ್ನು ಉತ್ತೇಜಿಸುವುದು ಮಾತ್ರವಲ್ಲದೆ ಭಾಗವಹಿಸುವವರಲ್ಲಿ ಏಕತೆಯ ಭಾವವನ್ನು ಹುಟ್ಟುಹಾಕುತ್ತದೆ. ನಾವು ಮೊದಲ ಮೂರು ವಿಜೇತರಿಗೆ ನಗದು ಬೆಲೆಗಳನ್ನು ಮತ್ತು ಎಲ್ಲಾ ಭಾಗವಹಿಸುವವರಿಗೆ ಪ್ರಮಾಣಪತ್ರಗಳನ್ನು ಒದಗಿಸುತ್ತೇವೆ.</Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಶಿಕ್ಷಣ ಮತ್ತು ಜಾಗೃತಿ</Card.Title>
        <Card.Text>
        ಲೀಗ್‌ನ ಹೊರತಾಗಿ, ಶಿಕ್ಷಣವು ಅತ್ಯುನ್ನತವಾಗಿದೆ. ಭತ್ತದ ಕೃಷಿಯ ಪ್ರಯೋಜನಗಳು, ಸುಸ್ಥಿರ ಕೃಷಿ ಪದ್ಧತಿಗಳು ಮತ್ತು ಭತ್ತ-ಆಧಾರಿತ ಆಹಾರದ ಪೌಷ್ಟಿಕಾಂಶದ ಶ್ರೇಷ್ಠತೆಯ ಬಗ್ಗೆ ಜಾಗೃತಿಯನ್ನು ಹರಡಲು ನಾವು ನಂಬುತ್ತೇವೆ. ಈ ಉಪಕ್ರಮದಲ್ಲಿ ಹೆಚ್ಚು ರೈತರು ಸಕ್ರಿಯವಾಗಿ ಭಾಗವಹಿಸುವಂತೆ ಪ್ರೇರೇಪಿಸುವುದು ನಮ್ಮ ಉದ್ದೇಶವಾಗಿದೆ.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಮಾರುಕಟ್ಟೆ ಪ್ರವೇಶ</Card.Title>
        <Card.Text>
        ನಮ್ಮ ರೈತರಿಗೆ ನ್ಯಾಯಯುತ ಮಾರುಕಟ್ಟೆ ಪ್ರವೇಶವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ನಮ್ಮ ಯಶಸ್ಸಿನ ಪ್ರಮುಖ ಅಂಶವಾಗಿದೆ. ನಮ್ಮ ರೈತರು ತಮ್ಮ ಭತ್ತದ ಉತ್ಪನ್ನಗಳನ್ನು ಸಮಾನ ಬೆಲೆಗೆ ಮಾರಾಟ ಮಾಡಬಹುದೆಂದು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ನಾವು ದೃಢವಾದ ಮಾರುಕಟ್ಟೆ ಸಂಪರ್ಕವನ್ನು ರಚಿಸಲು ಬದ್ಧರಾಗಿದ್ದೇವೆ. </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಸಮರ್ಥನೀಯತೆ</Card.Title>
        <Card.Text>
        ಭತ್ತದ ಕೃಷಿಗಾಗಿ ನಮ್ಮ ದೀರ್ಘಾವಧಿಯ ದೃಷ್ಟಿ ಸಾಂಪ್ರದಾಯಿಕ ಭತ್ತದ ತಳಿಗಳನ್ನು ಸಂರಕ್ಷಿಸುವುದು, ಬೀಜ ಉಳಿತಾಯವನ್ನು ಉತ್ತೇಜಿಸುವುದು ಮತ್ತು ನೈಸರ್ಗಿಕ ಕೃಷಿ ಪದ್ಧತಿಗಳನ್ನು ಉತ್ತೇಜಿಸುವುದು. ಸುಸ್ಥಿರತೆ ಕೇವಲ ಗುರಿಯಲ್ಲ; ಇದು ಜೀವನ ವಿಧಾನವಾಗಿದೆ.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ನೀತಿ ಬೆಂಬಲ</Card.Title>
        <Card.Text>
        ನಾವು ಬದಲಾವಣೆಯ ಪ್ರತಿಪಾದಕರು. ಸ್ಥಳೀಯ ಮತ್ತು ರಾಷ್ಟ್ರೀಯ ಮಟ್ಟದಲ್ಲಿ ಬೆಂಬಲ ನೀತಿಗಳನ್ನು ಒತ್ತಾಯಿಸಲು ನಾವು ದಣಿವರಿಯಿಲ್ಲದೆ ಕೆಲಸ ಮಾಡುತ್ತೇವೆ. ಇದು ಭತ್ತದ ಕೃಷಿ ಮತ್ತು ಬಳಕೆಗೆ ಅನುಕೂಲವಾಗುವ ಪ್ರೋತ್ಸಾಹಗಳು, ಸಬ್ಸಿಡಿಗಳು ಮತ್ತು ನೀತಿ ಹೊಂದಾಣಿಕೆಗಳನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ.</Card.Text> </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>ರೈತರ ಭತ್ತದ <span>ಲೀಗ್‌ನ ಪ್ರಮುಖ ಮಾಲೀಕತ್ವ:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;