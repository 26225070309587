import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Why Sustainability Cause?</h1>
</div>


<div className="internship">
        <img src="../img/360.webp"></img>
        </div>
<div className="goalpointsp">

<p>Expanding a sustainability development club like Zha Foundation to all countries allows for a global impact by addressing diverse environmental and social challenges. It enables the sharing of resources, knowledge, and best practices, fostering a collaborative approach towards sustainable development on a broader scale. Additionally, the interconnected nature of global issues emphasizes the need for widespread participation to create a more sustainable and resilient world.Causes of unsustainable human life as mentioned below.Unsustainable lifestyles for humans can be attributed to several interconnected factors:
</p>


<ol className="goalpoints">
    <li><span>Excessive Mobile Phone Usage : </span>Children and parents are increasingly spending a significant amount of time on mobile phones, which has led to a decline in physical activity, reading habits, and social interaction.</li>
    <li><span>Video Game Overuse : </span>Children, in particular, are devoting excessive hours to video games, often at the expense of participating in sports and cultivating a love for reading.</li>
    <li><span>Erosion of Social Trust : </span>Reduced face-to-face interactions between people due to digital distractions are contributing to a decline in trust and interpersonal relationships, raising concerns about the quality of our social connections.</li>


    <li><span>Overconsumption and Materialism : </span>The pursuit of excessive material possessions and consumption of resources beyond what is necessary for a comfortable and fulfilling life can lead to unsustainable lifestyles.

</li>
    <li><span>Resource Depletion : </span>Over-exploitation of natural resources, including fossil fuels, minerals, and fresh water, can lead to their depletion and contribute to environmental degradation.


</li>
    <li><span>Waste Generation : </span>Inefficient resource use and improper waste disposal contribute to pollution, landfill accumulation, and the release of harmful substances into the environment.


</li>
    <li><span>High Energy Consumption : </span>Reliance on energy-intensive practices, such as excessive use of electricity, heating, and transportation, can lead to increased greenhouse gas emissions and environmental strain.


</li>
    <li><span>Transportation and Commuting Habits : </span>MReliance on personal vehicles for transportation, particularly in areas lacking efficient public transit, can contribute to air pollution, congestion, and fossil fuel consumption.
</li>
    <li><span>Food Production and Consumption : </span>Unsustainable agricultural practices, including monoculture farming, excessive use of chemical inputs, and long-distance transportation of food, can lead to environmental degradation and food security challenges.
</li>
    <li><span>Deforestation and Habitat Destruction : </span>Clearing land for agriculture, urbanization, and infrastructure development can lead to loss of biodiversity, disruption of ecosystems, and reduced carbon sequestration.

</li>
<li><span>Celebrating Life's Victories : </span>We don’t make time for celebrating other’s achievements while they are alive
</li>
<li><span>Neglecting Sympathy : </span>We don’t make time for sharing our condolences for others loss etc</li>
    <li><span>Overfishing and Marine Degradation : </span>Unsustainable fishing practices, including overfishing and destructive fishing methods, can deplete fish stocks and harm marine ecosystems.

</li> 

<li><span>Lack of Sustainable Infrastructure : </span>Inadequate urban planning, inefficient building designs, and outdated infrastructure can contribute to resource inefficiency and environmental strain.

</li> 

<li><span>Consumer Behavior and Choices : </span>Choices made by consumers, such as purchasing non-sustainable products, can drive demand for resource-intensive and environmentally harmful goods and services.

</li> 

<li><span>Lack of Environmental Awareness and Education : </span>Insufficient knowledge and understanding of the environmental impacts of certain behaviors can lead to unintentional unsustainable practices.
</li> 

<li><span>Policy and Regulatory Frameworks : </span>Weak or inadequate environmental policies, lack of enforcement, and subsidies that incentivize unsustainable practices can contribute to unsustainable lifestyles.
</li> 
</ol>



<p>Addressing unsustainable lifestyles requires a multi-faceted approach, including education, policy changes, technological innovation, and shifts in cultural and societal norms. Encouraging sustainable practices, promoting responsible consumption, and adopting eco-friendly technologies are all important steps towards a more sustainable future.</p>



</div>
        
     

        </div>

    );
}

