import React from "react";
import "./Sponsors.css"
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
export default function Sponsors(){

    return(

        <div>

          
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/sponsors-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/sponsors-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/sponsors-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/sponsors-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/sponsors-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

                {/* <div  id="language" >
        
        <a href=""><NavLink to="/sponsors-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/sponsors-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/sponsors-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/sponsors-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/sponsors-ka">Kannada</NavLink></a>


        </div> */}
            <div className="social-main">

            <h1>Zha Sponsorship Program</h1>
            </div>

            <div className="titlesponsor">
                <div className="titleimg">
                <img src="../img/sponsorship.webp"></img>
                </div>
                <p>The sponsors of the Organic Farmers League competition, organized by the Zha Foundation, will enjoy prominent exposure opportunities. Your brand's advertisements will be strategically placed at the competition venues, ensuring that they are seen by a diverse and engaged audience. Additionally, as a valued sponsor, your company will be prominently featured in the dedicated "Sponsor" section on our website. This online presence will further enhance your visibility and demonstrate your commitment to supporting sustainable agriculture and the mission of the Natural Farmers League. Join us as a sponsor and seize the chance to showcase your brand to a community passionate about eco-friendly farming practices.</p>

            </div>

            
           

            <div className="freepik">
<h5 className="cardtitle">Here are the benefits for the sponsors of the<span> Organic Farmers League.</span></h5>
</div>

           
<div className="cards">

<Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>Media Coverage</Card.Title>
           <Card.Text>
           Youtube media’s will take live stream of zha events for at least 30minutes day and Our zha events will be covered in television news media   </Card.Text>
         </Card.Body>
       </Card>
   

   <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>Enhanced Visibility</Card.Title>
           <Card.Text>
           By sponsoring a hoarding for 3 months in a prime farm area spot, you gain significant visibility among a targeted audience. This exposure can significantly boost your brand recognition.
           </Card.Text>
         </Card.Body>
       </Card>
   
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>Cost-Effective Advertising</Card.Title>
           <Card.Text>
           By sponsoring a hoarding for 3 months in one farm area spot, you can reach your target audience effectively at a cost of just 12,000 Rs. This is a highly cost-effective way to promote your brand or products.
           </Card.Text>
         </Card.Body>
       </Card>
   
      
   
   </div>
   <div className="cards2">
   

   <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>Online Visibility</Card.Title>
           <Card.Text>
           In addition to physical hoarding placement, your sponsorship includes online exposure. Your brand will be featured on the website, increasing your digital presence and reach.
           </Card.Text>
         </Card.Body>
       </Card>
   
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>Joint Advertising Opportunities</Card.Title>
           <Card.Text>
           When you sponsor a hoarding, you have the advantage of advertising alongside other dealers. This shared platform can lead to increased visibility as customers are more likely to notice multiple businesses at the same location.
           </Card.Text>
         </Card.Body>
       </Card>
       <Card style={{ width: '18rem' }}>
         <Card.Body>
           <Card.Title>Customized Exposure</Card.Title>
           <Card.Text>
           If your client needs advertising across multiple farm game spots, you can easily scale up your sponsorship by multiplying the hoarding placements. This flexibility allows you to tailor your advertising strategy to your specific needs and budget.
           </Card.Text>
         </Card.Body>
       </Card>
      
   
   
    


   </div>

   
        </div>
    
    );
}