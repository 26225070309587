import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


export default function Ecofriendly() {
    return (
        <div>

            
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Wellness-Program">English</NavLink></a>
         
        </div>
{/* 
            <div  id="language" >

        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a>
        

        </div> */}

<h1 id="mhead">ఝా వెల్‌నెస్ ప్రోగ్రామ్</h1>

   
<div className='second-mcontent'>
           <img src='../../img/self.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>ఝా యొక్క ఫిజికల్ అండ్ మెంటల్ వెల్నెస్ ఫ్రీ కోచింగ్ విభిన్న రకాల కార్యకలాపాలను కలిగి ఉంటుంది, ఇది శ్రేయస్సుకు సమగ్ర విధానాన్ని నిర్ధారిస్తుంది. ఇందులో ఇవి ఉన్నాయి:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>అథ్లెటిక్ శిక్షణా కార్యక్రమాలు :</span>మారథాన్, సైక్లింగ్ మరియు వాలీబాల్ వంటి వివిధ విభాగాలను కవర్ చేస్తూ శారీరక దృఢత్వం కోసం రూపొందించిన కార్యక్రమాలు.
</li>

<li><span>మైండ్‌ఫుల్ యాక్టివిటీస్ :</span> శారీరక శిక్షణకు మించి, పాల్గొనేవారు ధ్యానం మరియు యోగా వంటి మానసిక ఆరోగ్య కార్యకలాపాల నుండి ప్రయోజనం పొందుతారు.
 
</li>
<li><span>ఇండోర్ చదరంగం :</span> చదరంగం వంటి వ్యూహాత్మక ఆటల ద్వారా మనస్సును నిమగ్నం చేయడం, అభిజ్ఞా వికాసం మరియు మానసిక చురుకుదనాన్ని ప్రోత్సహించడం.

</li>
<p>
కోచింగ్ ప్రోగ్రామ్‌లో పాల్గొనే వ్యక్తుల కోసం సమతుల్య మరియు ఆరోగ్యకరమైన జీవనశైలిని పెంపొందించడం, శ్రేయస్సు యొక్క శారీరక మరియు మానసిక అంశాలను రెండింటినీ పరిష్కరించే సమగ్ర విధానాన్ని ఝా వెల్‌నెస్ ప్రోగ్రామ్ కలిగి ఉంది.</p>

<p>విద్యార్థుల కోసం గ్రామీణ మరియు సబర్బన్ ప్రాంతాల్లో ఝా ఉచిత శారీరక మరియు మానసిక ఆరోగ్య కోచింగ్ అనేక ప్రయోజనాలను అందిస్తుంది:</p>
</ol>
        
 

<ol className="goalpoints"> 
<li><span>వనరులకు ప్రాప్యత :</span> గ్రామీణ ప్రాంతాల్లోని విద్యార్థులు ఆర్థిక పరిమితుల కారణంగా వారు లేని కోచింగ్ ప్రోగ్రామ్‌లకు ప్రాప్యతను పొందుతారు, శ్రేయస్సు కోసం సమాన అవకాశాలను ప్రోత్సహిస్తారు.

</li>
<li><span>మెరుగైన శారీరక ఆరోగ్యం : </span> మారథాన్‌లు, సైక్లింగ్ మరియు వాలీబాల్ వంటి అథ్లెటిక్ శిక్షణా కార్యక్రమాలు మెరుగైన శారీరక దృఢత్వానికి, చురుకైన జీవనశైలిని ప్రోత్సహిస్తాయి.


</li>
<li><span>మెరుగైన మానసిక శ్రేయస్సు :</span> ధ్యానం, యోగా తరగతులు మరియు ఇండోర్ చెస్ ఒత్తిడి ఉపశమనం, మెరుగైన దృష్టి మరియు మొత్తం మానసిక స్థితిస్థాపకత కోసం సాధనాలను అందిస్తాయి.
</li>
<li><span>నైపుణ్యాభివృద్ధి : </span>విభిన్న కార్యకలాపాలలో పాల్గొనడం జట్టుకృషి, క్రమశిక్షణ మరియు వ్యూహాత్మక ఆలోచనలతో సహా అనేక రకాల నైపుణ్యాలను అభివృద్ధి చేస్తుంది.

</li>
<li><span>కమ్యూనిటీ బిల్డింగ్ : </span>  వెల్‌నెస్ ప్రోగ్రామ్‌లు పాల్గొనేవారిలో కమ్యూనిటీ భావాన్ని సృష్టిస్తాయి, సామాజిక కనెక్షన్‌లు మరియు సపోర్ట్ నెట్‌వర్క్‌లను ప్రోత్సహిస్తాయి.

</li>
<li><span>సాధికారత :</span> కోచింగ్ విద్యార్థులను సవాళ్లను నావిగేట్ చేయడానికి మరియు జీవితంలోని వివిధ కోణాల్లో విజయం సాధించడానికి శారీరక మరియు మానసిక సాధనాలతో సన్నద్ధం చేయడం ద్వారా వారికి శక్తినిస్తుంది.
</li>
<li><span>ఆరోగ్యకరమైన జీవనశైలిని ప్రోత్సహించడం :</span>శారీరక శ్రమ మరియు మానసిక ఆరోగ్య పద్ధతులను ప్రోత్సహించడం ద్వారా, కోచింగ్ ఆరోగ్యకరమైన జీవనశైలిని అవలంబించడానికి దోహదం చేస్తుంది, జీవనశైలికి సంబంధించిన ఆరోగ్య సమస్యల ప్రమాదాన్ని తగ్గిస్తుంది.
</li>


</ol>




<p>
మొత్తంమీద, ఉచిత కోచింగ్ ప్రోగ్రామ్‌లు గ్రామీణ మరియు సబర్బన్ ప్రాంతాల్లోని విద్యార్థులను ఉద్ధరించడం మరియు సాధికారత కల్పించడం, వారి సంపూర్ణ శ్రేయస్సును పెంపొందించడం మరియు వారికి విలువైన జీవన నైపుణ్యాలను అందించడం లక్ష్యంగా పెట్టుకున్నాయి.
 </p>


</section>
 

</div>
 
 
);
}

