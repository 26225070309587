import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
          <div  id="language" >
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club">English</NavLink></a>
        </div>

            <div className="social-ma">

<h1>Role of Zha Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/role.webp"></img>
        </div>
<div className="goalpointsp">

<p>உலகெங்கிலும் உள்ள Zha நிலைத்தன்மை மேம்பாட்டுக் கழகத்தின் பாத்திரங்கள் மற்றும் பொறுப்புகள் பொதுவாக அடங்கும்:
</p>


<ol className="goalpoints">
    <li><span>விழிப்புணர்வை ஊக்குவித்தல் : </span>சுற்றுச்சூழல் மற்றும் சமூகப் பிரச்சனைகள் பற்றிய விழிப்புணர்வை ஏற்படுத்துதல், நிலையான நடைமுறைகள் மற்றும் நடத்தைகளுக்காக வாதிடுதல்.
</li>

    <li><span>கல்வி முன்முயற்சிகள் : </span>நிலையான வளர்ச்சி இலக்குகள் மற்றும் நடைமுறைகளைப் பற்றி சமூகத்திற்குத் தெரிவிக்க கல்வித் திட்டங்கள், பட்டறைகள் மற்றும் பிரச்சாரங்களை நடத்துதல்.</li>
    <li><span>சமூக ஈடுபாடு : </span>உள்ளூர் சமூகங்களின் தேவைகளைப் புரிந்துகொள்வதற்கும், நிலையான திட்டங்களில் அவர்களை ஈடுபடுத்துவதற்கும், பொறுப்புணர்வு உணர்வை வளர்ப்பதற்கும் அவர்களுடன் ஈடுபடுங்கள்.
</li>


    <li><span>திட்ட அமலாக்கம் : </span>சுற்றுச்சூழலுக்கு சாதகமாக பங்களிக்க கழிவு குறைப்பு, ஆற்றல் திறன் அல்லது பாதுகாப்பு முயற்சிகள் போன்ற நிலையான திட்டங்களைத் திட்டமிட்டு செயல்படுத்தவும்.

</li>
    <li><span>வக்கீல் : </span>உள்ளூர் மற்றும் தேசிய மட்டங்களில் நிலைத்தன்மையை ஆதரிக்கும் கொள்கைகளுக்கு வக்கீல், நேர்மறையான மாற்றத்தை பாதிக்க அதிகாரிகள் மற்றும் பிற நிறுவனங்களுடன் ஒத்துழைத்தல்.



</li>
    <li><span>ஆராய்ச்சி மற்றும் கண்டுபிடிப்பு : </span>நிலையான தொழில்நுட்பங்கள் மற்றும் கண்டுபிடிப்புகள் பற்றிய ஆராய்ச்சியை நடத்துதல், சுற்றுச்சூழல் சவால்களை எதிர்கொள்ள புதிய வழிகளை ஆராய்தல்.

</li>
    <li><span>நெட்வொர்க்கிங் : </span>வளங்களைப் பயன்படுத்துவதற்கும், நிலைத்தன்மை முன்முயற்சிகளின் தாக்கத்தை மேம்படுத்துவதற்கும் பிற நிறுவனங்கள், வணிகங்கள் மற்றும் அரசாங்க அமைப்புகளுடன் கூட்டாண்மைகளை உருவாக்குதல்.



</li>
    <li><span>கண்காணிப்பு மற்றும் மதிப்பீடு : </span>திட்டங்களின் தாக்கத்தை தொடர்ந்து மதிப்பீடு செய்து மதிப்பீடு செய்தல், அவை நிலையான இலக்குகளுடன் ஒத்துப்போவதை உறுதிசெய்து, தேவைக்கேற்ப மாற்றங்களைச் செய்ய வேண்டும்.
</li>
    <li><span>நிதி திரட்டுதல் : </span>மானியங்கள், நன்கொடைகள் அல்லது நிகழ்வுகள் மூலம் நீடித்து நிலைக்கக்கூடிய திட்டங்கள் மற்றும் நடந்துகொண்டிருக்கும் கிளப் செயல்பாடுகளை ஆதரிக்கும் நிதியைப் பாதுகாக்கவும்.

</li>
    <li><span>தொடர்பு : </span>கிளப்பிற்குள்ளும், பரந்த சமூகத்துடனும் வெளிப்படையான தொடர்பைப் பேணுதல், முன்னேற்றம், சவால்கள் மற்றும் வெற்றிக் கதைகளைப் பகிர்ந்து, ஈடுபாட்டை ஊக்குவிக்கவும்.
</li>
<li><span>திறன் மேம்பாடு : </span>உறுப்பினர்களுக்கும் சமூகத்திற்கும் பயிற்சி மற்றும் ஆதரவை வழங்குதல், அவர்களின் திறன்கள் மற்றும் நிலையான நடைமுறைகள் பற்றிய புரிதலை மேம்படுத்துதல்.
</li>
<li><span>உள்ளடக்கியமை : </span>நிலைத்தன்மை சவால்களை எதிர்கொள்வதில் பல்வேறு முன்னோக்குகளின் முக்கியத்துவத்தை உணர்ந்து, அனைத்து நடவடிக்கைகளிலும் உள்ளடக்கிய தன்மை மற்றும் பன்முகத்தன்மையை உறுதி செய்தல்.</li>

</ol>



</div>
        
     

        </div>

    );
}

