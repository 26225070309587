import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./Paddy.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Millet() {
    return(

        <div>
                <div  id="language" >
        
                <a href=""><NavLink to="/zhafarmerspaddyleague">English</NavLink></a>



        </div>
            <div className="social-main">

            <h1>Zha Farmers Paddy League</h1>
            </div>
           <section className="intro">
            <div className="paddyhero">
            <img src="../img/asian.jpg"></img>
            </div>
            <div className="titletext">
              <p> പേരാവൂരണി താലൂക്കിലെ കർഷകർക്കിടയിൽ കർഷക നെൽക്കൃഷി ലീഗ് നടത്താനുള്ള ഴ ഫൗണ്ടേഷന്റെ മുൻകൈ അഭിനന്ദനാർഹമാണ്. നെല്ല്, നെല്ല്, നെല്ല്, ഇന്ത്യയിൽ സാംസ്കാരികവും പോഷകപരവുമായ പ്രാധാന്യമുള്ള ഒരു പ്രധാന വിളയാണ്. എന്നിരുന്നാലും, സമീപ വർഷങ്ങളിൽ, അതിന്റെ കൃഷി വെല്ലുവിളികളെ അഭിമുഖീകരിച്ചു, ഇത് പരമ്പരാഗത അറിവും അത് നൽകുന്ന പോഷക ഗുണങ്ങളും നഷ്ടപ്പെടുന്നതിലേക്ക് നയിക്കുന്നു. ലോകമെമ്പാടുമുള്ള കർഷകരെയും ഉത്സാഹികളെയും നൂതനാശയക്കാരെയും ഒരുമിപ്പിച്ചുകൊണ്ട് കാർഷിക ലോകത്ത് വിപ്ലവം സൃഷ്ടിക്കാൻ ഫാർമേഴ്സ് പാഡി ലീഗ് ഒരുങ്ങുകയാണ്. ഐ‌പി‌എൽ ക്രിക്കറ്റിന്റെ മുഖച്ഛായ മാറ്റിയതുപോലെ, ആവേശകരമായ ടൂർണമെന്റുകളും അത്യാധുനിക സാങ്കേതിക വിദ്യകളും ഉപയോഗിച്ച് നെൽകൃഷിയെ ഒരു പുതിയ തലത്തിലേക്ക് ഉയർത്താൻ ഴ ഫൗണ്ടേഷന്റെ ഫാർമേഴ്‌സ് പാഡി ലീഗ് ലക്ഷ്യമിടുന്നു.</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/kC8byZNt8dFH4Z1e6"> <Button variant="outline-success">ഇപ്പോള് രജിസ്റ്റര് ചെയ്യുക</Button>{' '}</a>

    </div>

    <div>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfkUnlmPbv846wVQGKAD41SfB_JrRIsIO3DwkMcK0Wpcpvpdw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
  </section>

  <section className="organics">

    <div className="orgcon">
      <div className="orgtext">  <h3>അജൈവ നെൽകൃഷിയുടെ പ്രത്യാഘാതങ്ങൾ</h3>
      <div className="side"></div>
      </div>
      <div className="orgflex">

      
           <ul>
        <h5>1. പരിസ്ഥിതി ആഘാതം:</h5>
        <li>അജൈവ നെൽകൃഷി പലപ്പോഴും കീടങ്ങളെയും കളകളെയും നിയന്ത്രിക്കാൻ സിന്തറ്റിക് കീടനാശിനികളെയും കളനാശിനികളെയും ആശ്രയിക്കുന്നു. ഈ രാസവസ്തുക്കൾ മണ്ണ്, വെള്ളം, വായു എന്നിവയെ മലിനമാക്കുന്നതിലൂടെ പരിസ്ഥിതിയെ ദോഷകരമായി ബാധിക്കും, കൃത്രിമ വളങ്ങളുടെ ഉപയോഗം മണ്ണിന്റെ നാശത്തിനും പോഷകങ്ങളുടെ അസന്തുലിതാവസ്ഥയ്ക്കും കാലക്രമേണ മണ്ണിന്റെ ഫലഭൂയിഷ്ഠത കുറയുന്നതിനും ഇടയാക്കും.</li>

        <h5>2. സാമ്പത്തിക ആഘാതം:</h5>
        <li>അജൈവ കൃഷിക്ക് പലപ്പോഴും സിന്തറ്റിക് വളങ്ങൾ, കീടനാശിനികൾ, കളനാശിനികൾ എന്നിവയ്ക്ക് ഉയർന്ന ഇൻപുട്ട് ചെലവ് ആവശ്യമാണ്, ഇത് കർഷകർക്ക് സാമ്പത്തിക ബാധ്യതയാണ്.</li>

        <h5>3.ആരോഗ്യ ആഘാതം:</h5>
        <li>കർഷകരും കർഷകത്തൊഴിലാളികളും കീടനാശിനികളിൽ നിന്നും കളനാശിനികളിൽ നിന്നുമുള്ള രാസ അവശിഷ്ടങ്ങൾക്ക് വിധേയരായേക്കാം, ഇത് ആരോഗ്യപ്രശ്നങ്ങളിലേക്ക് നയിച്ചേക്കാം.</li>

        <h5>4. കാർബൺ കാൽപ്പാട്: </h5>
        <li>അജൈവ കൃഷിരീതികൾ, പ്രത്യേകിച്ച് സിന്തറ്റിക് വളങ്ങളുടെ ഉപയോഗം ഉൾപ്പെടുന്നവ, കാലാവസ്ഥാ വ്യതിയാനത്തിന് കാരണമാകുന്ന ഹരിതഗൃഹ വാതക ഉദ്‌വമനത്തിന് കാരണമാകുന്നു.</li>

        <h5>5. ദീർഘകാല സുസ്ഥിരത:</h5>
        <li>മണ്ണിന്റെ ശോഷണം, ജൈവവൈവിധ്യം കുറയൽ, രാസവസ്തുക്കളോടുള്ള കീട പ്രതിരോധം വർധിപ്പിക്കൽ എന്നിവ കാരണം അജൈവ നെൽകൃഷി രീതികൾ ദീർഘകാലത്തേക്ക് സുസ്ഥിരമാകണമെന്നില്ല.
        </li>
        </ul>

      
       
    
    <img src="../img/ori2.jpg"></img>
    </div>
    </div>
  </section>




<div className="freepik">
<h5 className="cardtitle">ഇതുമായി ബന്ധപ്പെട്ട പ്രധാന പോയിന്റുകളുടെ ഒരു അവലോകനം ഇതാ <span> കർഷക നെല്ല്
ലീഗ്</span>സംരംഭം:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ഞങ്ങളുടെ ദൗത്യം</Card.Title>
        <Card.Text>
        ഇന്ത്യയിലുടനീളമുള്ള പേരാവൂരണി താലൂക്കിലും അതിനപ്പുറവും കർഷകർക്കിടയിൽ നെൽകൃഷി പ്രോത്സാഹിപ്പിക്കുകയും പുനരുജ്ജീവിപ്പിക്കുകയും ചെയ്യുക എന്ന ലക്ഷ്യമാണ് ഞങ്ങളുടെ സംരംഭത്തിന്റെ കാതൽ. "പോഷക-ധാന്യങ്ങൾ" എന്ന് പലപ്പോഴും വിളിക്കപ്പെടുന്ന നെല്ല്, അതിന്റെ അസാധാരണമായ പോഷകമൂല്യത്തിനും പരിസ്ഥിതി സൗഹൃദ കൃഷിരീതികൾക്കും പേരുകേട്ടതാണ്, ഇത് സുസ്ഥിര കൃഷിയുടെ മൂലക്കല്ലാക്കി മാറ്റുന്നു. </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ഴ ഫൗണ്ടേഷന്റെ പ്രതിബദ്ധത</Card.Title>
        <Card.Text>
        ഞങ്ങളുടെ സമർപ്പിത കർഷകർക്കിടയിൽ നെൽകൃഷി കിക്ക്സ്റ്റാർട്ട് ചെയ്യുന്നതിലും പിന്തുണയ്ക്കുന്നതിലും ഞങ്ങൾ ഒരു പ്രധാന പങ്ക് വഹിക്കുന്നു. നെൽക്കൃഷിയെ അവരുടെ കൃഷിരീതികളിൽ തടസ്സങ്ങളില്ലാതെ സമന്വയിപ്പിക്കാൻ കർഷകരെ സഹായിക്കുന്നതിന് ആവശ്യമായ വിഭവങ്ങൾ, സമഗ്ര പരിശീലനം, സാങ്കേതിക സഹായം എന്നിവ നൽകുന്നതിൽ ഞങ്ങളുടെ പിന്തുണ ഉൾപ്പെടുന്നു.

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ജൈവ നെൽകൃഷി തിരികെ കൊണ്ടുവരിക</Card.Title>
        <Card.Text>
        ഇന്ത്യൻ സംസ്ഥാന സർക്കാരുമായി ചേർന്ന് ജൈവ നെൽകൃഷി പ്രോത്സാഹിപ്പിക്കുകയും കൃഷിയിൽ പാർട്ട് ടൈം ജോലികൾക്കായി യുവാക്കളെ അണിനിരത്തുകയും ചെയ്യുക എന്നതാണ് ഞങ്ങളുടെ മുൻകൈ. ഴ കർഷകരും ഉപഭോക്തൃ കോൾ സെന്റർ വഴിയും ആഗോള വിപണിയിൽ ഞങ്ങളുടെ കർഷക ഉൽപ്പന്നങ്ങൾ നിർമ്മിക്കുന്നതിനുള്ള ഡിജിറ്റൽ തന്ത്രങ്ങളിലൂടെ ഒരു വിതരണ ശൃംഖല സൃഷ്ടിക്കുക. ഗുണങ്ങൾ ബഹുവിധമാണ്: ഭക്ഷ്യസുരക്ഷ മെച്ചപ്പെടുത്തൽ, മണ്ണിന്റെ ആരോഗ്യം വർധിപ്പിക്കൽ, ജലം കൂടുതലുള്ള ജൈവവിളകളെ അപേക്ഷിച്ച് ജല ഉപഭോഗം ഗണ്യമായി കുറയ്ക്കുകയും ZHA ഫൗണ്ടേഷൻ മുഖേന ജൈവ ഉൽപ്പന്നങ്ങൾ സാക്ഷ്യപ്പെടുത്തുകയും ചെയ്യുന്നു.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>കർഷക നെല്ല് ലീഗ്</Card.Title>
        <Card.Text>
        ഞങ്ങൾ ഒരു നൂതന ആശയം അവതരിപ്പിക്കുകയാണ് - "കർഷകരുടെ നെല്ല് ലീഗ്". അപേക്ഷകളുടെ അടിസ്ഥാനത്തിൽ കർഷകരെ വിവിധ ടീമുകളായി ക്രമീകരിച്ച്, ഴ ടീം മുഖേന സംഘടിപ്പിക്കുകയും സർക്കാർ കാർഷിക വിദഗ്ധർ റഫറി ചെയ്യുകയും ചെയ്യുക. അതിനാൽ ആരോഗ്യകരമായ മത്സരം ഉത്തേജിപ്പിക്കാനും നമ്മുടെ കർഷക സമൂഹങ്ങൾക്കിടയിൽ സഹകരണം വളർത്താനും നമുക്ക് ലക്ഷ്യമിടുന്നു. ഈ ലീഗുകൾ സൗഹൃദം പ്രോത്സാഹിപ്പിക്കുക മാത്രമല്ല, പങ്കാളികൾക്കിടയിൽ ഐക്യബോധം വളർത്തുകയും ചെയ്യുന്നു. ആദ്യ മൂന്ന് വിജയികൾക്ക് ഞങ്ങൾ ക്യാഷ് വിലകളും പങ്കെടുക്കുന്ന എല്ലാവർക്കും സർട്ടിഫിക്കറ്റുകളും നൽകുന്നു.</Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>വിദ്യാഭ്യാസവും അവബോധവും</Card.Title>
        <Card.Text>
        ലീഗിനപ്പുറം വിദ്യാഭ്യാസത്തിനാണ് പ്രാധാന്യം. നെൽകൃഷിയുടെ നേട്ടങ്ങൾ, സുസ്ഥിര കൃഷിരീതികൾ, നെല്ല് അടിസ്ഥാനമാക്കിയുള്ള ഭക്ഷണക്രമത്തിന്റെ പോഷക മേന്മ എന്നിവയെക്കുറിച്ച് അവബോധം പ്രചരിപ്പിക്കുന്നതിൽ ഞങ്ങൾ വിശ്വസിക്കുന്നു. ഈ സംരംഭത്തിൽ സജീവമായി പങ്കെടുക്കാൻ കൂടുതൽ കർഷകരെ പ്രചോദിപ്പിക്കുക എന്നതാണ് ഞങ്ങളുടെ ദൗത്യം.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>വിപണി പ്രവേശനം</Card.Title>
        <Card.Text>
        നമ്മുടെ കർഷകർക്ക് ന്യായമായ വിപണി പ്രവേശനം ഉറപ്പാക്കുക എന്നത് ഞങ്ങളുടെ വിജയത്തിന്റെ പ്രധാന ഘടകമാണ്. ഞങ്ങളുടെ കർഷകർക്ക് അവരുടെ നെല്ലുൽപ്പന്നങ്ങൾ ന്യായമായ വിലയ്ക്ക് വിൽക്കാൻ കഴിയുമെന്ന് ഉറപ്പാക്കിക്കൊണ്ട് ശക്തമായ വിപണി ബന്ധം സൃഷ്ടിക്കാൻ ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്.</Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>സുസ്ഥിരത</Card.Title>
        <Card.Text>
        പരമ്പരാഗത നെല്ലിനങ്ങൾ സംരക്ഷിക്കുക, വിത്ത് സംരക്ഷിക്കൽ പ്രോത്സാഹിപ്പിക്കുക, പ്രകൃതിദത്ത കൃഷിരീതികൾ പ്രോത്സാഹിപ്പിക്കുക എന്നിവ നെൽകൃഷിയെക്കുറിച്ചുള്ള ഞങ്ങളുടെ ദീർഘകാല കാഴ്ചപ്പാടിൽ ഉൾപ്പെടുന്നു. സുസ്ഥിരത ഒരു ലക്ഷ്യമല്ല; അതൊരു ജീവിതരീതിയാണ്.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>നയ പിന്തുണ</Card.Title>
        <Card.Text>
        ഞങ്ങൾ മാറ്റത്തിന് വേണ്ടി വാദിക്കുന്നവരാണ്. പ്രാദേശിക തലത്തിലും ദേശീയ തലത്തിലും പിന്തുണ നൽകുന്ന നയങ്ങൾക്കായി ഞങ്ങൾ അശ്രാന്തമായി പ്രവർത്തിക്കുന്നു. നെൽകൃഷിക്കും ഉപഭോഗത്തിനും അനുകൂലമായ ആനുകൂല്യങ്ങൾ, സബ്‌സിഡികൾ, നയ ക്രമീകരണങ്ങൾ എന്നിവ ഇതിൽ ഉൾപ്പെടുന്നു.</Card.Text> </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>പ്രധാന ഉടമസ്ഥത <span>കർഷക നെല്ല് ലീഗ്:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;