import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
 
 
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Family-Wellness-Program-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}
 
            <div className="social-ma">

{/* <h1>Zha Husband Rescue Program: Proactive Counseling for Family Wellness
</h1> */}
<h1>Zha Free Mind Wellness Counselling</h1>

<div className='second-mcontent'>
                    <img src='../../img/membershipwellness.webp'></img>
                </div>

<p>Zha Free Mind Wellness Counselling for needy people such as students, employees, workers, and older people 
</p>
<p>
Zha Foundation's call center plays a crucial role in enabling sustainable lives for the poor and needy. The program focuses on providing emotional support, counseling, and information on mental health remedies. Activities include:
</p>


<ol className="goalpoints">
     

<li><span>Helpline Counseling : </span>Trained professionals offer confidential counseling to individuals facing mental struggles or emotional distress, fostering emotional well-being.
 </li>

 <li><span>Educational Outreach : </span>The call center disseminates information about remedies for depression, anxiety, and suicidal thoughts, promoting mental health awareness within the community.

 </li>
 <li><span>Resource Navigation : </span>Connecting callers with local resources such as healthcare services, community support programs, and other assistance to address holistic needs.

 </li>
 <li><span>Follow-up Support : </span>Establishing a system for periodic follow-up calls to ensure ongoing support and monitor progress, contributing to the long-term mental health of individuals.

 </li>
 <li><span>Collaboration with Experts : </span>Partnering with mental health professionals and organizations to enhance the depth and breadth of support services provided through the helpline.

 </li>


 </ol>


<p>By combining emotional support with practical assistance, Zha Foundation's call center contributes to the holistic well-being of individuals, fostering sustainability in their lives.</p>

<h1>Zha Family Wellness Program: Proactive Counseling for Harmonious Living</h1>

<div className='second-mcontent'>
                    <img src='../../img/family.webp'></img>
                </div>
 
 


 
<h3>Objective:</h3>
<p>The Zha Family Wellness Program is a comprehensive initiative designed to promote harmonious living by offering proactive counseling to prevent stress, anxiousness, and arguments. This program is tailored to strengthen the emotional well-being of husbands, wives, and children, fostering a resilient family unit capable of overcoming challenges together.
</p>

 

<ol className="goalpoints">
    <h3>Program Components:</h3>

<li><span>Proactive Family Counseling Sessions : </span>  
<ul>
    <li>Specialized counseling sessions for husbands, wives, and children to address individual and collective stressors.



</li>
    <li>Emphasis on building effective communication, understanding each other's perspectives, and enhancing family dynamics.



</li>
</ul>
</li> 

<li><span>Holistic Wellness Workshops : </span>  
<ul>
    <li>Tailored workshops covering a range of topics, including stress management, conflict resolution, and fostering emotional resilience.




</li>
    <li>Interactive sessions for the entire family to participate in and learn together.





</li>
</ul>
</li> 

<li><span>Family Bonding Activities : </span>  
<ul>
    <li>Incorporate activities that promote family bonding and teamwork, reinforcing a sense of unity.


</li>
    <li>Outdoor events, creative workshops, and collaborative projects to strengthen familial connections.





</li>
</ul>
</li> 

<li><span>Individualized Wellness Plans : </span>  
<ul>
    <li>Personalized wellness plans developed for each family member based on their unique needs and challenges.
</li>
    <li>Ongoing support to implement and adjust these plans as circumstances evolve.
</li>
</ul>
</li> 

<li><span>Parenting Support and Education : </span>  
<ul>
    <li>Workshops and resources specifically designed for parents to enhance their parenting skills.
</li>
    <li>Guidance on effective communication with children, positive discipline strategies, and fostering a nurturing home environment.
</li>
</ul>
</li> 
<li><span>Youth Empowerment Sessions : </span>  
<ul>
    <li>Special counseling sessions and workshops dedicated to addressing the unique challenges faced by children and teenagers.
</li>
    <li>Encouragement of open dialogue and the development of coping mechanisms.
</li>
</ul>
</li> 

</ol>

 
 

 

 

 

 
<h3>Benefits:</h3>
<ul className="goalpoints">
  <li>Strengthened family bonds through effective communication and understanding.</li>  
  <li>Reduction in stress, anxiousness, and arguments within the family.</li>  
  <li>Enhanced emotional resilience for both parents and children.</li>  
  <li>Development of teamwork and problem-solving skills within the family unit.</li>  
  <li>Promotion of a positive and supportive home environment.

</li>  
 
</ul>


<h3>How to Participate:</h3>
<ul className="goalpoints">
  <li>Families interested in the Zha Family Wellness Program can enroll by reaching out to Zha Foundation through their dedicated counseling services hotline or website.</li>  
  <li>Participation is voluntary, confidential, and open to families seeking proactive support for holistic well-being.</li>  
 
 
</ul>
     
       
       <p>The Zha Family Wellness Program is designed to empower families to navigate life's challenges as a united team, fostering a healthy and supportive environment for all members. Feedback on this program or suggestions for improvement are welcome.</p>
       
       
        </div>


        </div>




 
        


    );
}

