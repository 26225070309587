import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Csr.css';
import Pptx from './Pptx';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/How-Zha-Use-CSR-Funds">English</NavLink></a>

        </div>
            

            <div className="social-ma">

<h1>Zha CSR నిధులను ఎలా ఉపయోగిస్తుంది
</h1>

<Pptx />
 
 


 

<p>ఝా ఫౌండేషన్ కార్పొరేట్ సామాజిక బాధ్యత (CSR) నిధులను క్రింది స్తంభాలతో సమలేఖనం చేయడం ద్వారా దాని స్థిరత్వ కార్యక్రమాలలో వ్యూహాత్మకంగా పెట్టుబడి పెట్టవచ్చు:
</p>

 

<ol className="goalpoints">

<li><span>మానవ ఆరోగ్య స్తంభం : </span>  
<ul>
    <li>ఆరోగ్య సంరక్షణ కార్యక్రమాలు, మానసిక ఆరోగ్య అవగాహన మరియు మొత్తం శ్రేయస్సును ప్రోత్సహించే కార్యక్రమాల కోసం నిధులను కేటాయించండి.


</li>
    <li> పోషకాహారం మరియు నివారణ ఆరోగ్య సంరక్షణ చర్యలతో సహా ఆరోగ్యం మరియు ఆరోగ్యంపై దృష్టి సారించే విద్యా ప్రాజెక్టులలో పెట్టుబడి పెట్టండి.


</li>
</ul>
</li> 

<li><span>ప్రకృతి సంరక్షణ స్తంభం :</span>  
<ul>
    <li>జీవవైవిధ్య పరిరక్షణ, అటవీ నిర్మూలన మరియు సహజ ఆవాసాల రక్షణను ప్రోత్సహించే నిధుల ప్రాజెక్టులు.



</li>
    <li>పర్యావరణ అనుకూల పద్ధతులు మరియు పర్యావరణ వ్యవస్థల ఆరోగ్యాన్ని మెరుగుపరిచే స్థిరమైన వ్యవసాయ కార్యక్రమాలలో పెట్టుబడి పెట్టండి.



</li>
</ul>
</li> 

<li><span>ఎర్త్ వెల్నెస్ పిల్లర్ : </span>  
<ul>
    <li>పునరుత్పాదక శక్తి కార్యక్రమాలు మరియు కార్బన్ ఆఫ్‌సెట్ ప్రోగ్రామ్‌ల వంటి వాతావరణ మార్పుల ఉపశమనాన్ని మరియు అనుసరణను పరిష్కరించే ప్రాజెక్ట్‌లకు మద్దతు ఇవ్వండి.

</li>
    <li>నీటి సంరక్షణ మరియు పారిశుద్ధ్య ప్రాజెక్టులలో పెట్టుబడి పెట్టండి, గ్రహం యొక్క ముఖ్యమైన వనరుల ఆరోగ్యానికి దోహదపడుతుంది.





</li>
</ul>
</li> 

<li><span>స్థిరమైన లాభాల స్తంభం : </span>  
<ul>
    <li>స్థిరమైన వ్యాపార పద్ధతులకు కట్టుబడి స్థిరమైన లాభాలను ఆర్జించే సామాజిక సంస్థలను స్థాపించండి లేదా మద్దతు ఇవ్వండి.



</li>
    <li> దీర్ఘ-కాల ప్రభావం కోసం స్వీయ-నిరంతర నమూనాను సృష్టించే ZHA ఫౌండేషన్ యొక్క మిషన్‌తో సమలేఖనం చేసే ఆదాయ-ఉత్పత్తి ప్రాజెక్ట్‌లలో పెట్టుబడి పెట్టండి.



</li>
</ul>
</li> 

</ol>

<p>
ZHA ఫౌండేషన్ యొక్క CSR పెట్టుబడి వ్యూహంలో ఈ స్తంభాలను ఏకీకృతం చేయడం ద్వారా, సంస్థ మానవ ఆరోగ్యం, ప్రకృతి పరిరక్షణ, భూమి యొక్క శ్రేయస్సు మరియు ఆర్థిక స్థిరత్వాన్ని సూచించే సమగ్ర విధానాన్ని రూపొందించవచ్చు. ఈ బహుముఖ వ్యూహం సమాజాలు మరియు పర్యావరణం రెండింటిపై సమతుల్య మరియు సమగ్ర ప్రభావాన్ని నిర్ధారిస్తుంది, మెరుగైన భవిష్యత్తు కోసం స్థిరమైన మనస్తత్వాన్ని పెంపొందించే ఫౌండేషన్ యొక్క లక్ష్యంతో సమలేఖనం చేస్తుంది.
 </p>

 

 <br></br>

 <p>ZHA ఫౌండేషన్ కింది విధానాల ద్వారా సుస్థిరత కార్యక్రమాలను ప్రోత్సహించడానికి కార్పొరేట్ సామాజిక బాధ్యత (CSR) నిధులను కూడా వ్యూహాత్మకంగా ఉపయోగించుకోవచ్చు:

</p>

 

<ol className="goalpoints">

<li><span>పర్యావరణ పరిరక్షణ ప్రాజెక్టులు : </span>  
<ul>
    <li>ఆరోగ్య సంరక్షణ కార్యక్రమాలు, మానసిక ఆరోగ్య అవగాహన మరియు మొత్తం శ్రేయస్సును ప్రోత్సహించే కార్యక్రమాల కోసం నిధులను కేటాయించండి.


</li>
 
</ul>
</li> 

<li><span>గ్రీన్ ఇన్‌ఫ్రాస్ట్రక్చర్ డెవలప్‌మెంట్ : </span>  
<ul>
    <li>పర్యావరణ అనుకూల భవనాలు, ఇంధన-సమర్థవంతమైన సాంకేతికతలు లేదా హరిత రవాణా వంటి స్థిరమైన మౌలిక సదుపాయాల అభివృద్ధికి నిధులను కేటాయించండి.



</li>
    
</ul>
</li> 

<li><span>కమ్యూనిటీ ఆధారిత సుస్థిర వ్యవసాయం : </span>  
<ul>
    <li>సేంద్రీయ వ్యవసాయం, పెర్మాకల్చర్ లేదా కమ్యూనిటీ గార్డెన్‌లపై దృష్టి సారించే స్థిరమైన వ్యవసాయ ప్రాజెక్టుల ద్వారా స్థానిక కమ్యూనిటీలకు మద్దతు ఇవ్వండి, పర్యావరణం మరియు ఆహార స్థిరత్వం రెండింటికీ దోహదపడుతుంది.


</li>
     
</ul>
</li> 

<li><span>సర్క్యులర్ ఎకానమీ ఇనిషియేటివ్స్ : </span>  
<ul>
    <li>వ్యర్థాలను తగ్గించడం మరియు రీసైక్లింగ్, అప్‌సైక్లింగ్ లేదా పదార్థాల పునర్వినియోగాన్ని ప్రోత్సహించడం ద్వారా వృత్తాకార ఆర్థిక వ్యవస్థను ప్రోత్సహించే ప్రాజెక్ట్‌లలో పెట్టుబడి పెట్టండి.




</li>
    
</ul>
</li> 


<li><span>జీవవైవిధ్య పరిరక్షణ :</span>  
<ul>
    <li>సహజ ఆవాసాలను సంరక్షించడం, అంతరించిపోతున్న జాతులను రక్షించడం మరియు స్థిరమైన పర్యావరణ వ్యవస్థ నిర్వహణను ప్రోత్సహించడం వంటి కార్యక్రమాలకు మద్దతు ఇవ్వడం ద్వారా జీవవైవిధ్యాన్ని రక్షించడానికి మరియు పునరుద్ధరించడానికి CSR నిధులను ఉపయోగించండి.


</li>
 
</ul>
</li> 

<li><span>నీరు మరియు శక్తి సంరక్షణ : </span>  
<ul>
    <li>నీరు మరియు శక్తి పరిరక్షణ ప్రాజెక్ట్‌లలోకి ఛానల్ ఫండ్స్, వినియోగాన్ని తగ్గించే మరియు సమర్థవంతమైన వనరుల వినియోగాన్ని ప్రోత్సహించే సాంకేతికతలు మరియు అభ్యాసాలను అమలు చేయడం.





</li>
    
</ul>
</li> 

<li><span>విద్య మరియు అవగాహన కార్యక్రమాలు : </span>  
<ul>
    <li>సుస్థిరత సమస్యల గురించి అవగాహన పెంచే విద్యా కార్యక్రమాలలో పెట్టుబడి పెట్టండి, పర్యావరణ అనుకూల పద్ధతులను ప్రోత్సహించండి మరియు స్థిరమైన జీవనశైలిని అవలంబించడానికి కమ్యూనిటీలను శక్తివంతం చేయండి.




</li>
     
</ul>
</li> 

<li><span>వాతావరణ మార్పు తగ్గింపు : </span>  
<ul>
    <li>కార్బన్ ఆఫ్‌సెట్ ప్రోగ్రామ్‌లు, స్థిరమైన రవాణా పరిష్కారాలు లేదా గ్రీన్‌హౌస్ వాయు ఉద్గారాలను తగ్గించడానికి ఉద్దేశించిన కార్యక్రమాలు వంటి వాతావరణ మార్పుల ఉపశమనానికి దోహదపడే ప్రాజెక్ట్‌లకు మద్దతు ఇస్తుంది.
</li>
    
</ul>
</li> 



<li><span>పర్యావరణ దృష్టితో సామాజిక సంస్థలు : </span>  
<ul>
    <li>పర్యావరణ బాధ్యతను ప్రోత్సహిస్తూ ఆర్థికాభివృద్ధిని పెంపొందించడం ద్వారా వారి వ్యాపార నమూనాలలో స్థిరత్వాన్ని ఏకీకృతం చేసే సామాజిక సంస్థలలో పెట్టుబడి పెట్టండి.




</li>
 
</ul>
</li> 

<li><span>స్థిరత్వం కోసం పరిశోధన మరియు ఆవిష్కరణ : </span>  
<ul>
    <li>స్థిరత్వ సవాళ్ల కోసం వినూత్న పరిష్కారాలను అన్వేషించే పరిశోధనా కార్యక్రమాలకు నిధులను కేటాయించండి, కొత్త సాంకేతికతలు లేదా అభ్యాసాల అభివృద్ధిని ప్రోత్సహిస్తుంది.







</li>
    
</ul>
</li> 

<li><span>సస్టైనబుల్ ప్రాక్టీసెస్ కోసం కెపాసిటీ బిల్డింగ్ :</span>  
<ul>
    <li>స్థిరమైన పద్ధతులను అవలంబించడం, దీర్ఘకాలిక ప్రభావం కోసం శిక్షణ మరియు వనరులను అందించడంలో సంఘాలు మరియు సంస్థల సామర్థ్యాలను మెరుగుపరచడానికి CSR నిధులను ఉపయోగించండి.




</li>
     
</ul>
</li> 

<li><span>సస్టైనబిలిటీ ఇంపాక్ట్‌ని కొలవడం మరియు నివేదించడం : </span>  
<ul>
    <li>వాటాదారులకు పారదర్శకత మరియు జవాబుదారీతనాన్ని నిర్ధారించడం, స్థిరత్వ కార్యక్రమాల ప్రభావాన్ని కొలవడానికి మరియు నివేదించడానికి బలమైన వ్యవస్థలలో పెట్టుబడి పెట్టండి.
</li>
    
</ul>
</li> 
</ol>

<p>

స్థిరత్వ కార్యక్రమాలతో CSR నిధులను సమలేఖనం చేయడం ద్వారా, లాభాపేక్ష లేని సంస్థలు పర్యావరణ సవాళ్లను పరిష్కరించడంలో, స్థితిస్థాపకతను పెంపొందించడంలో మరియు కమ్యూనిటీలు మరియు గ్రహం కోసం మరింత స్థిరమైన భవిష్యత్తుకు దోహదం చేయడంలో కీలక పాత్ర పోషిస్తాయి.</p>
       
       
       
       
        </div>


        </div>




 
        


    );
}

