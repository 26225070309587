import React from 'react';
import './sustainabilitymebership.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>


<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>


        {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainability-Club-Membership-Ka">Kannada</NavLink></a>
        </div> */}

{/* <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div> */}

       
        <h1 id="mhead">Zha Sustainability Board Membership</h1>
   
        <div className='second-mcontent'>
                <img src='../../img/membership.webp'></img>
            </div>


        <p className='tone'>There are three different zha board membership that is provided,
</p>
                <span>
<div className='wellnesspoints'> 
<p>1) Active Board
</p>
<p>2) Advisory Board
 </p>
<p>3) Witness Board</p>
</div>
</span>

        <div className='second-mcontent'>
           
           
            <div>
             

           <p>It's a great way to stay updated on the latest trends and best practices in sustainability, and to connect with like-minded individuals around the world. Keep in mind that specific offerings can vary between different organizations, so it's a good idea to research and compare options before choosing a membership program.

            </p>
<p>
Joining a sustainability club can be a powerful way to develop a sustainability mindset. Through membership, you're likely to engage in discussions, attend events, and collaborate on projects focused on sustainable practices. This exposure can deepen your understanding of environmental, social, and economic impacts, and help you integrate sustainable thinking into your professional and personal life. Additionally, networking with fellow members can provide valuable insights and perspectives, fostering a holistic approach to sustainability. Remember to actively participate and contribute to make the most out of your club membership.Our ZHA sustainability professional membership focuses on three pillars such as 
</p>
<p>
</p>
<span>
<div className='wellnesspoints'> 
<p>1) human wellness membership</p>
<p>2) nature wellness membership </p>
<p>3) earth wellness membership</p>
</div>
</span>
           </div>
        </div>





        <h2>Sustainable human wellness membership
</h2>      
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/membershipwellness.webp'></img>
            </div>
            <div>
           <p>Sustainable human wellness encompasses practices and lifestyles that promote well-being while also considering the long-term health of individuals and the planet. It involves making choices that support physical, mental, and emotional health, as well as considering the environmental and social impacts of those choices.

            </p>
            <h4>This can include things like:

</h4>

<ol>
    <li><span>Nutrition : </span>Choosing locally-sourced, organic, and plant-based foods to reduce environmental impact and support personal health.

</li>
    <li><span>Physical Activity : </span>Engaging in exercise and outdoor activities that promote fitness and mental well-being, while also minimizing harm to the environment.

</li>
    <li><span>Mental Health : </span>Incorporating practices like mindfulness, meditation, and spending time in nature to support mental and emotional well-being.

</li>
    <li><span>Community and Social Connections : </span>Building and maintaining strong social ties, while also participating in activities that contribute positively to the community and society at large.

</li>
    <li><span>Mindful Consumption : </span>Being conscious of the products and services you consume, opting for sustainable and ethically-produced options.

</li>
    <li><span>Reducing Waste : </span>Adopting practices like recycling, composting, and minimizing single-use items to reduce your environmental footprint.

</li>
    <li><span>Balanced Work-Life Integration : </span>Finding a healthy balance between work, personal life, and leisure activities to support overall well-being.
</li>
    <li><span>Self care and self love and appreciating others : </span>Advocating for and supporting policies that ensure everyone has access to quality healthcare.
</li> 
</ol>

<p>By adopting practices that promote sustainable human wellness, individuals can lead healthier, more fulfilling lives while also contributing positively to the health and well-being of the planet and its communities.</p>
           </div>
        </div>







        
        <h2>Sustainable nature wellness membership
</h2>      
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/membershipnature.webp'></img>
            </div>
            <div>
           <p>Sustainable nature wellness involves fostering a harmonious and mutually beneficial relationship between humans and the natural world. It emphasizes the importance of not only preserving and protecting nature, but also recognizing the profound impact it has on our physical, mental, and emotional well-being.


            </p>
            <h4>Key elements of sustainable nature wellness include:

</h4>

<ol>
    <li><span>Time Spent in Nature : </span>Regularly immersing oneself in natural environments, whether it be parks, forests, beaches, or gardens, to experience the therapeutic benefits of the outdoors.

</li>
    <li><span>Nature Conservation and Restoration : </span>Actively participating in efforts to protect and restore natural habitats, including initiatives like reforestation, wetland conservation, and wildlife preservation.

</li>
    <li><span>Biophilic Design and Architecture : </span>Incorporating elements of nature into the design of living and working spaces to enhance well-being and promote a sense of connection to the natural world.

</li>
    <li><span>Wildlife and Biodiversity Appreciation : </span>Valuing and respecting the diverse array of species that share the planet with us, and understanding the crucial role they play in maintaining ecological balance.

</li>
    <li><span>Nature-Based Therapies : </span>Engaging in practices like forest bathing, ecotherapy, and nature meditation to promote mental and emotional well-being.

</li>
    <li><span>Responsible Outdoor Activities : </span>Participating in recreational activities like hiking, birdwatching, and camping in a manner that minimizes impact on the environment and respects wildlife.


</li>
    <li><span>Environmental Education and Awareness : </span>Continually learning about the ecosystems, species, and natural processes that make up the world around us, and advocating for their protection.

</li>
    <li><span>Supporting Sustainable Agriculture and Food Systems : </span>Choosing food options that are sourced and produced in a way that respects and enhances natural ecosystems.
</li>
    <li><span>Advocacy for Conservation Policies : </span>Supporting policies and initiatives that promote sustainable land use, wildlife protection, and conservation efforts at local, national, and global levels.
</li>
</ol>
<p>Sustainable nature wellness recognizes that our well-being is intimately tied to the health and vitality of the natural world. By nurturing this relationship and taking actions to protect and preserve nature, we not only enhance our own health and happiness,</p>

           </div>
        </div>






        <h2>Sustainable earth wellness membership
</h2>      
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/membership earth.webp'></img>
            </div>
            <div>
           <p>Sustainable Earth wellness involves nurturing the health and well-being of the planet as a whole, ensuring that natural systems can thrive and support life for generations to come. It encompasses a holistic approach to environmental stewardship, considering the interconnectedness of ecosystems, species, and human communities.

            </p>
            <h4>Key aspects of sustainable Earth wellness include:
</h4>

<ol>
    <li><span>Biodiversity Conservation : </span>Protecting and preserving the diversity of life forms and ecosystems, recognizing the value of each species and their roles in maintaining a balanced ecosystem.
</li>
    <li><span>Climate Stewardship : </span>Taking action to mitigate climate change by reducing greenhouse gas emissions, transitioning to renewable energy sources, and adapting to the impacts of a changing climate.
</li>
    <li><span>Resource Efficiency : </span>Using resources (such as water, energy, and materials) in a way that minimizes waste and environmental degradation, and supports the long-term availability of these resources.
</li>
    <li><span>Regenerative Agriculture : </span>Promoting farming practices that enhance soil health, biodiversity, and ecosystem resilience, while providing nutritious food for communities.
</li>
    <li><span>Conservation of Natural Habitats : </span>Preserving and restoring natural habitats, including forests, wetlands, and oceans, to provide critical ecosystem services and support wildlife.
</li>
    <li><span>Circular Economy Practices : </span>Embracing a circular economy model that minimizes waste, encourages recycling, and promotes the sustainable use of resources.
</li>
    <li><span>Ethical and Sustainable Business Practices : </span>Encouraging businesses to operate with transparency, fairness, and a commitment to environmental and social responsibility.
</li>
    <li><span>Global Cooperation : </span>Fostering international collaboration to address global environmental challenges, recognizing that many environmental issues transcend national borders.</li>
    <li><span>Environmental Education and Advocacy : </span>Promoting awareness, education, and</li>
</ol>

           </div>
        </div>









               
        
       

    </div>
);
}