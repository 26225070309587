import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


export default function Ecofriendly() {
    return (
        <div>

            
            
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Wellness-Program">English</NavLink></a>
         
        </div>
{/* 
            <div  id="language" >

        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a>
        

        </div> */}

<h1 id="mhead">ഴ വെൽനസ് പ്രോഗ്രാം</h1>

   
<div className='second-mcontent'>
           <img src='../../img/self.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>ഴയുടെ ശാരീരികവും മാനസികവുമായ വെൽനസ് ഫ്രീ കോച്ചിംഗ് വൈവിധ്യമാർന്ന പ്രവർത്തനങ്ങളെ ഉൾക്കൊള്ളുന്നു, ക്ഷേമത്തിനായുള്ള സമഗ്രമായ സമീപനം ഉറപ്പാക്കുന്നു. ഇതിൽ ഉൾപ്പെടുന്നു:


</p>
 
<ol className="goalpoints">

	
   
   <li><span>അത്‌ലറ്റിക് പരിശീലന പരിപാടികൾ :</span>മാരത്തൺ, സൈക്ലിംഗ്, വോളിബോൾ തുടങ്ങിയ വിവിധ വിഷയങ്ങൾ ഉൾക്കൊള്ളുന്ന ശാരീരിക ക്ഷമതയ്‌ക്കായി തയ്യാറാക്കിയ പ്രോഗ്രാമുകൾ.

</li>

<li><span>ശ്രദ്ധാപൂർവ്വമായ പ്രവർത്തനങ്ങൾ : </span> ശാരീരിക പരിശീലനത്തിനപ്പുറം, പങ്കെടുക്കുന്നവർക്ക് ധ്യാനം, യോഗ തുടങ്ങിയ മാനസികാരോഗ്യ പ്രവർത്തനങ്ങളിൽ നിന്ന് പ്രയോജനം ലഭിക്കും.
 

</li>
<li><span>ഇൻഡോർ ചെസ്സ് :</span>  ചെസ്സ് പോലുള്ള തന്ത്രപ്രധാനമായ ഗെയിമുകളിലൂടെ മനസ്സിനെ ഇടപഴകുക, വൈജ്ഞാനിക വികാസവും മാനസിക ചടുലതയും പ്രോത്സാഹിപ്പിക്കുക.

</li>
<p>
ഴ വെൽനെസ് പ്രോഗ്രാമിന് ക്ഷേമത്തിന്റെ ശാരീരികവും മാനസികവുമായ വശങ്ങളെ അഭിസംബോധന ചെയ്യുന്ന സമഗ്രമായ സമീപനമുണ്ട്, കോച്ചിംഗ് പ്രോഗ്രാമിൽ പങ്കെടുക്കുന്ന വ്യക്തികൾക്ക് സമതുലിതമായതും ആരോഗ്യകരവുമായ ഒരു ജീവിതശൈലി വളർത്തിയെടുക്കുന്നു.</p>

<p>വിദ്യാർത്ഥികൾക്കായി ഗ്രാമീണ, സബർബൻ പ്രദേശങ്ങളിൽ Zha സൗജന്യ ശാരീരികവും മാനസികവുമായ ആരോഗ്യ പരിശീലനം നിരവധി ആനുകൂല്യങ്ങൾ വാഗ്ദാനം ചെയ്യുന്നു:</p>


</ol>
        
 

<ol className="goalpoints"> 

<li><span>റിസോഴ്സുകളിലേക്കുള്ള പ്രവേശനം :</span>  ഗ്രാമീണ മേഖലയിലെ വിദ്യാർത്ഥികൾക്ക് സാമ്പത്തിക ഞെരുക്കം കാരണം അവർക്ക് ഇല്ലാത്ത കോച്ചിംഗ് പ്രോഗ്രാമുകളിലേക്ക് പ്രവേശനം ലഭിക്കുന്നു, ക്ഷേമത്തിനുള്ള തുല്യ അവസരങ്ങൾ പ്രോത്സാഹിപ്പിക്കുന്നു.


</li>
<li><span>മെച്ചപ്പെട്ട ശാരീരിക ആരോഗ്യം : </span>മാരത്തൺ, സൈക്ലിംഗ്, വോളിബോൾ തുടങ്ങിയ കായിക പരിശീലന പരിപാടികൾ, സജീവമായ ജീവിതശൈലി പ്രോത്സാഹിപ്പിക്കുന്നതിന് മെച്ചപ്പെട്ട ശാരീരിക ക്ഷമതയ്ക്ക് സംഭാവന നൽകുന്നു.


</li>
<li><span>മെച്ചപ്പെട്ട മാനസിക ക്ഷേമം :</span>ധ്യാനം, യോഗ ക്ലാസുകൾ, ഇൻഡോർ ചെസ്സ് എന്നിവ സമ്മർദ്ദം ഒഴിവാക്കുന്നതിനും മെച്ചപ്പെട്ട ഫോക്കസ്, മൊത്തത്തിലുള്ള മാനസിക പ്രതിരോധം എന്നിവയ്ക്കുള്ള ഉപകരണങ്ങൾ നൽകുന്നു.
</li>
<li><span>നൈപുണ്യ വികസനം : </span>വൈവിധ്യമാർന്ന പ്രവർത്തനങ്ങളിലെ പങ്കാളിത്തം ടീം വർക്ക്, അച്ചടക്കം, തന്ത്രപരമായ ചിന്ത എന്നിവയുൾപ്പെടെ വൈവിധ്യമാർന്ന കഴിവുകളുടെ വികസനം പ്രോത്സാഹിപ്പിക്കുന്നു.
</li>
<li><span>കമ്മ്യൂണിറ്റി ബിൽഡിംഗ് : </span> വെൽനസ് പ്രോഗ്രാമുകൾ പങ്കാളികൾക്കിടയിൽ കമ്മ്യൂണിറ്റിയുടെ ഒരു ബോധം സൃഷ്ടിക്കുന്നു, സാമൂഹിക ബന്ധങ്ങളും പിന്തുണാ ശൃംഖലകളും വളർത്തുന്നു.

</li>
<li><span>ശാക്തീകരണം :</span> വെല്ലുവിളികളെ നാവിഗേറ്റ് ചെയ്യുന്നതിനും ജീവിതത്തിന്റെ വിവിധ വശങ്ങളിൽ വിജയിക്കുന്നതിനും ശാരീരികവും മാനസികവുമായ ഉപകരണങ്ങൾ ഉപയോഗിച്ച് അവരെ സജ്ജരാക്കുന്നതിലൂടെ കോച്ചിംഗ് വിദ്യാർത്ഥികളെ ശാക്തീകരിക്കുന്നു.
</li>
<li><span>ആരോഗ്യകരമായ ജീവിതശൈലി പ്രോത്സാഹിപ്പിക്കുക : </span>ശാരീരിക പ്രവർത്തനങ്ങളും മാനസികാരോഗ്യ പ്രവർത്തനങ്ങളും പ്രോത്സാഹിപ്പിക്കുന്നതിലൂടെ, ആരോഗ്യകരമായ ജീവിതശൈലി സ്വീകരിക്കുന്നതിന് കോച്ചിംഗ് സംഭാവന ചെയ്യുന്നു, ജീവിതശൈലിയുമായി ബന്ധപ്പെട്ട ആരോഗ്യപ്രശ്നങ്ങളുടെ സാധ്യത കുറയ്ക്കുന്നു.
</li>


</ol>




<p>
മൊത്തത്തിൽ, സൗജന്യ കോച്ചിംഗ് പ്രോഗ്രാമുകൾ ഗ്രാമീണ, സബർബൻ പ്രദേശങ്ങളിലെ വിദ്യാർത്ഥികളുടെ ഉന്നമനവും ശാക്തീകരണവും ലക്ഷ്യമിടുന്നു, അവരുടെ സമഗ്രമായ ക്ഷേമം പരിപോഷിപ്പിക്കുകയും അവർക്ക് വിലപ്പെട്ട ജീവിത നൈപുണ്യങ്ങൾ നൽകുകയും ചെയ്യുന്നു.
 </p>


</section>
 

</div>
 
 
);
}

