import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Students-Sustainability-Club-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Students-Sustainability-Club-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Zha-Students-Sustainability-Club-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Zha-Students-Sustainability-Club-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Zha-Students-Sustainability-Club-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Zha Students Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/students.webp"></img>
        </div>
<div className="goalpointsp">

<p> The ZHA Students Sustainability Club Membership Program is a holistic initiative that encompasses the four pillars of sustainability established by the ZHA Foundation. This comprehensive program is designed to address the diverse facets of sustainability, ensuring a well-rounded and impactful experience for participating students.

</p>

<ol className="goalpoints">
    <li><span>Human Wellness Pillar : </span> </li>
    <ul>
        <li>The program promotes human wellness by offering educational materials and workshops that focus on sustainable living practices, mental health in connection with the environment, and personal well-being aligned with eco-conscious values.
 </li>
    </ul>

    <li><span>Earth Wellness Pillar : </span> </li>
    <ul>
        <li>Students engage in activities and projects that contribute to the well-being of the Earth. This includes learning about and actively participating in initiatives related to renewable energy, waste reduction, and eco-friendly technologies.
 </li>
    </ul>

    <li><span>Nature Wellness Pillar : </span> </li>
    <ul>
        <li>The program emphasizes a connection with nature, encouraging students to appreciate and protect biodiversity. Through events like tree planting, habitat restoration, and nature excursions, students develop a profound understanding of the importance of preserving the natural world.
 </li>
    </ul>

    <li><span>Consistent Profit Pillar : </span> </li>
    <ul>
        <li>Sustainability in the context of consistent profit is addressed by equipping students with knowledge about sustainable business practices. This pillar includes workshops on green entrepreneurship, responsible consumption, and strategies for businesses to thrive while prioritizing environmental and social responsibilities.
 </li>
    </ul>
  </ol>
<p>Overall, the ZHA Students Sustainability Club Membership Program stands out for its inclusivity, covering the full spectrum of sustainability pillars. By integrating human, Earth, and nature wellness with considerations for consistent profit, the program nurtures environmentally conscious leaders who are not only aware of the interconnectedness of these pillars but actively contribute to a balanced and sustainable future.</p>

<p>Schools and Colleges ZHA Students Sustainability Membership Program offers several benefits to schools through the introduction of sustainability clubs:

</p>
<ol className="goalpoints">
    <li><span>Enhanced Education : </span>Schools gain access to a sustainability coach who not only imparts knowledge but also facilitates experiential learning, fostering a deeper understanding of social responsibility and sustainability among students.

 </li>
    <li><span>Holistic Development : </span>The sustainability club concept promotes holistic development by integrating social responsibility and sustainability mindset into students' education, encouraging a sense of purpose and global citizenship.

 </li>
    <li><span>Practical Application : </span>The membership provides a platform for practical application of sustainability principles through club activities, projects, and events, allowing students to translate theoretical knowledge into real-world actions.

 </li>
    <li><span>Community Engagement : </span>Sustainability clubs encourage students to actively engage with their local communities, promoting social causes and creating a positive impact in the surrounding environment.

 </li>
    <li><span>Leadership Opportunities : </span>Students involved in sustainability clubs have the opportunity to take on leadership roles, fostering leadership skills and instilling a sense of responsibility in guiding their peers toward sustainable practices.

 </li>
    <li><span>Networking and Collaboration : </span>The club membership connects schools with a broader network of educational institutions participating in sustainability initiatives, creating opportunities for collaboration, knowledge sharing, and joint projects.

 </li>
    <li><span>Aligned with Curriculum Goals : </span>Integration of sustainability principles aligns with contemporary educational goals, providing schools with a framework to address global challenges and contribute to sustainable development.

 </li>
    <li><span>Positive School Culture : </span>The presence of a sustainability club contributes to a positive and forward-thinking school culture, emphasizing values such as environmental stewardship, social responsibility, and ethical conduct.

 </li>
    <li><span>Recognition and Accreditation : </span>Schools affiliated with the ZHA sustainability club gain recognition for their commitment to sustainability, potentially leading to accreditation or awards that highlight their efforts in promoting a sustainable educational environment.

 </li>
    <li><span>Preparation for the Future : </span>The sustainability club concept prepares students for the future by equipping them with the knowledge, skills, and values necessary to navigate a world increasingly focused on environmental and social sustainability.

 </li>
  </ol>

  <p>In summary, ZHA Students Sustainability Membership benefits schools by integrating sustainability education, fostering holistic development, and empowering students to actively contribute to a more sustainable and socially responsible future.</p>
     
</div>
        

        </div>

    );
}

