import React from "react";
import "./Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';




export default function Zhasports(){




    

    return(
        <div>
            <div className="social-ma">

<h1>Zha Cycling Club</h1>
</div>


<header>
    <div>
    <h2>Welcome to <br></br>Zha Cycling Club!</h2>
    <p>Only on weekends 6AM to 10AM</p>
    <a href="https://chat.whatsapp.com/F10QKeazorbBWpAPQuf6Ym"><Button className="clickto" variant="success">Click to Join</Button>{' '}</a>
    </div>
    <img src="../img/CYCLE/6.webp"></img>
</header>


<section className="cycle">
    <div className="aboutcycle">
<h2>About Us
</h2>
<p>Zha Initiates the Cycling Club for Heart Wellness.In an effort to promote physical fitness and heart wellness in the local community, the Zha Foundation Charitable Trust has taken a significant step by initiating the Zha Foundation Cycling Club. This new endeavor is set to make a positive impact on the lives of individuals of all age groups residing in the Peravurani Taluk area.The Zha Foundation Cycling Club is all set to hit the road every Saturday and Sunday morning at 6 AM. These weekend rides will be an excellent opportunity for enthusiasts to not only improve their cardiovascular health but also bond with like-minded individuals who share a passion for cycling.</p>
</div>
</section>

<div className="cyclese">
    <Card className="horicard" style={{ width: '18rem' }}>
      <Card.Img variant="top" src="../img/neelagandan.webp" />
      <Card.Body>
        <Card.Title>Mr.A.S.NEELAKANDAN B.A.,</Card.Title>
        <Card.Text>
          Zha Cylcing Club Secretary
        </Card.Text>
        <a href="https://wa.link/c11b3g"><Button variant="primary">Any enquiries</Button></a>
      </Card.Body>
    </Card>
    </div>

</div>



);
}