// import React, { useState } from 'react';

// import './Payment.css';
// import Tab from 'react-bootstrap/Tab';
// import Tabs from 'react-bootstrap/Tabs';
// // import Razorpay from 'razorpay';
// // import reportWebVitals from './reportWebVitals';


// function App() {
//     const [amount, setamount] = useState('');
//     const handleButtonClick = (buttonAmount) => {
//         setAmount(buttonAmount);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (amount === "") {
//             alert("please enter amount");

//         } else {
//             var options = {
//                 key: "rzp_test_X3PfpPuGcwtI7s",
//                 key_secret: "8NvdRDMIhcYcXRsLl1YUuWz3",
//                 amount: amount * 100,
//                 currency: "INR",
//                 name: "Zha Foundation",
//                 description: "You are paying to Zha Foundation",
//                 handler: function (response) {
//                     alert(response.razorpay_payment_id);
//                 },
//                 prefill: {
//                     name: "Abineshwaran",
//                     email: "abineshwaranelangovan@gmail.com",
//                     contact: "9385428945"
//                 },
//                 notes: {
//                     address: "Razorpay Corporate office"
//                 },
//                 theme: {
//                     color: "#3399cc"
//                 }
//             };
//             var pay = new window.Razorpay(options);
//       pay.open();

//         }
//     }
//     return (


//         <div className="Payout">
//      <h2>Donate Us</h2>

//             <Tabs
//                 defaultActiveKey="profile"
//                 id="uncontrolled-tab-example"
//                 className="mb-2"
//             >
              
//                 <Tab eventKey="home" title="One Time Gift">
//                 <p className='pannumber'>Zha Foundation PAN No:  </p>   
//             <br />
//             <div className='buttontype'>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('500')}>₹500</button>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('750')}>₹750</button>

//             </div>
//             <div className='buttontype'>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('1000')}>₹1000</button>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('1500')}>₹1500</button>
//             </div>
//             <br />
//             <div className='inputt'>
//                 <input
//                     type="text"
//                     placeholder="Enter Custom Amount"
//                     value={amount}
//                     onChange={(e) => setAmount(e.target.value)}
//                 />
//             </div>


//             <div className='buttontype'>
//                 <button class="button button-primary" onClick={handleSubmit}>Doante</button>
//             </div> 
//                 </Tab>
//                 <Tab eventKey="profile" title="Monthly Gift">
//                 <p className='pannumber'>Zha Foundation PAN No:  </p>  



                
//             <br />
//             <div className='buttontype'>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('750')}>₹750</button>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('1500')}>₹1500</button>

//             </div>
//             <div className='buttontype'>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('3000')}>₹3000</button>
//                 <button class="button button-secondary" onClick={() => handleButtonClick('7500')}>₹7500</button>
//             </div>
//             <br />
//             <div className='inputt'>
//                 <input
//                     type="text"
//                     placeholder="Enter Custom Amount"
//                     value={amount}
//                     onChange={(e) => setAmount(e.target.value)}
//                 />
//             </div>


//             <div className='buttontype'>
//                 <button class="button button-primary" onClick={handleSubmit}>Doante</button>
//             </div>
//                 </Tab>
            
//             </Tabs>

//             <p className='pannumber'>Zha Foundation does NOT accept cash & foreign donations.
// *All Donations to Zha Foundation are 50% Tax Exempt under section 80G of IT Act, 1961, terms & conditions apply.</p>
           
//         </div>
//     );
// }

// export default App();



import React,{useState} from 'react';
// import Razorpay from 'razorpay';

function Payment() {
  const [amount, setamount] = useState('');
  var pay = new window.Razorpay();
  pay.open();
  const handleSubmit = (e)=>{
    e.preventDefault();
    if(amount === ""){
    alert("please enter amount");
    }else{
      
      let options = {
        key: "rzp_test_X3PfpPuGcwtI7s",
        key_secret: "8NvdRDMIhcYcXRsLl1YUuWz3",
        amount: amount *100,
        currency:"INR",
        name:"STARTUP_PROJECTS",
        description:"for testing purpose",
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        prefill: {
          name:"Velmurugan",
          email:"mvel1620r@gmail.com",
          contact:"7904425033"
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme: {
          color:"#3399cc"
        }
      
      };
      
      const rzp = new window.Razorpay(options);
rzp.open();
     
      // const pay = new window.Razorpay(options);
      // pay.open();
   
    }
  }
  
  return (
    <div className="App">
     <h2>Razorpay Payment Integration Using React</h2>
     <br/>
     <input type="text"placeholder='Enter Amount'value={amount}onChange={(e)=>setamount(e.target.value)} />
     <br/><br/>
     <button onClick={handleSubmit}>submit</button>
    </div>
  );
}

export default Payment;
