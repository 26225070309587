import React from "react";
import "./Geofensing.css";
export default function Geofensing(){

    return(
        <div>
            <div className="social-ma">

<h1>Sustainable Energy Solutions</h1>
</div>
<div className="heroti">
                <h2>Sustainable Biofuel Feedstock Development through App-Guided Geo Fencing of Pongamia and Mahua Trees in Thanjavur District</h2>
<div className="float-element">
                <img src="../img/energysolution.webp"></img>
                </div>
                </div>
                
              <div className="projectover">
                <h2>Project Overview: </h2>
                <p> The goal of this project is to promote the sustainable production of biofuels by developing a comprehensive system that leverages technology and local resources. This system involves utilizing the Pongamia and Mahua trees as feedstock sources for biofuel production. The project focuses on creating an efficient supply chain, ensuring environmental sustainability, and supporting local farmers and communities. </p>
                </div>


<section className="poinnts">


                <h4>Key Components: </h4>
                <ol>
                    <li className="tobebold">Tree Selection and Geo Fencing:</li>
                    <ul>
                        <li>
                        Identify suitable Pongamia and Mahua tree clusters across Thanjavur district.
                        </li>
                        <li>Implement geo-fencing using a mobile application to mark and allocate specific tree clusters to local farmers.</li>
                    </ul>
               
                    <li className="tobebold">Local Farmer Engagement:</li>
                    <ul>
                        <li>
                        Allocate geo-fenced tree clusters to interested local farmers.
                        </li>
                        <li>Educate farmers about sustainable harvesting practices, emphasizing ecological preservation and long-term benefits.</li>
                    </ul>

                    <li className="tobebold">Seed Collection and Aggregation:</li>
                    <ul>
                        <li>
                        Local farmers gather seeds from the allocated trees within the geo-fenced areas.
                        </li>
                        <li>Seeds are collected and aggregated by the farmers, ensuring proper handling and storage.</li>
                    </ul>

                    <li className="tobebold">Supply Chain Management:</li>
                    <ul>
                        <li>
                        Establish a well-organized supply chain team responsible for collecting the aggregated seeds from the farmers.
                        </li>
                        <li>Transport the collected seeds to a central warehouse using eco-friendly transportation methods.</li>
                    </ul>

                    <li className="tobebold">Seed Processing:</li>
                    <ul>
                        <li>
                        Conduct seed quality checks and sorting at the warehouse to ensure optimal oil yield.
                        </li>
                        <li>Prepare the seeds for processing in oil crushing mills.</li>
                    </ul>

                    <li className="tobebold">Oil Extraction and Processing:</li>
                    <ul>
                        <li>
                        Send the bulk load of quality-assured seeds to oil crushing mills for oil extraction.
                        </li>
                        <li>The extracted oil is refined and prepared as biofuel feedstock, adhering to industry standards.</li>
                    </ul>

                    <li className="tobebold">Utilization of Byproducts:</li>
                    <ul>
                        <li>
                        The residue (cake) remaining after oil extraction is repurposed as organic fertilizer and animal feed.
                        </li>
                        <li>This step adds value to the process and supports sustainable agricultural practices.</li>
                    </ul>

                    <li className="tobebold">App-Based Tracking:</li>
                    <ul>
                        <li>
                        Develop a mobile application that tracks and documents each stage of the process, from tree allocation to biofuel supply.
                        </li>
                        <li>Enable real-time monitoring, data collection, and transparency for all stakeholders.</li>
                    </ul>
                </ol>
                
          
                </section>
                <section className="benefits">
                    <h4>Benefits</h4>
                    <ul>
                        <li><span>Environmental Sustainability:  </span> Promote the cultivation of native Pongamia and Mahua trees, reducing the carbon footprint and dependence on fossil fuels.</li>
                        <li><span>Rural Empowerment:  </span>Engage local farmers in sustainable practices, providing them with additional income streams and supporting rural development.</li>
                        <li><span>Waste Reduction:  </span>Utilize byproducts as organic fertilizers and animal feed, minimizing waste and enhancing agricultural productivity.</li>
                        <li><span>Technological Innovation:  </span>Employ geo-fencing and app-based tracking to streamline the entire process and ensure accountability.</li>
                        <li><span>Biofuel Production:  </span>Contribute to the production of clean and renewable biofuels, fostering a more sustainable energy landscape.</li>

                    </ul>
                    <p>By integrating technology, local knowledge, and sustainable practices, this project aims to create a holistic approach to biofuel feedstock development that benefits both the environment and local communities in Thanjavur district.</p>
                </section>
                
        </div>

    );
}
