import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Csr.css';
import Pptx from './Pptx';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/How-Zha-Use-CSR-Funds">English</NavLink></a>

        </div>

            <div className="social-ma">

<h1>ಝಾ ಹೇಗೆ ಸಿಎಸ್ಆರ್ ಫಂಡ್ಗಳನ್ನು ಬಳಸುತ್ತಾರೆ
</h1>

<Pptx />
 
 


 

<p>ಝಾ ಫೌಂಡೇಶನ್ ತನ್ನ ಸುಸ್ಥಿರತೆಯ ಉಪಕ್ರಮಗಳಲ್ಲಿ ಕಾರ್ಪೊರೇಟ್ ಸಾಮಾಜಿಕ ಹೊಣೆಗಾರಿಕೆ (CSR) ನಿಧಿಗಳನ್ನು ಕೆಳಗಿನ ಸ್ತಂಭಗಳೊಂದಿಗೆ ಜೋಡಿಸುವ ಮೂಲಕ ಕಾರ್ಯತಂತ್ರವಾಗಿ ಹೂಡಿಕೆ ಮಾಡಬಹುದು:
</p>

 

<ol className="goalpoints">

<li><span>ಮಾನವ ಸ್ವಾಸ್ಥ್ಯ ಸ್ತಂಭ : </span>  
<ul>
    <li>ಆರೋಗ್ಯ ಕಾರ್ಯಕ್ರಮಗಳು, ಮಾನಸಿಕ ಆರೋಗ್ಯ ಜಾಗೃತಿ ಮತ್ತು ಒಟ್ಟಾರೆ ಯೋಗಕ್ಷೇಮವನ್ನು ಉತ್ತೇಜಿಸುವ ಉಪಕ್ರಮಗಳಿಗೆ ಹಣವನ್ನು ನಿಯೋಜಿಸಿ.


</li>
    <li> ಪೌಷ್ಟಿಕಾಂಶ ಮತ್ತು ತಡೆಗಟ್ಟುವ ಆರೋಗ್ಯ ರಕ್ಷಣೆ ಕ್ರಮಗಳನ್ನು ಒಳಗೊಂಡಂತೆ ಆರೋಗ್ಯ ಮತ್ತು ಕ್ಷೇಮವನ್ನು ಕೇಂದ್ರೀಕರಿಸುವ ಶೈಕ್ಷಣಿಕ ಯೋಜನೆಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ.



</li>
</ul>
</li> 

<li><span>ಪ್ರಕೃತಿ ಸ್ವಾಸ್ಥ್ಯ ಸ್ತಂಭ : </span>  
<ul>
    <li>ಜೀವವೈವಿಧ್ಯ ಸಂರಕ್ಷಣೆ, ಮರು ಅರಣ್ಯೀಕರಣ ಮತ್ತು ನೈಸರ್ಗಿಕ ಆವಾಸಸ್ಥಾನಗಳ ರಕ್ಷಣೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ನಿಧಿ ಯೋಜನೆಗಳು.


</li>
    <li>ಪರಿಸರ ಸ್ನೇಹಿ ಅಭ್ಯಾಸಗಳು ಮತ್ತು ಪರಿಸರ ವ್ಯವಸ್ಥೆಗಳ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವ ಸುಸ್ಥಿರ ಕೃಷಿ ಉಪಕ್ರಮಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ.



</li>
</ul>
</li> 

<li><span>ಭೂಮಿಯ ಸ್ವಾಸ್ಥ್ಯ ಸ್ತಂಭ : </span>  
<ul>
    <li>ನವೀಕರಿಸಬಹುದಾದ ಇಂಧನ ಉಪಕ್ರಮಗಳು ಮತ್ತು ಕಾರ್ಬನ್ ಆಫ್‌ಸೆಟ್ ಕಾರ್ಯಕ್ರಮಗಳಂತಹ ಹವಾಮಾನ ಬದಲಾವಣೆಯ ತಗ್ಗಿಸುವಿಕೆ ಮತ್ತು ರೂಪಾಂತರವನ್ನು ತಿಳಿಸುವ ಬೆಂಬಲ ಯೋಜನೆಗಳು.

</li>
    <li>ನೀರಿನ ಸಂರಕ್ಷಣೆ ಮತ್ತು ನೈರ್ಮಲ್ಯ ಯೋಜನೆಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ, ಗ್ರಹದ ಪ್ರಮುಖ ಸಂಪನ್ಮೂಲಗಳ ಆರೋಗ್ಯಕ್ಕೆ ಕೊಡುಗೆ ನೀಡಿ.





</li>
</ul>
</li> 

<li><span>ಸ್ಥಿರವಾದ ಲಾಭದ ಸ್ತಂಭ : </span>  
<ul>
    <li>ಸುಸ್ಥಿರ ವ್ಯಾಪಾರ ಅಭ್ಯಾಸಗಳನ್ನು ಅನುಸರಿಸುವಾಗ ಸ್ಥಿರವಾದ ಲಾಭವನ್ನು ಗಳಿಸುವ ಸಾಮಾಜಿಕ ಉದ್ಯಮಗಳನ್ನು ಸ್ಥಾಪಿಸಿ ಅಥವಾ ಬೆಂಬಲಿಸಿ.



</li>
    <li> ZHA ಫೌಂಡೇಶನ್‌ನ ಮಿಷನ್‌ಗೆ ಹೊಂದಿಕೆಯಾಗುವ ಆದಾಯ-ಉತ್ಪಾದಿಸುವ ಯೋಜನೆಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ, ದೀರ್ಘಾವಧಿಯ ಪ್ರಭಾವಕ್ಕಾಗಿ ಸ್ವಯಂ-ಸಮರ್ಥನೀಯ ಮಾದರಿಯನ್ನು ರಚಿಸುತ್ತದೆ.



</li>
</ul>
</li> 

</ol>

<p>
ಈ ಸ್ತಂಭಗಳನ್ನು ZHA ಫೌಂಡೇಶನ್‌ನ CSR ಹೂಡಿಕೆಯ ಕಾರ್ಯತಂತ್ರಕ್ಕೆ ಸಂಯೋಜಿಸುವ ಮೂಲಕ, ಸಂಸ್ಥೆಯು ಮಾನವ ಕ್ಷೇಮ, ಪ್ರಕೃತಿ ಸಂರಕ್ಷಣೆ, ಭೂಮಿಯ ಯೋಗಕ್ಷೇಮ ಮತ್ತು ಆರ್ಥಿಕ ಸಮರ್ಥನೀಯತೆಯನ್ನು ತಿಳಿಸುವ ಸಮಗ್ರ ವಿಧಾನವನ್ನು ರಚಿಸಬಹುದು. ಈ ಬಹುಮುಖಿ ಕಾರ್ಯತಂತ್ರವು ಸಮುದಾಯಗಳು ಮತ್ತು ಪರಿಸರದ ಮೇಲೆ ಸಮತೋಲಿತ ಮತ್ತು ಸಮಗ್ರ ಪರಿಣಾಮವನ್ನು ಖಾತ್ರಿಗೊಳಿಸುತ್ತದೆ, ಉತ್ತಮ ಭವಿಷ್ಯಕ್ಕಾಗಿ ಸುಸ್ಥಿರ ಮನಸ್ಥಿತಿಯನ್ನು ಬೆಳೆಸುವ ಪ್ರತಿಷ್ಠಾನದ ಧ್ಯೇಯದೊಂದಿಗೆ ಸಂಯೋಜಿಸುತ್ತದೆ.</p>
 

 

 <br></br>

 <p>ZHA ಫೌಂಡೇಶನ್ ಈ ಕೆಳಗಿನ ವಿಧಾನಗಳ ಮೂಲಕ ಸುಸ್ಥಿರತೆಯ ಉಪಕ್ರಮಗಳನ್ನು ಹೆಚ್ಚಿಸಲು ಕಾರ್ಪೊರೇಟ್ ಸಾಮಾಜಿಕ ಜವಾಬ್ದಾರಿ (CSR) ನಿಧಿಗಳನ್ನು ಕಾರ್ಯತಂತ್ರವಾಗಿ ಬಳಸಿಕೊಳ್ಳಬಹುದು:
</p>

 

<ol className="goalpoints">

<li><span>ಪರಿಸರ ಸಂರಕ್ಷಣೆ ಯೋಜನೆಗಳು : </span>  
<ul>
    <li>ಆರೋಗ್ಯ ಕಾರ್ಯಕ್ರಮಗಳು, ಮಾನಸಿಕ ಆರೋಗ್ಯ ಜಾಗೃತಿ ಮತ್ತು ಒಟ್ಟಾರೆ ಯೋಗಕ್ಷೇಮವನ್ನು ಉತ್ತೇಜಿಸುವ ಉಪಕ್ರಮಗಳಿಗೆ ಹಣವನ್ನು ನಿಯೋಜಿಸಿ.


</li>
 
</ul>
</li> 

<li><span>ಹಸಿರು ಮೂಲಸೌಕರ್ಯ ಅಭಿವೃದ್ಧಿ : </span>  
<ul>
    <li>ಪರಿಸರ ಸ್ನೇಹಿ ಕಟ್ಟಡಗಳು, ಶಕ್ತಿ-ಸಮರ್ಥ ತಂತ್ರಜ್ಞಾನಗಳು ಅಥವಾ ಹಸಿರು ಸಾರಿಗೆಯಂತಹ ಸುಸ್ಥಿರ ಮೂಲಸೌಕರ್ಯಗಳ ಅಭಿವೃದ್ಧಿಗೆ ಹಣವನ್ನು ನಿಯೋಜಿಸಿ.



</li>
    
</ul>
</li> 

<li><span>ಸಮುದಾಯ ಆಧಾರಿತ ಸುಸ್ಥಿರ ಕೃಷಿ : </span>  
<ul>
    <li>ಸಾವಯವ ಕೃಷಿ, ಪರ್ಮಾಕಲ್ಚರ್ ಅಥವಾ ಸಮುದಾಯ ಉದ್ಯಾನಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುವ ಸುಸ್ಥಿರ ಕೃಷಿ ಯೋಜನೆಗಳ ಮೂಲಕ ಸ್ಥಳೀಯ ಸಮುದಾಯಗಳನ್ನು ಬೆಂಬಲಿಸಿ, ಪರಿಸರ ಮತ್ತು ಆಹಾರ ಸುಸ್ಥಿರತೆಗೆ ಕೊಡುಗೆ ನೀಡುತ್ತದೆ.


</li>
     
</ul>
</li> 

<li><span>ವೃತ್ತಾಕಾರದ ಆರ್ಥಿಕ ಉಪಕ್ರಮಗಳು : </span>  
<ul>
    <li>ತ್ಯಾಜ್ಯವನ್ನು ಕಡಿಮೆ ಮಾಡುವ ಮೂಲಕ ಮತ್ತು ವಸ್ತುಗಳ ಮರುಬಳಕೆ, ಅಪ್‌ಸೈಕ್ಲಿಂಗ್ ಅಥವಾ ಮರುಬಳಕೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಮೂಲಕ ವೃತ್ತಾಕಾರದ ಆರ್ಥಿಕತೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಯೋಜನೆಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ.





</li>
    
</ul>
</li> 


<li><span>ಜೀವವೈವಿಧ್ಯ ಸಂರಕ್ಷಣೆ : </span>  
<ul>
    <li>ನೈಸರ್ಗಿಕ ಆವಾಸಸ್ಥಾನಗಳನ್ನು ಸಂರಕ್ಷಿಸುವ, ಅಳಿವಿನಂಚಿನಲ್ಲಿರುವ ಜಾತಿಗಳನ್ನು ರಕ್ಷಿಸುವ ಮತ್ತು ಸುಸ್ಥಿರ ಪರಿಸರ ವ್ಯವಸ್ಥೆಯ ನಿರ್ವಹಣೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಉಪಕ್ರಮಗಳನ್ನು ಬೆಂಬಲಿಸುವ ಮೂಲಕ ಜೀವವೈವಿಧ್ಯತೆಯನ್ನು ರಕ್ಷಿಸಲು ಮತ್ತು ಪುನಃಸ್ಥಾಪಿಸಲು CSR ನಿಧಿಗಳನ್ನು ಬಳಸಿ.



</li>
 
</ul>
</li> 

<li><span>ನೀರು ಮತ್ತು ಶಕ್ತಿ ಸಂರಕ್ಷಣೆ : </span>  
<ul>
    <li>ನೀರು ಮತ್ತು ಶಕ್ತಿ ಸಂರಕ್ಷಣಾ ಯೋಜನೆಗಳಿಗೆ ಚಾನಲ್ ನಿಧಿಗಳು, ಬಳಕೆಯನ್ನು ಕಡಿಮೆ ಮಾಡುವ ಮತ್ತು ಸಮರ್ಥ ಸಂಪನ್ಮೂಲ ಬಳಕೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ತಂತ್ರಜ್ಞಾನಗಳು ಮತ್ತು ಅಭ್ಯಾಸಗಳನ್ನು ಅನುಷ್ಠಾನಗೊಳಿಸುವುದು.





</li>
    
</ul>
</li> 

<li><span>ಶಿಕ್ಷಣ ಮತ್ತು ಜಾಗೃತಿ ಕಾರ್ಯಕ್ರಮಗಳು : </span>  
<ul>
    <li>ಸುಸ್ಥಿರತೆಯ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸುವ, ಪರಿಸರ ಸ್ನೇಹಿ ಅಭ್ಯಾಸಗಳನ್ನು ಉತ್ತೇಜಿಸುವ ಮತ್ತು ಸುಸ್ಥಿರ ಜೀವನಶೈಲಿಯನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳಲು ಸಮುದಾಯಗಳಿಗೆ ಅಧಿಕಾರ ನೀಡುವ ಶೈಕ್ಷಣಿಕ ಉಪಕ್ರಮಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ.




</li>
     
</ul>
</li> 

<li><span>ಹವಾಮಾನ ಬದಲಾವಣೆ ತಗ್ಗಿಸುವಿಕೆ : </span>  
<ul>
    <li>ಕಾರ್ಬನ್ ಆಫ್‌ಸೆಟ್ ಕಾರ್ಯಕ್ರಮಗಳು, ಸುಸ್ಥಿರ ಸಾರಿಗೆ ಪರಿಹಾರಗಳು ಅಥವಾ ಹಸಿರುಮನೆ ಅನಿಲ ಹೊರಸೂಸುವಿಕೆಯನ್ನು ಕಡಿಮೆ ಮಾಡುವ ಗುರಿಯನ್ನು ಹೊಂದಿರುವ ಉಪಕ್ರಮಗಳಂತಹ ಹವಾಮಾನ ಬದಲಾವಣೆಯ ತಗ್ಗಿಸುವಿಕೆಗೆ ಕೊಡುಗೆ ನೀಡುವ ಬೆಂಬಲ ಯೋಜನೆಗಳು.
</li>
    
</ul>
</li> 



<li><span>ಪರಿಸರದ ಗಮನವನ್ನು ಹೊಂದಿರುವ ಸಾಮಾಜಿಕ ಉದ್ಯಮಗಳು :</span>  
<ul>
    <li>ಸಾಮಾಜಿಕ ಉದ್ಯಮಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ ಅದು ಸುಸ್ಥಿರತೆಯನ್ನು ತಮ್ಮ ವ್ಯವಹಾರ ಮಾದರಿಗಳಲ್ಲಿ ಸಂಯೋಜಿಸುತ್ತದೆ, ಪರಿಸರದ ಜವಾಬ್ದಾರಿಯನ್ನು ಉತ್ತೇಜಿಸುವಾಗ ಆರ್ಥಿಕ ಅಭಿವೃದ್ಧಿಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.




</li>
 
</ul>
</li> 

<li><span>ಸುಸ್ಥಿರತೆಗಾಗಿ ಸಂಶೋಧನೆ ಮತ್ತು ನಾವೀನ್ಯತೆ :</span>  
<ul>
    <li>ಸುಸ್ಥಿರತೆ ಸವಾಲುಗಳಿಗೆ ನವೀನ ಪರಿಹಾರಗಳನ್ನು ಅನ್ವೇಷಿಸುವ ಸಂಶೋಧನಾ ಉಪಕ್ರಮಗಳಿಗೆ ಹಣವನ್ನು ನಿಯೋಜಿಸಿ, ಹೊಸ ತಂತ್ರಜ್ಞಾನಗಳು ಅಥವಾ ಅಭ್ಯಾಸಗಳ ಅಭಿವೃದ್ಧಿಯನ್ನು ಉತ್ತೇಜಿಸುತ್ತದೆ.







</li>
    
</ul>
</li> 

<li><span>ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳಿಗಾಗಿ ಸಾಮರ್ಥ್ಯ ನಿರ್ಮಾಣ : </span>  
<ul>
    <li>ಸುಸ್ಥಿರ ಅಭ್ಯಾಸಗಳನ್ನು ಅಳವಡಿಸಿಕೊಳ್ಳುವಲ್ಲಿ, ದೀರ್ಘಾವಧಿಯ ಪ್ರಭಾವಕ್ಕಾಗಿ ತರಬೇತಿ ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳನ್ನು ಒದಗಿಸುವಲ್ಲಿ ಸಮುದಾಯಗಳು ಮತ್ತು ಸಂಸ್ಥೆಗಳ ಸಾಮರ್ಥ್ಯಗಳನ್ನು ಹೆಚ್ಚಿಸಲು CSR ನಿಧಿಗಳನ್ನು ಬಳಸಿ.





</li>
     
</ul>
</li> 

<li><span>ಸುಸ್ಥಿರತೆಯ ಪರಿಣಾಮವನ್ನು ಅಳೆಯುವುದು ಮತ್ತು ವರದಿ ಮಾಡುವುದು : </span>  
<ul>
    <li>ಸುಸ್ಥಿರತೆಯ ಉಪಕ್ರಮಗಳ ಪ್ರಭಾವವನ್ನು ಅಳೆಯಲು ಮತ್ತು ವರದಿ ಮಾಡಲು ದೃಢವಾದ ವ್ಯವಸ್ಥೆಗಳಲ್ಲಿ ಹೂಡಿಕೆ ಮಾಡಿ, ಮಧ್ಯಸ್ಥಗಾರರಿಗೆ ಪಾರದರ್ಶಕತೆ ಮತ್ತು ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಖಾತರಿಪಡಿಸುತ್ತದೆ.
</li>
    
</ul>
</li> 
</ol>

<p>

ಸುಸ್ಥಿರತೆಯ ಉಪಕ್ರಮಗಳೊಂದಿಗೆ CSR ನಿಧಿಗಳನ್ನು ಒಟ್ಟುಗೂಡಿಸುವ ಮೂಲಕ, ಲಾಭೋದ್ದೇಶವಿಲ್ಲದ ಸಂಸ್ಥೆಗಳು ಪರಿಸರ ಸವಾಲುಗಳನ್ನು ಪರಿಹರಿಸುವಲ್ಲಿ ನಿರ್ಣಾಯಕ ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತವೆ, ಸ್ಥಿತಿಸ್ಥಾಪಕತ್ವವನ್ನು ಬೆಳೆಸುತ್ತವೆ ಮತ್ತು ಸಮುದಾಯಗಳು ಮತ್ತು ಗ್ರಹಕ್ಕೆ ಹೆಚ್ಚು ಸಮರ್ಥನೀಯ ಭವಿಷ್ಯಕ್ಕೆ ಕೊಡುಗೆ ನೀಡುತ್ತವೆ.</p>
       
       
       
       
        </div>


        </div>




 
        


    );
}

