import React from "react";
import "./Member.css";


export default function Farmer(){

    return(
        <div>
            <div className="social-ma">

<h1>Zha Farmer Members</h1>
</div>

<section className="photo-farmer">
    <div className="zha-photo">
        
        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>

        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>

        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>

        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>
        
       
      
    </div>
</section>

<section className="photo-farmer">
    <div className="zha-photo">
        
        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>

        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>

        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>

        <div className="img-Container">
        <img src="../img/anony.jpg"></img>
        <h6>Mr.Name</h6>
        <p>Peravurani</p>
        </div>
        
       
      
    </div>
</section>





</div>

);
}