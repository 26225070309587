import React from "react";
import "../Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
export default function Zhasports(){




    

    return(
      
        <div>
            <div>
                <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sports-Training-Club">English</NavLink></a>
       



        </div>
        </div>
            <div className="social-ma">

<h1>झा स्पोर्ट्स ट्रेनिंग क्लब</h1>
</div>


<header>
    <div>
    <h2>आपका स्वागत है <br></br>झा स्पोर्ट्स ट्रेनिंग क्लब!</h2>
    {/* <p>Your Gateway to Athletic Excellence</p> */}
    </div>
    <img src="https://img.freepik.com/free-vector/summer-sports-concept_1284-9394.jpg?w=740&t=st=1697262455~exp=1697263055~hmac=01cd661d29b38dc346eff24e8983ff504c93da5e8946bdfe5e397df7e6053b94"></img>
</header>

<section className="medeatils">
<div className="aboutcycle2">
<h2>विवरण</h2>
<p>(14 नवंबर से शुरू)</p>
</div>
<p></p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th></th>
          <th>झा साइकिल क्लब</th>
          <th>झा एथलेटिक्स ट्रेनिंग क्लब</th>
          <th>झा वॉलीबॉल ट्रेनिग क्लब</th>
          <th>झा इंडोर ट्रेनिंग क्लब <br />(झा इंडोर ट्रेनिंग क्लब)</th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>मासिक शुल्क</td>
         
          <td colSpan={4}>₹300 per person</td>
         
        </tr>
        <tr>
          <td>वयस्कों का समय (15 वर्ष से अधिक)</td>
          <td colSpan={4}>6AM to 10AM</td>

       
         
  
        </tr>
        <tr>
          <td>बच्चों का समय (5 से 15 वर्ष)</td>
          <td>6AM to 10AM</td>
          <td colSpan={3}>From 5PM</td>
          
        </tr>
        <tr>
          <td>दिन</td>
          <td>केवल सप्ताहांत</td>
          <td colSpan={3}>साप्ताहिक तीन बार</td>
         
        </tr>
      </tbody>
    </Table>
    </section>



<section className="parellal">
    <div className="parallax-content">
    <div className="towhithe">
<h2>हमारे बारे में</h2>
<p>झा स्पोर्ट्स ट्रेनिंग क्लब में, हमारा मुख्य मिशन ग्रामीण क्षेत्रों में खेलों को बढ़ावा देना, छिपी हुई प्रतिभाओं की पहचान करना और उन्हें खेल की दुनिया में उत्कृष्टता प्राप्त करने के लिए मंच और संसाधन प्रदान करना है। हमारा मानना है कि हमारे ग्रामीण समुदायों के हर कोने में असाधारण प्रतिभा मौजूद है, जो खोजे जाने और विकसित होने की प्रतीक्षा कर रही है। हमारी प्रतिबद्धता उस क्षमता को उजागर करना और हमारे एथलीटों को उनकी खेल आकांक्षाओं के शिखर तक पहुंचने में मदद करना है।</p>
</div>
</div>
</section>



<section className="cycle2">
    <div className="aboutcycle2">
<h2>हमारा दृष्टिकोण
</h2> 
<div className="membership">
    <img src="https://img.freepik.com/free-vector/businessman-looking-camera-target-it-s-like-business-looking-way-be-successful-vector-business-illustration-concept_1150-60931.jpg?w=1380&t=st=1697283092~exp=1697283692~hmac=a66770dae78b88e7c2289fde1c533f765eb3ca49a6067b53fec080eece82b27d"></img>
<p>झा स्पोर्ट्स ट्रेनिंग क्लब में, हमारा दृष्टिकोण हमारे समुदाय के युवाओं के जीवन को सशक्त बनाना और बदलना है। हम एक ऐसा भविष्य बनाने की आकांक्षा रखते हैं जहां हमारे युवा हानिकारक व्यसनों और तनाव से मुक्त होकर खेल के माध्यम से उद्देश्य, जीवन शक्ति और कल्याण पा सकें। हमारा मानना है कि एथलेटिक उत्कृष्टता और व्यक्तिगत विकास के प्रति हमारे समर्पण के माध्यम से, हम युवा व्यक्तियों की एक ऐसी पीढ़ी तैयार कर सकते हैं जो न केवल शारीरिक रूप से फिट हैं बल्कि मानसिक रूप से भी मजबूत, लचीला और केंद्रित हैं। हमारा दृष्टिकोण सकारात्मक बदलाव के लिए उत्प्रेरक बनना है, एक ऐसी दुनिया को बढ़ावा देना है जहां युवा प्रेरणा, सौहार्द और सशक्तिकरण के स्रोत के रूप में खेल की ओर रुख करें, जिससे अंततः स्वस्थ, खुशहाल और अधिक जीवंत जीवन प्राप्त हो सके।

</p>
</div>
</div>
</section>


<section className="coretheme">
<h3>हमारा मुख्य विषय:<span> ग्रामीण प्रतिभा को उजागर करना</span></h3>
<ul>
<li><span>खेलों के माध्यम से युवाओं को सशक्त बनाना: </span>झा स्पोर्ट्स ट्रेनिंग क्लब में, हम खेलों के माध्यम से युवाओं को सशक्त बनाने, उन्हें व्यसन मुक्त और लचीले भविष्य की ओर मार्गदर्शन करने के लिए समर्पित हैं।</li>
<li><span>पहचानें:</span> हमारे स्काउट खेल के प्रति जुनून और कच्ची प्रतिभा वाले व्यक्तियों की तलाश में दूरदराज के गांवों और कस्बों की यात्रा करते हैं।</li>
<li><span>पोषण:</span>हम इस प्रतिभा का दोहन करने, इसे बेहतर कौशल और विशेषज्ञता में बदलने के लिए व्यापक प्रशिक्षण कार्यक्रम और सुविधाएं प्रदान करते हैं।</li>
<li><span>एक्सेल:</span> हमारा अंतिम लक्ष्य इन उभरती प्रतिभाओं को क्षेत्रीय, राष्ट्रीय और अंतर्राष्ट्रीय मंचों पर चमकते हुए देखना है।
</li>
</ul>

</section>

<section className="medeatils">
<div className="aboutcycle2">
<h2>झा स्पोर्ट्स ट्रेनिंग क्लब के नियम
</h2>


 <p>1) क्लब के सदस्यों के बीच जाति या धर्म या राजनीतिक दल या स्थिति के बारे में चर्चा नहीं करनी चाहिए</p> 

  <p>2) सम्मान, प्रतिबद्धता, फोकस, अखंडता जैसे मूल्य दिखाएं और दूसरों को प्रोत्साहित करें</p>




</div></section>
<section className="Indoor">
    <h3>इंडोर गेम्स</h3>

<div className="inindoor">

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/carroms-stricker-carroms-board_1353-94.jpg?w=1060&t=st=1697266133~exp=1697266733~hmac=193a552938f8bc9d95f5fb9fe521c000cdced03b1a4121a04e418230d430d6ce" />
      <Card.Body>
        <Card.Title>कैरम</Card.Title>
        <Card.Text>
        एक इनडोर क्लासिक जो सिर्फ एक खेल नहीं है बल्कि सटीकता और रणनीति का परीक्षण है। हमारे कैरम उत्साही लोगों से जुड़ें और अपने कौशल को निखारें।
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/gold-silver-chess-chess-board-game-business-metaphor-leadership-concept_1150-19592.jpg?w=1060&t=st=1697265918~exp=1697266518~hmac=f4734cc77213b39552dc86a5cebd2a405cbd08682462777d3ffbc181779df389" />
      <Card.Body>
        <Card.Title>शतरंज</Card.Title>
        <Card.Text>
        बुद्धि और दूरदर्शिता का खेल, जहां ग्रामीण शतरंज प्रतिभाओं में वैश्विक मंच पर अपनी छाप छोड़ने की क्षमता है।
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-people-man-woman-playing-table-tennis_93675-134489.jpg?w=1060&t=st=1697265228~exp=1697265828~hmac=54551670c7b793374f5acc44d9f553fb727750e00de361d6d2ad2b5280d4657a" />
      <Card.Body>
        <Card.Title>टेबल टेनिस</Card.Title>
        <Card.Text>
        तेज़-तर्रार और रोमांचक, टेबल टेनिस एक ऐसा खेल है जिसमें ग्रामीण एथलीट वास्तव में उत्कृष्टता प्राप्त कर सकते हैं।
        </Card.Text>
     
      </Card.Body>
    </Card>
    
    </div>
  
</section>



<section>
    <h3>आउटडोर खेल</h3>

<div className="inoutdoor">
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-volleyball-man-player-court_23-2149492372.jpg?w=1060&t=st=1697265453~exp=1697266053~hmac=38b67aa1f0c569204e324ccd0a6025c358209ab74d0f8ac3f16a7b044a389ff7" />
      <Card.Body>
        <Card.Title>वॉलीबॉल</Card.Title>
        <Card.Text> समुदाय का पसंदीदा, हम भागीदारी और प्रतिभा विकास को प्रोत्साहित करने के लिए नियमित वॉलीबॉल टूर्नामेंट की मेजबानी करते हैं।
     
        </Card.Text>

      </Card.Body>
    </Card>

    {/* <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Marathon</Card.Title>
        <Card.Text>
        Take part in our marathons to test your endurance and discover the long-distance running stars of tomorrow.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
      </Card.Body>
    </Card> */}

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/different-people-participating-cross-country_23-2149157134.jpg?w=1060&t=st=1697265375~exp=1697265975~hmac=cc39d5c0cc918e43474f8a249139536b9172850571f8f2aa5b897b9215fca427" />
      <Card.Body>
        <Card.Title>एथलेटिक्स</Card.Title>
        <Card.Text>
        हम युवा एथलीटों को विभिन्न ट्रैक और फील्ड स्पर्धाओं में प्रशिक्षण और प्रतिस्पर्धा करने के लिए एक मंच प्रदान करते हैं।
        </Card.Text>
     
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/professional-cyclist-women_23-2149703331.jpg?w=1060&t=st=1697265287~exp=1697265887~hmac=782e175093e473ddfc0e2abd894b42673b3d40d0e72972fe4289c6e5bc083fa9" />
      <Card.Body>
        <Card.Title>साइकिल चलाना</Card.Title>
        <Card.Text>
        ग्रामीण इलाकों से मंच तक, हम उभरते साइकिल चालकों को उनकी महानता की यात्रा में समर्थन देने के लिए यहां हैं।
        </Card.Text>

      </Card.Body>
    </Card>
    </div>
  
</section>





<section className="whychoose">
<h3>झा स्पोर्ट्स ट्रेनिंग क्लब क्यों चुनें?</h3>
<ul>

<li><span>ग्रामीण फोकस: </span>हमारा प्राथमिक लक्ष्य ग्रामीण प्रतिभाओं की पहचान करना और उनका पोषण करना है, यह सुनिश्चित करना कि कोई भी प्रतिभा नजरअंदाज न हो।.</li>
<li><span>विशेषज्ञ कोचिंग: </span>हमारे कोच आपकी पूरी क्षमता तक पहुंचने में आपकी मदद करने के लिए भावुक और समर्पित हैं।</li>
<li><span>सामुदायिक निर्माण: </span>ऐसे समुदाय में शामिल हों जो खेल कौशल, अनुशासन और टीम वर्क को महत्व देता है।</li>
<li><span>समग्र विकास: </span> हम केवल शारीरिक कौशल पर ध्यान केंद्रित नहीं करते हैं; हम चरित्र निर्माण और शिक्षा पर भी जोर देते हैं।</li>
<li><span>प्रतियोगिताएं: </span>नियमित प्रतियोगिताओं और आयोजनों से हमारे एथलीटों को चमकने और पहचान हासिल करने का मौका मिलता है।</li>
</ul>

</section>


<section className="cycle2">
    <div className="aboutcycle2">
<h2>सदस्यता
</h2>
<div className="membership">
    <img src="../img/joinus.jpg"></img>
<p>इस क्लब का उपयोग करने का मासिक शुल्क ₹300 है, और सदस्य इसे प्रत्येक सप्ताह तीन दिनों तक एक्सेस कर सकते हैं। झा इंडोर क्लब के सदस्य बनें और इनडोर खेल, फिटनेस और मनोरंजन की दुनिया तक पहुंच प्राप्त करें। हम आपकी व्यक्तिगत आवश्यकताओं के अनुरूप लचीले सदस्यता विकल्प प्रदान करते हैं, चाहे आप बार-बार आते हों या सिर्फ हमारी सुविधाओं को आज़माना चाहते हों।
</p>
</div>
</div>
</section>



</div>



);
}