import React from "react";
import "./Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';




export default function Zhasports(){




    

    return(
        <div>
            <div className="social-ma">

<h1>Zha Indoor Training Club</h1>
</div>


<header>
    <div>
    <h2>Welcome to <br></br>Zha Indoor Training Club!</h2>
   
    </div>
    <img src="../img/chess.webp"></img>
</header>


<section className="cycle">
    <div className="aboutcycle">
<h2>About Us
</h2>
<p>At Zha Indoor Training Club, we're dedicated to providing a diverse range of indoor recreational activities for individuals and families of all ages. Nestled in a convenient location, our club offers a safe and comfortable environment for you to unwind, socialize, and engage in exciting indoor activities.</p>
</div>
</section>






<section className="Indoor">
    <h3>Indoor Games</h3>

<div className="inindoor">

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/carroms-stricker-carroms-board_1353-94.jpg?w=1060&t=st=1697266133~exp=1697266733~hmac=193a552938f8bc9d95f5fb9fe521c000cdced03b1a4121a04e418230d430d6ce" />
      <Card.Body>
        <Card.Title>Carrom</Card.Title>
        <Card.Text>
        An indoor classic that's not just a game but a test of precision and strategy. Join our carrom enthusiasts and hone your skills.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/gold-silver-chess-chess-board-game-business-metaphor-leadership-concept_1150-19592.jpg?w=1060&t=st=1697265918~exp=1697266518~hmac=f4734cc77213b39552dc86a5cebd2a405cbd08682462777d3ffbc181779df389" />
      <Card.Body>
        <Card.Title>Chess</Card.Title>
        <Card.Text>
        A game of intellect and foresight, where rural chess prodigies have the potential to make their mark on the global stage.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-people-man-woman-playing-table-tennis_93675-134489.jpg?w=1060&t=st=1697265228~exp=1697265828~hmac=54551670c7b793374f5acc44d9f553fb727750e00de361d6d2ad2b5280d4657a" />
      <Card.Body>
        <Card.Title>Table Tennis</Card.Title>
        <Card.Text>
        Fast-paced and exciting, table tennis is a sport where rural athletes can truly excel.
        </Card.Text>
     
      </Card.Body>
    </Card>
    
    </div>
  
</section>

<section className="cycle2">
    <div className="aboutcycle2">
<h2>Membership
</h2>
<div className="membership">
    <img src="../img/joinus.webp"></img>
<p>The monthly fee for using this club is ₹500, and members can access it for three days each week.Become a member of Zha Indoor Club and gain access to a world of indoor sports, fitness, and recreation. We offer flexible membership options to suit your individual needs, whether you're a frequent visitor or just looking to try out our facilities.

</p>
</div>
</div>
</section>





</div>
);
}