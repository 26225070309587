import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

            
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

             {/* <div  id="language" >
        
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club-ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Role of Zha Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/role.webp"></img>
        </div>
<div className="goalpointsp">

<p>Roles and responsibilities of Zha sustainability development club across globe typically include:
</p>


<ol className="goalpoints">
    <li><span>Promoting Awareness : </span>Raise awareness about environmental and social issues, advocating for sustainable practices and behaviors.
</li>

    <li><span>Educational Initiatives : </span>Conduct educational programs, workshops, and campaigns to inform the community about sustainable development goals and practices.</li>
    <li><span>Community Engagement : </span>Engage with local communities to understand their needs, involving them in sustainable projects, and fostering a sense of responsibility.
</li>


    <li><span>Project Implementation : </span>Plan and execute sustainable projects, such as waste reduction, energy efficiency, or conservation initiatives, to contribute positively to the environment.

</li>
    <li><span>Advocacy : </span>Advocate for policies that support sustainability at local and national levels, collaborating with authorities and other organizations to influence positive change.



</li>
    <li><span>Research and Innovation : </span>Conduct research on sustainable technologies and innovations, exploring new ways to address environmental challenges.


</li>
    <li><span>Networking : </span>Build partnerships with other organizations, businesses, and governmental bodies to leverage resources and enhance the impact of sustainability initiatives.



</li>
    <li><span>Monitoring and Evaluation : </span>Regularly assess and evaluate the impact of projects to ensure they align with sustainability goals and make adjustments as needed.

</li>
    <li><span>Fundraising : </span>Secure funds through grants, donations, or events to support sustainability projects and ongoing club activities.

</li>
    <li><span>Communication : </span>Maintain transparent communication within the club and with the broader community, sharing progress, challenges, and success stories to inspire involvement.

</li>
<li><span>Capacity Building : </span>Provide training and support to members and the community, enhancing their skills and understanding of sustainable practices.
</li>
<li><span>Inclusivity : </span>Ensure inclusivity and diversity in all activities, recognizing the importance of various perspectives in addressing sustainability challenges.</li>

</ol>



</div>
        
     

        </div>

    );
}

