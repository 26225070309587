import React from "react";
import "./About.css"
import { NavLink } from "react-router-dom";
 
// import ExampleCarouselImage from '../../img/abou2.jpg';

import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';


import Dropdown from 'react-bootstrap/Dropdown';
export default function About(){
    return(
        <div>
{/* 
<Carousel id="heroslider" >
      <Carousel.Item>

        
        <img className="herosliderimg" src="../../img/bedsheet/4.jpg" text="First slide" />
        <Carousel.Caption>
        <h3 className="tobecolwhite" >  </h3>
           
        </Carousel.Caption>
        
      </Carousel.Item>
      <Carousel.Item>
      <img className="herosliderimg" src="../../img/bedsheet/7.jpg"  text="Second slide" />
        <Carousel.Caption>
        <h3 className="tobecolwhite" > </h3>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item>
      <img className="herosliderimg" src="../../img/SHOES/QW4.jpg"  text="Third slide" />
        <Carousel.Caption>
        <h3 className="tobecolwhite" >  </h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img className="herosliderimg" src="../../img/Zhavent/6.jpg"  text="Third slide" />
        <Carousel.Caption>
        <h3 className="tobecolwhite" >  </h3>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img className="herosliderimg" src="../../img/recue/5.png"  text="Third slide" />
        <Carousel.Caption>
        <h3 className="tobecolwhite" >  </h3>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
 */}


{/*             
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/tamil-a">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/tamil-ama">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/tamil-ahi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/tamil-ate">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/tamil-aka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

           
            <h1 id="About-head">About Us</h1>
            <div className="about-flex">
                <div className="about-card">
                    <img src="../../img/fabout.webp" />
                    <p>
                    Our Foundation is a Non Profit Organisation which was started to help the Indian farmers through dynamic consulting for free in agriculture business, to yield value added benefits for farmers and to manage their farms based on requests. So we did see that we need to invent a product that delivers value to our consumers.
                    </p>
                </div>
                <div className="about-card">
     <img src="../../img/sabout.webp" />
     <p>
     We accelerate sustainable and inclusive growth and deliver transformational impact for food and agriculture companies and organizations by driving efficiencies, fueling growth, shaping industries, and increasing organizational effectiveness. Our proprietary tools and digital and advanced analytics capabilities, combined with extensive experience are the cornerstones of the value we bring to our clients.
     </p>
 </div>
 <div className="about-card">
     <img src="../../img/tabout.webp" />
     <p>
     Zha Foundation engages in multi-disciplinary research, capacity building, self improvements and undertakes campaigns linked to help the youth mind & heart health. A whole generation is more anxious, more fragile, more depressed. They’re much less comfortable taking risks. This is a real change in a generation.” So, We are developing ways to measure wellness that include resilience, love, hope, cultural identity, critical consciousness, self-determination, and agriculture ways of working improvements.
     </p>
 </div>

            </div>



            <section className="visionmi">

            <div className="Operatingmodel">
            <h2 className="structuretit">Operating Model</h2>
  {/* <h4>Operating Model</h4> */}
  <p>For creating a sustainable life for future generations & to conserve the planet, we would need to interact with various like minded humans to assess the global interests to align with the mission and vision of the Zha Sustainability Professionals Tower/ Club
</p>
<p>Sustainable living means understanding how our humans lifestyle choices impact the world around us and finding ways for everyone to live better and lighter.  Our operations of ZHA sustainability professionals board members are divided by Active, Advisory and Witness board membership. However Zha board members are obliged to find the interest of their sustainability cause from the following four pillars   
</p>

 
<span>
<div className='wellnesspoint'> 
<p>1) human wellness pillar</p>
<p>2) nature wellness pillar  </p>
<p>3) earth wellness pillar</p>
<p>4) consistant profit pillar</p>
</div>
</span>
<p>From the above four pillars of sustainability wellness, each of the board members would discover any gamified social initiatives to build a sustainable life for future generations earth, nature & human wellness</p>
<p>Thus the outcome of sustainable life in this life is determined through The principle of sustainable development which addresses social and economic improvement that protects the environment and supports equality, and therefore the economy and society and the ecological system are mutually dependent.</p>
 
 
 


<p>It's important for Zha foundation sustainability professionals club to be transparent about how  Board Membership Subscription Fees are utilized. We will be providing quarterly regular financial reports and updates to members helps build trust and ensures that they understand the value they receive in return for their contributions and celebrate our initiatives progress and accomplishments in the same forum</p>

</div>

<div className="outcome-value">
<h2 className="structuretit">Outcome - Value</h2>
  {/* <h4>Outcome - Value</h4> */}
  <ul>
  <li>The desired outcomes of sustainable development encompass economic prosperity, environmental conservation, social equity, and poverty reduction. Achieving these outcomes requires a collective effort from governments, businesses, and individuals. </li>
  <li>Zha foundation is an enabler for social responsibility for all levels of children and adults.</li>
  <li>ZHA provides valuable resources for anyone to grow their sustainability mindset, grow business networks and connect with like-minded professionals across globe. Whether you are just starting out or have been in business for years, you can benefit from the knowledge, support, and connections that this forum provides.</li>
  <li>Zha Foundation is based on Social Service + Business + Knowledge + Friendship & Fellowship that connects the sustainable life and find quality people across globe
</li>
  </ul>
</div>
</section>



<h2 className="structuretit">Zha Transformation Method</h2>
            <div className="heighttt">
                
                <img src="../img/transformationmethod.webp"></img>
            </div>
        </div>

    );
}