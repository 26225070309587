import React from "react";
import "./Zhasponsorscheme.css"
import { NavLink } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
export default function Sponsors(){

    return(

        <div>
             
             <div>
            <div className="social-ma">

<h1>Zha Sponsors Scheme</h1>
</div>
</div>
<div className="orgfar">
   <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Tamil">
       <img src="../img/sponsorscemeta.webp"></img>
      </Tab>
      <Tab eventKey="profile" title="English">
      <img src="../img/sponsorscemeen.webp"></img>
      </Tab>
    </Tabs>
    </div>
   
        </div>
    
    );
}

