import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import './Summit.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
     
     <div className="social-ma">

<h1>Zha Farm Management Services 
</h1>


<div className="internship">
        <img src="../img/farm.webp"></img>
        </div>
     
</div>


<div className="goalpointsp">

 

        <p>To enhance agricultural productivity, increase profitability & protect agriculture sustainability, Zha Farm Management Services should be catering to a variety of needs for farm owners. The following are key service requirements of the farmers from various location:


</p>
 
     


<ol className="goalpoints">

<li><span>Labor Support : </span>  
<ul>
    <li>Skilled and unskilled labor for planting, harvesting, and general farm activities.


</li>
    <li> Flexible labor arrangements based on seasonal demands.


</li>
</ul>
</li> 

<li><span>Equipment Rental : </span>  
<ul>
    <li>Access to a fleet of well-maintained tractors, plows, and other agricultural machinery.



</li>
    <li>Rental options based on specific tasks or seasonal requirements.



</li>
</ul>
</li> 

<li><span>Plant Health Analysis : </span>  
<ul>
    <li>Regular assessment of crop health through advanced analysis techniques.

</li>
    <li>Recommendations for soil improvement, pest control, and disease prevention.



</li>
</ul>
</li> 

<li><span>Plant Doctors : </span>  
<ul>
    <li>Expert agronomists available for consultation to address specific plant-related concerns.



</li>
    <li>Customized advice for optimal crop growth and yield.



</li>
</ul>
</li> 

<li><span>Utility Services : </span>  
<ul>
    <li>Provision of essential utilities such as water management solutions and irrigation services.



</li>
    <li>Implementation of sustainable practices to conserve resources.




</li>
</ul>
</li> 


<li><span>Customized Service Packages : </span>  
<ul>
    <li>Tailored service plans based on the type of crops and farm size.



</li>
    <li>Options for comprehensive packages or individual services based on specific needs.



</li>
</ul>
</li> 


<li><span>Training Programs : </span>  
<ul>
    <li>Workshops and training sessions for farm owners and workers on modern agricultural practices.


</li>
    <li>Knowledge transfer for sustainable and efficient farming methods.


</li>
</ul>
</li> 


<li><span>Technology Integration : </span>  
<ul>
    <li>Integration of modern technologies like IoT for real-time monitoring of crops and environmental conditions.



</li>
    <li>Implementation of precision farming techniques to optimize resource utilization.



</li>
</ul>
</li> 

<li><span>Harvesting Solutions : </span>  
<ul>
    <li>Efficient harvesting services with the latest equipment to maximize yield.



</li>
    <li>Post-harvest handling and storage solutions to maintain crop quality.


</li>
</ul>
</li> 


<li><span>Regulatory Compliance : </span>  
<ul>
    <li>Assistance in adhering to agricultural regulations and compliance standards.

</li>
    <li>Keeping farm owners informed about industry best practices and legal requirements.



</li>
</ul>
</li> 
 

</ol>

<p>By addressing these diverse needs, Zha Farm Management Services aims to empower farm owners with the resources and expertise needed to enhance agricultural productivity and overall farm sustainability.
</p>

 
       
       
       
       
        </div>


        </div>




 
        


    );
}

