import React from 'react';

const NotFound = () => {
  return (
    <div>
      <img src='../img/404.webp'></img>
    </div>
  );
};

export default NotFound;
