import React from "react";
import "./Companies.css"
import Image from 'react-bootstrap/Image';
import { NavLink } from "react-router-dom";
export default function Companies(){

    return(

        <div>
                
        
            <div className="social-ma">

            <h1>Researchers & Sourcing Membership</h1>
            </div>
            <div className="heroimg">
        <img src="../img/partnership.webp"></img>
       
        </div>
        <p className="herop">Zha Strategy for linking and tie-up with agricultural buyers/ sellers memberships has to be created for following reasons, so we need to design for creating the Agri Produce Buyers and Sellers Membership program</p>

        <section className="firstt">
            <div className="firpart">
                <p className="func">Today's agricultural marketing has to undergo a series of exchanges or transfers from one person to another before it reaches the consumer. There are three marketing functions involved in this, that are; 
</p>
<div className="piller">
                <ul>
                    <li>Assembling
                    </li>
                    <li>Preparation for Consumption 
                    </li>
                    <li>Distribution
                    </li>
                </ul>
                <img src="../img/piller.jpg"></img>
                </div>
                <p className="func">Selling of any agricultural produce depends on couple of factors like the demand of the product at that time, availability of storage etc. The products may be sold directly in the market or it may be stored locally for the time being. Moreover, it may be sold as it is gathered from the field or it may be cleaned, graded and processed by the farmer or the merchant of the village. Sometime processing is done because consumers want it, or sometimes to conserve the quality of that product.</p>
            </div>
        </section>
        
        
        <section className="secpart">
            <div className="functions">
            <h5>Functions of Agriculture Marketing</h5>
            <div className="containerr">
            <p>
A. Exchange Functions: Buying, Selling, Storage</p>
            <p>B. Physical Functions: Transportation, Processing, Standardization</p>
            <p>
C. Facilitating Functions: Financing, Risk Bearing, Market Intelligence</p>
</div>
<p className="func2"> These agriculture marketing functions performed by farmers, traders, retailers, consumers and manufacturer that rises importance of effective agriculture marketing system in India.</p>
</div>
        </section>

<section className="thipart">
<div>
    <h5>
    Agro-business sector is dependent on agriculture produce characteristics which are as follows: 
    </h5>
    <div className="pointss">
        <p><span>Quality: </span>To build a profitable business, food manufacturers seek to establish a preference for their products by differentiating those products in some way which is meaningful to consumers.</p>
        <p><span>Cost: </span>With an increased capability to search the world for raw materials, the food industry is able to find the lowest cost source for any given level of quality. </p>
        <p><span>Non-seasonality: </span>Agricultural products are traditionally seasonal in their production and supply.</p>
        <p><span>Reliability: </span>A manufacturer who has invested heavily in building up his brand will be very keen to get reliable supplies in terms of quality, timing and cost.</p>
        <p><span>Processing: </span>Ease of processing will become an increasingly important expectation of the food industry. Like all industries, reductions in the costs of capital equipment, wages and inventories are important objectives.</p>
    </div>
    <p className="func">Therefore agriculture marketing system is in a way dependant on quality, cost, and processing requirement of agriculture produce.</p>
</div>

</section>

<section className="foupart">
    <h5>Global Agricultural Market Information Network and Research Institute</h5>
    <p>Agriculture market information system (AMIS) is an international organization for exchange of agriculture produce prices and price trends in various countries. The Global Food Market Information Group works for the agriculture market information system which consists of technical representatives from countries participating in AMIS. It is responsible for providing regular reliable, accurate, timely and comparable data regarding the supply and demand position and its probable short term development, as well as regarding prices, of the AMIS crops. Furthermore, it organizes the timely collection of national policy developments that could impact the market situation and outlook. Website showcases statistics that provides summary tables of the main data items currently covered by AMIS. There are production, supply, utilization, trade and closing stocks.</p>
    <p>The CCS (Chaudhary Charan Singh) National Institute of Agricultural Marketing (NIAM) is a premier National level Institute set up by the Government of India in August 1988 to offer specialized Training, Research, Consultancy and Education in Agricultural Marketing. It is involved in research and analysis of agriculture produces prices of Indian crops. NIAM is an autonomous body under the Ministry of Agriculture, Government of India. It was set up as a Registered Society to cater to the needs of Agricultural Marketing personnel in India as well as from South East Asian countries. </p>
</section>

<section className="lastpart">
<h5>Agriculture Marketing Jobs</h5>    
<p>There are agriculture marketing jobs in government and private sector of agri-business responsible for</p>

<ul>
    <li>handling media relations, issue press releases, respond to negative press, and arrange for coverage of positive developments and important legislative issues</li>
    <li>developing official statements, question-and-answer documents, background materials, positioning messages, and other communications materials</li>
    <li>developing their organization’s public identity and branding (for example, by promoting the company’s dedication to “organic” methods, or reputation for quality)</li>
    <li>developing educational/promotional materials informing about their industry</li>
</ul>
</section>









            </div>
           );


}