import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


export default function Ecofriendly() {
    return (
        <div>

                
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Wellness-Program">English</NavLink></a>
         
        </div>
{/* 
            <div  id="language" >

        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a>
        

        </div> */}

<h1 id="mhead">ழ ஆரோக்கிய திட்டம் </h1>

   
<div className='second-mcontent'>
           <img src='../../img/self.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>ழ வின் உடல் மற்றும் மன ஆரோக்கிய இலவச பயிற்சியானது பல்வேறு வகையான செயல்பாடுகளை உள்ளடக்கி, நல்வாழ்வுக்கான முழுமையான அணுகுமுறையை உறுதி செய்கிறது. இதில் அடங்கும்:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>தடகளப் பயிற்சித் திட்டங்கள் :</span> மாரத்தான், சைக்கிள் ஓட்டுதல் மற்றும் கைப்பந்து போன்ற பல்வேறு துறைகளை உள்ளடக்கிய உடல் தகுதிக்காக வடிவமைக்கப்பட்ட திட்டங்கள்.
</li>

<li><span>கவனமுள்ள செயல்பாடுகள் : </span>  உடல் பயிற்சிக்கு அப்பால், பங்கேற்பாளர்கள் தியானம் மற்றும் யோகா போன்ற மனநல செயல்பாடுகளிலிருந்து பயனடைகிறார்கள்.
 

</li>
<li><span>உட்புற சதுரங்கம் :</span>  செஸ் போன்ற மூலோபாய விளையாட்டுகள் மூலம் மனதை ஈடுபடுத்துதல், அறிவாற்றல் வளர்ச்சி மற்றும் மன சுறுசுறுப்பை ஊக்குவித்தல்.

</li>
<p>
Zha ஆரோக்கியத் திட்டம் ஒரு விரிவான அணுகுமுறையைக் கொண்டுள்ளது, இது நல்வாழ்வின் உடல் மற்றும் மன அம்சங்களைக் குறிக்கிறது, பயிற்சித் திட்டத்தில் பங்கேற்கும் நபர்களுக்கு ஒரு சீரான மற்றும் ஆரோக்கியமான வாழ்க்கை முறையை வளர்க்கிறது.</p>

<p>மாணவர்களுக்கான கிராமப்புற மற்றும் புறநகர் பகுதிகளில் Zha இலவச உடல் மற்றும் மன ஆரோக்கிய பயிற்சி பல நன்மைகளை வழங்குகிறது:</p>

</ol>
        
 

<ol className="goalpoints"> 

<li><span>வளங்களுக்கான அணுகல் :</span>  கிராமப்புறங்களில் உள்ள மாணவர்கள் நிதிக் கட்டுப்பாடுகள் காரணமாக அவர்களுக்கு இல்லாத பயிற்சித் திட்டங்களுக்கான அணுகலைப் பெறுகின்றனர், நல்வாழ்வுக்கான சம வாய்ப்புகளை மேம்படுத்துகின்றனர்.


</li>
<li><span>மேம்படுத்தப்பட்ட உடல் ஆரோக்கியம் : </span> மராத்தான், சைக்கிள் ஓட்டுதல் மற்றும் கைப்பந்து போன்ற தடகள பயிற்சி திட்டங்கள் மேம்பட்ட உடல் தகுதிக்கு பங்களிக்கின்றன, சுறுசுறுப்பான வாழ்க்கை முறையை ஊக்குவிக்கின்றன.


</li>
<li><span>மேம்படுத்தப்பட்ட மன நலம் :</span>தியானம், யோகா வகுப்புகள் மற்றும் உட்புற சதுரங்கம் ஆகியவை மன அழுத்தத்தை குறைக்கும் கருவிகள், மேம்பட்ட கவனம் மற்றும் ஒட்டுமொத்த மன உறுதியை வழங்குகின்றன.
</li>
<li><span>திறன் மேம்பாடு :</span>பல்வேறு நடவடிக்கைகளில் பங்கேற்பது குழுப்பணி, ஒழுக்கம் மற்றும் மூலோபாய சிந்தனை உட்பட பலவிதமான திறன்களின் வளர்ச்சியை வளர்க்கிறது.
</li>
<li><span>சமூகத்தை கட்டியெழுப்புதல் : </span>  ஆரோக்கிய திட்டங்கள் பங்கேற்பாளர்களிடையே சமூக உணர்வை உருவாக்குகின்றன, சமூக இணைப்புகள் மற்றும் ஆதரவு நெட்வொர்க்குகளை வளர்க்கின்றன.
</li>
<li><span>அதிகாரமளித்தல் :</span> பயிற்சியானது, சவால்களுக்குச் செல்லவும், வாழ்க்கையின் பல்வேறு அம்சங்களில் வெற்றிபெறவும், உடல் மற்றும் மனக் கருவிகள் மூலம் மாணவர்களை மேம்படுத்துவதன் மூலம் அவர்களை மேம்படுத்துகிறது.
</li>
<li><span>ஆரோக்கியமான வாழ்க்கை முறைகளை ஊக்குவித்தல் :</span>உடல் செயல்பாடு மற்றும் மனநல நடைமுறைகளை ஊக்குவிப்பதன் மூலம், பயிற்சியானது ஆரோக்கியமான வாழ்க்கை முறைகளை ஏற்றுக்கொள்வதற்கு பங்களிக்கிறது, வாழ்க்கை முறை தொடர்பான உடல்நலப் பிரச்சினைகளின் அபாயத்தைக் குறைக்கிறது.
</li>


</ol>




<p>
ஒட்டுமொத்தமாக, இலவசப் பயிற்சித் திட்டங்கள் கிராமப்புற மற்றும் புறநகர்ப் பகுதிகளில் உள்ள மாணவர்களை மேம்படுத்துவதற்கும் மேம்படுத்துவதற்கும், அவர்களின் முழுமையான நல்வாழ்வை வளர்ப்பதற்கும் மதிப்புமிக்க வாழ்க்கைத் திறன்களை அவர்களுக்கு வழங்குவதற்கும் நோக்கமாக உள்ளன.
 </p>


</section>
 

</div>
 
 
);
}

