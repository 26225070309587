import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./millet.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Millet() {
    return(

        <div>
                <div  id="language" >
        
                <a href=""><NavLink to="/zhafarmersmilletleague">English</NavLink></a>



        </div>
            <div className="social-main">

            <h1>Zha Farmers Millet League</h1>
            </div>
           <section className="intro">
            <div className="heroim">
            <img src="../img/farme.jpg"></img>
            </div>
            <div className="titletext">
              <p> ഴ ഫൗണ്ടേഷന്റെ സംരംഭം
കർഷകർ മില്ലറ്റ് ലീഗ് നടത്തുക
പേരാവൂരണി താലൂക്കിലെ കർഷകരാണ് എ
പ്രശംസനീയമായ ശ്രമം. മില്ലറ്റുകൾ ഉയർന്നതാണ്
പോഷകസമൃദ്ധവും വരൾച്ചയെ പ്രതിരോധിക്കുന്നതുമായ വിളകൾ
അത് ഇന്ത്യയുടെ അവിഭാജ്യ ഘടകമായിരുന്നു
നൂറ്റാണ്ടുകളായി കൃഷി. എന്നിരുന്നാലും, ഇൻ
സമീപ വർഷങ്ങളിൽ, അവരുടെ കൃഷി ഉണ്ട്
നിരസിച്ചു, പരമ്പരാഗത നഷ്ടത്തിലേക്ക് നയിച്ചു
അറിവും പോഷക ഗുണങ്ങളും
അവർ വാഗ്ദാനം ചെയ്യുന്നു. കാർഷിക ലോകത്ത് വിപ്ലവം സൃഷ്ടിക്കാൻ ഒരുങ്ങുകയാണ് ഫാർമർ മില്ലറ്റ് ലീഗ്, ലോകമെമ്പാടുമുള്ള കർഷകരെയും ഉത്സാഹികളെയും പുതുമയുള്ളവരെയും ഒരുമിച്ച് കൊണ്ടുവരുന്നു. ഐ‌പി‌എൽ ക്രിക്കറ്റിന്റെ മുഖച്ഛായ മാറ്റിയതുപോലെ, ഴ ഫൗണ്ടേഷന്റെ കർഷക മില്ലറ്റ് ലീഗ് ലക്ഷ്യമിടുന്നത് മില്ലറ്റ് കൃഷിയെ ഉയർത്താനാണ് ആവേശകരമായ ടൂർണമെന്റുകളും അത്യാധുനിക സാങ്കേതിക വിദ്യകളും ഉപയോഗിച്ച് ഒരു പുതിയ തലത്തിലേക്ക്.</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/L59Gs4kPz14YRQZn6"> <Button variant="outline-success">ഇപ്പോള് രജിസ്റ്റര് ചെയ്യുക</Button>{' '}</a>

    </div>

    <div>
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScZeSA0M303pXW7SzmUA_BOCbWv3TXX_vDiZPR-xUudKvyImw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</div>
  </section>
<div className="freepik">
<h5 className="cardtitle">ഇതുമായി ബന്ധപ്പെട്ട പ്രധാന പോയിന്റുകളുടെ ഒരു അവലോകനം ഇതാ <span> കർഷകർ മില്ലറ്റ്
ലീഗ് </span>സംരംഭം:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ഞങ്ങളുടെ ദൗത്യം</Card.Title>
        <Card.Text>
        ഇന്ത്യയിലുടനീളമുള്ള പേരാവൂരണി താലൂക്കിലും അതിനപ്പുറവും കർഷകർക്കിടയിൽ മില്ലറ്റ് കൃഷി പ്രോത്സാഹിപ്പിക്കുകയും പുനരുജ്ജീവിപ്പിക്കുകയും ചെയ്യുക എന്നതാണ് ഞങ്ങളുടെ സംരംഭത്തിന്റെ കാതൽ. "പോഷക-ധാന്യങ്ങൾ" എന്ന് പലപ്പോഴും വിളിക്കപ്പെടുന്ന തിനകൾ അവയുടെ അസാധാരണമായ പോഷകമൂല്യത്തിനും പരിസ്ഥിതി സൗഹൃദ കൃഷിരീതികൾക്കും പേരുകേട്ടതാണ്, അവയെ സുസ്ഥിര കൃഷിയുടെ മൂലക്കല്ലാക്കി മാറ്റുന്നു.
        </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ഴ ഫൗണ്ടേഷന്റെ പ്രതിബദ്ധത</Card.Title>
        <Card.Text>
        ഞങ്ങളുടെ സമർപ്പിത കർഷകർക്കിടയിൽ മില്ലറ്റ് കൃഷി കിക്ക്സ്റ്റാർട്ടിംഗിലും പിന്തുണയ്ക്കുന്നതിലും ഞങ്ങൾ ഒരു പ്രധാന പങ്ക് വഹിക്കുന്നു. മില്ലറ്റ് കൃഷിയെ അവരുടെ കൃഷിരീതികളിൽ തടസ്സമില്ലാതെ സമന്വയിപ്പിക്കാൻ കർഷകരെ സഹായിക്കുന്നതിന് ആവശ്യമായ വിഭവങ്ങൾ, സമഗ്ര പരിശീലനം, സാങ്കേതിക സഹായം എന്നിവ നൽകുന്നതിൽ ഞങ്ങളുടെ പിന്തുണ ഉൾപ്പെടുന്നു.

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ജൈവ മില്ലറ്റ് കൃഷി തിരികെ കൊണ്ടുവരിക</Card.Title>
        <Card.Text>
        ഇന്ത്യൻ സംസ്ഥാന സർക്കാരിനൊപ്പം ജൈവ മില്ലറ്റ് കൃഷി പ്രോത്സാഹിപ്പിക്കുകയും കൃഷിയിൽ പാർട്ട് ടൈം ജോലികൾക്കായി യുവാക്കളെ അണിനിരത്തുകയും ചെയ്യുക എന്നതാണ് ഞങ്ങളുടെ സംരംഭത്തിന്റെ പ്രധാന ലക്ഷ്യം. ഴ കർഷകരും ഉപഭോക്തൃ കോൾ സെന്റർ വഴിയും ആഗോള വിപണിയിൽ ഞങ്ങളുടെ കർഷക ഉൽപ്പന്നങ്ങൾ നിർമ്മിക്കുന്നതിനുള്ള ഡിജിറ്റൽ തന്ത്രങ്ങളിലൂടെ ഒരു വിതരണ ശൃംഖല സൃഷ്ടിക്കുക. ഗുണങ്ങൾ ബഹുവിധമാണ്: ഭക്ഷ്യസുരക്ഷ മെച്ചപ്പെടുത്തൽ, മണ്ണിന്റെ ആരോഗ്യം വർധിപ്പിക്കൽ, ജലം കൂടുതലുള്ള ജൈവവിളകളെ അപേക്ഷിച്ച് ജല ഉപഭോഗം ഗണ്യമായി കുറയ്ക്കുകയും ZHA ഫൗണ്ടേഷൻ മുഖേന ജൈവ ഉൽപ്പന്നങ്ങൾ സാക്ഷ്യപ്പെടുത്തുകയും ചെയ്യുന്നു.
        </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>കർഷകർ മില്ലറ്റ് ലീഗ്</Card.Title>
        <Card.Text>
        ഞങ്ങൾ ഒരു നൂതന ആശയം അവതരിപ്പിക്കുകയാണ് - "ഫാർമേഴ്സ് മില്ലറ്റ് ലീഗ്". അപേക്ഷകളുടെ അടിസ്ഥാനത്തിൽ കർഷകരെ വിവിധ ടീമുകളായി ക്രമീകരിച്ച്, ഴ ടീം മുഖേന സംഘടിപ്പിക്കുകയും സർക്കാർ കാർഷിക വിദഗ്ധർ റഫറി ചെയ്യുകയും ചെയ്യുക. അതിനാൽ ആരോഗ്യകരമായ മത്സരം ഉത്തേജിപ്പിക്കാനും നമ്മുടെ കർഷക സമൂഹങ്ങൾക്കിടയിൽ സഹകരണം വളർത്താനും നമുക്ക് ലക്ഷ്യമിടുന്നു. ഈ ലീഗുകൾ സൗഹൃദം പ്രോത്സാഹിപ്പിക്കുക മാത്രമല്ല, പങ്കാളികൾക്കിടയിൽ ഐക്യബോധം വളർത്തുകയും ചെയ്യുന്നു. ആദ്യ മൂന്ന് വിജയികൾക്ക് ഞങ്ങൾ ക്യാഷ് വിലകളും പങ്കെടുക്കുന്ന എല്ലാവർക്കും സർട്ടിഫിക്കറ്റുകളും നൽകുന്നു.
        </Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>വിദ്യാഭ്യാസവും അവബോധവും</Card.Title>
        <Card.Text>
        ലീഗിനപ്പുറം വിദ്യാഭ്യാസത്തിനാണ് പ്രാധാന്യം. മില്ലറ്റ് കൃഷിയുടെ പ്രയോജനങ്ങൾ, സുസ്ഥിരമായ കൃഷിരീതികൾ, മില്ലറ്റ് അധിഷ്ഠിത ഭക്ഷണരീതികളുടെ പോഷക മേന്മ എന്നിവയെക്കുറിച്ച് അവബോധം പ്രചരിപ്പിക്കുന്നതിൽ ഞങ്ങൾ വിശ്വസിക്കുന്നു. ഈ സംരംഭത്തിൽ സജീവമായി പങ്കെടുക്കാൻ കൂടുതൽ കർഷകരെ പ്രചോദിപ്പിക്കുക എന്നതാണ് ഞങ്ങളുടെ ദൗത്യം.
 </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>വിപണി പ്രവേശനം</Card.Title>
        <Card.Text>
        നമ്മുടെ കർഷകർക്ക് ന്യായമായ വിപണി പ്രവേശനം ഉറപ്പാക്കുക എന്നത് ഞങ്ങളുടെ വിജയത്തിന്റെ പ്രധാന ഘടകമാണ്. ഞങ്ങളുടെ കർഷകർക്ക് അവരുടെ മില്ലറ്റ് ഉൽപന്നങ്ങൾ ന്യായമായ വിലയ്ക്ക് വിൽക്കാൻ കഴിയുമെന്ന് ഉറപ്പാക്കിക്കൊണ്ട് ശക്തമായ വിപണി ബന്ധം സൃഷ്ടിക്കാൻ ഞങ്ങൾ പ്രതിജ്ഞാബദ്ധരാണ്.

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>സുസ്ഥിരത</Card.Title>
        <Card.Text>
        മില്ലറ്റ് കൃഷിയെക്കുറിച്ചുള്ള ഞങ്ങളുടെ ദീർഘകാല കാഴ്ചപ്പാടിൽ പരമ്പരാഗത മില്ലറ്റ് ഇനങ്ങൾ സംരക്ഷിക്കുക, വിത്ത് സംരക്ഷിക്കൽ പ്രോത്സാഹിപ്പിക്കുക, പ്രകൃതിദത്ത കൃഷിരീതികൾ പ്രോത്സാഹിപ്പിക്കുക എന്നിവ ഉൾപ്പെടുന്നു. സുസ്ഥിരത ഒരു ലക്ഷ്യമല്ല; അതൊരു ജീവിതരീതിയാണ്.
 </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>നയ പിന്തുണ</Card.Title>
        <Card.Text>
        ഞങ്ങൾ മാറ്റത്തിന് വേണ്ടി വാദിക്കുന്നവരാണ്. പ്രാദേശിക തലത്തിലും ദേശീയ തലത്തിലും പിന്തുണ നൽകുന്ന നയങ്ങൾക്കായി ഞങ്ങൾ അശ്രാന്തമായി പ്രവർത്തിക്കുന്നു. മില്ലറ്റ് കൃഷിക്കും ഉപഭോഗത്തിനും അനുകൂലമായ ആനുകൂല്യങ്ങൾ, സബ്‌സിഡികൾ, നയ ക്രമീകരണങ്ങൾ എന്നിവ ഇതിൽ ഉൾപ്പെടുന്നു. </Card.Text>
      </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>പ്രധാന ഉടമസ്ഥത <span>കർഷക മില്ലറ്റ് ലീഗ്:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;