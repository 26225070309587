import Carousel from 'react-bootstrap/Carousel';
 
import React from 'react';

function IndividualIntervalsExample() {
  return (
    <div className='carouselll'>

 

    
    <Carousel>
      
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/1.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/2.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/3.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/4.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     

      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/5.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     

      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/6.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/7.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/8.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     

      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/10.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/11.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/12.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/14.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img src='../../img/PPT/15.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
     
    </Carousel>

    </div>
  );
}

export default IndividualIntervalsExample;