import React from 'react';
import './Membership.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        <div  id="language" >
        
        <a href=""><NavLink to="/tamil-m">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/tamil-mma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/tamil-mhi">Hindi</NavLink></a>
        <a href=""><NavLink to="/tamil-mte">Telugu</NavLink></a>

<div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div>

        </div>
        <h1 id="mhead">Scaling Farmers Profitability Program - Pilot Phase</h1>
        <h3 id="mheadd">(Limited membership only 150 no’s for now admitted)</h3>

<div className='membertab'>

          <section className='offer'>
            <div>
                <h1>₹600/mon</h1>
                <h1>₹3500/6mon</h1>
                <h1>₹7000/year</h1>
           </div>
        
           </section>  

        
        {/* <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3"
      justify
    > */}
      {/* <Tab eventKey="home" title="Farmer">
      <div className='Memberfprmcontent'>
        <div>
            <ul>
                <li>Farm property management services  </li>
                <li>Soil tests</li>
                <li>Health check of plants and trees</li>
                <li>Regular reporting on the status</li>
            </ul>
        </div>
        <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
     </div>
      </Tab> */}

      {/* <Tab eventKey="profile" title="Agent">

      <div className='Memberfprmcontent'>
        <div>
            <ul>
                <li>Marketing strategy assessments</li>
                <li>Digital marketing based on target provided</li>
                <li>New market or buyer identification</li>
                <li>Regular reporting on the status</li>
            </ul>
        </div>
        <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
     </div>
      </Tab> 
      <Tab eventKey="longer-tab" title="Supporter">*/}
      <div className='membershiptype'>
             <img src='../img/Graph.png'></img>
             </div>
 
      <div className='Memberfprmcontent'>
        
        <div>
            {/* <h4>Under supporters membership</h4> */}
          {/* <section className='offer'>
            <div>
                <h1>₹600/mon</h1>
                <h1>₹3500/6mon</h1>
                <h1>₹7000/year</h1>
           </div>
           <div>
                <h1><span>₹600/mon</span>₹300/mon </h1>
                <p>Farmers only</p>
           </div>
           </section>   */}
           
            <ul>
           <li>Zha foundation will be dedicated to engage farmers in agriculture, making agriculture
profitable and developing new marketing strategies.</li> 
           <li>Our zha call center and information center is to be set up for direct procurement of
agricultural products by integrating global Indians.</li>     
           <li>Our ZHA agriculture analyst will provide suggestions to the farmers by researching on
any questions, government policies, new crop varieties, trees, plants etc. which crops
are profitable for the farmers according to current market conditions and regional climate
factors.</li>   
           <li>Our Zha research analyst will support Farmers producer’s organization or any
association of agriculture for providing marketing services and predictability services
</li>   
           <li>Zha active board will conduct a web meeting once in a month to showcase our zha
social works,risks & needs,we will determine & publish the demand of specific food type
across india and world , based on zha research team</li>   
           <li>Zha active board members will facilitate the monthly Technical Agriculture Development
Webinar, which aims to bring the technologists, entrepreneurs and agriculture
professionals from around the world to discuss food sustainability</li>   
           <li>Zha team also provides internship technical training to college students for sustainable
skill development education that is given at low cost monthly that helps build our human
resources of india in rural too. For internship of students, parents may obtain agricultural
membership.</li>   
           <li>Zha team will help the farmers by conducting annual agricultural fair to showcase the
delta districts produces of the farmers. Our zha social tech professionals and business
people will participate in this exhibition for better branding and for marketing (after
crossing 1000 members in the Foundation, we can get this done).
</li>   
           <li>Our zha agri consulting will help the farmers on checking the quality of their agricultural
products and then help brand their products, so as to position the branding of the farm
prouces of any kind via food and safety department (this point will be active in future).
</li>   
           <li> Zha maintains a transparent and professional setup to facilitate and manage
membership programs and uses the membership payments for the agricultural research
and marketting purpose, scaling the farmer profits etc. it also covers research call center
maintenance costs, salaries</li>   
            </ul>
        </div>
        {/* <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div> */}
     </div>
      {/* </Tab>
     
    </Tabs> */}
    </div>

    <div className='bankk'>
    <img src='../img/bankdetails.png'></img>

    </div>
    <div className='membercontent'>
<h2>Son & daughter of soil<span className='green'> zha farmers supporters membership</span> initiative</h2>
        <div className='first-mcontent'>
            <div>
           <h4 id='member-benifits'>Member benefits include:</h4>
            <ul>
                <li>Can be part of yearly agricultural expo, when conducted,Can be part of R&D on new crop testing, assessment on maturity, new market inventions, new tech solutions  etc </li>
                <li>Camping in coconut farm areas can be availed</li>
                <li> Witness various progressive plans for farmers sustainability programs </li>
                <li>Engage in sustainable education of suburban and rural students by being a mentor</li>
                <li> Be a advisory board member of Zha</li>
                <li> Once a year, families of up to four members can stay for free in tents with amenities like pump set motor baths, etc., in coconut farms.</li>
            </ul>
            </div>
            <div>
                <img src='../../img/member1.png'></img>
            </div>
        </div>
        <h2>About the son & daughter of <span className='green'>Indian soil initiative </span>is an sustainability cause</h2>      
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/member2.png'></img>
            </div>
            <div>
           <p>Zha farmers supporters membership is mainly for the purpose of creating sustainability in farmers life.you may also know about the challenges faced by the farmers on scarcity of technology resources, lack of scalability in farm business, less export connections, no research and development of agriculture practices, feasibility studies etc ..
        hence the farmers do not have a global view of the international markets etc and even if they want to create research and development center for each farmer, it would heavily cost, so ZHA foundation charitable trust wants to enhance their scalability in agricultural business through various researchers through technology leaders, farmers and agri tech analysts.

            </p>
<p>
This initiative is named as “Son and Daughter of Indian Soil” , to take responsibility for making the agricultural business of farmers for a sustainable profitability. Eventually you ll realize that this son & daughter of soil initiative would want to connect the next generation of the farmers children across globe to align with our vision, so they are also connected with agricultural mindset for a continued agricultural practices . This approach helps us create a next level of digital agriculture community, even if they don’t own a farm land. Our zha foundation board wants to facilitate this service for free, however it requires to hire more professionals to carry out various activities such as research and development, & for managing tech systems 
</p>
<p>Zha is focusing on scaling the farmers by bringing them in to agri tech practices and enhance their supply chain through feasibility studies of new markets, promote their produces through digital marketing approaches etc . Hence a membership fee could help a non profit organization to pay the salary of the zha employees.
</p>
<p>This initiative would help us build a sustainable agricultural technology systems around the farmers life for a sustainable profits and help the world from future food crisis.</p>
           </div>
        </div>
    </div>
    </div>
);
}