import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Feedback.css';

const Feedback = (props) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 2000, min: 1024 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}


            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
         
                <div className='multicard'>
                <Card className='multicardshadow' style={{ width: '18rem',height: '20rem'  }}>

                <iframe src="https://www.youtube.com/embed/rvf3SIHJxKo?si=Y4tvpbsYPFXAQYTb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <Card.Body>
                        <Card.Title>Recognition & Excellency Award for Zha Foundation Innovative Social Services</Card.Title>
                        <Card.Text>
                       from CNI Annual Conference-2023
                        </Card.Text>

                    </Card.Body>
                </Card>

            </div>

            <div className='multicard'>
                <Card className='multicardshadow' style={{ width: '18rem',height: '20rem'  }}>

                <img src='../../img/award.webp' style={{ width: '18rem' }} />
                    {/* <iframe src="../../img/Award.png" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen  ></iframe> */}

                    <Card.Body>
                        <Card.Title>Clean Initiative & Youth Wellness Excellence Award</Card.Title>
                        <Card.Text>
                    from Government Arts and Science College, Peravurani
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>
            
           
             
        </Carousel>
    );
};

export default Feedback;


