import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Csr.css';
import Pptx from './Pptx';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/How-Zha-Use-CSR-Funds">English</NavLink></a>

        </div>

            <div className="social-ma">

<h1>झा सीएसआर फंड का उपयोग कैसे करें
</h1>

<Pptx />
 
 


 

<p>झा फाउंडेशन निम्नलिखित स्तंभों के साथ संरेखित करके अपनी स्थिरता पहल में कॉर्पोरेट सामाजिक जिम्मेदारी (सीएसआर) फंड को रणनीतिक रूप से निवेश कर सकता है:
</p>

 

<ol className="goalpoints">

<li><span>मानव कल्याण स्तंभ : </span>  
<ul>
    <li>स्वास्थ्य देखभाल कार्यक्रमों, मानसिक स्वास्थ्य जागरूकता और समग्र कल्याण को बढ़ावा देने वाली पहलों के लिए धन आवंटित करें।


</li>
    <li> शैक्षिक परियोजनाओं में निवेश करें जो पोषण और निवारक स्वास्थ्य देखभाल उपायों सहित स्वास्थ्य और कल्याण पर ध्यान केंद्रित करते हैं।



</li>
</ul>
</li> 

<li><span>प्रकृति कल्याण स्तंभ : </span>  
<ul>
    <li>जैव विविधता संरक्षण, पुनर्वनीकरण और प्राकृतिक आवासों की सुरक्षा को बढ़ावा देने वाली परियोजनाओं को निधि देना।



</li>
    <li>पर्यावरण-अनुकूल प्रथाओं और टिकाऊ कृषि पहलों में निवेश करें जो पारिस्थितिक तंत्र के स्वास्थ्य को बढ़ाते हैं।




</li>
</ul>
</li> 

<li><span>पृथ्वी कल्याण स्तंभ : </span>  
<ul>
    <li>जलवायु परिवर्तन शमन और अनुकूलन को संबोधित करने वाली परियोजनाओं का समर्थन करें, जैसे नवीकरणीय ऊर्जा पहल और कार्बन ऑफसेट कार्यक्रम।


</li>
    <li>ग्रह के महत्वपूर्ण संसाधनों के स्वास्थ्य में योगदान करते हुए जल संरक्षण और स्वच्छता परियोजनाओं में निवेश करें।





</li>
</ul>
</li> 

<li><span>लगातार लाभ स्तंभ : </span>  
<ul>
    <li>ऐसे सामाजिक उद्यमों की स्थापना या समर्थन करें जो स्थायी व्यावसायिक प्रथाओं का पालन करते हुए लगातार लाभ उत्पन्न करते हैं।




</li>
    <li> आय-सृजन करने वाली परियोजनाओं में निवेश करें जो ZHA फाउंडेशन के मिशन के साथ संरेखित हों, दीर्घकालिक प्रभाव के लिए एक आत्मनिर्भर मॉडल तैयार करें।



</li>
</ul>
</li> 

</ol>

<p>
इन स्तंभों को ZHA फाउंडेशन की CSR निवेश रणनीति में एकीकृत करके, संगठन एक समग्र दृष्टिकोण बना सकता है जो मानव कल्याण, प्रकृति संरक्षण, पृथ्वी की भलाई और वित्तीय स्थिरता को संबोधित करता है। यह बहुआयामी रणनीति बेहतर भविष्य के लिए एक स्थायी मानसिकता को बढ़ावा देने के फाउंडेशन के मिशन के साथ संरेखित करते हुए, समुदायों और पर्यावरण दोनों पर एक संतुलित और व्यापक प्रभाव सुनिश्चित करती है।</p>
 

 

 <br></br>

 <p>ZHA फाउंडेशन निम्नलिखित दृष्टिकोणों के माध्यम से स्थिरता पहल को बढ़ावा देने के लिए कॉर्पोरेट सामाजिक जिम्मेदारी (CSR) फंड का रणनीतिक उपयोग भी कर सकता है:
</p>

 

<ol className="goalpoints">

<li><span>पर्यावरण संरक्षण परियोजनाएँ : </span>  
<ul>
    <li>स्वास्थ्य देखभाल कार्यक्रमों, मानसिक स्वास्थ्य जागरूकता और समग्र कल्याण को बढ़ावा देने वाली पहलों के लिए धन आवंटित करें।


</li>
 
</ul>
</li> 

<li><span>हरित बुनियादी ढाँचा विकास : </span>  
<ul>
    <li>पर्यावरण-अनुकूल इमारतों, ऊर्जा-कुशल प्रौद्योगिकियों या हरित परिवहन जैसे टिकाऊ बुनियादी ढांचे के विकास के लिए धन आवंटित करें।




</li>
    
</ul>
</li> 

<li><span>समुदाय आधारित सतत कृषि :</span>  
<ul>
    <li>टिकाऊ कृषि परियोजनाओं के माध्यम से स्थानीय समुदायों का समर्थन करें जो जैविक खेती, पर्माकल्चर, या सामुदायिक उद्यानों पर ध्यान केंद्रित करते हैं, जो पर्यावरण और खाद्य स्थिरता दोनों में योगदान करते हैं।


</li>
     
</ul>
</li> 

<li><span>चक्रीय अर्थव्यवस्था पहल : </span>  
<ul>
    <li>उन परियोजनाओं में निवेश करें जो अपशिष्ट को कम करके और सामग्रियों के पुनर्चक्रण, अपसाइक्लिंग या पुन: उपयोग को प्रोत्साहित करके एक चक्रीय अर्थव्यवस्था को बढ़ावा देते हैं।




</li>
    
</ul>
</li> 


<li><span>जैव विविधता संरक्षण : </span>  
<ul>
    <li>प्राकृतिक आवासों के संरक्षण, लुप्तप्राय प्रजातियों की रक्षा और टिकाऊ पारिस्थितिकी तंत्र प्रबंधन को बढ़ावा देने वाली पहलों का समर्थन करके जैव विविधता की रक्षा और पुनर्स्थापित करने के लिए सीएसआर फंड का उपयोग करें।



</li>
 
</ul>
</li> 

<li><span>जल एवं ऊर्जा संरक्षण : </span>  
<ul>
    <li>जल और ऊर्जा संरक्षण परियोजनाओं में धन लगाएं, ऐसी प्रौद्योगिकियों और प्रथाओं को लागू करें जो खपत को कम करती हैं और कुशल संसाधन उपयोग को बढ़ावा देती हैं।





</li>
    
</ul>
</li> 

<li><span>शिक्षा एवं जागरूकता कार्यक्रम : </span>  
<ul>
    <li>शैक्षिक पहलों में निवेश करें जो स्थिरता के मुद्दों के बारे में जागरूकता बढ़ाते हैं, पर्यावरण-अनुकूल प्रथाओं को बढ़ावा देते हैं और समुदायों को टिकाऊ जीवन शैली अपनाने के लिए सशक्त बनाते हैं।




</li>
     
</ul>
</li> 

<li><span>जलवायु परिवर्तन शमन : </span>  
<ul>
    <li>जलवायु परिवर्तन शमन में योगदान देने वाली परियोजनाओं का समर्थन करें, जैसे कार्बन ऑफसेट कार्यक्रम, टिकाऊ परिवहन समाधान, या ग्रीनहाउस गैस उत्सर्जन को कम करने के उद्देश्य से पहल।
</li>
    
</ul>
</li> 



<li><span>पर्यावरणीय फोकस वाले सामाजिक उद्यम :</span>  
<ul>
    <li>ऐसे सामाजिक उद्यमों में निवेश करें जो पर्यावरणीय जिम्मेदारी को बढ़ावा देते हुए आर्थिक विकास को बढ़ावा देते हुए अपने व्यवसाय मॉडल में स्थिरता को एकीकृत करते हैं।




</li>
 
</ul>
</li> 

<li><span>स्थिरता के लिए अनुसंधान और नवाचार : </span>  
<ul>
    <li>अनुसंधान पहलों के लिए धन आवंटित करें जो स्थिरता चुनौतियों के लिए नवीन समाधान तलाशते हैं, नई प्रौद्योगिकियों या प्रथाओं के विकास को बढ़ावा देते हैं।







</li>
    
</ul>
</li> 

<li><span>सतत प्रथाओं के लिए क्षमता निर्माण : </span>  
<ul>
    <li>स्थायी प्रथाओं को अपनाने, दीर्घकालिक प्रभाव के लिए प्रशिक्षण और संसाधन प्रदान करने में समुदायों और संगठनों की क्षमताओं को बढ़ाने के लिए सीएसआर फंड का उपयोग करें।





</li>
     
</ul>
</li> 

<li><span>स्थिरता प्रभाव को मापना और रिपोर्ट करना :</span>  
<ul>
    <li>स्थिरता पहल के प्रभाव को मापने और रिपोर्ट करने, हितधारकों के प्रति पारदर्शिता और जवाबदेही सुनिश्चित करने के लिए मजबूत प्रणालियों में निवेश करें।
</li>
    
</ul>
</li> 
</ol>

<p>

सीएसआर फंड को स्थिरता पहल के साथ जोड़कर, गैर-लाभकारी संगठन पर्यावरणीय चुनौतियों का समाधान करने, लचीलेपन को बढ़ावा देने और समुदायों और ग्रह के लिए अधिक टिकाऊ भविष्य में योगदान देने में महत्वपूर्ण भूमिका निभा सकते हैं।</p>
       
       
       
       
        </div>


        </div>




 
        


    );
}

