import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Summit.css';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
export default function Transformation(){

    return(
        <div>
            <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainability-Summit-2024-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainability-Summit-2024-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainability-Summit-2024-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainability-Summit-2024-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sustainability-Summit-2024-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>


            <div className="social-ma">

<h1>Global Sustainability Summit: "Harmony for Humanity and Planet Earth"
</h1>


<div className="internship">
        <img src="../img/summit.webp"></img>
        </div>
        
<p>The Global Sustainability Summit will bring together thought leaders, experts, and activists from around the world to address the sustainability pillars of human wellness, earth wellness, nature wellness and Increasing Profit Pillar. This summit aims to foster collaboration and knowledge-sharing to create a sustainable and harmonious future for both humanity and the planet.
</p>
</div>


<div className="goalpointsp">

<h4>Themes :
</h4>


<ol className="goalpoints">
    <li><span>Human Wellness Pillar : </span>
    <ul>
        <li>Promoting holistic health and well-being for individuals, encompassing physical, mental, and emotional aspects.
    </li>

    <li>Addressing access to clean water, nutritious food, healthcare, education, and equitable opportunities for all.
    </li>
    </ul>
    </li>

    <li><span>Earth Wellness Pillar : </span>
    <ul>
        <li>Focusing on sustainable practices to protect and restore the environment, including efforts to combat climate change, conserve natural resources, and promote clean energy solutions.

    </li>

    <li>Highlighting innovations in green technology and sustainable infrastructure.


    </li>
    </ul>
    </li>


    <li><span>Nature Wellness Pillar
 : </span>
    <ul>
        <li>Advocating for the preservation of biodiversity, conservation of ecosystems, and restoration of natural habitats.

    </li>

    <li>Exploring the intrinsic connection between human well-being and the health of the natural world.
    </li>
    </ul>
    </li>

    <li><span>Increasing Profit Pillar : </span>
    <ul>
        <li>To sustain profitability for producers, manufacturers, and consumers, consider implementing the following strategies:
    </li>
    </ul>
    </li>


    <ol className="goalpoints">

<li><span>Cost Efficiency : </span>  
<ul>
    <li>Encourage producers and manufacturers to adopt cost-effective and sustainable practices.
</li>
    <li> Invest in technologies that enhance efficiency in production processes.
</li>
</ul>
</li> 

<li><span>Supply Chain Optimization : </span>  
<ul>
    <li>Streamline supply chains to reduce costs and minimize waste.

</li>
    <li>Implement sustainable and ethical sourcing practices.

</li>
</ul>
</li> 

<li><span>Market Diversification : </span>  
<ul>
    <li>Explore new markets and distribution channels to reduce dependence on a single market.

</li>
    <li> Foster partnerships and collaborations to enhance market reach.

</li>
</ul>
</li> 

<li><span>Product Innovation : </span>  
<ul>
    <li>Invest in research and development for innovative products that meet consumer demands.

</li>
    <li>Emphasize sustainable and eco-friendly product development.

</li>
</ul>
</li> 

<li><span>Consumer Education : </span>  
<ul>
    <li>Educate consumers about the value and benefits of sustainable products.

</li>
    <li>Foster a consumer mindset that values quality and sustainability.


</li>
</ul>
</li> 


<li><span>Fair Pricing : </span>  
<ul>
    <li>Ensure fair pricing practices along the supply chain, benefiting both producers and consumers.

</li>
    <li>Promote transparency in pricing to build trust with consumers.

</li>
</ul>
</li> 


<li><span>Technology Integration : </span>  
<ul>
    <li>Embrace technology to optimize production, reduce waste, and enhance overall efficiency.

</li>
    <li>Implement e-commerce solutions to reach a broader consumer base.

</li>
</ul>
</li> 


<li><span>Quality Assurance : </span>  
<ul>
    <li>Prioritize quality control measures to deliver consistent and reliable products.

</li>
    <li>Build a reputation for high-quality and sustainable products.

</li>
</ul>
</li> 

<li><span>Customer Engagement : </span>  
<ul>
    <li>Establish direct communication channels with consumers to understand their needs and preferences.

</li>
    <li> Implement customer feedback mechanisms to continuously improve products.
</li>
</ul>
</li> 


<li><span>Regulatory Compliance : </span>  
<ul>
    <li>Stay informed and compliant with evolving regulations related to sustainability and product standards.

</li>
    <li>Proactively engage with regulators to contribute to the development of fair and supportive policies.

</li>
</ul>
</li> 

</ol>
   
   </ol>

   <h4>Key Sessions : </h4>

    <ol className="goalpoints">
   
    <li><span>Holistic Health and Well-being : </span>Exploring approaches to enhance physical, mental, and emotional wellness, including discussions on nutrition, mental health, and community engagement.
</li>
    <li><span>Climate Action and Environmental Stewardship : </span>Addressing strategies to combat climate change, reduce pollution, and promote sustainable resource management.
</li>


    <li><span>Biodiversity Conservation and Ecological Restoration : </span>Examining efforts to protect and restore natural ecosystems, and the importance of preserving biodiversity for a balanced planet.


</li>
    <li><span>Sustainable Development and Equitable Access : </span>Discussing policies and initiatives to ensure that all communities have access to basic needs, education, and opportunities for growth.



</li>
    <li><span>Innovation for a Greener Future : </span>Showcasing groundbreaking technologies and innovations in renewable energy, sustainable agriculture, and eco-friendly infrastructure.



</li>
    <li><span>Advocacy and Activism for Change : </span>Empowering individuals and communities to become advocates for sustainable practices and agents of positive change.

</li>
</ol>


<h4>Outcomes : </h4>

    <ol className="goalpoints">

    <li><span>Collaborative Solutions : </span> Foster international partnerships and collaborations to address global sustainability challenges.

</li>
    <li><span>Policy Recommendations
 : </span>Develop and share actionable policy recommendations to promote sustainable practices at local, national, and global levels.

</li>
    <li><span>Inspire Action : </span>Motivate individuals, businesses, and governments to take concrete steps towards achieving a more sustainable and balanced future.
</li>




</ol>






<p>By focusing on these three pillars, the Global Sustainability Summit aims to create a platform for meaningful dialogue, knowledge exchange, and collective action towards a more sustainable and harmonious world for all living beings.</p>




{/*        

     
<h4> Increasing Profit Pillar

 </h4>

        <p>To sustain profitability for producers, manufacturers, and consumers, consider implementing the following strategies:

</p>
 
     


<ol className="goalpoints">

<li><span>Cost Efficiency : </span>  
<ul>
    <li>Encourage producers and manufacturers to adopt cost-effective and sustainable practices.
</li>
    <li> Invest in technologies that enhance efficiency in production processes.
</li>
</ul>
</li> 

<li><span>Supply Chain Optimization : </span>  
<ul>
    <li>Streamline supply chains to reduce costs and minimize waste.

</li>
    <li>Implement sustainable and ethical sourcing practices.

</li>
</ul>
</li> 

<li><span>Market Diversification : </span>  
<ul>
    <li>Explore new markets and distribution channels to reduce dependence on a single market.

</li>
    <li> Foster partnerships and collaborations to enhance market reach.

</li>
</ul>
</li> 

<li><span>Product Innovation : </span>  
<ul>
    <li>Invest in research and development for innovative products that meet consumer demands.

</li>
    <li>Emphasize sustainable and eco-friendly product development.

</li>
</ul>
</li> 

<li><span>Consumer Education : </span>  
<ul>
    <li>Educate consumers about the value and benefits of sustainable products.

</li>
    <li>Foster a consumer mindset that values quality and sustainability.


</li>
</ul>
</li> 


<li><span>Fair Pricing : </span>  
<ul>
    <li>Ensure fair pricing practices along the supply chain, benefiting both producers and consumers.

</li>
    <li>Promote transparency in pricing to build trust with consumers.

</li>
</ul>
</li> 


<li><span>Technology Integration : </span>  
<ul>
    <li>Embrace technology to optimize production, reduce waste, and enhance overall efficiency.

</li>
    <li>Implement e-commerce solutions to reach a broader consumer base.

</li>
</ul>
</li> 


<li><span>Quality Assurance : </span>  
<ul>
    <li>Prioritize quality control measures to deliver consistent and reliable products.

</li>
    <li>Build a reputation for high-quality and sustainable products.

</li>
</ul>
</li> 

<li><span>Customer Engagement : </span>  
<ul>
    <li>Establish direct communication channels with consumers to understand their needs and preferences.

</li>
    <li> Implement customer feedback mechanisms to continuously improve products.
</li>
</ul>
</li> 


<li><span>Regulatory Compliance : </span>  
<ul>
    <li>Stay informed and compliant with evolving regulations related to sustainability and product standards.

</li>
    <li>Proactively engage with regulators to contribute to the development of fair and supportive policies.

</li>
</ul>
</li> 

</ol>

<p>By integrating these pillars, you can create a holistic approach to sustaining profitability across the entire value chain, ensuring that producers, manufacturers, and consumers benefit from economically viable and environmentally conscious practices.
</p>

 
       
       
        */}
       
        </div>


        




        </div>
        


    );
}

