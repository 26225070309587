import Carousel from 'react-bootstrap/Carousel';
import './Carousal.css';
import './Carousalcopy.css';

import './Mycaro.css';
import React from 'react';

function IndividualIntervalsExample() {
  return (
    <div className='carouselll'>
    
    <Carousel>
      
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/1.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>The winning event in the junior athletics competition held in our area  </h3>
          <p>   </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/2.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  Event that won the MARATHON (Anavayal)</h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/3.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> The moment our students won the prize in the MARATHON competition in Anavayal </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/4.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>The moment our students won the prize in the MARATHON competition in Anavayal  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/5.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>The moment our students won the prize in the MARATHON competition in Anavayal  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/6.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> The moment our students won the prize in the MARATHON competition in Anavayal </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/7.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our Zha Athletic Club Students Participating in Kumbakonam Delta Meet MARATHON </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/8.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our Zha Athletic Club Students Participating in Kumbakonam Delta Meet MARATHON </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/9.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Overall champions in kumbakonam delta meet </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/10.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our Zha Athletic Club Students Participating in Kumbakonam Delta Meet MARATHON </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/11.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> A winning moment in the mini MARATHON held at Aranthangi </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/12.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> A winning event in the mini MARATHON held at Pappanadu </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/13.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> The event that won the athletics competition for the Chief Minister's Trophy </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/14.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our Zha Athletic Club Students Won the Aringar Anna MARATHON competition held in Pattukottai </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/15.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our students won the overall champion title in Kumbakonam Delta Athletics </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/16.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our students Won Thanjavur District Junior Athletics event </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/17.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Event of participation in state athletics competition </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/18.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Event of participation in state athletics competition </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/19.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Event of participation in state athletics competition </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/20.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Event of participation in state athletics competition </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/21.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Event of participation in state athletics competition </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/22.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> won the district athletics competition held between schools this year and qualified for the state competition </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/23.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Suganya, who coaches the girl students of our association, participates and wins MARATHONs even today. </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/24.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our students are trained at the beach once a week to improve their skills </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/25.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Our Students Participating event at the Cross County MARATHON held in Kanyakumari   </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/26.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> A winning event at the Cross County MARATHON held in Kanyakumari </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/MARATHON/27.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3> Won the event of Thanjavur Mathakottai MARATHON  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
  
    
    </Carousel>

    </div>
  );
}

export default IndividualIntervalsExample;