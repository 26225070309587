import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Csr.css';
import Pptx from './Pptx';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

            
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/How-Zha-Use-CSR-Funds-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/How-Zha-Use-CSR-Funds-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/How-Zha-Use-CSR-Funds-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/How-Zha-Use-CSR-Funds-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/How-Zha-Use-CSR-Funds-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
            

            <div className="social-ma">

<h1>How Zha Use CSR Funds
</h1>

<Pptx />
 
 


 

<p>The Zha Foundation can strategically invest corporate social responsibility (CSR) funds in its sustainability initiatives by aligning them with the following pillars:
</p>

 

<ol className="goalpoints">

<li><span>Human Wellness Pillar : </span>  
<ul>
    <li>Allocate funds for healthcare programs, mental health awareness, and initiatives promoting overall well-being.



</li>
    <li> Invest in educational projects that focus on health and wellness, including nutrition and preventive healthcare measures.



</li>
</ul>
</li> 

<li><span>Nature Wellness Pillar : </span>  
<ul>
    <li>Fund projects that promote biodiversity conservation, reforestation, and protection of natural habitats.




</li>
    <li>Invest in eco-friendly practices and sustainable agriculture initiatives that enhance the health of ecosystems.




</li>
</ul>
</li> 

<li><span>Earth Wellness Pillar : </span>  
<ul>
    <li>Support projects addressing climate change mitigation and adaptation, such as renewable energy initiatives and carbon offset programs.


</li>
    <li>Invest in water conservation and sanitation projects, contributing to the health of the planet's vital resources.





</li>
</ul>
</li> 

<li><span>Consistent Profit Pillar : </span>  
<ul>
    <li>Establish or support social enterprises that generate consistent profits while adhering to sustainable business practices.




</li>
    <li> Invest in income-generating projects that align with the ZHA Foundation's mission, creating a self-sustaining model for long-term impact.




</li>
</ul>
</li> 

</ol>

<p>
By integrating these pillars into the ZHA Foundation's CSR investment strategy, the organization can create a holistic approach that addresses human wellness, nature conservation, Earth's well-being, and financial sustainability. This multifaceted strategy ensures a balanced and comprehensive impact on both communities and the environment, aligning with the foundation's mission of fostering a sustainable mindset for a better future.</p>
 

 

 <br></br>

 <p>ZHA foundation would can also strategically utilize corporate social responsibility (CSR) funds to bolster sustainability initiatives through the following approaches:

</p>

 

<ol className="goalpoints">

<li><span>Environmental Conservation Projects : </span>  
<ul>
    <li>Allocate funds for healthcare programs, mental health awareness, and initiatives promoting overall well-being.



</li>
 
</ul>
</li> 

<li><span>Green Infrastructure Development : </span>  
<ul>
    <li>Allocate funds for the development of sustainable infrastructure, such as eco-friendly buildings, energy-efficient technologies, or green transportation.





</li>
    
</ul>
</li> 

<li><span>Community-Based Sustainable Agriculture : </span>  
<ul>
    <li>Support local communities through sustainable agriculture projects that focus on organic farming, permaculture, or community gardens, contributing to both environmental and food sustainability.



</li>
     
</ul>
</li> 

<li><span>Circular Economy Initiatives : </span>  
<ul>
    <li>Invest in projects that promote a circular economy by minimizing waste and encouraging recycling, upcycling, or repurposing of materials.





</li>
    
</ul>
</li> 


<li><span>Biodiversity Conservation : </span>  
<ul>
    <li>Use CSR funds to protect and restore biodiversity by supporting initiatives that conserve natural habitats, protect endangered species, and promote sustainable ecosystem management.



</li>
 
</ul>
</li> 

<li><span>Water and Energy Conservation : </span>  
<ul>
    <li>Channel funds into water and energy conservation projects, implementing technologies and practices that reduce consumption and promote efficient resource use.






</li>
    
</ul>
</li> 

<li><span>Education and Awareness Programs : </span>  
<ul>
    <li>Invest in educational initiatives that raise awareness about sustainability issues, promote eco-friendly practices, and empower communities to adopt sustainable lifestyles.





</li>
     
</ul>
</li> 

<li><span>Climate Change Mitigation : </span>  
<ul>
    <li>Support projects that contribute to climate change mitigation, such as carbon offset programs, sustainable transportation solutions, or initiatives aimed at reducing greenhouse gas emissions.

</li>
    
</ul>
</li> 



<li><span>Social Enterprises with Environmental Focus : </span>  
<ul>
    <li>Invest in social enterprises that integrate sustainability into their business models, fostering economic development while promoting environmental responsibility.




</li>
 
</ul>
</li> 

<li><span>Research and Innovation for Sustainability : </span>  
<ul>
    <li>Allocate funds to research initiatives that explore innovative solutions for sustainability challenges, fostering the development of new technologies or practices.







</li>
    
</ul>
</li> 

<li><span>Capacity Building for Sustainable Practices : </span>  
<ul>
    <li>Use CSR funds to enhance the capabilities of communities and organizations in adopting sustainable practices, providing training and resources for long-term impact.





</li>
     
</ul>
</li> 

<li><span>Measuring and Reporting Sustainability Impact : </span>  
<ul>
    <li>Invest in robust systems for measuring and reporting the impact of sustainability initiatives, ensuring transparency and accountability to stakeholders.

</li>
    
</ul>
</li> 
</ol>

<p>

By aligning CSR funds with sustainability initiatives, non-profit organizations can play a crucial role in addressing environmental challenges, fostering resilience, and contributing to a more sustainable future for communities and the planet.</p>
       
       
       
       
        </div>


        </div>




 
        


    );
}

