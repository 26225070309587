// import { initializeApp }  from 'firebase/app';
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/auth';
// import 'firebase/compat/database'

// const firebaseConfig = {
//     apiKey: "AIzaSyDkWHWXlqgFqFmqPH3-9kt1Sk80Hlkg6Qk",
//     authDomain: "zha-signup-db.firebaseapp.com",
//     projectId: "zha-signup-db",
//     storageBucket: "zha-signup-db.appspot.com",
//     messagingSenderId: "620296666586",
//     appId: "1:620296666586:web:eaf9d0f632ffcea04454e3"
// };

// const fire = firebase.initializeApp(firebaseConfig)

// export default firebase;








import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyDkWHWXlqgFqFmqPH3-9kt1Sk80Hlkg6Qk",
    authDomain: "zha-signup-db.firebaseapp.com",
    databaseURL: "https://zha-signup-db-default-rtdb.firebaseio.com",
    projectId: "zha-signup-db",
    storageBucket: "zha-signup-db.appspot.com",
    messagingSenderId: "620296666586",
    appId: "1:620296666586:web:eaf9d0f632ffcea04454e3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getDatabase(app);

export { auth, database };
