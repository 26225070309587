import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


// import './Whyzha.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Common-Humans-COPs-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Common-Humans-COPs-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Common-Humans-COPs-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Common-Humans-COPs-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Common-Humans-COPs-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

 

            <div className="social-ma">

<h1>Zha Common Man COP’s </h1>
</div>


<div className="internship">
        <img src="../img/cops.webp"></img>
        </div>
<div className="goalpointsp">

<p>The Zha Common Man Community of Practices (COPs) aims to foster a sustainability mindset among individuals globally. Mentored by Zha Foundation's global board members, the COPs focus on monthly forums to educate, discuss, and inspire action toward sustainable practices. The goals include raising awareness, sharing best practices, and cultivating a global network committed to sustainability. Benefits include knowledge exchange, collaborative initiatives, and empowering individuals to contribute to a more sustainable world.
</p>
<p>
So we have our various COPs for every country which should be focusing on  delivering sessions & resolution outcome, innovating initiatives based on the following agendas
</p>

<ol className="goalpoints">
    <li><span>Education and Awareness :</span>Zha's COPs provide a platform for common people to gain knowledge about climate change and sustainability, fostering a deeper understanding of their significance.</li>
    <li><span>Local Impact : </span> By engaging common individuals, the COPs empower local communities to take action, contributing collectively to global sustainability efforts.
</li>
    <li><span>Behavioral Change : </span>The COPs aim to influence daily habits and practices, encouraging sustainable choices among participants who may not initially grasp the importance of climate change.</li>


    <li><span>Community Empowerment : </span>Through mentorship and collaboration, the COPs empower individuals to become advocates for sustainability within their communities, creating a ripple effect of positive change.

</li>
    <li><span>Global Network : </span>The COPs connect people from diverse backgrounds, fostering a sense of global responsibility and illustrating how individual actions collectively impact the planet.


</li>
    <li><span>Alignment with UN Goals : </span>Zha's COPs directly align with the United Nations' sustainability goals, offering a grassroots approach to achieving broader global objectives.


</li>
    <li><span>Inclusive Participation : </span>By targeting common people, the COPs ensure inclusivity, engaging a wider demographic and avoiding the exclusion of those who may not be familiar with the language or concepts of sustainability.


</li>
    <li><span>Monthly Forums :</span>Regular forums provide a consistent platform for ongoing dialogue and learning, reinforcing the importance of sustainability over time and making it a part of participants' ongoing consciousness.
</li>
    <li><span>Practical Solutions : </span>The COPs focus on practical, achievable solutions that common people can implement in their daily lives, making sustainability more accessible and less abstract.
</li>
    <li><span>Adaptation and Resilience : </span>Educating common people through COPs helps communities adapt to changing environmental conditions and develop resilience against the impacts of climate change.


</li>
 
 </ol>
 
 <p>In essence, Zha's Common Man COPs play a crucial role in bridging the gap between the abstract concepts of climate change and sustainability goals and the practical actions of everyday individuals, creating a more sustainable and informed global society.</p>
 </div>

        </div>

    );
}

