import React from "react";
import "./Structure.css";
import Tab from 'react-bootstrap/Tab';
import Tower from './Tower';
import Singapore from './Tower copy 2';
 import Bengalore from './Tower copy 3';
 import Andhra from './Tower copy';

import Tabs from 'react-bootstrap/Tabs';
export default function Structure(){

    return(
        
<div className="structure">
<div className="social-ma">

<h1>Org Structure</h1>
</div>




<div className="titlebar">
        <div className="bar"></div>
        <h4 className="towertitile">Tamilnadu Tower</h4>
    </div>
    <Tower />
 

<div className="titlebar">
        <div className="bar"></div>
        <h4 className="towertitile">Andhra Tower</h4>
    </div>
    <Andhra />
 
<div className="titlebar">
        <div className="bar"></div>
        <h4 className="towertitile">Karnataka Tower</h4>
    </div>
    <Bengalore />
<div>
<div className="titlebar">
        <div className="bar"></div>
        <h4 className="towertitile">Singapore Tower</h4>
    </div>
    <Singapore />
 

 
 

{/*  
 
<div  >
       
        <h4>Chozha Tower</h4>
    </div>
    <div className="recuee">
    <img src="../img/tamilnaduboard.png"></img>
    </div>

    <div  >
       
        <h4>Pallava Tower</h4>
    </div>
    <div className="recuee">
    <img src="../img/common.png"></img>
    </div>

    <div >
       
        <h4>Chera Tower</h4>
    </div>
    <div className="recuee">
    <img src="../img/common.png"></img>
    </div>

    <div >
       
       <h4>Pandiya Tower</h4>
   </div>
   <div className="recuee">
   <img src="../img/common.png"></img>
   </div> */}
{/* <Tabs
      
      className="mb-2"
      defaultActiveKey="home"
    >
      <Tab eventKey="contact" title="Tamilnadu Tower" disabled >
      </Tab>
 
       <Tab eventKey="home" title="Chozha Tower">
        <div className="tamimg">
        <img src="../../img/tamilnaduboard.png"></img>
      
        </div>
      </Tab>
      <Tab eventKey="profile" title="Pallava Tower">
      <div className="tamimg">
        <img src="../../img/andhraboard.png"></img>
        
        </div>
      </Tab>

      <Tab eventKey="profilee" title="Chera Tower">
      <div className="tamimg">
        <img src="../../img/common.png"></img>
        
        </div>
      </Tab>

      <Tab eventKey="profileel" title="Pandiya Tower">
      <div className="tamimg">
        <img src="../../img/common.png"></img>
        
        </div>
      </Tab>
      
    </Tabs> */}


    {/* <Tabs
      
      className="mb-2"
    >

<Tab eventKey="contact" title="Andhra Tower" disabled >
      </Tab>

      <Tab eventKey="home" title="Nellore Tower">
        <div className="tamimg">
        <img src="../../img/common.png"></img>
      
        </div>
      </Tab>
    </Tabs>

    <Tabs
      
      className="mb-2"
  
    >

<Tab eventKey="contact" title="Karnataka Tower" disabled >
      </Tab>

      <Tab eventKey="home" title="Bengalore Tower">
        <div className="tamimg">
        <img src="../../img/common.png"></img>
      
        </div>
      </Tab>
    </Tabs>


    <Tabs
      
      className="mb-2"
      
    >
       <Tab eventKey="contact" title="Singapore Tower" disabled >
      </Tab>

      <Tab eventKey="home" title="Singapore Tower">
        <div className="tamimg">
        <img src="../../img/common.png"></img>
      
        </div>
      </Tab>
    </Tabs>


</div>
    <div>
     
    </div>
    
    <div className="row">
        <h2>Here Illustration of overall<span> Operating Model</span>
</h2>
    <section className="floww">
           <img src="../img/flow/floww.png"  />
           </section> */}
 
   
   {/* <div className="row">  
   <h2>Zha <span>research and information call center </span>for farmers</h2> 
    <img  src="../img/flow/row.png" />
   </div> */}
  
  <div className="row">
        <h2>Here Illustration of overall<span> Operating Model</span>
</h2>
    <section className="floww">
           <img src="../img/floww.webp"  />
           
</section>

</div>
    </div>
</div>
    );
}