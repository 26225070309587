import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Assesment.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
             {/* <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>ZHA Sustainability Assessment Framework: "Balanced Well-being for Humanity and Planet Earth"
</h1>

<div className="internship">
        <img src="../img/summit.webp"></img>
        </div>

<p>The ZHA Sustainability Assessment Framework is a comprehensive tool designed to evaluate and enhance sustainability efforts across three vital pillars: human wellness, nature wellness, and earth wellness. This framework provides a structured approach to measure, analyze, and improve sustainability practices in these critical areas.
</p>
</div>


<div className="goalpointsp">

<h4>Components of the Framework :
</h4>


<ol className="goalpoints">
    <li><span>Human Wellness Pillar Assessment : </span>
    <ul>
        <li><span>Health and Well-being : </span>Evaluates access to healthcare, mental health support, nutrition, and overall physical well-being within a community or organization.
    </li>

    <li><span>Education and Empowerment : </span>Assesses access to quality education, skill-building opportunities, and resources that empower individuals for personal and professional growth.
    </li>

    <li><span>Social Equity and Inclusivity : </span>Measures inclusivity, diversity, and equity initiatives to ensure fair opportunities and social cohesion within a community or organization.

    </li>

    </ul>
    </li>


    <li><span>Nature Wellness Pillar Assessment : </span>
    <ul>
        <li><span>Biodiversity and Conservation : </span>Examines efforts to protect and enhance biodiversity, conserve ecosystems, and support wildlife habitats.
    </li>

    <li><span>Green Spaces and Recreational Opportunities : </span>Assesses the availability and accessibility of parks, nature reserves, and recreational spaces for the community.

    </li>

    <li><span>Water and Air Quality : </span>Evaluates measures taken to maintain clean water sources, reduce pollution, and improve air quality.

    </li>

    </ul>
    </li>


    <li><span>Earth Wellness Pillar Assessment : </span>
    <ul>
        <li><span>Climate Action and Mitigation : </span>Assesses initiatives to reduce greenhouse gas emissions, promote renewable energy sources, and combat climate change.
    </li>

    <li><span>Resource Management and Efficiency : </span>Measures efforts to conserve natural resources, minimize waste, and promote sustainable production and consumption.
    </li>

    <li><span>Resilient Infrastructure and Green Technology : </span>Evaluates the integration of sustainable and resilient infrastructure, including the adoption of green technologies.

    </li>

    </ul>
    </li>

    


   </ol>









   <h4>Implementation : </h4>

    <ol className="goalpoints">
   
    <li><span>Data Collection and Analysis : </span>Gather relevant data through surveys, interviews, and environmental assessments to evaluate performance in each pillar.
</li>
    <li><span>Stakeholder Engagement : </span>Involve stakeholders, including community members, employees, and experts, in the assessment process to ensure diverse perspectives.
</li>


    <li><span>Action Planning and Recommendations : </span>Generate actionable recommendations based on assessment results, focusing on areas that require improvement and opportunities for innovation.


</li>
    <li><span>Continuous Monitoring and Reporting : </span>Establish mechanisms for ongoing monitoring and reporting to track progress, measure impact, and make necessary adjustments.
</li>
</ol>




<p>By utilizing the ZHA Sustainability Assessment Framework, organizations and communities can systematically evaluate and enhance their efforts towards achieving balanced well-being for both humanity and the planet. This framework empowers stakeholders to take meaningful steps towards a more sustainable and harmonious future.</p>



</div>
        
     

        </div>

    );
}

