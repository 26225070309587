import React from "react";
import "./Operating.css"
import Image from 'react-bootstrap/Image';
import { NavLink } from "react-router-dom";
export default function Operating(){

    return(

        <div>
                
        
            <div className="social-ma">

            <h1>Operating Model</h1>
            </div>
           
           <section className="floww">
           <img src="../img/flow/floww.png"  />
           </section>
   <div className="arrow">
    <h2>How <span>Zha Foundation</span> Helps?</h2>
   <div className="arrflex">
   
    <p>Build a eco system of sutainability model.for example,In agriculture how we want to sustainable life for a farmer </p>

   <div className="arroww"><img  src="../img/flow/arrow.png" />
   </div>
   </div>
   </div>
   <div className="row">  
   <h2>Zha <span>research and information call center </span>for farmers</h2> 
    <img  src="../img/flow/row.png" />
   </div>
  

        </div>
    
    );
}


