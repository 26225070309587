import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


export default function Ecofriendly() {
    return (
        <div>

            
            
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Wellness-Program">English</NavLink></a>
         
        </div>
{/* 
            <div  id="language" >

        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a>
        

        </div> */}

<h1 id="mhead">झा कल्याण कार्यक्रम</h1>

   
<div className='second-mcontent'>
           <img src='../../img/self.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>झा की शारीरिक और मानसिक कल्याण नि:शुल्क कोचिंग में गतिविधियों की एक विविध श्रृंखला शामिल है, जो कल्याण के लिए एक समग्र दृष्टिकोण सुनिश्चित करती है। यह भी शामिल है:

</p>
 
<ol className="goalpoints">

	
   
   <li><span>एथलेटिक प्रशिक्षण कार्यक्रम :</span>शारीरिक फिटनेस के लिए अनुकूलित कार्यक्रम, जिसमें मैराथन, साइकिलिंग और वॉलीबॉल जैसे विभिन्न विषयों को शामिल किया गया है।

</li>

<li><span>मानसिक गतिविधियाँ : </span> शारीरिक प्रशिक्षण से परे, प्रतिभागियों को ध्यान और योग जैसी मानसिक कल्याण गतिविधियों से लाभ होता है।


</li>
<li><span>इनडोर शतरंज :</span> शतरंज जैसे रणनीतिक खेलों के माध्यम से दिमाग को व्यस्त रखना, संज्ञानात्मक विकास और मानसिक चपलता को बढ़ावा देना।

</li>
<p>
झा कल्याण कार्यक्रम में एक व्यापक दृष्टिकोण है जो कोचिंग कार्यक्रम में भाग लेने वाले व्यक्तियों के लिए संतुलित और स्वस्थ जीवनशैली को बढ़ावा देने, कल्याण के शारीरिक और मानसिक दोनों पहलुओं को संबोधित करता है।</p>

<p>छात्रों के लिए ग्रामीण और उपनगरीय क्षेत्रों में झा निःशुल्क शारीरिक और मानसिक कल्याण कोचिंग कई लाभ प्रदान करती है :</p>
</ol>
        
 

<ol className="goalpoints"> 

<li><span>संसाधनों तक पहुंच :</span>  ग्रामीण क्षेत्रों में छात्रों को कोचिंग कार्यक्रमों तक पहुंच प्राप्त होती है जो अन्यथा वित्तीय बाधाओं के कारण उनके पास नहीं होती, जिससे कल्याण के लिए समान अवसरों को बढ़ावा मिलता है।




</li>
<li><span>बेहतर शारीरिक स्वास्थ्य : </span> मैराथन, साइकिलिंग और वॉलीबॉल जैसे एथलेटिक प्रशिक्षण कार्यक्रम सक्रिय जीवनशैली को बढ़ावा देकर शारीरिक फिटनेस को बढ़ाने में योगदान करते हैं।

</li>
<li><span>उन्नत मानसिक कल्याण : </span> ध्यान, योग कक्षाएं और इनडोर शतरंज तनाव से राहत, बेहतर फोकस और समग्र मानसिक लचीलेपन के लिए उपकरण प्रदान करते हैं।
</li>
<li><span>कौशल विकास : </span>विविध गतिविधियों में भागीदारी टीम वर्क, अनुशासन और रणनीतिक सोच सहित कौशल की एक विस्तृत श्रृंखला के विकास को बढ़ावा देती है।
</li>
<li><span>सामुदायिक निर्माण : </span>  कल्याण कार्यक्रम प्रतिभागियों के बीच समुदाय की भावना पैदा करते हैं, सामाजिक कनेक्शन और समर्थन नेटवर्क को बढ़ावा देते हैं।

</li>
<li><span>सशक्तिकरण :</span>कोचिंग छात्रों को चुनौतियों से निपटने और जीवन के विभिन्न पहलुओं में सफल होने के लिए शारीरिक और मानसिक दोनों उपकरणों से लैस करके सशक्त बनाती है।
</li>
<li><span>स्वस्थ जीवन शैली को बढ़ावा देना : </span>शारीरिक गतिविधि और मानसिक स्वास्थ्य प्रथाओं को प्रोत्साहित करके, कोचिंग स्वस्थ जीवन शैली को अपनाने में योगदान देती है, जिससे जीवन शैली से संबंधित स्वास्थ्य समस्याओं का खतरा कम हो जाता है।
</li>


</ol>




<p>
कुल मिलाकर, मुफ्त कोचिंग कार्यक्रमों का उद्देश्य ग्रामीण और उपनगरीय क्षेत्रों में छात्रों का उत्थान और सशक्तिकरण करना, उनके समग्र कल्याण को बढ़ावा देना और उन्हें मूल्यवान जीवन कौशल प्रदान करना है।
 </p>


</section>
 

</div>
 
 
);
}

