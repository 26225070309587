import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Dropdown from 'react-bootstrap/Dropdown';


 
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>

<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Professionals-Sustainability-Club-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Professionals-Sustainability-Club-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Zha-Professionals-Sustainability-Club-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Zha-Professionals-Sustainability-Club-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Zha-Professionals-Sustainability-Club-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

{/* <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown> */}

{/* 
             <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Zha Professionals Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/professionals.webp"></img>
        </div>
<div className="goalpointsp">

<p>The ZHA Professionals Sustainability Club Membership Program is a comprehensive initiative aligned with the ZHA Foundation's four pillars of sustainability, creating a platform for professionals to engage deeply in the multifaceted aspects of sustainable practices.

</p>


<ol className="goalpoints">
    <li><span>Human Wellness Pillar : </span> </li>
    <ul>
        <li>The program prioritizes human wellness by offering resources and activities focused on work-life balance, mental health, and the integration of sustainable practices into professional lifestyles. Workshops may address stress management, fostering a positive work environment, and promoting employee well-being.
 </li>
    </ul>

    <li><span>Earth Wellness Pillar : </span> </li>
    <ul>
        <li>Professionals in the club actively participate in initiatives related to sustainable business practices. This involves learning about and implementing eco-friendly technologies, energy-efficient solutions, and waste reduction strategies within the professional sphere.

 </li>
    </ul>

    <li><span>Nature Wellness Pillar : </span> </li>
    <ul>
        <li>The program encourages a connection with nature even in professional settings. Activities may include corporate volunteering for environmental causes, supporting biodiversity initiatives, or organizing eco-friendly corporate events that promote a sense of responsibility towards nature.

 </li>
    </ul>

    <li><span>Consistent Profit Pillar : </span> </li>
    <ul>
        <li>Sustainability in terms of consistent profit is addressed through business-oriented workshops and resources. Professionals gain insights into sustainable business models, responsible investment strategies, and approaches to achieving profitability while maintaining ethical and environmental standards.

 </li>
    </ul>
  </ol>
<p>By integrating these four pillars, the ZHA Professionals Sustainability Club Membership Program stands as a beacon for professionals committed to fostering a sustainable and well-balanced future. It not only equips members with the knowledge and tools to integrate sustainability into their professional lives but also encourages a collective effort to drive positive change within their respective industries</p>

<p>Providing free ZHA sustainability memberships to a diverse range of professionals, including businessmen and businesswomen, can yield several benefits:
</p>

<ol className="goalpoints">
    <li><span>Sustainable Business Practices : </span>Professionals gain insights into sustainable business models, eco-friendly technologies, and responsible corporate practices, fostering a commitment to sustainable business operations.
 </li>
 <li><span>Networking Opportunities : </span>Membership provides a platform for professionals to connect with like-minded individuals, creating opportunities for collaboration, partnerships, and the exchange of sustainable business ideas.

</li>
 <li><span>Education and Skill Development : </span>Access to workshops and resources enhances professionals' understanding of sustainability, equipping them with practical skills to integrate eco-friendly practices into their business strategies.
 </li>
 <li><span>Corporate Social Responsibility (CSR) : </span>Membership encourages businesses to actively engage in corporate social responsibility initiatives, contributing to environmental and social causes, and enhancing the company's reputation.
 </li>
 <li><span>Cost Savings : </span>Sustainable practices often lead to cost savings through energy efficiency, waste reduction, and resource optimization, providing economic benefits to businesses in the long run.

</li>
 <li><span>Market Competitiveness : </span>Sustainable businesses are increasingly valued in the market. Membership fosters a competitive edge by positioning professionals and their businesses as socially responsible and environmentally conscious.

</li>
 <li><span>Employee Engagement : </span> Sustainability initiatives can boost employee morale and engagement. Professionals can leverage the membership to implement employee wellness programs and environmentally friendly workplace practices.
 </li>
 <li><span>Regulatory Compliance : </span>Stay updated on sustainability regulations and standards, ensuring businesses comply with evolving environmental requirements and contribute to global sustainability goals. </li>
  </ol>
</div>
        
     

        </div>

    );
}

