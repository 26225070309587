import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
          <div  id="language" >
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club">English</NavLink></a>
        </div>

            <div className="social-ma">

<h1>Role of Zha Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/role.webp"></img>
        </div>
<div className="goalpointsp">

<p>ಜಗತ್ತಿನಾದ್ಯಂತ ಝಾ ಸುಸ್ಥಿರತೆ ಅಭಿವೃದ್ಧಿ ಕ್ಲಬ್‌ನ ಪಾತ್ರಗಳು ಮತ್ತು ಜವಾಬ್ದಾರಿಗಳು ವಿಶಿಷ್ಟವಾಗಿ ಸೇರಿವೆ:
</p>


<ol className="goalpoints">
    <li><span>ಜಾಗೃತಿಯನ್ನು ಉತ್ತೇಜಿಸುವುದು : </span>ಪರಿಸರ ಮತ್ತು ಸಾಮಾಜಿಕ ಸಮಸ್ಯೆಗಳ ಬಗ್ಗೆ ಅರಿವು ಮೂಡಿಸುವುದು, ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳು ಮತ್ತು ನಡವಳಿಕೆಗಳನ್ನು ಪ್ರತಿಪಾದಿಸುವುದು.
</li>

    <li><span>ಶೈಕ್ಷಣಿಕ ಉಪಕ್ರಮಗಳು : </span>ಸುಸ್ಥಿರ ಅಭಿವೃದ್ಧಿ ಗುರಿಗಳು ಮತ್ತು ಅಭ್ಯಾಸಗಳ ಬಗ್ಗೆ ಸಮುದಾಯಕ್ಕೆ ತಿಳಿಸಲು ಶೈಕ್ಷಣಿಕ ಕಾರ್ಯಕ್ರಮಗಳು, ಕಾರ್ಯಾಗಾರಗಳು ಮತ್ತು ಪ್ರಚಾರಗಳನ್ನು ನಡೆಸುವುದು.</li>
    <li><span>ಸಮುದಾಯ ತೊಡಗಿಸಿಕೊಳ್ಳುವಿಕೆ : </span>ಸ್ಥಳೀಯ ಸಮುದಾಯಗಳೊಂದಿಗೆ ಅವರ ಅಗತ್ಯಗಳನ್ನು ಅರ್ಥಮಾಡಿಕೊಳ್ಳಲು ತೊಡಗಿಸಿಕೊಳ್ಳಿ, ಸಮರ್ಥನೀಯ ಯೋಜನೆಗಳಲ್ಲಿ ಅವರನ್ನು ಒಳಗೊಳ್ಳುವುದು ಮತ್ತು ಜವಾಬ್ದಾರಿಯ ಪ್ರಜ್ಞೆಯನ್ನು ಬೆಳೆಸುವುದು.
</li>


    <li><span>ಯೋಜನೆಯ ಅನುಷ್ಠಾನ : </span>ಪರಿಸರಕ್ಕೆ ಧನಾತ್ಮಕ ಕೊಡುಗೆ ನೀಡಲು ತ್ಯಾಜ್ಯ ಕಡಿತ, ಶಕ್ತಿ ದಕ್ಷತೆ ಅಥವಾ ಸಂರಕ್ಷಣೆ ಉಪಕ್ರಮಗಳಂತಹ ಸಮರ್ಥನೀಯ ಯೋಜನೆಗಳನ್ನು ಯೋಜಿಸಿ ಮತ್ತು ಕಾರ್ಯಗತಗೊಳಿಸಿ.

</li>
    <li><span>ವಕಾಲತ್ತು : </span>ಸ್ಥಳೀಯ ಮತ್ತು ರಾಷ್ಟ್ರೀಯ ಮಟ್ಟದಲ್ಲಿ ಸುಸ್ಥಿರತೆಯನ್ನು ಬೆಂಬಲಿಸುವ ನೀತಿಗಳಿಗಾಗಿ ವಕೀಲರು, ಧನಾತ್ಮಕ ಬದಲಾವಣೆಯ ಮೇಲೆ ಪ್ರಭಾವ ಬೀರಲು ಅಧಿಕಾರಿಗಳು ಮತ್ತು ಇತರ ಸಂಸ್ಥೆಗಳೊಂದಿಗೆ ಸಹಕರಿಸುತ್ತಾರೆ.



</li>
    <li><span>ಸಂಶೋಧನೆ ಮತ್ತು ನಾವೀನ್ಯತೆ : </span>ಸುಸ್ಥಿರ ತಂತ್ರಜ್ಞಾನಗಳು ಮತ್ತು ನಾವೀನ್ಯತೆಗಳ ಕುರಿತು ಸಂಶೋಧನೆ ನಡೆಸುವುದು, ಪರಿಸರ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸಲು ಹೊಸ ಮಾರ್ಗಗಳನ್ನು ಅನ್ವೇಷಿಸುವುದು.


</li>
    <li><span>ನೆಟ್‌ವರ್ಕಿಂಗ್ : </span>ಸಂಪನ್ಮೂಲಗಳನ್ನು ಹತೋಟಿಗೆ ತರಲು ಮತ್ತು ಸುಸ್ಥಿರತೆಯ ಉಪಕ್ರಮಗಳ ಪ್ರಭಾವವನ್ನು ಹೆಚ್ಚಿಸಲು ಇತರ ಸಂಸ್ಥೆಗಳು, ವ್ಯವಹಾರಗಳು ಮತ್ತು ಸರ್ಕಾರಿ ಸಂಸ್ಥೆಗಳೊಂದಿಗೆ ಪಾಲುದಾರಿಕೆಯನ್ನು ನಿರ್ಮಿಸಿ.



</li>
    <li><span>ಮಾನಿಟರಿಂಗ್ ಮತ್ತು ಮೌಲ್ಯಮಾಪನ : </span>ಯೋಜನೆಗಳು ಸಮರ್ಥನೀಯ ಗುರಿಗಳೊಂದಿಗೆ ಹೊಂದಾಣಿಕೆಯಾಗುವುದನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಲು ಮತ್ತು ಅಗತ್ಯವಿರುವಂತೆ ಹೊಂದಾಣಿಕೆಗಳನ್ನು ಮಾಡಲು ಯೋಜನೆಗಳ ಪರಿಣಾಮವನ್ನು ನಿಯಮಿತವಾಗಿ ಮೌಲ್ಯಮಾಪನ ಮಾಡಿ ಮತ್ತು ಮೌಲ್ಯಮಾಪನ ಮಾಡಿ.

</li>
    <li><span>ನಿಧಿಸಂಗ್ರಹಣೆ : </span>ಸುಸ್ಥಿರತೆ ಯೋಜನೆಗಳು ಮತ್ತು ನಡೆಯುತ್ತಿರುವ ಕ್ಲಬ್ ಚಟುವಟಿಕೆಗಳನ್ನು ಬೆಂಬಲಿಸಲು ಅನುದಾನಗಳು, ದೇಣಿಗೆಗಳು ಅಥವಾ ಈವೆಂಟ್‌ಗಳ ಮೂಲಕ ಹಣವನ್ನು ಸುರಕ್ಷಿತಗೊಳಿಸಿ.
</li>
    <li><span>ಸಂವಹನ : </span>ಕ್ಲಬ್‌ನಲ್ಲಿ ಮತ್ತು ವಿಶಾಲ ಸಮುದಾಯದೊಂದಿಗೆ ಪಾರದರ್ಶಕ ಸಂವಹನವನ್ನು ನಿರ್ವಹಿಸಿ, ಒಳಗೊಳ್ಳುವಿಕೆಯನ್ನು ಪ್ರೇರೇಪಿಸಲು ಪ್ರಗತಿ, ಸವಾಲುಗಳು ಮತ್ತು ಯಶಸ್ಸಿನ ಕಥೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುವುದು.
</li>
<li><span>ಸಾಮರ್ಥ್ಯ ವೃದ್ಧಿ : </span>ಸದಸ್ಯರಿಗೆ ಮತ್ತು ಸಮುದಾಯಕ್ಕೆ ತರಬೇತಿ ಮತ್ತು ಬೆಂಬಲವನ್ನು ಒದಗಿಸಿ, ಅವರ ಕೌಶಲ್ಯ ಮತ್ತು ಸಮರ್ಥನೀಯ ಅಭ್ಯಾಸಗಳ ತಿಳುವಳಿಕೆಯನ್ನು ಹೆಚ್ಚಿಸುವುದು.
</li>
<li><span>ಒಳಗೊಳ್ಳುವಿಕೆ : </span>ಎಲ್ಲಾ ಚಟುವಟಿಕೆಗಳಲ್ಲಿ ಒಳಗೊಳ್ಳುವಿಕೆ ಮತ್ತು ವೈವಿಧ್ಯತೆಯನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ, ಸಮರ್ಥನೀಯತೆಯ ಸವಾಲುಗಳನ್ನು ಎದುರಿಸುವಲ್ಲಿ ವಿವಿಧ ದೃಷ್ಟಿಕೋನಗಳ ಪ್ರಾಮುಖ್ಯತೆಯನ್ನು ಗುರುತಿಸಿ.</li>

</ol>



</div>
        
     

        </div>

    );
}

