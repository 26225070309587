import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./millet.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Millet() {
    return(

        <div>
                  <div  id="language" >
        
               
        <a href=""><NavLink to="/zhafarmersmilletleague">English</NavLink></a>



</div>
            <div className="social-main">

            <h1>Zha Farmers Millet League</h1>
            </div>
           <section className="intro">
            <div className="heroim">
            <img src="../img/farme.jpg"></img>
            </div>
            <div className="titletext">
              <p>ಝಾ ಫೌಂಡೇಶನ್ ಪೆರವೂರಣಿ ತಾಲೂಕಿನ ರೈತರ ನಡುವೆ ರೈತರ ರಾಗಿ ಸಂಘವನ್ನು ನಡೆಸಲು ಮುಂದಾಗಿರುವುದು ಶ್ಲಾಘನೀಯ ಪ್ರಯತ್ನವಾಗಿದೆ. ರಾಗಿಗಳು ಹೆಚ್ಚು ಪೌಷ್ಟಿಕ ಮತ್ತು ಬರ-ನಿರೋಧಕ ಬೆಳೆಗಳಾಗಿವೆ, ಇದು ಶತಮಾನಗಳಿಂದ ಭಾರತೀಯ ಕೃಷಿಯ ಅವಿಭಾಜ್ಯ ಅಂಗವಾಗಿದೆ. ಆದಾಗ್ಯೂ, ಇತ್ತೀಚಿನ ವರ್ಷಗಳಲ್ಲಿ, ಅವರ ಕೃಷಿಯು ಕ್ಷೀಣಿಸಿದೆ, ಇದು ಸಾಂಪ್ರದಾಯಿಕ ಜ್ಞಾನ ಮತ್ತು ಅವರು ನೀಡುವ ಪೌಷ್ಟಿಕಾಂಶದ ಪ್ರಯೋಜನಗಳ ನಷ್ಟಕ್ಕೆ ಕಾರಣವಾಗುತ್ತದೆ. ರೈತ ಮಿಲ್ಲೆಟ್ ಲೀಗ್ ಪ್ರಪಂಚದಾದ್ಯಂತದ ರೈತರು, ಉತ್ಸಾಹಿಗಳು ಮತ್ತು ನವೋದ್ಯಮಗಳನ್ನು ಒಟ್ಟುಗೂಡಿಸಿ ಕೃಷಿ ಪ್ರಪಂಚವನ್ನು ಕ್ರಾಂತಿಗೊಳಿಸಲು ಸಿದ್ಧವಾಗಿದೆ. .ಐಪಿಎಲ್ ಕ್ರಿಕೆಟ್‌ನ ಮುಖವನ್ನು ಬದಲಿಸಿದಂತೆಯೇ, ಝಾ ಫೌಂಡೇಶನ್‌ನ ರೈತ ಮಿಲೆಟ್ ಲೀಗ್, ರೋಮಾಂಚಕಾರಿ ಪಂದ್ಯಾವಳಿಗಳು ಮತ್ತು ಅತ್ಯಾಧುನಿಕ ತಂತ್ರಗಳೊಂದಿಗೆ ರಾಗಿ ಕೃಷಿಯನ್ನು ಸಂಪೂರ್ಣ ಹೊಸ ಮಟ್ಟಕ್ಕೆ ಏರಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಿದೆ.</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/L59Gs4kPz14YRQZn6"> <Button variant="outline-success">Register Now</Button>{' '}</a>

    </div>

    <div>
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScZeSA0M303pXW7SzmUA_BOCbWv3TXX_vDiZPR-xUudKvyImw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</div>
  </section>
<div className="freepik">
<h5 className="cardtitle">ರೈತರ ರಾಗಿ ಲೀಗ್ ಉಪಕ್ರಮಕ್ಕೆ ಸಂಬಂಧಿಸಿದ ಪ್ರಮುಖ ಅಂಶಗಳ ಅವಲೋಕನ ಇಲ್ಲಿದೆ:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ನಮ್ಮ ಮಿಷನ್</Card.Title>
        <Card.Text>
        ನಮ್ಮ ಉಪಕ್ರಮದ ಹೃದಯಭಾಗವು ಭಾರತದಾದ್ಯಂತ ಪೆರವೂರಾಣಿ ತಾಲ್ಲೂಕು ಮತ್ತು ಅದರಾಚೆಗಿನ ರೈತರಲ್ಲಿ ರಾಗಿ ಕೃಷಿಯನ್ನು ಉತ್ತೇಜಿಸುವ ಮತ್ತು ಪುನರುಜ್ಜೀವನಗೊಳಿಸುವ ಗುರಿಯಾಗಿದೆ. ರಾಗಿಗಳನ್ನು ಸಾಮಾನ್ಯವಾಗಿ "ಪೌಷ್ಠಿಕಾಂಶದ ಧಾನ್ಯಗಳು" ಎಂದು ಕರೆಯಲಾಗುತ್ತದೆ, ಅವುಗಳ ಅಸಾಧಾರಣ ಪೌಷ್ಟಿಕಾಂಶದ ಮೌಲ್ಯ ಮತ್ತು ಪರಿಸರ ಸ್ನೇಹಿ ಕೃಷಿ ಪದ್ಧತಿಗಳಿಗೆ ಹೆಸರುವಾಸಿಯಾಗಿದೆ, ಅವುಗಳನ್ನು ಸುಸ್ಥಿರ ಕೃಷಿಯ ಮೂಲಾಧಾರವನ್ನಾಗಿ ಮಾಡುತ್ತದೆ.
        </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಝಾ ಫೌಂಡೇಶನ್‌ನ ಬದ್ಧತೆ</Card.Title>
        <Card.Text>
        ನಮ್ಮ ಸಮರ್ಪಿತ ರೈತರಲ್ಲಿ ರಾಗಿ ಬೇಸಾಯವನ್ನು ಕಿಕ್‌ಸ್ಟಾರ್ಟಿಂಗ್ ಮತ್ತು ಬೆಂಬಲಿಸುವಲ್ಲಿ ನಾವು ಪ್ರಮುಖ ಪಾತ್ರವನ್ನು ವಹಿಸುತ್ತೇವೆ. ನಮ್ಮ ಬೆಂಬಲವು ಅಗತ್ಯ ಸಂಪನ್ಮೂಲಗಳು, ಸಮಗ್ರ ತರಬೇತಿ ಮತ್ತು ತಾಂತ್ರಿಕ ಸಹಾಯವನ್ನು ಒದಗಿಸುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ, ರೈತರಿಗೆ ತಮ್ಮ ಕೃಷಿ ಪದ್ಧತಿಗಳಲ್ಲಿ ರಾಗಿ ಕೃಷಿಯನ್ನು ಮನಬಂದಂತೆ ಸಂಯೋಜಿಸಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಸಾವಯವ ರಾಗಿ ಕೃಷಿಯನ್ನು ಮರಳಿ ತನ್ನಿ</Card.Title>
        <Card.Text>
        ನಮ್ಮ ಉಪಕ್ರಮವು ಭಾರತೀಯ ರಾಜ್ಯ ಸರ್ಕಾರದ ಜೊತೆಗೆ ಸಾವಯವ ರಾಗಿ ಕೃಷಿಯನ್ನು ಪ್ರೋತ್ಸಾಹಿಸುವುದು ಮತ್ತು ಕೃಷಿಯಲ್ಲಿ ಅರೆಕಾಲಿಕ ಕೆಲಸಗಳಿಗೆ ಯುವಕರನ್ನು ಒಗ್ಗೂಡಿಸುವುದು. ಝಾ ರೈತರು ಮತ್ತು ಗ್ರಾಹಕರ ಕಾಲ್ ಸೆಂಟರ್ ಮೂಲಕ ಜಾಗತಿಕ ಮಾರುಕಟ್ಟೆಯಲ್ಲಿ ನಮ್ಮ ರೈತರ ಉತ್ಪನ್ನಗಳನ್ನು ತಯಾರಿಸಲು ಡಿಜಿಟಲ್ ತಂತ್ರಗಳ ಮೂಲಕ ಪೂರೈಕೆ ಸರಪಳಿಯನ್ನು ರಚಿಸಿ. ಪ್ರಯೋಜನಗಳು ಬಹುಮಟ್ಟಿಗೆ: ಆಹಾರ ಭದ್ರತೆಯನ್ನು ಸುಧಾರಿಸುವುದು, ಮಣ್ಣಿನ ಆರೋಗ್ಯವನ್ನು ಹೆಚ್ಚಿಸುವುದು ಮತ್ತು ನೀರಿನ-ತೀವ್ರ ಸಾವಯವ ಬೆಳೆಗಳಿಗೆ ಹೋಲಿಸಿದರೆ ನೀರಿನ ಬಳಕೆಯನ್ನು ಗಣನೀಯವಾಗಿ ಕಡಿಮೆ ಮಾಡುವುದು ಮತ್ತು ZHA ಫೌಂಡೇಶನ್ ಮೂಲಕ ಸಾವಯವ ಉತ್ಪನ್ನಗಳನ್ನು ಪ್ರಮಾಣೀಕರಿಸುವುದು
        </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ರೈತರ ರಾಗಿ ಲೀಗ್</Card.Title>
        <Card.Text>
        ನಾವು ನವೀನ ಪರಿಕಲ್ಪನೆಯನ್ನು ಪರಿಚಯಿಸುತ್ತಿದ್ದೇವೆ - "ಫಾರ್ಮರ್ಸ್ ಮಿಲೆಟ್ ಲೀಗ್." ಅರ್ಜಿಗಳ ಆಧಾರದ ಮೇಲೆ ರೈತರನ್ನು ವಿವಿಧ ತಂಡಗಳಾಗಿ ಸಂಘಟಿಸುವ ಮೂಲಕ ಮತ್ತು ಝಾ ತಂಡದ ಮೂಲಕ ಸಂಘಟಿಸಿ ಮತ್ತು ಸರ್ಕಾರಿ ಕೃಷಿ ತಜ್ಞರಿಂದ ನಿರ್ಣಯಿಸುವುದು. ಆದ್ದರಿಂದ ನಾವು ಆರೋಗ್ಯಕರ ಸ್ಪರ್ಧೆಯನ್ನು ಉತ್ತೇಜಿಸುವ ಗುರಿಯನ್ನು ಹೊಂದಬಹುದು ಮತ್ತು ನಮ್ಮ ರೈತ ಸಮುದಾಯದ ನಡುವೆ ಸಹಕಾರವನ್ನು ಬೆಳೆಸಬಹುದು. ಈ ಲೀಗ್‌ಗಳು ಸೌಹಾರ್ದತೆಯನ್ನು ಉತ್ತೇಜಿಸುವುದು ಮಾತ್ರವಲ್ಲದೆ ಭಾಗವಹಿಸುವವರಲ್ಲಿ ಏಕತೆಯ ಭಾವವನ್ನು ಹುಟ್ಟುಹಾಕುತ್ತದೆ. ನಾವು ಮೊದಲ ಮೂರು ವಿಜೇತರಿಗೆ ನಗದು ಬೆಲೆಗಳನ್ನು ಮತ್ತು ಎಲ್ಲಾ ಭಾಗವಹಿಸುವವರಿಗೆ ಪ್ರಮಾಣಪತ್ರಗಳನ್ನು ಒದಗಿಸುತ್ತೇವೆ.
        </Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಶಿಕ್ಷಣ ಮತ್ತು ಜಾಗೃತಿ</Card.Title>
        <Card.Text>
        ಲೀಗ್‌ನ ಹೊರತಾಗಿ, ಶಿಕ್ಷಣವು ಅತ್ಯುನ್ನತವಾಗಿದೆ. ರಾಗಿ ಕೃಷಿಯ ಪ್ರಯೋಜನಗಳು, ಸುಸ್ಥಿರ ಕೃಷಿ ಪದ್ಧತಿಗಳು ಮತ್ತು ರಾಗಿ-ಆಧಾರಿತ ಆಹಾರದ ಪೌಷ್ಟಿಕಾಂಶದ ಶ್ರೇಷ್ಠತೆಯ ಬಗ್ಗೆ ಜಾಗೃತಿಯನ್ನು ಹರಡಲು ನಾವು ನಂಬುತ್ತೇವೆ. ಈ ಉಪಕ್ರಮದಲ್ಲಿ ಹೆಚ್ಚು ರೈತರು ಸಕ್ರಿಯವಾಗಿ ಭಾಗವಹಿಸುವಂತೆ ಪ್ರೇರೇಪಿಸುವುದು ನಮ್ಮ ಉದ್ದೇಶವಾಗಿದೆ.
 </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಮಾರುಕಟ್ಟೆ ಪ್ರವೇಶ</Card.Title>
        <Card.Text>
        ನಮ್ಮ ರೈತರಿಗೆ ನ್ಯಾಯಯುತ ಮಾರುಕಟ್ಟೆ ಪ್ರವೇಶವನ್ನು ಖಚಿತಪಡಿಸಿಕೊಳ್ಳುವುದು ನಮ್ಮ ಯಶಸ್ಸಿನ ಪ್ರಮುಖ ಅಂಶವಾಗಿದೆ. ನಮ್ಮ ರೈತರು ತಮ್ಮ ರಾಗಿ ಉತ್ಪನ್ನಗಳನ್ನು ಸಮಾನ ಬೆಲೆಗೆ ಮಾರಾಟ ಮಾಡಬಹುದೆಂದು ಖಾತ್ರಿಪಡಿಸುವ ಮೂಲಕ ದೃಢವಾದ ಮಾರುಕಟ್ಟೆ ಸಂಪರ್ಕವನ್ನು ರಚಿಸಲು ನಾವು ಬದ್ಧರಾಗಿದ್ದೇವೆ.

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ಸಮರ್ಥನೀಯತೆ</Card.Title>
        <Card.Text>
        ರಾಗಿ ಕೃಷಿಗಾಗಿ ನಮ್ಮ ದೀರ್ಘಾವಧಿಯ ದೃಷ್ಟಿಯು ಸಾಂಪ್ರದಾಯಿಕ ರಾಗಿ ತಳಿಗಳನ್ನು ಸಂರಕ್ಷಿಸುವುದು, ಬೀಜ ಉಳಿತಾಯವನ್ನು ಉತ್ತೇಜಿಸುವುದು ಮತ್ತು ನೈಸರ್ಗಿಕ ಕೃಷಿ ಪದ್ಧತಿಗಳನ್ನು ಉತ್ತೇಜಿಸುವುದನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. ಸುಸ್ಥಿರತೆ ಕೇವಲ ಗುರಿಯಲ್ಲ; ಇದು ಜೀವನ ವಿಧಾನವಾಗಿದೆ.
 </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ನೀತಿ ಬೆಂಬಲ</Card.Title>
        <Card.Text>
        ನಾವು ಬದಲಾವಣೆಯ ಪ್ರತಿಪಾದಕರು. ಸ್ಥಳೀಯ ಮತ್ತು ರಾಷ್ಟ್ರೀಯ ಮಟ್ಟದಲ್ಲಿ ಬೆಂಬಲ ನೀತಿಗಳನ್ನು ಒತ್ತಾಯಿಸಲು ನಾವು ದಣಿವರಿಯಿಲ್ಲದೆ ಕೆಲಸ ಮಾಡುತ್ತೇವೆ. ಇದು ರಾಗಿ ಕೃಷಿ ಮತ್ತು ಬಳಕೆಗೆ ಅನುಕೂಲವಾಗುವ ಪ್ರೋತ್ಸಾಹಗಳು, ಸಬ್ಸಿಡಿಗಳು ಮತ್ತು ನೀತಿ ಹೊಂದಾಣಿಕೆಗಳನ್ನು ಒಳಗೊಂಡಿರುತ್ತದೆ. </Card.Text>
      </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>ರೈತರ ರಾಗಿ <span>ಲೀಗ್‌ನ ಪ್ರಮುಖ ಮಾಲೀಕತ್ವ:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;