// ProfileDropdown.js
import React from 'react';
import { Dropdown, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useAuth from './useAuth'; // Update the path
import { FaBorderAll } from 'react-icons/fa';

const ProfileDropdown = () => {
  const { currentUser, logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      alert('Logged out successfully');
    } catch (error) {
      alert(error.message);
    }
  };
 

 


  return (
    <Dropdown align="end" alignCenter>
     <Dropdown.Toggle id="profile-dropdown"  variant="white" style={{ border: 'none' }}>

     <Image
  src={currentUser?.photoURL || '../img/profile-user.png'}
  alt="User Profile"
  className="rounded-circle"
  onError={(e) => {
    console.error('Error loading image:', e.target.src);
    e.target.src = '../img/profile-user.png'; // Set a fallback image on error
  }}
/>

      </Dropdown.Toggle>

      <Dropdown.Menu>
        {currentUser ? (
          <>
            <Dropdown.Item disabled>Welcome,<br /> {currentUser.email}</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
          </>
        ) : (
          <>
            <Dropdown.Item as={Link} to="/login">
              Login
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/signup">
              Signup
            </Dropdown.Item>
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
