import React from "react";
import "./Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

export default function Zhasports(){




    

    return(
      
        <div>
            <div>

            <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sports-Training-Club-Tamil">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Sports-Training-Club-Malayalam">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Zha-Sports-Training-Club-Hindi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Zha-Sports-Training-Club-Telugu">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Zha-Sports-Training-Club-Kannada">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

                {/* <div  id="language" >
        
        <a href=""><NavLink to="/Zha-Sports-Training-Club-Tamil">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sports-Training-Club-Malayalam">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sports-Training-Club-Hindi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sports-Training-Club-Telugu">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sports-Training-Club-Kannada">Kannada</NavLink></a>


        </div> */}
        </div>
            <div className="social-ma">

<h1>Zha Sports Training Club</h1>
</div>


<header>
    <div>
    <h2>Welcome to <br></br>Zha Sports Training Club!</h2>
    {/* <p>Your Gateway to Athletic Excellence</p> */}
    </div>
    <img src="https://img.freepik.com/free-vector/summer-sports-concept_1284-9394.jpg?w=740&t=st=1697262455~exp=1697263055~hmac=01cd661d29b38dc346eff24e8983ff504c93da5e8946bdfe5e397df7e6053b94"></img>
</header>

<section className="medeatils">
<div className="aboutcycle2">
<h2>Details</h2>
<p>(Begins on November 14)</p>
</div>
<p></p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th></th>
          <th>Zha Cycle Club</th>
          <th>Zha Athletics Training Club</th>
          <th>Zha Volleyball Trainig Club</th>
          <th>Zha Indoor Training Club <br />(5 to 16 Age only)</th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Monthly Fee</td>
         
          <td colSpan={4}>₹300 per person</td>
         
        </tr>
        <tr>
          <td>Adults Timing (Above 15 Age)</td>
          <td colSpan={4}>6AM to 10AM</td>

       
         
  
        </tr>
        <tr>
          <td>Childrens Timing (5 to 15 Age )</td>
          <td>6AM to 10AM</td>
          <td colSpan={3}>From 5PM</td>
          
        </tr>
        <tr>
          <td>Day</td>
          <td>Weekends Only</td>
          <td colSpan={3}>Weekly Thrice</td>
         
        </tr>
      </tbody>
    </Table>
    </section>



<section className="parellal">
    <div className="parallax-content">
      <div className="towhithe">
<h2>About Us</h2>
<p>At Zha Sports Training Club, our core mission is to promote sports in rural areas, identify hidden talents, and provide them with the platform and resources to excel in the world of sports. We believe that extraordinary talent exists in every corner of our rural communities, waiting to be discovered and developed. Our commitment is to unlock that potential and help our athletes reach the pinnacle of their sporting aspirations.</p>
</div>
</div>
</section>



<section className="cycle2">
    <div className="aboutcycle2">
<h2>Our Vision
</h2> 
<div className="membership">
    <img src="https://img.freepik.com/free-vector/businessman-looking-camera-target-it-s-like-business-looking-way-be-successful-vector-business-illustration-concept_1150-60931.jpg?w=1380&t=st=1697283092~exp=1697283692~hmac=a66770dae78b88e7c2289fde1c533f765eb3ca49a6067b53fec080eece82b27d"></img>
<p>At Zha Sports Training Club, our vision is to empower and transform the lives of our community's youth. We aspire to create a future where our youngsters find purpose, vitality, and well-being through sports, free from harmful addictions and stress. We believe that through our dedication to athletic excellence and personal growth, we can cultivate a generation of young individuals who are not only physically fit but also mentally strong, resilient, and focused. Our vision is to be the catalyst for positive change, fostering a world where the youth turn to sports as a source of inspiration, camaraderie, and empowerment, ultimately leading to healthier, happier, and more vibrant lives

</p>
</div>
</div>
</section>


<section className="coretheme">
<h3>Our Core Theme:<span> Unleashing Rural Talent</span></h3>
<ul>
<li><span>Empowering Youth through Sports: </span>At Zha Sports Training Club, we are dedicated to empowering youth through sports, guiding them toward an addiction-free and resilient future.</li>
<li><span>Identify:</span> Our scouts travel to remote villages and towns, seeking out individuals with a passion for sports and raw talent.</li>
<li><span>Nurture:</span> We provide extensive training programs and facilities to harness this talent, transforming it into polished skill and expertise.</li>
<li><span>Excel:</span> Our ultimate goal is to see these budding talents shine on regional, national, and international platforms.

</li>
</ul>

</section>

<section className="medeatils">
<div className="aboutcycle2">
<h2>Rules of the Zha Sports Training Club
</h2>


 <p>1)  Shouldn’t discuss about caste or religion or political party or status, between club members </p> 

  <p>2) Show values such as respect, commitment, focus, integrity and encourage others</p>




</div></section>
<section className="Indoor">
    <h3>Indoor Games</h3>

<div className="inindoor">

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/carroms-stricker-carroms-board_1353-94.jpg?w=1060&t=st=1697266133~exp=1697266733~hmac=193a552938f8bc9d95f5fb9fe521c000cdced03b1a4121a04e418230d430d6ce" />
      <Card.Body>
        <Card.Title>Carrom</Card.Title>
        <Card.Text>
        An indoor classic that's not just a game but a test of precision and strategy. Join our carrom enthusiasts and hone your skills.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/gold-silver-chess-chess-board-game-business-metaphor-leadership-concept_1150-19592.jpg?w=1060&t=st=1697265918~exp=1697266518~hmac=f4734cc77213b39552dc86a5cebd2a405cbd08682462777d3ffbc181779df389" />
      <Card.Body>
        <Card.Title>Chess</Card.Title>
        <Card.Text>
        A game of intellect and foresight, where rural chess prodigies have the potential to make their mark on the global stage.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-people-man-woman-playing-table-tennis_93675-134489.jpg?w=1060&t=st=1697265228~exp=1697265828~hmac=54551670c7b793374f5acc44d9f553fb727750e00de361d6d2ad2b5280d4657a" />
      <Card.Body>
        <Card.Title>Table Tennis</Card.Title>
        <Card.Text>
        Fast-paced and exciting, table tennis is a sport where rural athletes can truly excel.
        </Card.Text>
     
      </Card.Body>
    </Card>
    
    </div>
  
</section>



<section>
    <h3>Outdoor Games</h3>

<div className="inoutdoor">
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-volleyball-man-player-court_23-2149492372.jpg?w=1060&t=st=1697265453~exp=1697266053~hmac=38b67aa1f0c569204e324ccd0a6025c358209ab74d0f8ac3f16a7b044a389ff7" />
      <Card.Body>
        <Card.Title>Volleyball</Card.Title>
        <Card.Text> A community favorite, we host regular volleyball tournaments to encourage participation and talent development.
     
        </Card.Text>

      </Card.Body>
    </Card>

    {/* <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Marathon</Card.Title>
        <Card.Text>
        Take part in our marathons to test your endurance and discover the long-distance running stars of tomorrow.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
      </Card.Body>
    </Card> */}

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/different-people-participating-cross-country_23-2149157134.jpg?w=1060&t=st=1697265375~exp=1697265975~hmac=cc39d5c0cc918e43474f8a249139536b9172850571f8f2aa5b897b9215fca427" />
      <Card.Body>
        <Card.Title>Athletics</Card.Title>
        <Card.Text>
        We provide a platform for young athletes to train and compete in various track and field events.
        </Card.Text>
     
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/professional-cyclist-women_23-2149703331.jpg?w=1060&t=st=1697265287~exp=1697265887~hmac=782e175093e473ddfc0e2abd894b42673b3d40d0e72972fe4289c6e5bc083fa9" />
      <Card.Body>
        <Card.Title>Cycling</Card.Title>
        <Card.Text>
        From the countryside to the podium, we're here to support budding cyclists in their journey to greatness.
        </Card.Text>

      </Card.Body>
    </Card>
    </div>
  
</section>





<section className="whychoose">
<h3>Why Choose <span>Zha Sports Training Club?</span></h3>
<ul>

<li><span>Rural Focus: </span>Our primary goal is to identify and nurture rural talents, ensuring no talent goes unnoticed.</li>
<li><span>Expert Coaching: </span>Our coaches are passionate and dedicated to helping you reach your full potential.</li>
<li><span>Community Building: </span>Join a community that values sportsmanship, discipline, and teamwork.</li>
<li><span>Holistic Development: </span> We don't just focus on physical skills; we emphasize character building and education too.</li>
<li><span>Competitions: </span>Regular competitions and events give our athletes the chance to shine and gain recognition.</li>
</ul>

</section>


<section className="cycle2">
    <div className="aboutcycle2">
<h2>Membership
</h2>
<div className="membership">
    <img src="../img/joinus.webp"></img>
<p>The monthly fee for using this club is ₹300, and members can access it for three days each week.Become a member of Zha Indoor Club and gain access to a world of indoor sports, fitness, and recreation. We offer flexible membership options to suit your individual needs, whether you're a frequent visitor or just looking to try out our facilities.

</p>
</div>
</div>
</section>



</div>



);
}