import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import './Feedback.css';

const Feedback = (props) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}


            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={[]}
            deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
        >
         
                <div className='multicard'>
                <Card className='multicardshadow' style={{ width: '18rem',height: '20rem'  }}>

                    <iframe width="auto" src="https://www.youtube.com/embed/TGmTpq2Jz0Q?si=0cplD31EFiLgtEgz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <Card.Body>
                        <Card.Title>Thiru.GaneshRaja</Card.Title>
                        <Card.Text>
                            Participants of Zha Organic Farmers League
                        </Card.Text>

                    </Card.Body>
                </Card>

            </div>

            <div className='multicard'>
                <Card className='multicardshadow' style={{ width: '18rem',height: '20rem'  }}>

                    <iframe src="https://www.youtube.com/embed/Vr0kWmHzLDw?si=mAX1Nfeav7DOuFWj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <Card.Body>
                        <Card.Title>Thiru.Suresh</Card.Title>
                        <Card.Text>
                            Participants of Zha Organic Farmers League
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>
            <div className='multicard'>
                <Card className='multicardshadow' style={{ width: '18rem',height: '20rem'  }}>

                    <iframe src="https://www.youtube.com/embed/F_KFDHS791k?si=75DrMmKOXmnftN3Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <Card.Body>
                        <Card.Title>Dr.Neelakandan</Card.Title>
                        <Card.Text>
                            Dharshana Hospital,Peravurani
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>
            <div className='multicard'>
                <Card className='multicardshadow' style={{ width: '18rem',height: '20rem'  }}>

                    <iframe src="https://www.youtube.com/embed/DkneeSdrK2M?si=iPop0N1ldzMyMXNz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <Card.Body>
                        <Card.Title>Mr.Muneesh Raja
                        </Card.Title>
                        <Card.Text>
                            Actor & Zha Media Secretary
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>
            <div className='multicard'>
                <Card className='multicardshadow' style={{ width: '18rem',height: '20rem'  }}>

                    <iframe src="https://www.youtube.com/embed/HEyezlk7Kqk?si=NGHQRd7I1agI_syi" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                    <Card.Body>
                        <Card.Title> Honourable Minister Thiru.Siva.V.Meyyanathan
                        </Card.Title>
                        <Card.Text>
                       
 Department of Environment and Climate Change 
                        </Card.Text>

                    </Card.Body>
                </Card>
            </div>
             
        </Carousel>
    );
};

export default Feedback;


