import Carousel from 'react-bootstrap/Carousel';

import './Carousalcopy.css';

import './Carousal.css';

import './Mycaro.css';
import React from 'react';





function IndividualIntervalsExample() {
  return (
    <div className='carousellll'>
    <Carousel>
    
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/0.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>cleaned up the roadways and cut trees etc when gaja cyclone<br/></h3>
         <p>November 23,2018</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/1.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation provided rice bags to needy 50 very poor 
families </h3>

<p>May 23,2020</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/2.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation provided rice bags to needy 50 very poor 
families </h3>
<p>May 23,2020</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/3.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation provided rice bags to needy 50 very poor 
families </h3>
<p>May 23,2020</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/4.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation provided rice bags to needy 50 very poor 
families </h3>
<p>May 23,2020</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/5.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation provided rice bags to needy 50 very poor 
families </h3>
<p>May 23,2020</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/6.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Clean College Environment Initiative activities by Zha Team Members</h3>
          <p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/7.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Clean College Environment Initiative activities by Zha Team Members</h3>
          <p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/8.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Clean College Environment Initiative activities by Zha Team Members</h3>
          <p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/9.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
            <h3>Clean College Environment Initiative activities by Zha Team Members</h3>
          <p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/10.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
            <h3>Clean College Environment Initiative activities by Zha Team Members</h3>
          <p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/11.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
            <h3>Clean College Environment Initiative activities by Zha Team Members</h3>
          <p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/12.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
            <h3>Clean College Environment Initiative activities by Zha Team Members</h3>
          <p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/13.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>ZHA “Clean Environment 
Initiative” has sponsored the 
entire 
Peravurani
Government College facility 
for cleaning up works </h3>
<p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/14.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>ZHA “Clean Environment 
Initiative” has sponsored the 
entire 
Peravurani
Government College facility 
for cleaning up works </h3>
<p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/15.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>ZHA “Clean Environment 
Initiative” has sponsored the 
entire 
Peravurani
Government College facility 
for cleaning up works </h3>
<p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/16.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>ZHA “Clean Environment 
Initiative” has sponsored the 
entire 
Peravurani
Government College facility 
for cleaning up works </h3>
<p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/17.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>ZHA “Clean Environment 
Initiative” has sponsored the 
entire 
Peravurani
Government College facility 
for cleaning up works </h3>
<p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/18.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>ZHA “Clean Environment 
Initiative” has sponsored the 
entire 
Peravurani
Government College facility 
for cleaning up works </h3>
<p>February 17,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/19.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha Donates Food to Anbil Naam Trust</h3>
          <p>August 21,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/20.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha Donates Food to Anbil Naam Trust</h3>
          <p>August 21,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/21.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha Donates Food to Anbil Naam Trust</h3> 
            <p>August 21,2023</p>

          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
    
        <img src='../../img/NEW EVENTS/22.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Sponsor the bars to our govt boys hr sec school</h3>
        <p> June 21,2023</p> 
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
    
        <img src='../../img/NEW EVENTS/23.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha organizes a walkathon in association with the alumni of St. Arogya Annai High School and the Lions Club</h3>
          <p>October 1,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/24.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
            <h3>Zha organizes a walkathon in association with the alumni of St. Arogya Annai High School and the Lions Club</h3>
          <p>October 1,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
    
        <img src='../../img/NEW EVENTS/25.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
            <h3>Zha organizes a walkathon in association with the alumni of St. Arogya Annai High School and the Lions Club</h3>
          <p>October 1,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/26.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>In collaboration with Thanjavur Agriculture Department, the Foundation imparted organic farming training to the farmers</h3>
         <p>September 30,2023</p> 
         </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/27.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>In collaboration with Thanjavur Agriculture Department, the Foundation imparted organic farming training to the farmers</h3>
          <p>September 30,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      

      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/29.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
            <h3>In collaboration with Thanjavur Agriculture Department, the Foundation imparted organic farming training to the farmers</h3>
          <p>September 30,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
    
        <img src='../../img/NEW EVENTS/30.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3></h3>
          <p>September 12,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/31.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3></h3>
          <p>September 12,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/32.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3></h3>
          <p>October 10,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/33.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3></h3>
          <p>October 10,2023</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
   
        <img src='../../img/NEW EVENTS/34.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation Charitable Trust with Oudhuppattinam Beach Cleaning  by Zha Team</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
    
        <img src='../../img/NEW EVENTS/35.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation Charitable Trust with Oudhuppattinam Beach Cleaning  by Zha Team</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/36.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha foundation Charitable Trust with Oudhuppattinam Beach Cleaning  by Zha Team</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
    
        <img src='../../img/NEW EVENTS/37.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha celebrated the international yoga day with children’s</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
   
        <img src='../../img/NEW EVENTS/38.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha celebrated the international yoga day with children’s</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/39.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha celebrated the international yoga day with children’s</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
      
        <img src='../../img/NEW EVENTS/40.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha provided sports shoes for the
needy best sports students of Peravurani

</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
     
        <img src='../../img/NEW EVENTS/41.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha provided sports shoes for the
needy best sports students of Peravurani

</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
    
        <img src='../../img/NEW EVENTS/42.webp' text="Second slide" />
        <Carousel.Caption>
        <div className='description'>
          <h3>Zha provided sports shoes for the
needy best sports students of Peravurani

</h3>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
  
     
     

    
     z
    </Carousel>
  </div>
  );
}


export default IndividualIntervalsExample;