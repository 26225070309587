import React from "react";
import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
export default function Transformation(){

    return(
        <div>
            <div className="social-ma">

<h1>Zha Free Internship Program</h1>
</div>

<div className="internship">
<img src="../img/internship.webp"></img>
        </div> 



<div className="goalpointsp">

<p>The Zha Internship Program for Rural Students offers numerous benefits, including:

</p>


<ol className="goalpoints">
    <li><span>Free Learning Opportunities : </span>Participants have access to valuable educational resources and hands-on experiences without any cost.
</li> 
    <li><span>Skill Development : </span>The program focuses on enhancing essential skills, providing a platform for personal and professional growth.
</li> 
    <li><span>Real-world Experience : </span>Rural students gain practical, real-world experience, preparing them for future career opportunities.
</li> 
    <li><span>Networking : </span>Participants have the chance to connect with professionals and peers, expanding their professional network.
</li> 
    <li><span>Mentorship : </span>The program may include mentorship opportunities, offering guidance and support from experienced professionals.
</li> 
    <li><span>Enhanced Resume : </span>Completing the internship program adds a valuable entry to the participants' resumes, boosting their competitiveness in the job market.
</li> 
    <li><span>Community Impact : </span>The program contributes to the development of rural communities by empowering local talent and fostering economic growth.
</li> 

 
</ol>

<p>Overall, the Zha Internship Program aims to bridge the gap between education and employment for rural students, providing them with resources and experiences to thrive in their future endeavors.</p>

</div>
           
        {/* <div className="internship">
        <img src="../img/internship.png"></img>
        </div> */}

     

        </div>

    );
}

