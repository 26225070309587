import React from "react";
import "../Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Table from 'react-bootstrap/Table';
import { NavLink } from "react-router-dom";
export default function Zhasports(){




    

    return(
      
        <div>
            <div>
                <div  id="language" >
                <a href=""><NavLink to="/Zha-Sports-Training-Club">English</NavLink></a>
       
        </div>
        </div>
            <div className="social-ma">

<h1>ಝಾ ಸ್ಪೋರ್ಟ್ಸ್ ಟ್ರೈನಿಂಗ್ ಕ್ಲಬ್</h1>
</div>


<header>
    <div>
    <h2>ಸುಸ್ವಾಗತ <br></br>ಝಾ ಸ್ಪೋರ್ಟ್ಸ್ ಟ್ರೈನಿಂಗ್ ಕ್ಲಬ್!</h2>
    {/* <p>Your Gateway to Athletic Excellence</p> */}
    </div>
    <img src="https://img.freepik.com/free-vector/summer-sports-concept_1284-9394.jpg?w=740&t=st=1697262455~exp=1697263055~hmac=01cd661d29b38dc346eff24e8983ff504c93da5e8946bdfe5e397df7e6053b94"></img>
</header>

<section className="medeatils">
<div className="aboutcycle2">
<h2>ವಿವರಗಳು</h2>
<p>(ನವೆಂಬರ್ 14 ರಂದು ಪ್ರಾರಂಭವಾಗುತ್ತದೆ)</p>
</div>
<p></p>

<Table striped bordered hover>
      <thead>
        <tr>
          <th></th>
          <th>ಝಾ ಸೈಕಲ್ ಕ್ಲಬ್</th>
          <th>ಝಾ ಅಥ್ಲೆಟಿಕ್ಸ್ ತರಬೇತಿ ಕ್ಲಬ್</th>
          <th>ಝಾ ವಾಲಿಬಾಲ್ ತರಬೇತಿ ಕ್ಲಬ್</th>
          <th>ಝಾ ಒಳಾಂಗಣ ತರಬೇತಿ ಕ್ಲಬ್ <br />(5 ರಿಂದ 16 ವಯಸ್ಸು ಮಾತ್ರ)</th>
         
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>ಪ್ರತಿ ವ್ಯಕ್ತಿಗೆ</td>
         
          <td colSpan={4}>ಮಾಸಿಕ ಶುಲ್ಕ ₹ 300</td>
         
        </tr>
        <tr>
          <td>ವಯಸ್ಕರ ಸಮಯ (15 ವರ್ಷಕ್ಕಿಂತ ಮೇಲ್ಪಟ್ಟವರು)</td>
          <td colSpan={4}>ಬೆಳಿಗ್ಗೆ 6 ರಿಂದ 10 ರವರೆಗೆ</td>

       
         
  
        </tr>
        <tr>
          <td>ಮಕ್ಕಳ ಸಮಯ (5 ರಿಂದ 15 ವರ್ಷ)</td>
          <td>6 ರಿಂದ 10 ರವರೆಗೆ ಸಂಜೆ</td>
          <td colSpan={3}>5 ರವರೆಗೆ</td>
          
        </tr>
        <tr>
          <td>ದಿನ</td>
          <td>ವಾರಾಂತ್ಯದಲ್ಲಿ ಮಾತ್ರ</td>
          <td colSpan={3}>ವಾರಕ್ಕೆ ಮೂರು ಬಾರಿ</td>
         
        </tr>
      </tbody>
    </Table>
    </section>



<section className="parellal">
    <div className="parallax-content">
    <div className="towhithe">
<h2>ನಮ್ಮ ಬಗ್ಗೆ</h2>
<p>ಝಾ ಸ್ಪೋರ್ಟ್ಸ್ ಟ್ರೈನಿಂಗ್ ಕ್ಲಬ್‌ನಲ್ಲಿ, ಗ್ರಾಮೀಣ ಪ್ರದೇಶಗಳಲ್ಲಿ ಕ್ರೀಡೆಯನ್ನು ಉತ್ತೇಜಿಸುವುದು, ಅಡಗಿರುವ ಪ್ರತಿಭೆಗಳನ್ನು ಗುರುತಿಸುವುದು ಮತ್ತು ಅವರಿಗೆ ಕ್ರೀಡಾ ಜಗತ್ತಿನಲ್ಲಿ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡಲು ವೇದಿಕೆ ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳನ್ನು ಒದಗಿಸುವುದು ನಮ್ಮ ಮುಖ್ಯ ಉದ್ದೇಶವಾಗಿದೆ. ನಮ್ಮ ಗ್ರಾಮೀಣ ಸಮುದಾಯಗಳ ಪ್ರತಿಯೊಂದು ಮೂಲೆಯಲ್ಲೂ ಅಸಾಧಾರಣ ಪ್ರತಿಭೆ ಅಸ್ತಿತ್ವದಲ್ಲಿದೆ ಎಂದು ನಾವು ನಂಬುತ್ತೇವೆ, ಅನ್ವೇಷಿಸಲು ಮತ್ತು ಅಭಿವೃದ್ಧಿಪಡಿಸಲು ಕಾಯುತ್ತಿದ್ದೇವೆ. ಆ ಸಾಮರ್ಥ್ಯವನ್ನು ಅನ್ಲಾಕ್ ಮಾಡುವುದು ಮತ್ತು ನಮ್ಮ ಕ್ರೀಡಾಪಟುಗಳು ಅವರ ಕ್ರೀಡಾ ಆಕಾಂಕ್ಷೆಗಳ ಉತ್ತುಂಗವನ್ನು ತಲುಪಲು ಸಹಾಯ ಮಾಡುವುದು ನಮ್ಮ ಬದ್ಧತೆಯಾಗಿದೆ.</p>
</div>
</div>
</section>



<section className="cycle2">
    <div className="aboutcycle2">
<h2>ನಮ್ಮ ದೃಷ್ಟಿ
</h2> 
<div className="membership">
    <img src="https://img.freepik.com/free-vector/businessman-looking-camera-target-it-s-like-business-looking-way-be-successful-vector-business-illustration-concept_1150-60931.jpg?w=1380&t=st=1697283092~exp=1697283692~hmac=a66770dae78b88e7c2289fde1c533f765eb3ca49a6067b53fec080eece82b27d"></img>
<p>ಝಾ ಸ್ಪೋರ್ಟ್ಸ್ ಟ್ರೈನಿಂಗ್ ಕ್ಲಬ್‌ನಲ್ಲಿ, ನಮ್ಮ ಸಮುದಾಯದ ಯುವಕರ ಜೀವನವನ್ನು ಸಶಕ್ತಗೊಳಿಸುವುದು ಮತ್ತು ಪರಿವರ್ತಿಸುವುದು ನಮ್ಮ ದೃಷ್ಟಿಯಾಗಿದೆ. ಹಾನಿಕಾರಕ ವ್ಯಸನಗಳು ಮತ್ತು ಒತ್ತಡದಿಂದ ಮುಕ್ತವಾದ ಕ್ರೀಡೆಗಳ ಮೂಲಕ ನಮ್ಮ ಯುವಕರು ಉದ್ದೇಶ, ಚೈತನ್ಯ ಮತ್ತು ಯೋಗಕ್ಷೇಮವನ್ನು ಕಂಡುಕೊಳ್ಳುವ ಭವಿಷ್ಯವನ್ನು ರಚಿಸಲು ನಾವು ಬಯಸುತ್ತೇವೆ. ಅಥ್ಲೆಟಿಕ್ ಉತ್ಕೃಷ್ಟತೆ ಮತ್ತು ವೈಯಕ್ತಿಕ ಬೆಳವಣಿಗೆಗೆ ನಮ್ಮ ಸಮರ್ಪಣೆಯ ಮೂಲಕ, ನಾವು ದೈಹಿಕವಾಗಿ ಸದೃಢರಾಗಿರುವ ಯುವ ವ್ಯಕ್ತಿಗಳ ಪೀಳಿಗೆಯನ್ನು ಬೆಳೆಸಬಹುದು ಎಂದು ನಾವು ನಂಬುತ್ತೇವೆ ಆದರೆ ಮಾನಸಿಕವಾಗಿ ದೃಢವಾದ, ಚೇತರಿಸಿಕೊಳ್ಳುವ ಮತ್ತು ಗಮನಹರಿಸುತ್ತೇವೆ. ನಮ್ಮ ದೃಷ್ಟಿ ಧನಾತ್ಮಕ ಬದಲಾವಣೆಗೆ ವೇಗವರ್ಧಕವಾಗುವುದು, ಯುವಕರು ಸ್ಫೂರ್ತಿ, ಸೌಹಾರ್ದತೆ ಮತ್ತು ಸಬಲೀಕರಣದ ಮೂಲವಾಗಿ ಕ್ರೀಡೆಗಳತ್ತ ಮುಖಮಾಡುವ ಜಗತ್ತನ್ನು ಉತ್ತೇಜಿಸುವುದು, ಅಂತಿಮವಾಗಿ ಆರೋಗ್ಯಕರ, ಸಂತೋಷದಾಯಕ ಮತ್ತು ಹೆಚ್ಚು ರೋಮಾಂಚಕ ಜೀವನಕ್ಕೆ ಕಾರಣವಾಗುತ್ತದೆ.

</p>
</div>
</div>
</section>


<section className="coretheme">
<h3>ನಮ್ಮ ಮುಖ್ಯ ಥೀಮ್ : <span> ಗ್ರಾಮೀಣ ಪ್ರತಿಭೆಯನ್ನು ಅನಾವರಣಗೊಳಿಸುವುದು</span></h3>
<ul>
<li><span>ಕ್ರೀಡೆಯ ಮೂಲಕ ಯುವಕರನ್ನು ಸಬಲೀಕರಣಗೊಳಿಸುವುದು : </span>ಝಾ ಸ್ಪೋರ್ಟ್ಸ್ ಟ್ರೈನಿಂಗ್ ಕ್ಲಬ್‌ನಲ್ಲಿ, ನಾವು ಯುವಕರನ್ನು ಕ್ರೀಡೆಗಳ ಮೂಲಕ ಸಬಲೀಕರಣಗೊಳಿಸಲು, ವ್ಯಸನ-ಮುಕ್ತ ಮತ್ತು ಚೇತರಿಸಿಕೊಳ್ಳುವ ಭವಿಷ್ಯದ ಕಡೆಗೆ ಮಾರ್ಗದರ್ಶನ ನೀಡಲು ಸಮರ್ಪಿತರಾಗಿದ್ದೇವೆ.</li>
<li><span>ಗುರುತಿಸಿ :</span> ನಮ್ಮ ಸ್ಕೌಟ್‌ಗಳು ದೂರದ ಹಳ್ಳಿಗಳು ಮತ್ತು ಪಟ್ಟಣಗಳಿಗೆ ಪ್ರಯಾಣಿಸುತ್ತಾರೆ, ಕ್ರೀಡೆ ಮತ್ತು ಕಚ್ಚಾ ಪ್ರತಿಭೆಗಳ ಬಗ್ಗೆ ಉತ್ಸಾಹ ಹೊಂದಿರುವ ವ್ಯಕ್ತಿಗಳನ್ನು ಹುಡುಕುತ್ತಾರೆ.</li>
<li><span>ಪೋಷಣೆ :</span> ಈ ಪ್ರತಿಭೆಯನ್ನು ಬಳಸಿಕೊಳ್ಳಲು ನಾವು ವ್ಯಾಪಕವಾದ ತರಬೇತಿ ಕಾರ್ಯಕ್ರಮಗಳು ಮತ್ತು ಸೌಲಭ್ಯಗಳನ್ನು ಒದಗಿಸುತ್ತೇವೆ, ಅದನ್ನು ನಯಗೊಳಿಸಿದ ಕೌಶಲ್ಯ ಮತ್ತು ಪರಿಣತಿಯಾಗಿ ಪರಿವರ್ತಿಸುತ್ತೇವೆ.</li>
<li><span>ಎಕ್ಸೆಲ್ :</span> ಈ ಉದಯೋನ್ಮುಖ ಪ್ರತಿಭೆಗಳು ಪ್ರಾದೇಶಿಕ, ರಾಷ್ಟ್ರೀಯ ಮತ್ತು ಅಂತರರಾಷ್ಟ್ರೀಯ ವೇದಿಕೆಗಳಲ್ಲಿ ಮಿಂಚುವುದನ್ನು ನೋಡುವುದು ನಮ್ಮ ಅಂತಿಮ ಗುರಿಯಾಗಿದೆ.

</li>
</ul>

</section>

<section className="medeatils">
<div className="aboutcycle2">
<h2>ಝಾ ಸ್ಪೋರ್ಟ್ಸ್ ಟ್ರೈನಿಂಗ್ ಕ್ಲಬ್ನ ನಿಯಮಗಳು
</h2>


 <p>1)  ಕ್ಲಬ್ ಸದಸ್ಯರ ನಡುವೆ ಜಾತಿ ಅಥವಾ ಧರ್ಮ ಅಥವಾ ರಾಜಕೀಯ ಪಕ್ಷ ಅಥವಾ ಸ್ಥಾನಮಾನದ ಬಗ್ಗೆ ಚರ್ಚಿಸಬಾರದು </p> 

  <p>2) ಗೌರವ, ಬದ್ಧತೆ, ಗಮನ, ಸಮಗ್ರತೆಯಂತಹ ಮೌಲ್ಯಗಳನ್ನು ತೋರಿಸಿ ಮತ್ತು ಇತರರನ್ನು ಪ್ರೋತ್ಸಾಹಿಸಿ</p>




</div></section>
<section className="Indoor">
    <h3>ಒಳಾಂಗಣ ಆಟಗಳು</h3>

<div className="inindoor">

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/carroms-stricker-carroms-board_1353-94.jpg?w=1060&t=st=1697266133~exp=1697266733~hmac=193a552938f8bc9d95f5fb9fe521c000cdced03b1a4121a04e418230d430d6ce" />
      <Card.Body>
        <Card.Title>ಕೇರಂ</Card.Title>
        <Card.Text>
        ಒಳಾಂಗಣ ಕ್ಲಾಸಿಕ್ ಕೇವಲ ಆಟವಲ್ಲ ಆದರೆ ನಿಖರತೆ ಮತ್ತು ತಂತ್ರದ ಪರೀಕ್ಷೆಯಾಗಿದೆ. ನಮ್ಮ ಕೇರಂ ಉತ್ಸಾಹಿಗಳೊಂದಿಗೆ ಸೇರಿ ಮತ್ತು ನಿಮ್ಮ ಕೌಶಲ್ಯಗಳನ್ನು ಅಭಿವೃದ್ಧಿಪಡಿಸಿ.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/gold-silver-chess-chess-board-game-business-metaphor-leadership-concept_1150-19592.jpg?w=1060&t=st=1697265918~exp=1697266518~hmac=f4734cc77213b39552dc86a5cebd2a405cbd08682462777d3ffbc181779df389" />
      <Card.Body>
        <Card.Title>ಚದುರಂಗ</Card.Title>
        <Card.Text>
        ಬುದ್ಧಿವಂತಿಕೆ ಮತ್ತು ದೂರದೃಷ್ಟಿಯ ಆಟ, ಅಲ್ಲಿ ಗ್ರಾಮೀಣ ಚೆಸ್ ಪ್ರಾಡಿಜಿಗಳು ಜಾಗತಿಕ ವೇದಿಕೆಯಲ್ಲಿ ತಮ್ಮ ಛಾಪು ಮೂಡಿಸುವ ಸಾಮರ್ಥ್ಯವನ್ನು ಹೊಂದಿದ್ದಾರೆ.
        </Card.Text>

      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-people-man-woman-playing-table-tennis_93675-134489.jpg?w=1060&t=st=1697265228~exp=1697265828~hmac=54551670c7b793374f5acc44d9f553fb727750e00de361d6d2ad2b5280d4657a" />
      <Card.Body>
        <Card.Title>ಟೇಬಲ್ ಟೆನ್ನಿಸ್</Card.Title>
        <Card.Text>
        ವೇಗದ ಮತ್ತು ಉತ್ತೇಜಕ, ಟೇಬಲ್ ಟೆನ್ನಿಸ್ ಗ್ರಾಮೀಣ ಕ್ರೀಡಾಪಟುಗಳು ನಿಜವಾಗಿಯೂ ಉತ್ತಮ ಸಾಧನೆ ಮಾಡುವ ಕ್ರೀಡೆಯಾಗಿದೆ.
        </Card.Text>
     
      </Card.Body>
    </Card>
    
    </div>
  
</section>



<section>
    <h3>ಹೊರಾಂಗಣ ಆಟಗಳು</h3>

<div className="inoutdoor">
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/young-volleyball-man-player-court_23-2149492372.jpg?w=1060&t=st=1697265453~exp=1697266053~hmac=38b67aa1f0c569204e324ccd0a6025c358209ab74d0f8ac3f16a7b044a389ff7" />
      <Card.Body>
        <Card.Title>ವಾಲಿಬಾಲ್</Card.Title>
        <Card.Text> ಸಮುದಾಯದ ಅಚ್ಚುಮೆಚ್ಚಿನ, ಭಾಗವಹಿಸುವಿಕೆ ಮತ್ತು ಪ್ರತಿಭೆ ಅಭಿವೃದ್ಧಿಯನ್ನು ಉತ್ತೇಜಿಸಲು ನಾವು ನಿಯಮಿತ ವಾಲಿಬಾಲ್ ಪಂದ್ಯಾವಳಿಗಳನ್ನು ಆಯೋಜಿಸುತ್ತೇವೆ.
     
        </Card.Text>

      </Card.Body>
    </Card>

    {/* <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="holder.js/100px180" />
      <Card.Body>
        <Card.Title>Marathon</Card.Title>
        <Card.Text>
        Take part in our marathons to test your endurance and discover the long-distance running stars of tomorrow.
        </Card.Text>
        <Button variant="primary">Go somewhere</Button>
      </Card.Body>
    </Card> */}

    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/different-people-participating-cross-country_23-2149157134.jpg?w=1060&t=st=1697265375~exp=1697265975~hmac=cc39d5c0cc918e43474f8a249139536b9172850571f8f2aa5b897b9215fca427" />
      <Card.Body>
        <Card.Title>ಅಥ್ಲೆಟಿಕ್ಸ್</Card.Title>
        <Card.Text>
        ನಾವು ಯುವ ಕ್ರೀಡಾಪಟುಗಳಿಗೆ ವಿವಿಧ ಟ್ರ್ಯಾಕ್ ಮತ್ತು ಫೀಲ್ಡ್ ಈವೆಂಟ್‌ಗಳಲ್ಲಿ ತರಬೇತಿ ನೀಡಲು ಮತ್ತು ಸ್ಪರ್ಧಿಸಲು ವೇದಿಕೆಯನ್ನು ಒದಗಿಸುತ್ತೇವೆ.
        </Card.Text>
     
      </Card.Body>
    </Card>
    <Card style={{ width: '18rem' }}>
      <Card.Img variant="top" src="https://img.freepik.com/free-photo/professional-cyclist-women_23-2149703331.jpg?w=1060&t=st=1697265287~exp=1697265887~hmac=782e175093e473ddfc0e2abd894b42673b3d40d0e72972fe4289c6e5bc083fa9" />
      <Card.Body>
        <Card.Title>ಸೈಕ್ಲಿಂಗ್</Card.Title>
        <Card.Text>
        ಗ್ರಾಮಾಂತರದಿಂದ ವೇದಿಕೆಯವರೆಗೆ, ಉದಯೋನ್ಮುಖ ಸೈಕ್ಲಿಸ್ಟ್‌ಗಳ ಶ್ರೇಷ್ಠತೆಯ ಪ್ರಯಾಣದಲ್ಲಿ ಅವರನ್ನು ಬೆಂಬಲಿಸಲು ನಾವು ಇಲ್ಲಿದ್ದೇವೆ.
        </Card.Text>

      </Card.Body>
    </Card>
    </div>
  
</section>





<section className="whychoose">
<h3>ಝಾ ಸ್ಪೋರ್ಟ್ಸ್ <span>ಟ್ರೈನಿಂಗ್ ಕ್ಲಬ್ ಅನ್ನು ಏಕೆ ಆರಿಸಬೇಕು?</span></h3>
<ul>

<li><span>ಗ್ರಾಮೀಣ ಫೋಕಸ್ : </span>ನಮ್ಮ ಪ್ರಾಥಮಿಕ ಗುರಿ ಗ್ರಾಮೀಣ ಪ್ರತಿಭೆಗಳನ್ನು ಗುರುತಿಸುವುದು ಮತ್ತು ಪೋಷಿಸುವುದು, ಯಾವುದೇ ಪ್ರತಿಭೆ ಗಮನಕ್ಕೆ ಬರದಂತೆ ನೋಡಿಕೊಳ್ಳುವುದು.</li>
<li><span>ತಜ್ಞರ ತರಬೇತಿ : </span>ನಮ್ಮ ತರಬೇತುದಾರರು ಭಾವೋದ್ರಿಕ್ತರಾಗಿದ್ದಾರೆ ಮತ್ತು ನಿಮ್ಮ ಸಂಪೂರ್ಣ ಸಾಮರ್ಥ್ಯವನ್ನು ತಲುಪಲು ನಿಮಗೆ ಸಹಾಯ ಮಾಡಲು ಸಮರ್ಪಿತರಾಗಿದ್ದಾರೆ.</li>
<li><span>ಸಮುದಾಯ ನಿರ್ಮಾಣ : </span>ಕ್ರೀಡಾ ಮನೋಭಾವ, ಶಿಸ್ತು ಮತ್ತು ಟೀಮ್‌ವರ್ಕ್ ಅನ್ನು ಮೌಲ್ಯೀಕರಿಸುವ ಸಮುದಾಯಕ್ಕೆ ಸೇರಿ..</li>
<li><span>ಸಮಗ್ರ ಅಭಿವೃದ್ಧಿ : </span> ನಾವು ಕೇವಲ ದೈಹಿಕ ಕೌಶಲ್ಯಗಳ ಮೇಲೆ ಕೇಂದ್ರೀಕರಿಸುವುದಿಲ್ಲ; ನಾವು ಪಾತ್ರ ನಿರ್ಮಾಣ ಮತ್ತು ಶಿಕ್ಷಣಕ್ಕೂ ಒತ್ತು ನೀಡುತ್ತೇವೆ.</li>
<li><span>ಸ್ಪರ್ಧೆಗಳು : </span>ನಿಯಮಿತ ಸ್ಪರ್ಧೆಗಳು ಮತ್ತು ಈವೆಂಟ್‌ಗಳು ನಮ್ಮ ಕ್ರೀಡಾಪಟುಗಳಿಗೆ ಹೊಳಪು ಮತ್ತು ಮನ್ನಣೆ ಪಡೆಯಲು ಅವಕಾಶವನ್ನು ನೀಡುತ್ತವೆ.</li>
</ul>

</section>


<section className="cycle2">
    <div className="aboutcycle2">
<h2>ಸದಸ್ಯತ್ವ
</h2>
<div className="membership">
    <img src="../img/joinus.jpg"></img>
<p>ಈ ಕ್ಲಬ್ ಅನ್ನು ಬಳಸಲು ಮಾಸಿಕ ಶುಲ್ಕ ₹300, ಮತ್ತು ಸದಸ್ಯರು ಪ್ರತಿ ವಾರ ಮೂರು ದಿನಗಳವರೆಗೆ ಇದನ್ನು ಪ್ರವೇಶಿಸಬಹುದು. ಝಾ ಇಂಡೋರ್ ಕ್ಲಬ್‌ನ ಸದಸ್ಯರಾಗಿ ಮತ್ತು ಒಳಾಂಗಣ ಕ್ರೀಡೆಗಳು, ಫಿಟ್‌ನೆಸ್ ಮತ್ತು ಮನರಂಜನೆಯ ಜಗತ್ತಿಗೆ ಪ್ರವೇಶವನ್ನು ಪಡೆಯಿರಿ. ನೀವು ಆಗಾಗ್ಗೆ ಭೇಟಿ ನೀಡುವವರಾಗಿರಲಿ ಅಥವಾ ನಮ್ಮ ಸೌಲಭ್ಯಗಳನ್ನು ಪ್ರಯತ್ನಿಸಲು ನೋಡುತ್ತಿರಲಿ ನಿಮ್ಮ ವೈಯಕ್ತಿಕ ಅಗತ್ಯಗಳಿಗೆ ಸರಿಹೊಂದುವಂತೆ ನಾವು ಹೊಂದಿಕೊಳ್ಳುವ ಸದಸ್ಯತ್ವ ಆಯ್ಕೆಗಳನ್ನು ನೀಡುತ್ತೇವೆ.
</p>
</div>
</div>
</section>



</div>



);
}