import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';


export default function Ecofriendly() {
    return (
        <div>

            
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Wellness-Program-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Wellness-Program-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Zha-Wellness-Program-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Zha-Wellness-Program-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Zha-Wellness-Program-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
{/* 
            <div  id="language" >

        
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Sustainable-Certification-Process-ka">Kannada</NavLink></a>
        

        </div> */}

<h1 id="mhead">Zha Wellness Program </h1>

   
   <div className='second-mcontent'>
           <img src='../../img/NEW EVENTS/39.webp'></img>
       </div>


 

<section className="visionmi">
 
<p>Zha's Physical and Mental Wellness Free Coaching encompasses a diverse range of activities, ensuring a holistic approach to well-being. This includes:


</p>
 
<ol className="goalpoints">

	
   
   <li><span>Athletic Training Programs :</span> Tailored programs for physical fitness, covering various disciplines such as marathon, cycling, and volleyball.

</li>

<li><span>Mindful Activities : </span>  Beyond physical training, participants benefit from mental wellness activities like meditation and yoga


</li>
<li><span>Indoor Chess :</span>  Engaging the mind through strategic games like chess, promoting cognitive development and mental agility.

</li>
<p>
The Zha wellness program has a comprehensive approach that addresses both the physical and mental aspects of well-being, fostering a balanced and healthy lifestyle for individuals participating in the coaching program. The Zha free physical and mental wellness coaching in rural and suburban areas for students offers several benefits:</p>
 
</ol>
        
<ol className="goalpoints"> 

<li><span>Access to Resources :</span>  Students in rural areas gain access to coaching programs that they might not have otherwise due to financial constraints, promoting equal opportunities for well-being.

</li> 
<li><span>Improved Physical Health : </span> Athletic training programs such as marathons, cycling, and volleyball contribute to enhanced physical fitness, promoting an active lifestyle.


</li>
<li><span>Enhanced Mental Well-being : </span> Meditation, yoga classes, and indoor chess provide tools for stress relief, improved focus, and overall mental resilience.
</li>
<li><span>Skill Development : </span>Participation in diverse activities fosters the development of a wide range of skills, including teamwork, discipline, and strategic thinking.

</li>
<li><span>Community Building : </span>  Wellness programs create a sense of community among participants, fostering social connections and support networks.

</li>
<li><span>Empowerment :</span> The coaching empowers students by equipping them with both physical and mental tools to navigate challenges and succeed in various aspects of life.
</li>
<li><span>Promoting Healthy Lifestyles : </span>By encouraging physical activity and mental health practices, the coaching contributes to the adoption of healthy lifestyles, reducing the risk of lifestyle-related health issues.
</li>


</ol>




<p>
Overall, the free coaching programs aim to uplift and empower students in rural and suburban areas, fostering their holistic well-being and providing them with valuable life skills.
 </p>


</section>
 

</div>
 
 
);
}

