//QuizContext.js

import React, { createContext, useContext, useState } from "react";


const QuizContext = createContext();



let check = null;

const Provider = ({ children }) => {


  const questions = {
    A1: [
      {
        id: 1,
        question: "Do you engage in regular physical activity?",
        answers: [

          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 2,
        question: "Do you prioritize getting enough sleep each night?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 3,
        question: "Do you consume a balanced and nutritious diet?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 4,
        question: "Do you practice stress management techniques regularly?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 5,
        question: "Do you avoid smoking or using tobacco products?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 6,
        question: "Do you limit your alcohol intake to moderate levels?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 7,
        question: "Do you schedule regular health check-ups?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 8,
        question: "Do you actively work to maintain a healthy weight?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 9,
        question: "Do you practice good hygiene habits?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 10,
        question: "Do you avoid excessive screen time and take breaks?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 11,
        question: "Do you have a strong support system of friends and family?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 12,
        question: "Do you engage in activities that bring you joy and fulfillment?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 13,
        question: "Do you actively manage and cope with mental health challenges?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 14,
        question: "Do you avoid excessive consumption of processed foods?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 15,
        question: "Do you engage in mindfulness or meditation practices?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 16,
        question: "Do you actively seek opportunities for personal and professional growth?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 17,
        question: "Do you maintain a healthy work-life balance?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 18,
        question: "Do you avoid excessive consumption of sugary beverages?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 19,
        question: "Do you actively foster positive relationships in your life?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 20,
        question: "Do you engage in regular mindfulness or meditation practices to promote mental well-being?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
    ],
    A2: [
      {
        id: 1,
        question: "Do you recycle materials regularly?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 2,
        question: "Do you use energy-efficient appliances in your home?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer:  true },
        ],
      },
      {
        id: 3,
        question: "Do you avoid single-use plastic products whenever possible?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 4,
        question: "Do you support initiatives for reforestation and conservation?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 5,
        question: "Do you use public transportation or carpool to reduce emissions?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true  },
        ],
      },
      {
        id: 6,
        question: "Do you participate in community clean-up events?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 7,
        question: "Do you conserve water by fixing leaks and using it responsibly?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 8,
        question: "Do you purchase products with environmentally friendly packaging?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 9,
        question: "Do you minimize your use of disposable products?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 10,
        question: "Do you support companies with sustainable business practices?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 11,
        question: "Do you compost organic waste?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 12,
        question: "Do you minimize food waste by planning meals and using leftovers?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 13,
        question: "Do you educate others about environmental conservation?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 14,
        question: "Do you support policies and initiatives for environmental protection?",
        answers: [
          { answer: "Yes", trueAnswer:  false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 15,
        question: "Do you actively reduce your carbon footprint?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 16,
        question: "Do you participate in local environmental advocacy groups?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 17,
        question: "Do you choose products with minimal environmental impact?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 18,
        question: "Do you use renewable energy sources at home?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 19,
        question: "Do you responsibly dispose of electronic waste?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 20,
        question: "Do you educate yourself on environmental issues and solutions?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
    ],
    B1: [
      {
        id: 1,
        question: "Do you have a diversified investment portfolio?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 2,
        question: "Do you actively manage and monitor your finances?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 3,
        question: "Do you have an emergency fund for unexpected expenses?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 4,
        question: "Do you invest in your education and skills development?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 5,
        question: "Do you regularly review and adjust your financial goals?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 6,
        question: "Do you actively seek new opportunities for income generation?",
        answers: [
          { answer: "Yes", trueAnswer: false},
          { answer: "No", trueAnswer: true},
        ],
      },
      {
        id: 7,
        question: "Do you have a budget that you consistently follow?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 8,
        question: "Do you have a long-term financial plan for retirement?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 9,
        question: "Do you avoid unnecessary debt and manage existing debt responsibly?",
        answers: [
          { answer: "Yes", trueAnswer: false},
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 10,
        question: "Do you have insurance coverage for your health and assets??",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 11,
        question: "Do you actively seek ways to increase your income?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 12,
        question: "Do you diversify your income sources to reduce risk?",
        answers: [
          { answer: "Yes", trueAnswer: false},
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 13,
        question: "Do you stay informed about economic trends and market changes?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 14,
        question: "Do you negotiate and seek the best deals when making purchases?",
        answers: [
          { answer: "Yes", trueAnswer:false},
          { answer: "No", trueAnswer: true},
        ],
      },
      {
        id: 15,
        question: "Do you actively save for specific financial goals?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 16,
        question: "Do you have a plan for wealth transfer and inheritance?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 17,
        question: "Do you avoid impulsive and unnecessary spending?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer:  true },
        ],
      },
      {
        id: 18,
        question: "Do you seek professional financial advice when needed?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 19,
        question: "Do you track your expenses to identify areas for improvement?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 20,
        question: "Do you actively contribute to retirement savings accounts?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
    ],
    B2: [
      {
        id: 1,
        question: "Do you spend regular time outdoors in nature?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 2,
        question: "Do you practice sustainable and responsible outdoor activities?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 3,
        question: "Do you minimize your environmental impact when camping or hiking?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 4,
        question: "Do you support organizations working for wildlife conservation?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 5,
        question: "Do you practice Leave No Trace principles in natural settings?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 6,
        question: "Do you participate in environmental restoration projects?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 7,
        question: "Do you cultivate a garden or support local agriculture?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 8,
        question: "Do you minimize your use of harmful pesticides and chemicals?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 9,
        question: "Do you appreciate and respect the biodiversity around you?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 10,
        question: "Do you engage in activities that foster a connection with nature?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 11,
        question: "Do you educate yourself about local ecosystems and wildlife?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 12,
        question: "Do you advocate for policies that protect natural habitats?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 13,
        question: "Do you minimize your use of disposable items in outdoor activities?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 14,
        question: "Do you support initiatives for clean air and water in your community?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 15,
        question: "Do you actively reduce your energy consumption at home?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 16,
        question: "Do you educate others about the importance of nature conservation?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 17,
        question: "Do you participate in tree planting initiatives?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
      {
        id: 18,
        question: "Do you avoid supporting businesses with harmful environmental practices?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 19,
        question: "Do you prioritize experiences that involve nature and the outdoors?",
        answers: [
          { answer: "Yes", trueAnswer: false },
          { answer: "No", trueAnswer: true },
        ],
      },
      {
        id: 20,
        question: "Do you choose eco-friendly products in your daily life?",
        answers: [
          { answer: "Yes", trueAnswer: true },
          { answer: "No", trueAnswer: false },
        ],
      },
    ],
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);

  const sharedValuesAndMethods = {
    questions,
    currentQuestion,
    setCurrentQuestion,
  };
  return <QuizContext.Provider value={sharedValuesAndMethods}>{children}</QuizContext.Provider>;

}

const useQuizContext = () => {
  return useContext(QuizContext);
};




export { Provider, useQuizContext };
// export default QuizContext;
