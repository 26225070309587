import React from "react";
import './Invitation.css';
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
export default function Transformation(){

    return(
        <div>
            <div className="social-maa">

<h1>You're Invited!Zha Global Sustainability Summit - 2024<br></br>(March 11 - 12, Chennai)</h1>
</div>


           
        <div className="invitation">
        <img src="../img/invitation.png"></img>
        </div>
     
        <section className="visionmi">

<h3>Welcome to the Zha Global Sustainability Summit - 2024!</h3>

<p>We are thrilled to extend a warm invitation to individuals, businesses, and organizations passionate about sustainability to join us at the Zha Global Sustainability Summit in the vibrant city of Chennai. Mark your calendars for March 11 - 12, 2024, as we bring together thought leaders, innovators, and changemakers to discuss and shape the future of global sustainability.</p>

<h3>What to Expect</h3>
<p>The Zha Global Sustainability Summit is a two-day event dedicated to exploring innovative solutions, sharing insights, and fostering collaborations that will drive positive change in the world. Our diverse lineup of speakers will cover a wide range of topics, including:</p>
      <ul>
        <li>Sustainable Business Practices</li>
        <li>Renewable Energy Technologies</li>
        <li>Circular Economy Initiatives</li>
        <li>Climate Action and Policy</li>
        <li>Social Impact and Corporate Responsibility</li>
        <li>Biodiversity Conservation</li>
        </ul> 


       <h3>Why Attend?</h3>

       <ul>
        <li><span>Inspiration : </span>Gain inspiration from visionary leaders and experts in the field of sustainability</li>
        <li><span>Networking : </span>Connect with like-minded individuals, professionals, and organizations committed to making a difference.</li>
        <li><span>Knowledge Sharing : </span>Participate in engaging discussions, workshops, and breakout sessions to expand your understanding of sustainability challenges and solutions.</li>
        <li><span>Innovation Showcase : </span>Explore the latest innovations and technologies driving sustainable practices in various industries.</li>

       </ul>
       

       <h3>Location: Chennai - Where Tradition Meets Innovation</h3>
       <p>Chennai, with its rich cultural heritage and rapidly growing urban landscape, provides the perfect backdrop for our sustainability summit. Immerse yourself in the vibrant atmosphere of this dynamic city while contributing to discussions that will shape a sustainable future.</p>

       <h3>Register Now</h3>
       <p>Don't miss this opportunity to be a part of the Zha Global Sustainability Summit - 2024. Secure your spot today by registering online.</p>
       
       
       
       
       
       
       
       
       
       </section>
       
       
       
        </div>


    );
}

