import React from "react";
import "./Zhasports.css";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';




export default function Zhasports(){




    

    return(
        <div>
            <div className="social-ma">

<h1>Zha Volleyball Training Club</h1>
</div>


<header>
    <div>
    <h2>Welcome to <br></br>Zha Volleyball Training Club!</h2>
    
    </div>
    <img src="../img/volleyball.webp"></img>
</header>


<section className="cycle">
    <div className="aboutcycle">
<h2>About Us
</h2>
<p>At Zha Volleyball Training Club, we're passionate about the sport of volleyball and dedicated to creating an environment where players of all ages and skill levels can thrive. Whether you're a seasoned athlete or a beginner looking to learn the game, our club is the place to be. Our state-of-the-art facilities and experienced coaches are here to help you excel in the world of volleyball.

</p>
</div>
</section>



<section className="cycle2">
    <div className="aboutcycle2">
<h2>Membership
</h2>
<div className="membership">
    <img src="../img/joinus.webp"></img>
<p>The monthly fee for using this club is ₹500, and members can access it for three days each week.Become a member of Zha Indoor Club and gain access to a world of indoor sports, fitness, and recreation. We offer flexible membership options to suit your individual needs, whether you're a frequent visitor or just looking to try out our facilities.

</p>
</div>
</div>
</section>

</div>



);
}