import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Csr.css';
import Pptx from './Pptx';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/How-Zha-Use-CSR-Funds">English</NavLink></a>

        </div>
            

            <div className="social-ma">

<h1>Zha எப்படி CSR நிதியை பயன்படுத்துகிறது
</h1>

<Pptx />
 
 


 

<p>Zha அறக்கட்டளை பின்வரும் தூண்களுடன் அவற்றை சீரமைப்பதன் மூலம் கார்ப்பரேட் சமூக பொறுப்புணர்வு (CSR) நிதிகளை அதன் நிலைத்தன்மை முயற்சிகளில் மூலோபாய ரீதியாக முதலீடு செய்யலாம்:
</p>

 

<ol className="goalpoints">

<li><span>மனித ஆரோக்கிய தூண் : </span>  
<ul>
    <li>சுகாதார திட்டங்கள், மனநல விழிப்புணர்வு மற்றும் ஒட்டுமொத்த நல்வாழ்வை மேம்படுத்தும் முயற்சிகளுக்கு நிதி ஒதுக்குகிறது.


</li>
    <li> ஊட்டச்சத்து மற்றும் தடுப்பு சுகாதார நடவடிக்கைகள் உட்பட உடல்நலம் மற்றும் ஆரோக்கியத்தில் கவனம் செலுத்தும் கல்வித் திட்டங்களில் முதலீடு செய்கிறது.


</li>
</ul>
</li> 

<li><span>இயற்கை ஆரோக்கிய தூண் :</span>  
<ul>
    <li>பல்லுயிர் பாதுகாப்பு, மறு காடுகள் மற்றும் இயற்கை வாழ்விடங்களின் பாதுகாப்பு ஆகியவற்றை ஊக்குவிக்கும் நிதி திட்டங்கள்.



</li>
    <li>சுற்றுச்சூழல் அமைப்புகளின் ஆரோக்கியத்தை மேம்படுத்தும் சூழல் நட்பு நடைமுறைகள் மற்றும் நிலையான விவசாய முயற்சிகளில் முதலீடு செய்கிறது.




</li>
</ul>
</li> 

<li><span>பூமி ஆரோக்கிய தூண் : </span>  
<ul>
    <li>புதுப்பிக்கத்தக்க எரிசக்தி முன்முயற்சிகள் மற்றும் கார்பன் ஆஃப்செட் திட்டங்கள் போன்ற காலநிலை மாற்றத்தைத் தணித்தல் மற்றும் தழுவல் ஆகியவற்றைக் குறிக்கும் ஆதரவு திட்டங்கள்.

</li>
    <li>நீர் பாதுகாப்பு மற்றும் சுகாதார திட்டங்களில் முதலீடு செய்யுங்கள், இது கிரகத்தின் முக்கிய வளங்களின் ஆரோக்கியத்திற்கு பங்களிக்கிறது.




</li>
</ul>
</li> 

<li><span>நிலையான இலாபத் தூண் : </span>  
<ul>
    <li>நிலையான வணிக நடைமுறைகளை கடைபிடிக்கும் போது நிலையான லாபத்தை உருவாக்கும் சமூக நிறுவனங்களை நிறுவுதல் அல்லது ஆதரிக்கவும்.




</li>
    <li> ZHA அறக்கட்டளையின் நோக்கத்துடன் இணைந்த வருமானத்தை உருவாக்கும் திட்டங்களில் முதலீடு செய்யுங்கள், நீண்ட கால தாக்கத்திற்கு ஒரு சுய-நிலையான மாதிரியை உருவாக்குகிறது.




</li>
</ul>
</li> 

</ol>

<p>
ZHA அறக்கட்டளையின் CSR முதலீட்டு மூலோபாயத்தில் இந்தத் தூண்களை ஒருங்கிணைப்பதன் மூலம், மனித ஆரோக்கியம், இயற்கைப் பாதுகாப்பு, பூமியின் நல்வாழ்வு மற்றும் நிதி நிலைத்தன்மை ஆகியவற்றைக் குறிக்கும் ஒரு முழுமையான அணுகுமுறையை அமைப்பு உருவாக்க முடியும். இந்த பன்முக மூலோபாயம் சமூகங்கள் மற்றும் சுற்றுச்சூழலில் சமநிலையான மற்றும் விரிவான தாக்கத்தை உறுதி செய்கிறது, சிறந்த எதிர்காலத்திற்கான நிலையான மனநிலையை வளர்ப்பதற்கான அடித்தளத்தின் நோக்கத்துடன் இணைகிறது.</p>
 

 

 <br></br>

 <p>ZHA அறக்கட்டளை பின்வரும் அணுகுமுறைகள் மூலம் நிலைத்தன்மை முயற்சிகளை மேம்படுத்துவதற்கு பெருநிறுவன சமூகப் பொறுப்பு (CSR) நிதிகளை மூலோபாய ரீதியாகப் பயன்படுத்த முடியும்:
</p>

 

<ol className="goalpoints">

<li><span>சுற்றுச்சூழல் பாதுகாப்பு திட்டங்கள் : </span>  
<ul>
    <li>சுகாதார திட்டங்கள், மனநல விழிப்புணர்வு மற்றும் ஒட்டுமொத்த நல்வாழ்வை மேம்படுத்தும் முயற்சிகளுக்கு நிதி ஒதுக்கவும்.


</li>
 
</ul>
</li> 

<li><span>பசுமை உள்கட்டமைப்பு மேம்பாடு : </span>  
<ul>
    <li>சூழல் நட்பு கட்டிடங்கள், ஆற்றல்-திறனுள்ள தொழில்நுட்பங்கள் அல்லது பசுமை போக்குவரத்து போன்ற நிலையான உள்கட்டமைப்பின் வளர்ச்சிக்கு நிதி ஒதுக்கவும்.




</li>
    
</ul>
</li> 

<li><span>சமூகம் சார்ந்த நிலையான விவசாயம் : </span>  
<ul>
    <li>சுற்றுச்சூழல் மற்றும் உணவு நிலைத்தன்மைக்கு பங்களிக்கும் இயற்கை விவசாயம், பெர்மாகல்ச்சர் அல்லது சமூக தோட்டங்களில் கவனம் செலுத்தும் நிலையான விவசாய திட்டங்களின் மூலம் உள்ளூர் சமூகங்களை ஆதரிக்கவும்.



</li>
     
</ul>
</li> 

<li><span>சுற்றறிக்கை பொருளாதார முயற்சிகள் : </span>  
<ul>
    <li>கழிவுகளைக் குறைப்பதன் மூலமும், மறுசுழற்சி, மறுசுழற்சி செய்தல் அல்லது பொருட்களின் மறுபயன்பாடு ஆகியவற்றை ஊக்குவிப்பதன் மூலமும் ஒரு வட்டப் பொருளாதாரத்தை ஊக்குவிக்கும் திட்டங்களில் முதலீடு செய்யுங்கள்.




</li>
    
</ul>
</li> 


<li><span>பல்லுயிர் பாதுகாப்பு : </span>  
<ul>
    <li>இயற்கை வாழ்விடங்களைப் பாதுகாத்தல், அழிந்து வரும் உயிரினங்களைப் பாதுகாத்தல் மற்றும் நிலையான சுற்றுச்சூழல் மேலாண்மையை மேம்படுத்துதல் போன்ற முயற்சிகளை ஆதரிப்பதன் மூலம் பல்லுயிரியலைப் பாதுகாக்கவும் மீட்டெடுக்கவும் CSR நிதியைப் பயன்படுத்தவும்.


</li>
 
</ul>
</li> 

<li><span>நீர் மற்றும் ஆற்றல் பாதுகாப்பு : </span>  
<ul>
    <li>நீர் மற்றும் ஆற்றல் பாதுகாப்புத் திட்டங்களில், நுகர்வைக் குறைக்கும் மற்றும் திறமையான வளப் பயன்பாட்டை ஊக்குவிக்கும் தொழில்நுட்பங்கள் மற்றும் நடைமுறைகளைச் செயல்படுத்துதல்.





</li>
    
</ul>
</li> 

<li><span>கல்வி மற்றும் விழிப்புணர்வு நிகழ்ச்சிகள் : </span>  
<ul>
    <li>நிலைத்தன்மை சிக்கல்கள் பற்றிய விழிப்புணர்வை ஏற்படுத்தும் கல்வி முயற்சிகளில் முதலீடு செய்யவும், சூழல் நட்பு நடைமுறைகளை மேம்படுத்தவும், நிலையான வாழ்க்கை முறையை பின்பற்ற சமூகங்களுக்கு அதிகாரம் அளிக்கவும்.





</li>
     
</ul>
</li> 

<li><span>காலநிலை மாற்றம் தணிப்பு : </span>  
<ul>
    <li>கார்பன் ஆஃப்செட் திட்டங்கள், நிலையான போக்குவரத்து தீர்வுகள் அல்லது கிரீன்ஹவுஸ் வாயு உமிழ்வைக் குறைப்பதை நோக்கமாகக் கொண்ட முன்முயற்சிகள் போன்ற காலநிலை மாற்றத்தைத் தணிக்கும் திட்டங்களுக்கு ஆதரவு.
</li>
    
</ul>
</li> 



<li><span>சுற்றுச்சூழல் கவனம் கொண்ட சமூக நிறுவனங்கள் : </span>  
<ul>
    <li>சுற்றுச்சூழல் பொறுப்பை ஊக்குவிக்கும் அதே வேளையில் பொருளாதார வளர்ச்சியை ஊக்குவிக்கும் வகையில், அவர்களின் வணிக மாதிரிகளில் நிலைத்தன்மையை ஒருங்கிணைக்கும் சமூக நிறுவனங்களில் முதலீடு செய்யுங்கள்.




</li>
 
</ul>
</li> 

<li><span>நிலைத்தன்மைக்கான ஆராய்ச்சி மற்றும் புதுமை : </span>  
<ul>
    <li>புதிய தொழில்நுட்பங்கள் அல்லது நடைமுறைகளின் வளர்ச்சியை ஊக்குவித்தல், நிலைத்தன்மை சவால்களுக்கான புதுமையான தீர்வுகளை ஆராயும் ஆராய்ச்சி முயற்சிகளுக்கு நிதி ஒதுக்கவும்.







</li>
    
</ul>
</li> 

<li><span>நிலையான நடைமுறைகளுக்கான திறன் உருவாக்கம் : </span>  
<ul>
    <li>சமூகங்கள் மற்றும் நிறுவனங்களின் திறன்களை மேம்படுத்த, நிலையான நடைமுறைகளைப் பின்பற்றுதல், நீண்ட கால தாக்கத்திற்கான பயிற்சி மற்றும் வளங்களை வழங்க CSR நிதிகளைப் பயன்படுத்தவும்.





</li>
     
</ul>
</li> 

<li><span>நிலைத்தன்மை தாக்கத்தை அளவிடுதல் மற்றும் அறிக்கை செய்தல் : </span>  
<ul>
    <li>பங்குதாரர்களுக்கு வெளிப்படைத்தன்மை மற்றும் பொறுப்புணர்வை உறுதிசெய்தல், நிலைத்தன்மை முயற்சிகளின் தாக்கத்தை அளவிடுவதற்கும் அறிக்கை செய்வதற்கும் வலுவான அமைப்புகளில் முதலீடு செய்யுங்கள்.
</li>
    
</ul>
</li> 
</ol>

<p>

நிலையான முயற்சிகளுடன் CSR நிதிகளை சீரமைப்பதன் மூலம், இலாப நோக்கற்ற நிறுவனங்கள் சுற்றுச்சூழல் சவால்களை எதிர்கொள்வதிலும், பின்னடைவை வளர்ப்பதிலும், சமூகங்கள் மற்றும் கிரகத்தின் மிகவும் நிலையான எதிர்காலத்திற்கு பங்களிப்பதிலும் முக்கிய பங்கு வகிக்க முடியும்.</p>
       
       
       
       
        </div>


        </div>




 
        


    );
}

