import React from "react";
import "./Pathfinder.css";

export default function Transformation() {

    return (
        <div>
            <div className="social-ma">

                <h1>Free Pathfinder Course for Public</h1>
            </div>

            <div className="pathh">
                <div className="pathtempleft">

                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/1.webp"></img>
                        <div>
                            <h6>Self Awareness & Meditation (2 hrs)
                            </h6>
                            <p>How to discover your inner-self, understand the flow of your mind and breadth, Self Love ways to find peace
                            </p>
                        </div>
                    </div>


                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/2.webp"></img>
                        <div>
                            <h6>Life Introspection & Retrospection (2 hrs)
                            </h6>
                            <p>Discover and Understand your past, present and plan the future goals according to each of your capabilities & Understand the Purpose of life
                            </p>
                        </div>
                    </div>

                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/3.webp"></img>
                        <div>
                            <h6>Self Heal & Re-Program yourself (2 hrs)
                            </h6>
                            <p>Learning a method to self heal and re-program the mind & intellect through self awareness
                            </p>
                        </div>
                    </div>


                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/4.webp"></img>
                        <div>
                            <h6>Developing Powerful Habits & Values (2 hrs)
                            </h6>
                            <p>Discover what triggers you to be impulsive, How to view your goals, One habit at a time, Conscious state, Minimize decision making, Reward yourself
                            </p>
                        </div>
                    </div>

                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/5.webp"></img>
                        <div>
                            <h6>Disregarding Negativity (2 hrs)
                            </h6>
                            <p>Taking control over your thoughts and feelings to disregard negativity is by learning self awareness
                            </p>
                        </div>
                    </div>

                </div>



                <div className="pathtempright">

                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/6.webp"></img>
                        <div>
                            <h6>Art of talking to anyone (2 hrs)

                            </h6>
                            <p>What are the various tones and how to use those tones to make an positive impression in your talking to anyone & Better communication management

                            </p>
                        </div>
                    </div>


                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/7.webp"></img>
                        <div>
                            <h6>Art of Managing Relationships (2 hrs)

                            </h6>
                            <p>Making friends, Happy couple management, Business relations management & Public relationship management

                            </p>
                        </div>
                    </div>

                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/8.webp"></img>
                        <div>
                            <h6>Art of Life Leadership Skills (2 hrs)

                            </h6>
                            <p>Public speaking, having a motivational personality, responding over reacting & Leadership case studies, Various leadership skills for successful life

                            </p>
                        </div>
                    </div>


                    <div className="pathtemp">
                        <img src="../../img/PATHFINDER/9.webp"></img>
                        <div>
                            <h6>Thought Leadership for
                                Financial Stability (2 hrs)

                            </h6>
                            <p>How to have your mindset that can attract wealth , How your thoughts can inspire you to take action on your goals

                            </p>
                        </div>
                    </div>



                </div>






            </div>



        </div>

    );
}

