import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';
 

export default function Health() {
    return (
        <div>
<div className="hmain">



<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Gender-Equality-and-Women-Empowerment-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Gender-Equality-and-Women-Empowerment-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Gender-Equality-and-Women-Empowerment-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Gender-Equality-and-Women-Empowerment-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Gender-Equality-and-Women-Empowerment-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

    <h1 id="mhead">Empowering Women for a Better Tomorrow</h1>
   
   <div className='second-mcontent'>
   <img src='../../img/women.webp'></img>
       </div>
    
    
    </div> 
 

<section className="visionmi">
 
<div className="purpose">
 
<p>At Zha Foundation, where we champion the cause of gender equality and women's empowerment. We firmly believe that a society thrives when everyone, regardless of gender, has equal opportunities, rights, and a voice that is heard.

</p>
</div>

<h3>Our Vision</h3>
<div className="missi">
  <h4 className="toleftneed">A World of Equals:</h4>
<p>At Zha Foundation we envision a world where gender is not a barrier to success, where every woman and girl is empowered to reach her full potential. We believe that by dismantling gender stereotypes and promoting equality, we can create a more just and prosperous future for all.</p>

 
</div>


<h3>Our Mission</h3>
<div className="missi">
<h4 className="toleftneed">Empowering Women Across Sectors:</h4>
<p>Our mission is to empower women across various sectors, including education, healthcare, employment, and leadership. We work tirelessly to break down systemic barriers and create environments that foster the growth and development of women at every stage of life.</p>

<h4 className="toleftneed">Advocacy and Awareness:</h4>
<p>Education is a powerful tool for change. We engage in advocacy and awareness campaigns to challenge societal norms and promote a more inclusive understanding of gender roles. By sparking conversations, we aim to create a cultural shift towards equality.</p>
</div>

<h3>Our Initiatives</h3>
<div className="missi">
<h4 className="toleftneed">Educational Empowerment:</h4>
<p>We believe in the transformative power of education. Our initiatives focus on providing equal educational opportunities for girls, ensuring that every young mind has the chance to learn, grow, and contribute to society.</p>

<h4 className="toleftneed">Career Development Programs:</h4>
<p>Zha foundation is committed to supporting women in their professional journeys. Through mentorship programs, skill-building workshops, and networking opportunities, we strive to create pathways for women to excel in their chosen fields.</p>

<h4 className="toleftneed">Health and Well-being:</h4>
<p>We recognize the importance of women's health and well-being. Our healthcare initiatives aim to address specific challenges faced by women, ensuring access to quality healthcare services and promoting overall wellness.</p>
</div>


</section>
</div>
 
           
    );
}

