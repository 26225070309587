import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
          <div  id="language" >
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club">English</NavLink></a>
        </div>

            <div className="social-ma">

<h1>Role of Zha Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/role.webp"></img>
        </div>
<div className="goalpointsp">

<p>ലോകമെമ്പാടുമുള്ള Zha സുസ്ഥിര വികസന ക്ലബ്ബിന്റെ റോളുകളും ഉത്തരവാദിത്തങ്ങളും സാധാരണയായി ഉൾപ്പെടുന്നു:
</p>


<ol className="goalpoints">
    <li><span>ബോധവൽക്കരണം പ്രോത്സാഹിപ്പിക്കുക : </span>സുസ്ഥിരമായ സമ്പ്രദായങ്ങൾക്കും പെരുമാറ്റങ്ങൾക്കും വേണ്ടി വാദിക്കുന്ന, പാരിസ്ഥിതികവും സാമൂഹികവുമായ പ്രശ്നങ്ങളെക്കുറിച്ച് അവബോധം വളർത്തുക.
</li>

    <li><span>വിദ്യാഭ്യാസ സംരംഭങ്ങൾ : </span>സുസ്ഥിര വികസന ലക്ഷ്യങ്ങളെയും പ്രവർത്തനങ്ങളെയും കുറിച്ച് സമൂഹത്തെ അറിയിക്കുന്നതിന് വിദ്യാഭ്യാസ പരിപാടികൾ, ശിൽപശാലകൾ, പ്രചാരണങ്ങൾ എന്നിവ നടത്തുക.</li>
    <li><span>കമ്മ്യൂണിറ്റി ഇടപഴകൽ : </span>പ്രാദേശിക കമ്മ്യൂണിറ്റികളുമായി അവരുടെ ആവശ്യങ്ങൾ മനസ്സിലാക്കാനും സുസ്ഥിര പദ്ധതികളിൽ അവരെ ഉൾപ്പെടുത്താനും ഉത്തരവാദിത്തബോധം വളർത്താനും അവരുമായി ഇടപഴകുക.
</li>


    <li><span>പ്രോജക്റ്റ് നടപ്പിലാക്കൽ : </span>പരിസ്ഥിതിക്ക് ക്രിയാത്മകമായി സംഭാവന ചെയ്യുന്നതിനായി മാലിന്യം കുറയ്ക്കൽ, ഊർജ്ജ കാര്യക്ഷമത, അല്ലെങ്കിൽ സംരക്ഷണ സംരംഭങ്ങൾ തുടങ്ങിയ സുസ്ഥിര പദ്ധതികൾ ആസൂത്രണം ചെയ്യുകയും നടപ്പിലാക്കുകയും ചെയ്യുക.

</li>
    <li><span>അഭിഭാഷകൻ : </span>പ്രാദേശികവും ദേശീയവുമായ തലങ്ങളിൽ സുസ്ഥിരതയെ പിന്തുണയ്ക്കുന്ന നയങ്ങൾക്കുവേണ്ടി വാദിക്കുന്നു, നല്ല മാറ്റത്തെ സ്വാധീനിക്കാൻ അധികാരികളുമായും മറ്റ് സംഘടനകളുമായും സഹകരിച്ച് പ്രവർത്തിക്കുന്നു.



</li>
    <li><span>ഗവേഷണവും ഇന്നൊവേഷനും : </span>പാരിസ്ഥിതിക വെല്ലുവിളികളെ അഭിമുഖീകരിക്കുന്നതിനുള്ള പുതിയ വഴികൾ പര്യവേക്ഷണം ചെയ്തുകൊണ്ട് സുസ്ഥിര സാങ്കേതികവിദ്യകളെയും നൂതനാശയങ്ങളെയും കുറിച്ച് ഗവേഷണം നടത്തുക.


</li>
    <li><span>നെറ്റ്‌വർക്കിംഗ് : </span>വിഭവങ്ങൾ പ്രയോജനപ്പെടുത്തുന്നതിനും സുസ്ഥിര സംരംഭങ്ങളുടെ സ്വാധീനം വർദ്ധിപ്പിക്കുന്നതിനും മറ്റ് ഓർഗനൈസേഷനുകൾ, ബിസിനസ്സുകൾ, സർക്കാർ സ്ഥാപനങ്ങൾ എന്നിവയുമായി പങ്കാളിത്തം ഉണ്ടാക്കുക.



</li>
    <li><span>നിരീക്ഷണവും മൂല്യനിർണ്ണയവും : </span>സുസ്ഥിര ലക്ഷ്യങ്ങളുമായി പൊരുത്തപ്പെടുന്നതായും ആവശ്യാനുസരണം ക്രമീകരണങ്ങൾ വരുത്തുന്നതായും ഉറപ്പാക്കുന്നതിന് പ്രോജക്ടുകളുടെ സ്വാധീനം പതിവായി വിലയിരുത്തുകയും വിലയിരുത്തുകയും ചെയ്യുക.
</li>
    <li><span>ധനസമാഹരണം : </span>ഗ്രാന്റുകൾ, സംഭാവനകൾ, അല്ലെങ്കിൽ ഇവന്റുകൾ എന്നിവയിലൂടെ സുസ്ഥിരത പ്രോജക്റ്റുകൾക്കും നടന്നുകൊണ്ടിരിക്കുന്ന ക്ലബ്ബ് പ്രവർത്തനങ്ങൾക്കും പിന്തുണ നൽകുക.

</li>
    <li><span>ആശയവിനിമയം : </span>ക്ലബിനുള്ളിലും വിശാലമായ സമൂഹവുമായും സുതാര്യമായ ആശയവിനിമയം നിലനിർത്തുക, പങ്കാളിത്തത്തെ പ്രചോദിപ്പിക്കുന്നതിന് പുരോഗതി, വെല്ലുവിളികൾ, വിജയഗാഥകൾ എന്നിവ പങ്കിടുക.
</li>
<li><span>കപ്പാസിറ്റി ബിൽഡിംഗ് : </span>അംഗങ്ങൾക്കും സമൂഹത്തിനും പരിശീലനവും പിന്തുണയും നൽകുക, അവരുടെ കഴിവുകളും സുസ്ഥിര പ്രവർത്തനങ്ങളെക്കുറിച്ചുള്ള ധാരണയും വർദ്ധിപ്പിക്കുക.
</li>
<li><span>ഇൻക്ലൂസിവിറ്റി : </span>സുസ്ഥിരതാ വെല്ലുവിളികളെ അഭിമുഖീകരിക്കുന്നതിൽ വിവിധ വീക്ഷണങ്ങളുടെ പ്രാധാന്യം തിരിച്ചറിഞ്ഞുകൊണ്ട് എല്ലാ പ്രവർത്തനങ്ങളിലും ഉൾക്കൊള്ളലും വൈവിധ്യവും ഉറപ്പാക്കുക.</li>

</ol>



</div>
        
     

        </div>

    );
}

