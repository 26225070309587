import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
          <div  id="language" >
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club">English</NavLink></a>
        </div>

            <div className="social-ma">

<h1>Role of Zha Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/role.webp"></img>
        </div>
<div className="goalpointsp">

<p>दुनिया भर में झा स्थिरता विकास क्लब की भूमिकाएं और जिम्मेदारियां आम तौर पर शामिल हैं:
</p>


<ol className="goalpoints">
    <li><span>जागरूकता को बढ़ावा देना : </span>पर्यावरण और सामाजिक मुद्दों के बारे में जागरूकता बढ़ाना, स्थायी प्रथाओं और व्यवहारों की वकालत करना।
</li>

    <li><span>शैक्षिक पहल : </span>समुदाय को सतत विकास लक्ष्यों और प्रथाओं के बारे में सूचित करने के लिए शैक्षिक कार्यक्रम, कार्यशालाएं और अभियान चलाना।</li>
    <li><span>सामुदायिक जुड़ाव : </span>स्थानीय समुदायों की जरूरतों को समझने के लिए उनके साथ जुड़ना, उन्हें टिकाऊ परियोजनाओं में शामिल करना और जिम्मेदारी की भावना को बढ़ावा देना।
</li>


    <li><span>परियोजना कार्यान्वयन : </span>पर्यावरण में सकारात्मक योगदान देने के लिए टिकाऊ परियोजनाओं, जैसे अपशिष्ट कटौती, ऊर्जा दक्षता, या संरक्षण पहल की योजना बनाएं और निष्पादित करें।

</li>
    <li><span>वकालत : </span>उन नीतियों की वकालत करना जो स्थानीय और राष्ट्रीय स्तर पर स्थिरता का समर्थन करती हैं, सकारात्मक बदलाव को प्रभावित करने के लिए अधिकारियों और अन्य संगठनों के साथ सहयोग करती हैं।



</li>
    <li><span>अनुसंधान और नवाचार : </span>टिकाऊ प्रौद्योगिकियों और नवाचारों पर अनुसंधान करना, पर्यावरणीय चुनौतियों से निपटने के नए तरीकों की खोज करना।


</li>
    <li><span>नेटवर्किंग : </span>संसाधनों का लाभ उठाने और स्थिरता पहल के प्रभाव को बढ़ाने के लिए अन्य संगठनों, व्यवसायों और सरकारी निकायों के साथ साझेदारी बनाएं।



</li>
    <li><span>निगरानी और मूल्यांकन : </span>परियोजनाओं के प्रभाव का नियमित रूप से आकलन और मूल्यांकन करें ताकि यह सुनिश्चित किया जा सके कि वे स्थिरता लक्ष्यों के साथ संरेखित हों और आवश्यकतानुसार समायोजन करें।
</li>
    <li><span>धन उगाहना : </span>स्थिरता परियोजनाओं और चल रही क्लब गतिविधियों का समर्थन करने के लिए अनुदान, दान या कार्यक्रमों के माध्यम से धन सुरक्षित करना।

</li>
    <li><span>संचार : </span>क्लब के भीतर और व्यापक समुदाय के साथ पारदर्शी संचार बनाए रखें, भागीदारी को प्रेरित करने के लिए प्रगति, चुनौतियों और सफलता की कहानियों को साझा करें।

</li>
<li><span>क्षमता निर्माण : </span>सदस्यों और समुदाय को प्रशिक्षण और सहायता प्रदान करना, उनके कौशल और टिकाऊ प्रथाओं की समझ को बढ़ाना।
</li>
<li><span>समावेशिता : </span>स्थिरता चुनौतियों से निपटने में विभिन्न दृष्टिकोणों के महत्व को पहचानते हुए, सभी गतिविधियों में समावेशिता और विविधता सुनिश्चित करें।</li>

</ol>



</div>
        
     

        </div>

    );
}

