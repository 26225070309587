import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./Paddy.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';

function Millet() {
    return(

        <div>
          
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/zhafarmerspaddyleague-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/zhafarmerspaddyleague-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/zhafarmerspaddyleague-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/zhafarmerspaddyleague-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/zhafarmerspaddyleague-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
{/* 
                <div  id="language" >
        
        <a href=""><NavLink to="/zhafarmerspaddyleague-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/zhafarmerspaddyleague-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/zhafarmerspaddyleague-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/zhafarmerspaddyleague-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/zhafarmerspaddyleague-ka">Kannada</NavLink></a>


        </div> */}
            <div className="social-main">

            <h1>Zha Farmers Paddy League</h1>
            </div>
           <section className="intro">
            <div className="paddyhero">
            <img src="../img/asian.jpg"></img>
            </div>
            <div className="titletext">
              <p>  The Zha Foundation's initiative to conduct a Farmers Paddy League among farmers in Peravurani taluk is a commendable effort. Paddy, also known as rice, is a staple crop with immense cultural and nutritional significance in India. However, in recent years, its cultivation has faced challenges, leading to a loss of traditional knowledge and the nutritional benefits it offers. The Farmers Paddy League is all set to revolutionize the world of agriculture, bringing together farmers, enthusiasts, and innovators from across the globe. Just like the IPL changed the face of cricket, the Zha Foundation's Farmers Paddy League aims to elevate paddy farming to a whole new level, with exciting tournaments and cutting-edge techniques.</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/kC8byZNt8dFH4Z1e6"> <Button variant="outline-success">Register Now</Button>{' '}</a>

    </div>

    <div>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfkUnlmPbv846wVQGKAD41SfB_JrRIsIO3DwkMcK0Wpcpvpdw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
  </section>

  <section className="organics">

    <div className="orgcon">
      <div className="orgtext">  <h3>Impacts of Non-Organic Paddy Cultivation</h3>
      <div className="side"></div>
      </div>
      <div className="orgflex">

      
           <ul>
        <h5>1.Environmental Impact:</h5>
        <li>Non-organic paddy cultivation often relies heavily on synthetic pesticides and herbicides to control pests and weeds. These chemicals can have detrimental effects on the environment by contaminating soil, water, and air The use of synthetic fertilizers can lead to soil degradation, nutrient imbalances, and decreased soil fertility over time.</li>

        <h5>2.Economic Impact:</h5>
        <li>Non-organic farming often requires higher input costs for synthetic fertilizers, pesticides, and herbicides, which can be a financial burden for farmers.</li>

        <h5>3.Health Impact:</h5>
        <li>Farmers and farmworkers may be exposed to chemical residues from pesticides and herbicides, potentially leading to health issues.</li>

        <h5>4.Carbon Footprint: </h5>
        <li>Non-organic farming practices, particularly those involving the use of synthetic fertilizers, contribute to greenhouse gas emissions, which contribute to climate change.</li>

        <h5>5.Long-Term Sustainability: </h5>
        <li>Non-organic paddy cultivation practices may not be sustainable in the long term due to soil degradation, reduced biodiversity, and increasing pest resistance to chemicals.
        </li>
        </ul>

      
       
    
    <img src="../img/ori2.jpg"></img>
    </div>
    </div>
  </section>




<div className="freepik">
<h5 className="cardtitle">Here's an overview of the key points related to the <span> Farmers Millet
League </span>initiative:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Our Mission</Card.Title>
        <Card.Text>
        At the heart of our initiative is the goal to promote and revive paddy cultivation among farmers in Peravurani taluk and beyond, across India. Paddy, often referred to as the "nutri-cereal," is renowned for its exceptional nutritional value and eco-friendly farming practices, making it a cornerstone of sustainable agriculture. </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Zha Foundation's Commitment</Card.Title>
        <Card.Text>
        We play a pivotal role in kickstarting and supporting paddy farming among our dedicated farmers. Our support includes providing essential resources, comprehensive training, and technical assistance to help farmers seamlessly integrate paddy cultivation into their farming practices.

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Bringing Back Organic Paddy Cultivation</Card.Title>
        <Card.Text>
        Our initiative core focus is to encourage organic paddy cultivation along with Indian state government and align the youngsters for part time works in farming. Create a supply chain through digital strategies for making our farmers products in global market via zha farmers & consumers call center. The advantages are multifold: improving food security, enhancing soil health, and significantly reducing water consumption compared to water-intensive organic crops and certify the organic products through ZHA foundation</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Farmers Paddy League</Card.Title>
        <Card.Text>
        We're introducing an innovative concept - the "Farmers Paddy League." By organizing farmers into various teams based on applications, and organize it through zha team and refereed by government agriculture experts. So we can aim to stimulate healthy competition and foster cooperation among our farming community. These leagues not only promote camaraderie but also instill a sense of unity among participants. We provide cash prices for first three winners and certificates to all the participants.</Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Education and Awareness</Card.Title>
        <Card.Text>
        Beyond the league, education is paramount. We believe in spreading awareness about the benefits of paddy cultivation, sustainable farming practices, and the nutritional superiority of paddy-based diets. Our mission is to inspire more farmers to actively participate in this initiative.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Market Access</Card.Title>
        <Card.Text>
        Ensuring fair market access for our farmers is a key factor in our success. We're committed to creating robust market linkages, ensuring that our farmers can sell their paddy produce at equitable prices. </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Sustainability</Card.Title>
        <Card.Text>
        Our long-term vision for paddy cultivation involves preserving traditional paddy varieties, encouraging seed saving, and promoting natural farming practices. Sustainability is not just a goal; it's a way of life.</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>Policy Support</Card.Title>
        <Card.Text>
        We're advocates for change. We work tirelessly to push for supportive policies at both local and national levels. This includes incentives, subsidies, and policy adjustments that favor paddy cultivation and consumption.</Card.Text> </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>key Ownership of <span>Farmers Paddy League:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;