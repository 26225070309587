import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
  return (
    <div>
      <div className="hmain">


     
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Rural-Primary-Health-Care-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Rural-Primary-Health-Care-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Rural-Primary-Health-Care-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Rural-Primary-Health-Care-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Rural-Primary-Health-Care-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

        <h1 id="mhead">Zha Rural Primary Healthcare</h1>

        <div className='second-mcontent'>
          <img src='../../img/healthcare.webp'></img> 
        </div>


      </div>


      <section className="visionmi">

        <div className="purpose">

          <p>At Zha Foundation, we believe in the power of accessible healthcare to transform communities. Our mission is to bridge the gap in healthcare services, particularly in rural areas, ensuring that every individual has access to quality primary healthcare.

          </p>
        </div>

        <h3>Our Commitment</h3>
        <div className="missi">
          <h4 className="toleftneed">Rural Empowerment:</h4>
          <p>At the heart of Zha Foundation's initiatives lies a commitment to empower rural communities with the tools and resources needed for better health outcomes. We recognize the unique challenges faced by individuals in remote areas and are dedicated to providing sustainable healthcare solutions.</p>

          <h4 className="toleftneed">Comprehensive Healthcare:</h4>
          <p>Our primary healthcare services encompass a wide range of medical, preventive, and wellness programs. From routine check-ups to health education, we aim to create a holistic healthcare ecosystem that addresses the diverse needs of rural populations.</p>
        </div>


        <h3>Our Approach</h3>
        <div className="missi">
          <h4 className="toleftneed">Community-Centric Care:</h4>
          <p>Zha Foundation believes in the strength of community-centric healthcare. Our programs are designed in collaboration with local communities, taking into account their specific needs, cultural nuances, and available resources. By working hand-in-hand with residents, we strive to create a healthcare model that is both inclusive and effective.</p>

          <h4 className="toleftneed">Skilled Healthcare Professionals:</h4>
          <p>We take pride in our team of dedicated healthcare professionals who are passionate about making a positive impact. Our doctors, nurses, and support staff are not only highly skilled but also committed to building lasting connections within the communities they serve.</p>
        </div>

        <h3>Our Services</h3>
        <div className="missi">
          <h4 className="toleftneed">Mobile Clinics:</h4>
          <p>Zha Foundation operates mobile clinics that reach the most remote corners, bringing healthcare directly to the doorstep of those in need. These clinics are equipped with the latest medical facilities and staffed by experienced healthcare professionals.</p>

          <h4 className="toleftneed">Health Education Programs:</h4>
          <p>Prevention is key to good health. We conduct regular health education programs to empower individuals with the knowledge needed to make informed decisions about their well-being. From hygiene practices to disease prevention, our goal is to build a foundation for healthier living.</p>

          <h4 className="toleftneed">Emergency Response:</h4>
          <p>Zha Foundation is prepared to respond swiftly to medical emergencies in rural areas. Our emergency response teams are trained to handle a range of situations, ensuring that help is never far away when it's needed the most.</p>
        </div>


      </section>
    </div>


  );
}

