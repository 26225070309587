import React from 'react';
import './Membership.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        <div  id="language" >
        
        <a href=""><NavLink to="/Membership">English</NavLink></a>

        </div>

        <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div>

<h1 id="mhead">Scaling Farmers Profitability Program - Pilot Phase</h1>
        <h3 id="mheadd">(Limited membership only 150 no’s for now admitted)</h3>

<div className='membertab'>
<section className='offer'>
            <div>
                <h1>₹600/mon</h1>
                <h1>₹3500/6mon</h1>
                <h1>₹7000/year</h1>
           </div>
         
           </section>  
        
        
        {/* <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3"
      justify
    > */}
      {/* <Tab eventKey="home" title="Farmer">
      <div className='Memberfprmcontent'>
        <div>
            <ul>
                <li>Farm property management services  </li>
                <li>Soil tests</li>
                <li>Health check of plants and trees</li>
                <li>Regular reporting on the status</li>
            </ul>
        </div>
        <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
     </div>
      </Tab> */}

      {/* <Tab eventKey="profile" title="Agent">

      <div className='Memberfprmcontent'>
        <div>
            <ul>
                <li>Marketing strategy assessments</li>
                <li>Digital marketing based on target provided</li>
                <li>New market or buyer identification</li>
                <li>Regular reporting on the status</li>
            </ul>
        </div>
        <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
     </div>
      </Tab> 
      <Tab eventKey="longer-tab" title="Supporter">*/}

<div className='membershiptype'>
             <img src='../img/Graph.png'></img>
             </div>

             
      <div className='Memberfprmcontent'>
        <div>
            {/* <h4>Under supporters membership</h4> */}
        
            <ul>
    <li>झा फाउंडेशन किसानों को कृषि में संलग्न करने, कृषि को लाभदायक बनाने और नई विपणन रणनीतियों को विकसित करने के लिए समर्पित होगा।</li>
    <li>वैश्विक भारतीयों को एकीकृत कर कृषि उत्पादों की सीधी खरीद के लिए हमारा झा कॉल सेंटर एवं सूचना केंद्र स्थापित किया जाना है।</li>
    <li>हमारे ZHA कृषि विश्लेषक किसानों के किसी भी प्रश्न, सरकारी नीतियों, नई फसल की किस्मों, पेड़-पौधों आदि पर शोध करके सुझाव देंगे कि वर्तमान बाजार स्थितियों और क्षेत्रीय जलवायु कारकों के अनुसार कौन सी फसलें किसानों के लिए लाभदायक हैं।</li>
    <li>हमारा झा अनुसंधान विश्लेषक विपणन सेवाएं और पूर्वानुमान सेवाएं प्रदान करने के लिए किसान उत्पादक संगठन या कृषि के किसी भी संघ का समर्थन करेगा।</li>
    <li>झा एक्टिव बोर्ड हमारे झा सामाजिक कार्यों, जोखिमों और जरूरतों को प्रदर्शित करने के लिए महीने में एक बार एक वेब मीटिंग आयोजित करेगा, हम झा अनुसंधान टीम के आधार पर भारत और दुनिया भर में विशिष्ट प्रकार के भोजन की मांग का निर्धारण और प्रकाशन करेंगे।</li>
    <li>झा के सक्रिय बोर्ड सदस्य मासिक तकनीकी कृषि विकास वेबिनार की सुविधा प्रदान करेंगे, जिसका उद्देश्य दुनिया भर से प्रौद्योगिकीविदों, उद्यमियों और कृषि पेशेवरों को खाद्य स्थिरता पर चर्चा करने के लिए लाना है।</li>
    <li>झा टीम कॉलेज के छात्रों को स्थायी कौशल विकास शिक्षा के लिए इंटर्नशिप तकनीकी प्रशिक्षण भी प्रदान करती है जो मासिक रूप से कम लागत पर दी जाती है जो ग्रामीण क्षेत्रों में भी हमारे मानव संसाधनों के निर्माण में मदद करती है। छात्रों की इंटर्नशिप के लिए माता-पिता कृषि सदस्यता प्राप्त कर सकते हैं।</li>
    <li>झा टीम किसानों की डेल्टा जिलों की उपज को प्रदर्शित करने के लिए वार्षिक कृषि मेला आयोजित करके किसानों की मदद करेगी। बेहतर ब्रांडिंग और मार्केटिंग के लिए हमारे झा सोशल टेक पेशेवर और व्यवसायी लोग इस प्रदर्शनी में भाग लेंगे (फाउंडेशन में 1000 सदस्यों को पार करने के बाद, हम यह काम कर सकते हैं)।</li>
    <li>हमारा झा कृषि परामर्श किसानों को उनके कृषि उत्पादों की गुणवत्ता की जांच करने में मदद करेगा और फिर उनके उत्पादों की ब्रांडिंग करने में मदद करेगा, ताकि खाद्य और सुरक्षा विभाग के माध्यम से किसी भी प्रकार की कृषि उपज की ब्रांडिंग की स्थिति बनाई जा सके (यह बिंदु सक्रिय होगा) भविष्य।</li>
    <li>झा सदस्यता कार्यक्रमों को सुविधाजनक बनाने और प्रबंधित करने के लिए एक पारदर्शी और पेशेवर सेटअप रखता है और कृषि अनुसंधान और विपणन उद्देश्य, किसानों के मुनाफे को बढ़ाने आदि के लिए सदस्यता भुगतान का उपयोग करता है। इसमें अनुसंधान कॉल सेंटर रखरखाव लागत, वेतन भी शामिल है।</li>
            </ul>
        </div>
        {/* <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div> */}
     </div>
      {/* </Tab>
     
    </Tabs> */}
    </div>
    <div className='membercontent'>
    <h2>मिट्टी के बेटे और बेटी <span className='green'>झा किसान समर्थक सदस्यता </span>पहल</h2> 
        <div className='first-mcontent'>
            <div>
           <h4 id='member-benifits'>सदस्य लाभों में शामिल हैं:                                                                                             </h4>
            <ul>
              <li>आयोजित होने पर वार्षिक कृषि प्रदर्शनी का हिस्सा बन सकते हैं, नई फसल परीक्षण, परिपक्वता पर मूल्यांकन, नए बाजार आविष्कार, नए तकनीकी समाधान आदि पर अनुसंधान एवं विकास का हिस्सा बन सकते हैं।</li>
              <li>नारियल के खेत वाले क्षेत्रों में कैम्पिंग का लाभ उठाया जा सकता है</li>
              <li>किसानों के स्थायित्व कार्यक्रमों के लिए विभिन्न प्रगतिशील योजनाओं के साक्षी बनें
</li>
              <li>एक संरक्षक बनकर उपनगरीय और ग्रामीण छात्रों की स्थायी शिक्षा में संलग्न रहें
</li>
              <li>झा के सलाहकार बोर्ड के सदस्य बनें
</li>
              <li>साल में एक बार, अधिकतम चार सदस्यों वाले परिवार नारियल के खेतों में पंप सेट मोटर स्नान आदि जैसी सुविधाओं वाले तंबू में मुफ्त में रह सकते हैं (फ़ीचर लक्ष्य)।
</li>
              <li>खेती का हिस्सा हो सकते हैं और खेत की फसल की उपज के बाद, हम उपज का 30-50% अनाथालयों या वृद्धाश्रमों में साझा करेंगे।
</li>
              <li>हम झा किसान समर्थक सदस्य के रूप में, इस दुनिया में साझा और जीवित, टिकाऊ अवधारणा का हिस्सा होंगे
</li>
            </ul>
            </div>
            <div>
                <img src='../../img/member1.png'></img>
            </div>
        </div>

        <div className='bankk'>
    <img src='../img/bankdetails.png'></img>

    </div>
    
        <h2>के बेटे और बेटी के बारे में पहल एक स्थिरता का कारण है  <span className='green'>भारतीय मिट्टी </span></h2>      
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/member2.png'></img>
            </div>
            <div>
           <p>झा किसान समर्थकों की सदस्यता मुख्य रूप से किसानों के जीवन में स्थिरता पैदा करने के उद्देश्य से है। आप प्रौद्योगिकी संसाधनों की कमी, कृषि व्यवसाय में स्केलेबिलिटी की कमी, कम निर्यात कनेक्शन, कृषि के कोई अनुसंधान और विकास नहीं होने पर किसानों के सामने आने वाली चुनौतियों के बारे में भी जान सकते हैं। प्रथाएं, व्यवहार्यता अध्ययन आदि .. इसलिए किसानों के पास अंतरराष्ट्रीय बाजारों आदि के बारे में वैश्विक दृष्टिकोण नहीं है और यहां तक ​​​​कि अगर वे प्रत्येक किसान के लिए अनुसंधान और विकास केंद्र बनाना चाहते हैं, तो इसकी भारी लागत होगी, इसलिए ZHA फाउंडेशन चैरिटेबल ट्रस्ट अपने को बढ़ाना चाहता है प्रौद्योगिकी नेताओं, किसानों और कृषि तकनीक विश्लेषकों के माध्यम से विभिन्न शोधकर्ताओं के माध्यम से कृषि व्यवसाय में स्केलेबिलिटी।




            </p>
<p>
किसानों के कृषि व्यवसाय को टिकाऊ लाभप्रद बनाने की जिम्मेदारी लेने के लिए इस पहल को "भारतीय मिट्टी का बेटा और बेटी" नाम दिया गया है। अंततः आपको एहसास होगा कि मिट्टी के बेटे और बेटी की यह पहल दुनिया भर में किसानों के बच्चों की अगली पीढ़ी को हमारे दृष्टिकोण के साथ जोड़ना चाहती है, ताकि वे निरंतर कृषि प्रथाओं के लिए कृषि मानसिकता से भी जुड़े रहें। यह दृष्टिकोण हमें अगले स्तर का डिजिटल कृषि समुदाय बनाने में मदद करता है, भले ही उनके पास कृषि भूमि न हो। हमारा झा फाउंडेशन बोर्ड इस सेवा को मुफ्त में उपलब्ध कराना चाहता है, हालांकि इसे अनुसंधान और विकास और तकनीकी प्रणालियों के प्रबंधन जैसी विभिन्न गतिविधियों को पूरा करने के लिए अधिक पेशेवरों को नियुक्त करने की आवश्यकता है।



</p>
<p>
झा किसानों को कृषि तकनीकी प्रथाओं में लाकर उन्हें आगे बढ़ाने और नए बाजारों की व्यवहार्यता अध्ययन के माध्यम से उनकी आपूर्ति श्रृंखला को बढ़ाने, डिजिटल मार्केटिंग दृष्टिकोण आदि के माध्यम से उनकी उपज को बढ़ावा देने पर ध्यान केंद्रित कर रहे हैं। इसलिए सदस्यता शुल्क एक गैर-लाभकारी संगठन को झा कर्मचारियों के वेतन का भुगतान करने में मदद कर सकता है।


</p>
<p>यह पहल हमें स्थायी लाभ के लिए किसानों के जीवन के आसपास एक स्थायी कृषि प्रौद्योगिकी प्रणाली बनाने में मदद करेगी और दुनिया को भविष्य के खाद्य संकट से बचाने में मदद करेगी।

</p>
           </div>
        </div>
    </div>
    </div>
);
}