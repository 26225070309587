import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">


            <Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Disaster-Relief-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

                <h1 id="mhead">Disaster Relief Efforts by Zha Foundation</h1>

                <div className='second-mcontent'>
                    <img src='../../img/disasterrelief.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>Welcome to Zha Foundation, where we stand as a beacon of support for those affected by disasters such as earthquakes, storms, floods, and more. Our commitment to providing swift and effective disaster relief is rooted in the belief that no one should face the aftermath of a crisis alone.
                    </p>
                </div>

                <h3>Our Mission</h3>
                <div className="missi">
                    <h4 className="toleftneed">Rapid Response, Lasting Impact:</h4>
                    <p>At Zha Foundation, we understand the urgency and gravity of disaster situations. Our mission is to provide rapid response and support to communities affected by natural disasters, offering immediate relief while also working towards long-term recovery and resilience.</p>


                </div>


                <h3>Our Approach</h3>
                <div className="missi">
                    <h4 className="toleftneed">Comprehensive Disaster Relief:</h4>
                    <p>Zha Foundation takes a comprehensive approach to disaster relief. From the initial response phase, where we provide emergency shelter, food, and medical assistance, to the recovery phase, where we focus on rebuilding communities and restoring livelihoods, our teams are dedicated to ensuring that affected individuals receive the support they need at every stage.</p>

                    <h4 className="toleftneed">Community-Centered Solutions:</h4>
                    <p>We believe in the strength of communities. Our disaster relief efforts are designed in collaboration with local residents, respecting their unique needs, cultural context, and knowledge of the terrain. By working closely with communities, we aim to create sustainable solutions that empower individuals to rebuild their lives.</p>
                </div>

                <h3>Our Initiatives</h3>
                <div className="missi">
                    <h4 className="toleftneed">Emergency Shelter and Aid:</h4>
                    <p>Zha Foundation swiftly mobilizes to provide emergency shelter, food, clean water, and medical aid to those affected by disasters. Our teams work around the clock to ensure that basic needs are met in the immediate aftermath of a crisis.</p>

                    <h4 className="toleftneed">Rebuilding Lives:</h4>
                    <p>Beyond the initial response, we focus on rebuilding lives. This includes reconstruction efforts, providing resources for livelihood restoration, and supporting education and healthcare services to help communities regain a sense of normalcy.</p>

                    <h4 className="toleftneed">Community Resilience Programs:</h4>
                    <p>Zha Foundation invests in programs that enhance community resilience. This involves training local residents in disaster preparedness, early warning systems, and sustainable practices to mitigate the impact of future disasters.</p>
                </div>


            </section>
        </div>


    );
}

