import React from "react";
import "./Contact.css";

export default function Contact(){
    return(
        <div>
          <section className='contactuss'>
            
      
  <div className="Contact">
      
       <div className="contactaddress">
        <h1>Contact Us</h1>

        <p>No.77D,Sethu Road,</p>
        <p>  KK Nagar,Peravurani,</p>
        <p> Thanjavur,Tamilnadu, </p>
        <p> India-614804.</p>
        <div className="social">
  <div className="socialicon">
    <a href="https://www.facebook.com/ZHAFoundation1/">
    <i className="fa-brands fa-facebook-f" />
    </a>
  </div>
  <div className="socialicon">
  <a href="https://instagram.com/zha_foundation?igshid=MmU2YjMzNjRlOQ==">
    <i className="fa-brands fa-instagram" />
    </a>
  </div>
  <div className="socialicon">
  <a href="https://www.youtube.com/@zhafoundation">
    <i className="fa-brands fa-youtube" />
    </a>
  </div>
  <div className="socialicon">
      <a href="https://www.linkedin.com/company/zha-foundation-charitable-trust/">
      <i className="fa-brands fa-linkedin" />
          </a>
        
      </div>
</div>
        
        </div>
        <iframe className="ifrmarecontact" src="https://docs.google.com/forms/d/e/1FAIpQLSe-0t7aSbaB5AY-PnzR86rlfhSaT1ncfncVqFH8voiCi7ff_g/viewform?embedded=true" width="640" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    
        
       </div>

       
    </section>



       




            </div>

            


    );
}


