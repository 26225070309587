import React from "react";
// import './internship.css';
// import "./Transformation.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
          <div  id="language" >
        <a href=""><NavLink to="/Role-of-Zha-Sustainability-Club">English</NavLink></a>
        </div>

            <div className="social-ma">

<h1>Role of Zha Sustainability Club</h1>
</div>


<div className="internship">
        <img src="../img/role.webp"></img>
        </div>
<div className="goalpointsp">

<p>ప్రపంచవ్యాప్తంగా ఝా సస్టైనబిలిటీ డెవలప్‌మెంట్ క్లబ్ యొక్క పాత్రలు మరియు బాధ్యతలు సాధారణంగా వీటిని కలిగి ఉంటాయి:
</p>


<ol className="goalpoints">
    <li><span>అవగాహనను ప్రోత్సహించడం : </span>పర్యావరణ మరియు సామాజిక సమస్యలపై అవగాహన పెంచడం, స్థిరమైన పద్ధతులు మరియు ప్రవర్తనల కోసం వాదించడం.
</li>

    <li><span>విద్యా కార్యక్రమాలు : </span>స్థిరమైన అభివృద్ధి లక్ష్యాలు మరియు అభ్యాసాల గురించి సమాజానికి తెలియజేయడానికి విద్యా కార్యక్రమాలు, వర్క్‌షాప్‌లు మరియు ప్రచారాలను నిర్వహించండి.</li>
    <li><span>కమ్యూనిటీ ఎంగేజ్‌మెంట్ : </span>స్థానిక కమ్యూనిటీలతో వారి అవసరాలను అర్థం చేసుకోవడానికి, స్థిరమైన ప్రాజెక్ట్‌లలో వారిని పాల్గొనడానికి మరియు బాధ్యతాయుత భావాన్ని పెంపొందించడానికి వారితో నిమగ్నమై ఉండండి.
</li>


    <li><span>ప్రాజెక్ట్ అమలు : </span>పర్యావరణానికి సానుకూలంగా దోహదపడేలా వ్యర్థాల తగ్గింపు, శక్తి సామర్థ్యం లేదా పరిరక్షణ కార్యక్రమాలు వంటి స్థిరమైన ప్రాజెక్టులను ప్లాన్ చేయండి మరియు అమలు చేయండి.

</li>
    <li><span>న్యాయవాదం : </span>సానుకూల మార్పును ప్రభావితం చేయడానికి అధికారులు మరియు ఇతర సంస్థలతో సహకరించడం, స్థానిక మరియు జాతీయ స్థాయిలలో స్థిరత్వానికి మద్దతు ఇచ్చే విధానాలకు న్యాయవాది.



</li>
    <li><span>పరిశోధన మరియు ఆవిష్కరణ : </span>పర్యావరణ సవాళ్లను పరిష్కరించడానికి కొత్త మార్గాలను అన్వేషించడం, స్థిరమైన సాంకేతికతలు మరియు ఆవిష్కరణలపై పరిశోధన నిర్వహించడం.


</li>
    <li><span>నెట్‌వర్కింగ్ : </span>ఇతర సంస్థలు, వ్యాపారాలు మరియు ప్రభుత్వ సంస్థలతో భాగస్వామ్యాలను నిర్మించడం ద్వారా వనరులను ఉపయోగించుకోవడం మరియు సుస్థిరత కార్యక్రమాల ప్రభావాన్ని మెరుగుపరచడం.


</li>
    <li><span>పర్యవేక్షణ మరియు మూల్యాంకనం : </span>ప్రాజెక్ట్‌లు స్థిరత్వ లక్ష్యాలకు అనుగుణంగా ఉన్నాయని నిర్ధారించుకోవడానికి మరియు అవసరమైన విధంగా సర్దుబాట్లు చేయడానికి వాటి ప్రభావాన్ని క్రమం తప్పకుండా అంచనా వేయండి మరియు మూల్యాంకనం చేయండి.

</li>
    <li><span>నిధుల సేకరణ : </span>సుస్థిరత ప్రాజెక్ట్‌లు మరియు కొనసాగుతున్న క్లబ్ కార్యకలాపాలకు మద్దతుగా గ్రాంట్లు, విరాళాలు లేదా ఈవెంట్‌ల ద్వారా సురక్షిత నిధులను పొందండి.

</li>
    <li><span>కమ్యూనికేషన్ : </span>క్లబ్‌లో మరియు విస్తృత కమ్యూనిటీతో పారదర్శక సంభాషణను నిర్వహించండి, ప్రమేయాన్ని ప్రేరేపించడానికి పురోగతి, సవాళ్లు మరియు విజయగాథలను పంచుకోండి.
</li>
<li><span>కెపాసిటీ బిల్డింగ్ : </span>సభ్యులకు మరియు సంఘానికి శిక్షణ మరియు మద్దతును అందించడం, వారి నైపుణ్యాలు మరియు స్థిరమైన అభ్యాసాల గురించి అవగాహన పెంచడం.
</li>
<li><span>చేరిక : </span>సుస్థిరత సవాళ్లను ఎదుర్కోవడంలో వివిధ దృక్కోణాల యొక్క ప్రాముఖ్యతను గుర్తిస్తూ, అన్ని కార్యకలాపాలలో చేరిక మరియు వైవిధ్యాన్ని నిర్ధారించండి.</li>

</ol>



</div>
        
     

        </div>

    );
}

