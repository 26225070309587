import React from 'react';
import './Membership.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavLink } from "react-router-dom";
export default function Membership(){
return(
    <div className='Membership'>
        <div  id="language" >
        
        <a href=""><NavLink to="/Membership">English</NavLink></a>

        </div>

        <div className='customercare'>
   <a href='https://wa.link/nc053c'> <img src='../img/customercare.png'></img></a>
</div>

<h1 id="mhead">Scaling Farmers Profitability Program - Pilot Phase</h1>
        <h3 id="mheadd">(Limited membership only 150 no’s for now admitted)</h3>

<div className='membertab'>
<section className='offer'>
            <div>
                <h1>₹600/mon</h1>
                <h1>₹3500/6mon</h1>
                <h1>₹7000/year</h1>
           </div>
          
           </section>  
        
        
        {/* <Tabs
      defaultActiveKey="profile"
      id="justify-tab-example"
      className="mb-3"
      justify
    > */}
      {/* <Tab eventKey="home" title="Farmer">
      <div className='Memberfprmcontent'>
        <div>
            <ul>
                <li>Farm property management services  </li>
                <li>Soil tests</li>
                <li>Health check of plants and trees</li>
                <li>Regular reporting on the status</li>
            </ul>
        </div>
        <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
     </div>
      </Tab> */}

      {/* <Tab eventKey="profile" title="Agent">

      <div className='Memberfprmcontent'>
        <div>
            <ul>
                <li>Marketing strategy assessments</li>
                <li>Digital marketing based on target provided</li>
                <li>New market or buyer identification</li>
                <li>Regular reporting on the status</li>
            </ul>
        </div>
        <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="500" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
     </div>
      </Tab> 
      <Tab eventKey="longer-tab" title="Supporter">*/}
       <div className='membershiptype'>
             <img src='../img/Graph.png'></img>
             </div>

             
      <div className='Memberfprmcontent'>
        <div>
            {/* <h4>Under supporters membership</h4> */}
           
            <ul>
    <li>వ్యవసాయంలో రైతులను నిమగ్నం చేయడానికి, వ్యవసాయాన్ని లాభదాయకంగా మార్చడానికి మరియు కొత్త మార్కెటింగ్ వ్యూహాలను అభివృద్ధి చేయడానికి ఝా ఫౌండేషన్ అంకితం చేయబడుతుంది.</li>
    <li>మా ఝా కాల్ సెంటర్ మరియు సమాచార కేంద్రం ప్రపంచ భారతీయులను ఏకీకృతం చేయడం ద్వారా వ్యవసాయ ఉత్పత్తుల ప్రత్యక్ష సేకరణ కోసం ఏర్పాటు చేయబడుతుంది.</li>
    <li>మా ZHA వ్యవసాయ విశ్లేషకుడు రైతులకు ప్రస్తుత మార్కెట్ పరిస్థితులు మరియు ప్రాంతీయ వాతావరణ కారకాల ప్రకారం ఏయే పంటలు లాభదాయకంగా ఉంటాయో ఏవైనా ప్రశ్నలు, ప్రభుత్వ విధానాలు, కొత్త పంట రకాలు, చెట్లు, మొక్కలు మొదలైన వాటిపై పరిశోధన చేయడం ద్వారా రైతులకు సూచనలను అందిస్తారు.</li>
    <li>మార్కెటింగ్ సేవలు మరియు ఊహాజనిత సేవలను అందించడానికి మా ఝా పరిశోధన విశ్లేషకుడు రైతుల ఉత్పత్తిదారుల సంస్థ లేదా వ్యవసాయానికి సంబంధించిన ఏదైనా సంఘానికి మద్దతు ఇస్తారు.</li>
    <li>Zha క్రియాశీల బోర్డు సభ్యులు నెలవారీ సాంకేతిక వ్యవసాయ అభివృద్ధి వెబ్‌నార్‌ను సులభతరం చేస్తారు, ఇది ఆహార స్థిరత్వం గురించి చర్చించడానికి ప్రపంచవ్యాప్తంగా ఉన్న సాంకేతిక నిపుణులు, వ్యవస్థాపకులు మరియు వ్యవసాయ నిపుణులను తీసుకురావాలని లక్ష్యంగా పెట్టుకుంది.</li>
    <li>ఝా యాక్టివ్ బోర్డ్ మా ఝా సామాజిక పనులు, రిస్క్‌లు & అవసరాలను ప్రదర్శించడానికి నెలకు ఒకసారి వెబ్ సమావేశాన్ని నిర్వహిస్తుంది, మేము ఝా పరిశోధనా బృందం ఆధారంగా భారతదేశం మరియు ప్రపంచవ్యాప్తంగా నిర్దిష్ట ఆహార రకాన్ని గుర్తించి మరియు ప్రచురిస్తాము.</li>
    <li>ఝా బృందం కళాశాల విద్యార్థులకు స్థిరమైన నైపుణ్యాభివృద్ధి విద్య కోసం ఇంటర్న్‌షిప్ సాంకేతిక శిక్షణను అందజేస్తుంది, ఇది గ్రామీణ ప్రాంతాల్లో కూడా మన భారతదేశంలోని మానవ వనరులను నిర్మించడంలో సహాయపడుతుంది. విద్యార్థుల ఇంటర్న్‌షిప్ కోసం, తల్లిదండ్రులు వ్యవసాయ సభ్యత్వాన్ని పొందవచ్చు.</li>
    <li>రైతుల డెల్టా జిల్లాల ఉత్పత్తులను ప్రదర్శించడానికి వార్షిక వ్యవసాయ మేళాను నిర్వహించడం ద్వారా ఝా బృందం రైతులకు సహాయం చేస్తుంది. మా ఝా సోషల్ టెక్ నిపుణులు మరియు వ్యాపార వ్యక్తులు మెరుగైన బ్రాండింగ్ కోసం మరియు మార్కెటింగ్ కోసం ఈ ప్రదర్శనలో పాల్గొంటారు (ఫౌండేషన్‌లో 1000 మంది సభ్యులను దాటిన తర్వాత, మేము దీన్ని పూర్తి చేయవచ్చు).</li>
    <li>మా ఝా అగ్రి కన్సల్టింగ్ రైతులకు వారి వ్యవసాయ ఉత్పత్తుల నాణ్యతను తనిఖీ చేయడంలో సహాయం చేస్తుంది మరియు వారి ఉత్పత్తులను బ్రాండ్ చేయడంలో సహాయపడుతుంది, తద్వారా ఆహార మరియు భద్రతా విభాగం ద్వారా ఏదైనా రకమైన వ్యవసాయ ఉత్పత్తుల బ్రాండింగ్‌ను ఉంచడానికి (ఈ అంశం చురుకుగా ఉంటుంది భవిష్యత్తు).</li>
    <li>Zha సభ్యత్వ కార్యక్రమాలను సులభతరం చేయడానికి మరియు నిర్వహించడానికి పారదర్శకమైన మరియు వృత్తిపరమైన సెటప్‌ను నిర్వహిస్తుంది మరియు వ్యవసాయ పరిశోధన మరియు మార్కెటింగ్ ప్రయోజనం కోసం సభ్యత్వ చెల్లింపులను ఉపయోగిస్తుంది, రైతు లాభాలను స్కేలింగ్ చేయడం మొదలైనవి. ఇది పరిశోధన కాల్ సెంటర్ నిర్వహణ ఖర్చులు, జీతాలు కూడా కవర్ చేస్తుంది.</li>
   

            </ul>
        </div>
        {/* <div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScdbAq1z14Ph5sR4g6Wa-xgtq7EBgP0XUsp4RVbsb6NxePWkQ/viewform?embedded=true" width="500" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div> */}
     </div>
      {/* </Tab>
     
    </Tabs> */}
    </div>
    <div className='membercontent'>
    <h2>కుమారుడు & కుమార్తె సభ్యత్వం చొరవ
 <span className='green'>నేల ఝా రైతుల మద్దతుదారుల </span></h2> 
        <div className='first-mcontent'>
            <div>
           <h4 id='member-benifits'>సభ్యుల ప్రయోజనాలు:                                                                                             </h4>
            <ul>
               <li>వార్షిక వ్యవసాయ ఎక్స్‌పోలో భాగంగా ఉండవచ్చు, నిర్వహించినప్పుడు, కొత్త పంట పరీక్ష, పరిపక్వతపై అంచనా, కొత్త మార్కెట్ ఆవిష్కరణలు, కొత్త సాంకేతిక పరిష్కారాలు మొదలైన వాటిపై R&Dలో భాగం కావచ్చు.
</li>
               <li>కొబ్బరి పొలం ప్రాంతాలలో క్యాంపింగ్ పొందవచ్చు
</li>
               <li>రైతుల సుస్థిరత కార్యక్రమాల కోసం వివిధ ప్రగతిశీల ప్రణాళికలను సాక్షి
</li>
               <li>గురువుగా ఉండటం ద్వారా సబర్బన్ మరియు గ్రామీణ విద్యార్థుల స్థిరమైన విద్యలో పాల్గొనండి
</li>
               <li>ఝా యొక్క సలహా మండలి సభ్యునిగా ఉండండి
</li>
               <li>సంవత్సరానికి ఒకసారి, నలుగురు సభ్యుల కుటుంబాలు కొబ్బరి పొలాలలో పంప్ సెట్ మోటార్ బాత్‌లు మొదలైన సౌకర్యాలతో కూడిన టెంట్‌లలో ఉచితంగా ఉండగలరు (ఫీచర్ గోల్).
</li>
               <li>సాగులో భాగం కావచ్చు & వ్యవసాయ పంటల దిగుబడి తర్వాత, మేము 30-50% ఉత్పత్తులను అనాథాశ్రమాలు లేదా వృద్ధాశ్రమాలకు పంచుకుంటాము.
</li>
               <li>మేము ఝా రైతుల మద్దతుదారుల సభ్యునిగా, ఈ ప్రపంచంలో భాగస్వామ్యం మరియు జీవించే, స్థిరమైన భావనలో భాగం అవుతాము
</li>
            </ul>
            </div>
            <div>
                <img src='../../img/member1.png'></img>
            </div>
        </div>

        <div className='bankk'>
    <img src='../img/bankdetails.png'></img>

    </div>
        <h2>యొక్క కుమారుడు & కుమార్తె గురించిన చొరవ సుస్థిరతకు కారణం  <span className='green'>భారత నేల </span></h2>      
        <div className='second-mcontent'>
           
            <div>
                <img src='../../img/member2.png'></img>
            </div>
            <div>
           <p>Zha రైతుల మద్దతుదారుల సభ్యత్వం ప్రధానంగా రైతుల జీవితంలో సుస్థిరతను సృష్టించే ఉద్దేశ్యంతో ఉంటుంది. సాంకేతిక వనరుల కొరత, వ్యవసాయ వ్యాపారంలో స్కేలబిలిటీ లేకపోవడం, తక్కువ ఎగుమతి కనెక్షన్లు, వ్యవసాయంలో పరిశోధన మరియు అభివృద్ధి లేని సవాళ్ల గురించి కూడా మీకు తెలుసు. అభ్యాసాలు, సాధ్యాసాధ్యాల అధ్యయనాలు మొదలైనవి.. అందువల్ల రైతులకు అంతర్జాతీయ మార్కెట్లు మొదలైన వాటిపై ప్రపంచ దృష్టి లేదు మరియు వారు ప్రతి రైతు కోసం పరిశోధన మరియు అభివృద్ధి కేంద్రాన్ని సృష్టించాలనుకున్నా, అది భారీగా ఖర్చు అవుతుంది, కాబట్టి ZHA ఫౌండేషన్ ఛారిటబుల్ ట్రస్ట్ వారిని మెరుగుపరచాలనుకుంటోంది. సాంకేతిక నాయకులు, రైతులు మరియు అగ్రి టెక్ విశ్లేషకుల ద్వారా వివిధ పరిశోధకుల ద్వారా వ్యవసాయ వ్యాపారంలో స్కేలబిలిటీ.




            </p>
<p>
సుస్థిరమైన లాభదాయకత కోసం రైతుల వ్యవసాయ వ్యాపారాన్ని చేయడానికి బాధ్యత వహించడానికి ఈ చొరవకు "భారత నేల యొక్క కుమారుడు మరియు కుమార్తె" అని పేరు పెట్టారు. మట్టి చొరవ యొక్క ఈ కుమారుడు & కుమార్తె ప్రపంచవ్యాప్తంగా ఉన్న తరువాతి తరం రైతుల పిల్లలను మన దృష్టితో అనుసంధానించాలని కోరుకుంటున్నారని చివరికి మీరు గ్రహిస్తారు, కాబట్టి వారు నిరంతర వ్యవసాయ పద్ధతుల కోసం వ్యవసాయ ఆలోచనలతో కూడా అనుసంధానించబడ్డారు. వారికి వ్యవసాయ భూమి లేకపోయినా, డిజిటల్ అగ్రికల్చర్ కమ్యూనిటీ యొక్క తదుపరి స్థాయిని రూపొందించడంలో ఈ విధానం మాకు సహాయపడుతుంది. మా ఝా ఫౌండేషన్ బోర్డు ఈ సేవను ఉచితంగా సులభతరం చేయాలనుకుంటోంది, అయితే పరిశోధన మరియు అభివృద్ధి మరియు సాంకేతిక వ్యవస్థలను నిర్వహించడం వంటి వివిధ కార్యకలాపాలను నిర్వహించడానికి మరింత మంది నిపుణులను నియమించుకోవడం అవసరం.



</p>
<p>
ఝా రైతులను అగ్రి టెక్ పద్ధతుల్లోకి తీసుకురావడం మరియు కొత్త మార్కెట్ల సాధ్యాసాధ్యాల అధ్యయనాల ద్వారా వారి సరఫరా గొలుసును మెరుగుపరచడం, డిజిటల్ మార్కెటింగ్ విధానాల ద్వారా వారి ఉత్పత్తులను ప్రోత్సహించడం మొదలైన వాటిపై దృష్టి సారిస్తున్నారు. అందువల్ల సభ్యత్వ రుసుము లాభాపేక్ష లేని సంస్థకు ఝా ఉద్యోగుల జీతం చెల్లించడానికి సహాయపడుతుంది.


</p>
<p>ఈ చొరవ రైతుల జీవితాల చుట్టూ స్థిరమైన లాభాల కోసం స్థిరమైన వ్యవసాయ సాంకేతిక వ్యవస్థలను నిర్మించడంలో మాకు సహాయపడుతుంది మరియు భవిష్యత్తులో ఆహార సంక్షోభం నుండి ప్రపంచానికి సహాయం చేస్తుంది.

 </p>
           </div>
        </div>
    </div>
    </div>
);
}