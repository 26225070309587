import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./millet.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Millet() {
    return(

        <div>
                <div  id="language" >
        
               
                <a href=""><NavLink to="/zhafarmersmilletleague">English</NavLink></a>



        </div>
            <div className="social-main">

            <h1>Zha Farmers Millet League</h1>
            </div>
           <section className="intro">
            <div className="heroim">
            <img src="../img/farme.jpg"></img>
            </div>
            <div className="titletext">
              <p> ఝా ఫౌండేషన్ యొక్క చొరవ
మధ్య రైతుల మిల్లెట్ లీగ్ నిర్వహించండి
పెరవూరని తాలూకాలోని రైతులు a
ప్రశంసనీయమైన ప్రయత్నం. మినుములు ఎక్కువగా ఉంటాయి
పోషకమైన మరియు కరువు నిరోధక పంటలు
అవి భారతీయులలో అంతర్భాగంగా ఉన్నాయి
శతాబ్దాలుగా వ్యవసాయం. అయితే, లో
ఇటీవలి సంవత్సరాలలో, వారి సాగు ఉంది
తిరస్కరించబడింది, సంప్రదాయ నష్టానికి దారితీసింది
జ్ఞానం మరియు పోషక ప్రయోజనాలు
ప్రపంచవ్యాప్తంగా ఉన్న రైతులు, ఔత్సాహికులు మరియు ఆవిష్కర్తలను ఏకతాటిపైకి తీసుకువస్తూ వ్యవసాయ ప్రపంచంలో విప్లవాత్మక మార్పులు తీసుకురావడానికి ఫార్మర్ మిల్లెట్ లీగ్ సిద్ధంగా ఉంది. ఐపీఎల్ క్రికెట్ రూపురేఖలను మార్చినట్లే, ఝా ఫౌండేషన్ యొక్క రైతు మిల్లెట్ లీగ్ మిల్లెట్ సేద్యాన్ని ఉన్నతీకరించాలని లక్ష్యంగా పెట్టుకుంది. ఉత్తేజకరమైన టోర్నమెంట్‌లు మరియు అత్యాధునిక సాంకేతికతలతో సరికొత్త స్థాయికి.</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/L59Gs4kPz14YRQZn6"> <Button variant="outline-success">ఇప్పుడు నమోదు చేసుకోండి</Button>{' '}</a>

    </div>

    <div>
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLScZeSA0M303pXW7SzmUA_BOCbWv3TXX_vDiZPR-xUudKvyImw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
</div>
  </section>
<div className="freepik">
<h5 className="cardtitle">దీనికి సంబంధించిన కీలక అంశాల యొక్క అవలోకనం ఇక్కడ ఉంది<span>రైతులు మిల్లెట్
లీగ్</span>చొరవ:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>మా మిషన్</Card.Title>
        <Card.Text>
        పెరవురాని తాలూకాలో మరియు భారతదేశం అంతటా రైతులలో మినుము సాగును ప్రోత్సహించడం మరియు పునరుద్ధరించడం మా చొరవ యొక్క ప్రధాన లక్ష్యం. మిల్లెట్స్, తరచుగా "న్యూట్రి-తృణధాన్యాలు" అని పిలుస్తారు, వాటి అసాధారణమైన పోషక విలువలు మరియు పర్యావరణ అనుకూల వ్యవసాయ పద్ధతులకు ప్రసిద్ధి చెందాయి, వాటిని స్థిరమైన వ్యవసాయానికి మూలస్తంభంగా మారుస్తుంది.
        </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>ఝా ఫౌండేషన్ యొక్క నిబద్ధత</Card.Title>
        <Card.Text>
        మా అంకితభావం గల రైతులలో మిల్లెట్ వ్యవసాయాన్ని కిక్‌స్టార్ట్ చేయడంలో మరియు మద్దతు ఇవ్వడంలో మేము కీలక పాత్ర పోషిస్తాము. మా మద్దతులో అవసరమైన వనరులు, సమగ్ర శిక్షణ మరియు సాంకేతిక సహాయం అందించడం ద్వారా రైతులు తమ వ్యవసాయ పద్ధతుల్లో మిల్లెట్ సాగును సజావుగా ఏకీకృతం చేయడంలో సహాయపడతారు.
        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>సేంద్రీయ మిల్లెట్ సాగును తిరిగి తీసుకురండి</Card.Title>
        <Card.Text>
        మా చొరవ ప్రధాన దృష్టి భారత రాష్ట్ర ప్రభుత్వంతో పాటు సేంద్రీయ మిల్లెట్ సాగును ప్రోత్సహించడం మరియు వ్యవసాయంలో పార్ట్ టైమ్ పనుల కోసం యువకులను సమం చేయడం. ఝా రైతులు & వినియోగదారుల కాల్ సెంటర్ ద్వారా ప్రపంచ మార్కెట్‌లో మా రైతుల ఉత్పత్తులను తయారు చేయడానికి డిజిటల్ వ్యూహాల ద్వారా సరఫరా గొలుసును సృష్టించండి. ప్రయోజనాలు చాలా రెట్లు ఉన్నాయి: ఆహార భద్రతను మెరుగుపరచడం, నేల ఆరోగ్యాన్ని మెరుగుపరచడం మరియు నీటిని ఎక్కువగా వినియోగించే సేంద్రీయ పంటలతో పోలిస్తే నీటి వినియోగాన్ని గణనీయంగా తగ్గించడం మరియు ZHA ఫౌండేషన్ ద్వారా సేంద్రీయ ఉత్పత్తులను ధృవీకరించడం
        </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>రైతుల మిల్లెట్ లీగ్</Card.Title>
        <Card.Text>
        మేము ఒక వినూత్నమైన కాన్సెప్ట్‌ను పరిచయం చేస్తున్నాము - "ఫార్మర్స్ మిల్లెట్ లీగ్." దరఖాస్తుల ఆధారంగా రైతులను వివిధ బృందాలుగా ఏర్పాటు చేసి, ఝా బృందం ద్వారా నిర్వహించి ప్రభుత్వ వ్యవసాయ నిపుణులచే రిఫరీ చేయడం ద్వారా. కాబట్టి మన వ్యవసాయ సమాజంలో ఆరోగ్యకరమైన పోటీని ప్రోత్సహించడం మరియు సహకారాన్ని పెంపొందించడం లక్ష్యంగా పెట్టుకోవచ్చు. ఈ లీగ్‌లు స్నేహాన్ని పెంపొందించడమే కాకుండా పాల్గొనేవారిలో ఐక్యతా భావాన్ని కలిగిస్తాయి. మేము మొదటి ముగ్గురు విజేతలకు నగదు ధరలను అందిస్తాము మరియు పాల్గొనే వారందరికీ సర్టిఫికెట్‌లను అందిస్తాము.
        </Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>విద్య మరియు అవగాహన</Card.Title>
        <Card.Text>
        లీగ్‌కు అతీతంగా, విద్య ప్రధానమైనది. మేము మిల్లెట్ సాగు యొక్క ప్రయోజనాలు, స్థిరమైన వ్యవసాయ పద్ధతులు మరియు మిల్లెట్ ఆధారిత ఆహారాల యొక్క పోషకాహార ఆధిక్యత గురించి అవగాహన కల్పించాలని విశ్వసిస్తున్నాము. ఈ కార్యక్రమంలో మరింత మంది రైతులు చురుకుగా పాల్గొనేలా ప్రేరేపించడమే మా లక్ష్యం.
 </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>మార్కెట్ యాక్సెస్</Card.Title>
        <Card.Text>
        మన రైతులకు న్యాయమైన మార్కెట్ యాక్సెస్‌ను నిర్ధారించడం మా విజయంలో కీలకమైన అంశం. మా రైతులు తమ మిల్లెట్ ఉత్పత్తులను సమానమైన ధరలకు విక్రయించగలరని నిర్ధారిస్తూ, బలమైన మార్కెట్ అనుసంధానాలను రూపొందించడానికి మేము కట్టుబడి ఉన్నాము.
        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>స్థిరత్వం</Card.Title>
        <Card.Text>
        మిల్లెట్ సాగు కోసం మా దీర్ఘకాలిక దృష్టిలో సాంప్రదాయ మిల్లెట్ రకాలను సంరక్షించడం, విత్తన పొదుపును ప్రోత్సహించడం మరియు సహజ వ్యవసాయ పద్ధతులను ప్రోత్సహించడం. సుస్థిరత కేవలం ఒక లక్ష్యం కాదు; అది ఒక జీవన విధానం.
 </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>విధాన మద్దతు</Card.Title>
        <Card.Text>
        మేము మార్పు కోసం న్యాయవాదులం. స్థానిక మరియు జాతీయ స్థాయిలలో సహాయక విధానాల కోసం మేము అవిశ్రాంతంగా కృషి చేస్తాము. ఇందులో మినుము సాగు మరియు వినియోగానికి అనుకూలంగా ఉండే ప్రోత్సాహకాలు, రాయితీలు మరియు విధాన సవరణలు ఉన్నాయి.</Card.Text>
      </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>యొక్క ముఖ్య యాజమాన్యం<span>రైతుల మిల్లెట్ లీగ్:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;