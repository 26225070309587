import React from "react";
import "./Member.css";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
export default function Farmersupporters(){

    return(
        <div>
            <div className="social-ma">

<h1>Zha Farmer Supporters</h1>
</div>
</div>

);
}