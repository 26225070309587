import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';

function VideoCarousel() {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const videos = [
    { url: '../img/video 1.mp4', playing: false },
    { url: '../img/video 2.mp4', playing: false },
    { url: '../img/video 3.mp4', playing: false },
    { url: '../img/video 4.mp4', playing: false },
  ];

  const handleVideoPlay = (index) => {
    // Pause the previous video
    if (currentVideoIndex !== index) {
      videoRefs[currentVideoIndex].current.seekTo(0);
      videoRefs[currentVideoIndex].current.pause();
    }

    // Set the current video index and update playing state
    setCurrentVideoIndex(index);
    videos[index].playing = true;
  };

  return (
    <div>
      <div className="carousel-container">
        {videos.map((video, index) => (
          <div key={index} className="carousel-item">
            <ReactPlayer
              ref={videoRefs[index]}
              url={video.url}
              playing={video.playing}
              width="100%"
              height="100%"
              onPlay={() => handleVideoPlay(index)}
              onPause={() => (videos[index].playing = false)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoCarousel;
