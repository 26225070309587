import react from "react";
import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';

export default function Ecofriendly() {
    return (
        <div>

                

<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Zha-Ecofriendly-Certification-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Zha-Ecofriendly-Certification-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item>         <a href=""><NavLink to="/Zha-Ecofriendly-Certification-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item>   <a href=""><NavLink to="/Zha-Ecofriendly-Certification-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item> <a href=""><NavLink to="/Zha-Ecofriendly-Certification-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
{/* 
<div  id="language" >
        
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification-ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification-ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification-hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification-te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Zha-Ecofriendly-Certification-ka">Kannada</NavLink></a>
        

        </div> */}

        
<h1 id="mhead">Zha Eco-Friendly Certification</h1>
   
   <div className='second-mcontent'>
           <img src='../../img/ecofriendly.webp'></img>
       </div>


 

<section className="visionmi">
 
	<div className="purpose">
	<h4>Vision</h4>
	<p>“Zha foundation for developing sustainability mindset in realestate business methods”
</p>
	</div>

<div className="missi">
  <h4>Concept of Zha foundation :
</h4>
<p> 
Builders incorporating sustainability infrastructures focus on eco-friendly construction methods, energy-efficient designs, and the use of sustainable materials to create environmentally responsible houses. These practices aim to reduce the ecological impact of construction and enhance the long-term sustainability of homes.

</p>

<h4>Zha value creation outcome :
</h4>
<p> 
Certifying sustainable real estate concepts involves obtaining certifications in the way of ensuring the construction Establishment is based on Environmental Assessment Method, which is done by zha assessors. Our ZHA foundation certifications acknowledge and verify that a real estate project adheres to specific eco-friendly and sustainable standards, promoting transparency and accountability in the construction and operation of buildings. So the builders can use our logo in their marketing or promoting aspects of the project.

</p>
 

</div>
<p>Eco-friendly building construction incorporates various features to minimize environmental impact and promote sustainability.Some key features include:
</p>
 
<ol className="goalpoints">

	
   
   <li><span>Energy Efficiency : </span> Integration of energy-efficient technologies, appliances, and lighting to reduce overall energy consumption.
</li>

<li><span>Renewable Energy Sources : </span>  Incorporation of renewable energy systems such as solar panels, wind turbines, or geothermal systems to generate clean energy on-site.
</li>
<li><span>Sustainable Materials : </span> Use of eco-friendly and recycled materials to reduce resource depletion and minimize waste generation.
</li>
<li><span>Water Conservation : </span> Implementation of water-saving fixtures, rainwater harvesting systems, and efficient irrigation practices to reduce water consumption.
</li>
<li><span>Green Roof and Walls : </span> Installation of green roofs and walls for improved insulation, reduced heat absorption, and enhanced biodiversity.
</li>
<li><span>Natural Ventilation : </span>Designing for natural ventilation to reduce the need for mechanical cooling systems and enhance indoor air quality.
</li>
<li><span>Waste Reduction and Recycling : </span> Strategies to minimize construction waste through recycling and responsible disposal practices.
</li>
<li><span>Low-impact Site Development : </span> Preservation of natural landscapes, responsible stormwater management, and minimizing disruption to local ecosystems during construction.

</li>
<li><span>Smart Building Systems : </span> Integration of smart technologies for energy management, efficient lighting, and temperature control to optimize resource use.
</li>
<li><span>Acknowledgement : </span> Seeking acknowledgment from an organization like Zha foundation etc that officially acknowledges the builders association that are having the plan with sustainability strategies for their the building.
</li>

</ol>



<p>These features collectively contribute to creating environmentally responsible buildings that aim to reduce their ecological footprint throughout their lifecycle. </p>


</section>
 

</div>
 
 
);
}

