import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Summit.css';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
             {/* <div  id="language" >
        
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ta">தமிழ்</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ma">Malayalam</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Hi">Hindi</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Te">Telugu</NavLink></a>
        <a href=""><NavLink to="/Why-Zha-360-Sustainability-Ka">Kannada</NavLink></a>
        </div> */}

            <div className="social-ma">

<h1>Profitable for producers & consumers  
</h1>


<div className="internship">
        <img src="../img/profit.webp"></img>
        </div>
     


     
 

        <p>Sustainability is not about efficiencies but it’s about effective new solutions and risk reduction. If we should overcome the climate struggle, we must bring in new innovative gamified solutions to help change the way we live, produce and work. These new solutions are excellent investment opportunities that will bring in upgraded industrial growth engines of the future

For instance, ZHA has conducted the pilot version of zha organic farmers league

</p>
<p>
To make India a global organic agricultural hub and facilitate farmers in adopting organic farming, a multi-faceted approach is essential. This could include:</p>
     


<ol className="goalpoints">

<li><span>Educational Programs : </span> Implement comprehensive training programs to educate farmers about organic farming practices, emphasizing the benefits for the environment and long-term sustainability.
</li>

<li><span>Research and Development : </span>Invest in research to develop region-specific organic farming techniques, crop varieties, and pest management strategies that suit Indian agro-climatic conditions.
</li>
<li><span>Government Support : </span>Provide financial incentives, subsidies, and support services to encourage farmers to transition to organic farming. This could include assistance in obtaining organic certifications.
</li>
<li><span>Market Access : </span>Establish and expand markets for organic produce, both domestically and internationally. Certify organic products to meet global standards, ensuring higher returns for farmers.
</li>
<li><span>Infrastructure Development : </span>Improve infrastructure for organic farming, including storage facilities, transportation networks, and processing units, to reduce post-harvest losses and ensure product quality.
</li>
<li><span>Community Engagement : </span> Foster community support for organic farming by organizing awareness campaigns, involving local communities in decision-making, and creating farmer cooperatives for collective benefits.
</li>
<li><span>Financial Inclusion : </span>Ensure that financial institutions offer accessible credit facilities to support the initial investment required for farmers transitioning to organic practices.
</li>
<li><span>Technology Integration : </span>Integrate modern technologies such as precision agriculture and data analytics to optimize organic farming practices, enhancing productivity and resource efficiency.
</li>
<li><span>Policy Framework : </span>Develop and enforce supportive policies that promote organic farming, discourage harmful chemical inputs, and encourage sustainable agricultural practices.
</li>
<li><span>Collaboration with International Organizations : </span>Collaborate with global organizations and experts to share knowledge, best practices, and market insights, helping India align with international organic standards.

</li>

</ol>

<p>By combining these strategies, India can progress towards becoming a global organic agricultural hub while empowering farmers to adopt sustainable and profitable organic farming practices.
</p>

<p>Hence our Zha as an non profit organisation wants to discover new and more effective ways to practice sustainability for protecting the soil, air etc. With this model we want to partner, learn and share a crucial way of keeping up with the developments through CSR investments from various organisations</p>
       
       
       
















 

</div>
</div>

);
}

