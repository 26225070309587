import Carousel from 'react-bootstrap/Carousel';
import './Carousal.css';
import './Carousalcopy.css';

import './Mycaro.css';
import React from 'react';

function IndividualIntervalsExample() {
  return (
    <div className='carousellll'>
    
    <Carousel>
      
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/1.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/2.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/3.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/4.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/5.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/6.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/7.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/8.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/9.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/10.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/11.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/12.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/13.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/14.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/15.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/16.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/17.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/18.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/19.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/20.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/21.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>  </p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/22.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/23.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/24.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/25.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/26.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/27.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/28.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/29.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/31.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img src='../../img/ORGANIC/32.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
          <h3>  </h3>
          <p>Seed sowing ceremony</p>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    
     
    </Carousel>

    </div>
  );
}

export default IndividualIntervalsExample;