import react from "react";
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from "react-router-dom";
import './Health.css';
import '../Home/Home.css';


export default function Health() {
    return (
        <div>
            <div className="hmain">


             
<Dropdown id="language">
      <Dropdown.Toggle id="droplang">
      <i class="fa-solid fa-globe"></i> Languages
      </Dropdown.Toggle>

      <Dropdown.Menu className="droplangslide">
        <Dropdown.Item><a href=""><NavLink to="/Environment-ta">தமிழ்</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Environment-ma">Malayalam</NavLink></a> </Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Environment-hi">Hindi</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Environment-te">Telugu</NavLink></a></Dropdown.Item>
        <Dropdown.Item><a href=""><NavLink to="/Environment-ka">Kannada</NavLink></a></Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

                <h1 id="mhead">Zha Foundation: Nurturing a Greener Tomorrow</h1>

                <div className='second-mcontent'>
                    <img src='../../img/environment.webp'></img>
                </div>


            </div>


            <section className="visionmi">

                <div className="purpose">       

                    <p>Welcome to Zha Foundation, where we are passionate about safeguarding the environment for current and future generations. Our commitment to environmental conservation is driven by the belief that each individual and community plays a crucial role in preserving the planet we call home.
                    </p>
                </div>

                <h3>Our Vision</h3>
                <div className="missi">
                    <h4 className="toleftneed">Sustainable Future:</h4>
                    <p>At Zha Foundation, we envision a world where humanity coexists harmoniously with nature. Our vision is rooted in sustainability, where ecosystems thrive, biodiversity is protected, and every action contributes to a healthier planet.</p>


                </div>


                <h3>Our Mission</h3>
                <div className="missi">
                    <h4 className="toleftneed">Empowering Change:</h4>
                    <p>Our mission is to empower individuals and communities to become stewards of the environment. Through education, advocacy, and hands-on initiatives, we aim to foster a collective commitment to responsible and sustainable practices.</p>
 
                </div>

                <h3>Our Initiatives</h3>
                <div className="missi">
                    <h4 className="toleftneed">Conservation and Restoration:</h4>
                    <p>Zha Foundation is actively involved in conservation and restoration projects. From reforestation efforts to protecting endangered species, our initiatives focus on preserving and restoring the natural habitats that sustain life on Earth.</p>

                    <h4 className="toleftneed">Waste Reduction Programs:</h4>
                    <p>We believe in the importance of reducing our ecological footprint. Our waste reduction programs promote responsible waste management, recycling, and upcycling to minimize the impact of human activities on the environment.</p>

                    <h4 className="toleftneed">Green Energy Advocacy:</h4>
                    <p>Transitioning to renewable energy is a key aspect of mitigating climate change. Zha Foundation advocates for and supports the adoption of green energy solutions, contributing to a more sustainable and cleaner energy landscape.</p>
                </div>


                <h3>Our Approach</h3>
                <div className="missi">
                    <h4 className="toleftneed">Education and Awareness:</h4>
                    <p>Knowledge is a powerful catalyst for change. We invest in educational programs that raise awareness about environmental issues, climate change, and sustainable living. By empowering individuals with information, we believe we can inspire meaningful action.</p>

                    <h4 className="toleftneed">Community Engagement:</h4>
                    <p>Environmental conservation is a collective effort. We engage with local communities, encouraging active participation in environmental initiatives. Through community-led projects and events, we aim to build a network of environmental champions.</p>

                     
                </div>

            </section>
        </div>


    );
}

