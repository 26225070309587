import React from "react";
import { NavLink } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import "./Paddy.css";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function Millet() {
    return(

        <div>
                <div  id="language" >
        
                <a href=""><NavLink to="/zhafarmerspaddyleague">English</NavLink></a>



        </div>
            <div className="social-main">

            <h1>Zha Farmers Paddy League</h1>
            </div>
           <section className="intro">
            <div className="paddyhero">
            <img src="../img/asian.jpg"></img>
            </div>
            <div className="titletext">
              <p> पेरावुरानी तालुक में किसानों के बीच किसान धान लीग आयोजित करने की झा फाउंडेशन की पहल एक सराहनीय प्रयास है। धान, जिसे चावल के नाम से भी जाना जाता है, भारत में अत्यधिक सांस्कृतिक और पोषण संबंधी महत्व वाली एक प्रमुख फसल है। हालाँकि, हाल के वर्षों में, इसकी खेती को चुनौतियों का सामना करना पड़ा है, जिससे पारंपरिक ज्ञान और इससे मिलने वाले पोषण संबंधी लाभों का नुकसान हुआ है। फार्मर्स पैडी लीग दुनिया भर के किसानों, उत्साही लोगों और नवप्रवर्तकों को एक साथ लाकर कृषि की दुनिया में क्रांति लाने के लिए पूरी तरह तैयार है। जिस तरह आईपीएल ने क्रिकेट का चेहरा बदल दिया, उसी तरह झा फाउंडेशन की किसान धान लीग का लक्ष्य रोमांचक टूर्नामेंट और अत्याधुनिक तकनीकों के साथ धान की खेती को एक नए स्तर पर ले जाना है।</p>



<section className="part2">
    <div className="leftside">
    <img src="../img/registr.jpg"></img>
   <a href="https://forms.gle/kC8byZNt8dFH4Z1e6"> <Button variant="outline-success">अभी पंजीकरण करें</Button>{' '}</a>

    </div>

    <div>
    <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfkUnlmPbv846wVQGKAD41SfB_JrRIsIO3DwkMcK0Wpcpvpdw/viewform?embedded=true" width="640" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe></div>
  </section>

  <section className="organics">

    <div className="orgcon">
      <div className="orgtext">  <h3>अजैविक धान की खेती के प्रभाव</h3>
      <div className="side"></div>
      </div>
      <div className="orgflex">

      
           <ul>
        <h5>1.पर्यावरणीय प्रभाव:</h5>
        <li>गैर-जैविक धान की खेती अक्सर कीटों और खरपतवारों को नियंत्रित करने के लिए सिंथेटिक कीटनाशकों और शाकनाशियों पर बहुत अधिक निर्भर करती है। ये रसायन मिट्टी, पानी और हवा को दूषित करके पर्यावरण पर हानिकारक प्रभाव डाल सकते हैं। सिंथेटिक उर्वरकों के उपयोग से मिट्टी का क्षरण, पोषक तत्वों का असंतुलन और समय के साथ मिट्टी की उर्वरता में कमी हो सकती है।</li>

        <h5>2.आर्थिक प्रभाव:</h5>
        <li>गैर-जैविक खेती के लिए अक्सर सिंथेटिक उर्वरकों, कीटनाशकों और शाकनाशियों के लिए उच्च इनपुट लागत की आवश्यकता होती है, जो किसानों के लिए वित्तीय बोझ हो सकता है।</li>

        <h5>3.स्वास्थ्य पर प्रभाव:</h5>
        <li>किसान और खेत मजदूर कीटनाशकों और शाकनाशियों के रासायनिक अवशेषों के संपर्क में आ सकते हैं, जिससे संभावित रूप से स्वास्थ्य संबंधी समस्याएं हो सकती हैं।</li>

        <h5>4.कार्बन पदचिह्न: </h5>
        <li>गैर-जैविक कृषि पद्धतियाँ, विशेष रूप से सिंथेटिक उर्वरकों के उपयोग से जुड़ी पद्धतियाँ, ग्रीनहाउस गैस उत्सर्जन में योगदान करती हैं, जो जलवायु परिवर्तन में योगदान करती हैं।</li>

        <h5>5.दीर्घकालिक स्थिरता: </h5>
        <li>मिट्टी के क्षरण, जैव विविधता में कमी और रसायनों के प्रति बढ़ते कीट प्रतिरोध के कारण गैर-जैविक धान की खेती के तरीके लंबे समय तक टिकाऊ नहीं हो सकते हैं।
        </li>
        </ul>

      
       
    
    <img src="../img/ori2.jpg"></img>
    </div>
    </div>
  </section>




<div className="freepik">
<h5 className="cardtitle">यहां इससे संबंधित प्रमुख बिंदुओं का अवलोकन दिया गया है<span> किसान धान
संघ </span>पहल:</h5>

<div className="cards">
   

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>हमारा विशेष कार्य</Card.Title>
        <Card.Text>
        हमारी पहल के केंद्र में पूरे भारत में पेरावुरानी तालुक और उससे आगे के किसानों के बीच धान की खेती को बढ़ावा देना और पुनर्जीवित करना है। धान, जिसे अक्सर "पोषक अनाज" कहा जाता है, अपने असाधारण पोषण मूल्य और पर्यावरण-अनुकूल कृषि पद्धतियों के लिए प्रसिद्ध है, जो इसे टिकाऊ कृषि की आधारशिला बनाता है। </Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>झा फाउंडेशन की प्रतिबद्धता</Card.Title>
        <Card.Text>
        हम अपने समर्पित किसानों के बीच धान की खेती को शुरू करने और समर्थन देने में महत्वपूर्ण भूमिका निभाते हैं। हमारे समर्थन में किसानों को उनकी कृषि पद्धतियों में धान की खेती को निर्बाध रूप से एकीकृत करने में मदद करने के लिए आवश्यक संसाधन, व्यापक प्रशिक्षण और तकनीकी सहायता प्रदान करना शामिल है।

        </Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>जैविक धान की खेती वापस लाएँ</Card.Title>
        <Card.Text>
        हमारी पहल का मुख्य उद्देश्य भारतीय राज्य सरकार के साथ मिलकर जैविक धान की खेती को प्रोत्साहित करना और युवाओं को खेती में अंशकालिक कार्यों के लिए जोड़ना है। झा किसानों और उपभोक्ताओं के कॉल सेंटर के माध्यम से हमारे किसानों के उत्पादों को वैश्विक बाजार में पहुंचाने के लिए डिजिटल रणनीतियों के माध्यम से एक आपूर्ति श्रृंखला बनाएं। फायदे कई गुना हैं: खाद्य सुरक्षा में सुधार, मिट्टी के स्वास्थ्य में वृद्धि, और जल-गहन जैविक फसलों की तुलना में पानी की खपत को काफी कम करना और ZHA फाउंडेशन के माध्यम से जैविक उत्पादों को प्रमाणित करना।</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>किसान धान लीग</Card.Title>
        <Card.Text>
        हम एक अभिनव अवधारणा - "किसान धान लीग" पेश कर रहे हैं। किसानों को अनुप्रयोगों के आधार पर विभिन्न टीमों में संगठित करके, और इसे झा टीम के माध्यम से संगठित करके और सरकारी कृषि विशेषज्ञों द्वारा रेफरी करके। इसलिए हम अपने कृषक समुदाय के बीच स्वस्थ प्रतिस्पर्धा को प्रोत्साहित करने और सहयोग को बढ़ावा देने का लक्ष्य रख सकते हैं। ये लीग न केवल सौहार्द को बढ़ावा देती हैं बल्कि प्रतिभागियों के बीच एकता की भावना भी पैदा करती हैं। हम पहले तीन विजेताओं को नकद मूल्य और सभी प्रतिभागियों को प्रमाणपत्र प्रदान करते हैं।</Card.Text>
      </Card.Body>
    </Card>

</div>

</div>
<section>
<div className="cards2">

<Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>शिक्षा और जागरूकता</Card.Title>
        <Card.Text>
        लीग से परे, शिक्षा सर्वोपरि है। हम धान की खेती के लाभों, टिकाऊ कृषि पद्धतियों और धान-आधारित आहार की पोषण संबंधी श्रेष्ठता के बारे में जागरूकता फैलाने में विश्वास करते हैं। हमारा मिशन अधिक से अधिक किसानों को इस पहल में सक्रिय रूप से भाग लेने के लिए प्रेरित करना है।</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>बाज़ार पहूंच</Card.Title>
        <Card.Text>
        हमारे किसानों के लिए उचित बाजार पहुंच सुनिश्चित करना हमारी सफलता का एक महत्वपूर्ण कारक है। हम मजबूत बाजार संपर्क बनाने के लिए प्रतिबद्ध हैं, यह सुनिश्चित करते हुए कि हमारे किसान अपनी धान की उपज उचित मूल्य पर बेच सकें।</Card.Text>
      </Card.Body>
    </Card>


    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>वहनीयता</Card.Title>
        <Card.Text>
        धान की खेती के लिए हमारी दीर्घकालिक दृष्टि में पारंपरिक धान की किस्मों को संरक्षित करना, बीज बचत को प्रोत्साहित करना और प्राकृतिक कृषि पद्धतियों को बढ़ावा देना शामिल है। स्थिरता सिर्फ एक लक्ष्य नहीं है; यह भी जीने का एक तरीका है।</Card.Text>
      </Card.Body>
    </Card>

    <Card style={{ width: '18rem' }}>
      <Card.Body>
        <Card.Title>नीति समर्थन</Card.Title>
        <Card.Text>
        हम बदलाव के समर्थक हैं। हम स्थानीय और राष्ट्रीय दोनों स्तरों पर सहायक नीतियों को आगे बढ़ाने के लिए अथक प्रयास करते हैं। इसमें प्रोत्साहन, सब्सिडी और नीति समायोजन शामिल हैं जो धान की खेती और खपत को बढ़ावा देते हैं।</Card.Text> </Card.Body>
    </Card>
    </div>
    </section>






            </div>
            <div className="teams">
                <h5>का प्रमुख स्वामित्व <span>किसान धान लीग:</span>
</h5>
              <div className="deskteam">
                <img src="../img/finalteam.png"></img>
            </div>
            <div className="mobteam">
                <img src="../img/mobteam.png"></img>
            </div>
            </div>

           </section>
        </div>
    
    );
}
export default Millet;