import Carousel from 'react-bootstrap/Carousel';
 
import React from 'react';

function IndividualIntervalsExample() {
  return (
    <div className='carouselll'>

 

    
    <Carousel>
      
      <Carousel.Item interval={20000}>
      <h3>Nellore Tower  </h3>
        <img src='../../img/nelloretower.webp' text="Second slide" />
        <Carousel.Caption>
            <div className='description'>
           
          </div>
        </Carousel.Caption>
      </Carousel.Item>
       
    </Carousel>

    </div>
  );
}

export default IndividualIntervalsExample;