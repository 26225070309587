import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './Csr.css';
import Pptx from './Pptx';
import { NavLink } from "react-router-dom";
export default function Transformation(){

    return(
        <div>
            <div  id="language" >
        
        <a href=""><NavLink to="/How-Zha-Use-CSR-Funds">English</NavLink></a>

        </div>

            <div className="social-ma">

<h1>Zha എങ്ങനെയാണ് CSR ഫണ്ടുകൾ ഉപയോഗിക്കുന്നത്
</h1>

<Pptx />
 
 


 

<p>Zha ഫൗണ്ടേഷന് കോർപ്പറേറ്റ് സോഷ്യൽ റെസ്‌പോൺസിബിലിറ്റി (CSR) ഫണ്ടുകളെ താഴെപ്പറയുന്ന തൂണുകളുമായി വിന്യസിച്ചുകൊണ്ട് അതിന്റെ സുസ്ഥിര സംരംഭങ്ങളിൽ തന്ത്രപരമായി നിക്ഷേപിക്കാൻ കഴിയും:
</p>

 

<ol className="goalpoints">

<li><span>മനുഷ്യ ആരോഗ്യ സ്തംഭം : </span>  
<ul>
    <li>ആരോഗ്യ സംരക്ഷണ പരിപാടികൾ, മാനസികാരോഗ്യ അവബോധം, മൊത്തത്തിലുള്ള ക്ഷേമം പ്രോത്സാഹിപ്പിക്കുന്ന സംരംഭങ്ങൾ എന്നിവയ്ക്കായി ഫണ്ട് അനുവദിക്കുക.


</li>
    <li> പോഷകാഹാരവും പ്രതിരോധ ആരോഗ്യ സംരക്ഷണ നടപടികളും ഉൾപ്പെടെ ആരോഗ്യത്തിലും ക്ഷേമത്തിലും ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്ന വിദ്യാഭ്യാസ പദ്ധതികളിൽ നിക്ഷേപിക്കുക.


</li>
</ul>
</li> 

<li><span>പ്രകൃതി ക്ഷേമ സ്തംഭം : </span>  
<ul>
    <li>ജൈവവൈവിധ്യ സംരക്ഷണം, വനനശീകരണം, പ്രകൃതിദത്ത ആവാസ വ്യവസ്ഥകളുടെ സംരക്ഷണം എന്നിവ പ്രോത്സാഹിപ്പിക്കുന്ന ഫണ്ട് പ്രോജക്ടുകൾ.



</li>
    <li>പരിസ്ഥിതി സൗഹൃദ രീതികളിലും ആവാസവ്യവസ്ഥയുടെ ആരോഗ്യം വർദ്ധിപ്പിക്കുന്ന സുസ്ഥിര കാർഷിക സംരംഭങ്ങളിലും നിക്ഷേപിക്കുക.




</li>
</ul>
</li> 

<li><span>എർത്ത് വെൽനസ് പില്ലർ : </span>  
<ul>
    <li>പുനരുപയോഗ ഊർജ സംരംഭങ്ങളും കാർബൺ ഓഫ്‌സെറ്റ് പ്രോഗ്രാമുകളും പോലുള്ള കാലാവസ്ഥാ വ്യതിയാന ലഘൂകരണവും പൊരുത്തപ്പെടുത്തലും അഭിസംബോധന ചെയ്യുന്ന പ്രോജക്ടുകളെ പിന്തുണയ്ക്കുക.

</li>
    <li>ജലസംരക്ഷണത്തിലും ശുചിത്വ പദ്ധതികളിലും നിക്ഷേപിക്കുക, ഗ്രഹത്തിന്റെ സുപ്രധാന വിഭവങ്ങളുടെ ആരോഗ്യത്തിന് സംഭാവന ചെയ്യുക.



</li>
</ul>
</li> 

<li><span>സ്ഥിരമായ ലാഭ സ്തംഭം : </span>  
<ul>
    <li>സുസ്ഥിരമായ ബിസിനസ്സ് രീതികൾ പാലിച്ചുകൊണ്ട് സ്ഥിരമായ ലാഭം സൃഷ്ടിക്കുന്ന സാമൂഹിക സംരംഭങ്ങൾ സ്ഥാപിക്കുകയോ പിന്തുണയ്ക്കുകയോ ചെയ്യുക.




</li>
    <li> ZHA ഫൗണ്ടേഷന്റെ ദൗത്യവുമായി യോജിപ്പിക്കുന്ന വരുമാനം സൃഷ്ടിക്കുന്ന പ്രോജക്റ്റുകളിൽ നിക്ഷേപിക്കുക, ദീർഘകാലാടിസ്ഥാനത്തിലുള്ള സ്വാധീനത്തിനായി ഒരു സ്വയം-സുസ്ഥിര മാതൃക സൃഷ്ടിക്കുക.



</li>
</ul>
</li> 

</ol>

<p>
ZHA ഫൗണ്ടേഷന്റെ CSR നിക്ഷേപ തന്ത്രത്തിലേക്ക് ഈ സ്തംഭങ്ങളെ സമന്വയിപ്പിക്കുന്നതിലൂടെ, മനുഷ്യ ആരോഗ്യം, പ്രകൃതി സംരക്ഷണം, ഭൂമിയുടെ ക്ഷേമം, സാമ്പത്തിക സുസ്ഥിരത എന്നിവയെ അഭിസംബോധന ചെയ്യുന്ന ഒരു സമഗ്ര സമീപനം സ്ഥാപനത്തിന് സൃഷ്ടിക്കാൻ കഴിയും. ഈ ബഹുമുഖ തന്ത്രം കമ്മ്യൂണിറ്റികളിലും പരിസ്ഥിതിയിലും സന്തുലിതവും സമഗ്രവുമായ സ്വാധീനം ഉറപ്പാക്കുന്നു, മെച്ചപ്പെട്ട ഭാവിക്കായി സുസ്ഥിരമായ ഒരു മാനസികാവസ്ഥ വളർത്തിയെടുക്കുക എന്ന ഫൗണ്ടേഷന്റെ ദൗത്യവുമായി യോജിപ്പിക്കുന്നു.</p>
 

 

 <br></br>

 <p>ZHA ഫൗണ്ടേഷന് താഴെ പറയുന്ന സമീപനങ്ങളിലൂടെ സുസ്ഥിരത മുൻകൈയെടുക്കാൻ കോർപ്പറേറ്റ് സോഷ്യൽ റെസ്‌പോൺസിബിലിറ്റി (CSR) ഫണ്ടുകൾ തന്ത്രപരമായി ഉപയോഗിക്കാനും കഴിയും:

</p>

 

<ol className="goalpoints">

<li><span>പരിസ്ഥിതി സംരക്ഷണ പദ്ധതികൾ :</span>  
<ul>
    <li>ആരോഗ്യ സംരക്ഷണ പരിപാടികൾ, മാനസികാരോഗ്യ അവബോധം, മൊത്തത്തിലുള്ള ക്ഷേമം പ്രോത്സാഹിപ്പിക്കുന്ന സംരംഭങ്ങൾ എന്നിവയ്ക്കായി ഫണ്ട് അനുവദിക്കുക.


</li>
 
</ul>
</li> 

<li><span>ഗ്രീൻ ഇൻഫ്രാസ്ട്രക്ചർ വികസനം : </span>  
<ul>
    <li>പരിസ്ഥിതി സൗഹൃദ കെട്ടിടങ്ങൾ, ഊർജ്ജ-കാര്യക്ഷമമായ സാങ്കേതിക വിദ്യകൾ, അല്ലെങ്കിൽ ഹരിത ഗതാഗതം തുടങ്ങിയ സുസ്ഥിര അടിസ്ഥാന സൗകര്യ വികസനത്തിന് ഫണ്ട് അനുവദിക്കുക.




</li>
    
</ul>
</li> 

<li><span>കമ്മ്യൂണിറ്റി അടിസ്ഥാനത്തിലുള്ള സുസ്ഥിര കൃഷി : </span>  
<ul>
    <li>ജൈവകൃഷി, പെർമാകൾച്ചർ അല്ലെങ്കിൽ കമ്മ്യൂണിറ്റി ഗാർഡനുകൾ എന്നിവയിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുന്ന സുസ്ഥിര കാർഷിക പദ്ധതികളിലൂടെ പ്രാദേശിക കമ്മ്യൂണിറ്റികളെ പിന്തുണയ്ക്കുക.


</li>
     
</ul>
</li> 

<li><span>വൃത്താകൃതിയിലുള്ള സാമ്പത്തിക സംരംഭങ്ങൾ : </span>  
<ul>
    <li>മാലിന്യങ്ങൾ കുറച്ചുകൊണ്ടും വസ്തുക്കളുടെ പുനരുപയോഗം, അപ്സൈക്ലിംഗ് അല്ലെങ്കിൽ പുനർനിർമ്മാണം എന്നിവ പ്രോത്സാഹിപ്പിക്കുന്നതിലൂടെയും വൃത്താകൃതിയിലുള്ള സമ്പദ്‌വ്യവസ്ഥയെ പ്രോത്സാഹിപ്പിക്കുന്ന പ്രോജക്റ്റുകളിൽ നിക്ഷേപിക്കുക.




</li>
    
</ul>
</li> 


<li><span>ജൈവവൈവിധ്യ സംരക്ഷണം : </span>  
<ul>
    <li>പ്രകൃതിദത്ത ആവാസ വ്യവസ്ഥകൾ സംരക്ഷിക്കുന്നതിനും, വംശനാശഭീഷണി നേരിടുന്ന ജീവികളെ സംരക്ഷിക്കുന്നതിനും, സുസ്ഥിരമായ ആവാസവ്യവസ്ഥ മാനേജ്മെന്റ് പ്രോത്സാഹിപ്പിക്കുന്നതിനുമുള്ള സംരംഭങ്ങളെ പിന്തുണച്ച് ജൈവവൈവിധ്യം സംരക്ഷിക്കുന്നതിനും പുനഃസ്ഥാപിക്കുന്നതിനും CSR ഫണ്ടുകൾ ഉപയോഗിക്കുക.



</li>
 
</ul>
</li> 

<li><span>ജല-ഊർജ്ജ സംരക്ഷണം : </span>  
<ul>
    <li>ജല-ഊർജ്ജ സംരക്ഷണ പദ്ധതികളിലേക്ക് ചാനൽ ഫണ്ടുകൾ, ഉപഭോഗം കുറയ്ക്കുകയും കാര്യക്ഷമമായ വിഭവ ഉപയോഗം പ്രോത്സാഹിപ്പിക്കുകയും ചെയ്യുന്ന സാങ്കേതികവിദ്യകളും സമ്പ്രദായങ്ങളും നടപ്പിലാക്കുന്നു.





</li>
    
</ul>
</li> 

<li><span>വിദ്യാഭ്യാസവും ബോധവൽക്കരണ പരിപാടികളും : </span>  
<ul>
    <li>സുസ്ഥിര പ്രശ്‌നങ്ങളെക്കുറിച്ച് അവബോധം വളർത്തുന്ന, പരിസ്ഥിതി സൗഹൃദ സമ്പ്രദായങ്ങൾ പ്രോത്സാഹിപ്പിക്കുന്ന, സുസ്ഥിരമായ ജീവിതശൈലി സ്വീകരിക്കാൻ കമ്മ്യൂണിറ്റികളെ ശാക്തീകരിക്കുന്ന വിദ്യാഭ്യാസ സംരംഭങ്ങളിൽ നിക്ഷേപിക്കുക.




</li>
     
</ul>
</li> 

<li><span>കാലാവസ്ഥാ വ്യതിയാന ലഘൂകരണം : </span>  
<ul>
    <li>കാർബൺ ഓഫ്‌സെറ്റ് പ്രോഗ്രാമുകൾ, സുസ്ഥിര ഗതാഗത പരിഹാരങ്ങൾ, അല്ലെങ്കിൽ ഹരിതഗൃഹ വാതക ഉദ്‌വമനം കുറയ്ക്കാൻ ലക്ഷ്യമിട്ടുള്ള സംരംഭങ്ങൾ എന്നിവ പോലുള്ള കാലാവസ്ഥാ വ്യതിയാന ലഘൂകരണത്തിന് സംഭാവന നൽകുന്ന പ്രോജക്ടുകളെ പിന്തുണയ്ക്കുക.
</li>
    
</ul>
</li> 



<li><span>പരിസ്ഥിതി കേന്ദ്രീകരിച്ചുള്ള സാമൂഹിക സംരംഭങ്ങൾ : </span>  
<ul>
    <li>പാരിസ്ഥിതിക ഉത്തരവാദിത്തം പ്രോത്സാഹിപ്പിക്കുന്നതിനിടയിൽ സാമ്പത്തിക വികസനം പ്രോത്സാഹിപ്പിക്കുന്ന, അവരുടെ ബിസിനസ്സ് മോഡലുകളിൽ സുസ്ഥിരതയെ സമന്വയിപ്പിക്കുന്ന സാമൂഹിക സംരംഭങ്ങളിൽ നിക്ഷേപിക്കുക.




</li>
 
</ul>
</li> 

<li><span>സുസ്ഥിരതയ്ക്കുള്ള ഗവേഷണവും നവീകരണവും : </span>  
<ul>
    <li>സുസ്ഥിരതാ വെല്ലുവിളികൾക്കായി നൂതനമായ പരിഹാരങ്ങൾ പര്യവേക്ഷണം ചെയ്യുന്ന ഗവേഷണ സംരംഭങ്ങൾക്ക് ഫണ്ട് അനുവദിക്കുക, പുതിയ സാങ്കേതികവിദ്യകളുടെയോ സമ്പ്രദായങ്ങളുടെയോ വികസനം പ്രോത്സാഹിപ്പിക്കുക.







</li>
    
</ul>
</li> 

<li><span>സുസ്ഥിര സമ്പ്രദായങ്ങൾക്കുള്ള ശേഷി വർദ്ധിപ്പിക്കൽ : </span>  
<ul>
    <li>സുസ്ഥിരമായ രീതികൾ സ്വീകരിക്കുന്നതിലും, ദീർഘകാല സ്വാധീനത്തിനായി പരിശീലനവും വിഭവങ്ങളും നൽകുന്നതിലും കമ്മ്യൂണിറ്റികളുടെയും ഓർഗനൈസേഷനുകളുടെയും കഴിവുകൾ വർദ്ധിപ്പിക്കുന്നതിന് CSR ഫണ്ടുകൾ ഉപയോഗിക്കുക.





</li>
     
</ul>
</li> 

<li><span>സുസ്ഥിരതയുടെ ആഘാതം അളക്കുകയും റിപ്പോർട്ടുചെയ്യുകയും ചെയ്യുക : </span>  
<ul>
    <li>സുസ്ഥിര സംരംഭങ്ങളുടെ ആഘാതം അളക്കുന്നതിനും റിപ്പോർട്ടുചെയ്യുന്നതിനും, പങ്കാളികൾക്ക് സുതാര്യതയും ഉത്തരവാദിത്തവും ഉറപ്പാക്കുന്നതിന് ശക്തമായ സംവിധാനങ്ങളിൽ നിക്ഷേപിക്കുക.

</li>
    
</ul>
</li> 
</ol>

<p>

സുസ്ഥിര സംരംഭങ്ങളുമായി CSR ഫണ്ടുകളെ വിന്യസിക്കുന്നതിലൂടെ, ലാഭേച്ഛയില്ലാത്ത ഓർഗനൈസേഷനുകൾക്ക് പരിസ്ഥിതി വെല്ലുവിളികളെ അഭിസംബോധന ചെയ്യുന്നതിനും പ്രതിരോധശേഷി വളർത്തുന്നതിനും സമൂഹങ്ങൾക്കും ഭൂമിക്കും കൂടുതൽ സുസ്ഥിരമായ ഭാവിയിലേക്ക് സംഭാവന നൽകുന്നതിനും നിർണായക പങ്ക് വഹിക്കാനാകും.</p>
       
       
       
       
        </div>


        </div>




 
        


    );
}

